import { bufferCount, map, Subject, distinctUntilChanged } from 'rxjs';

let isDisabled = false;

const preventDefault = e => {
  if (e.preventDefault) {
    e.preventDefault();
  }
  e.returnValue = false;
};

export const enableBodyScroll = () => {
  isDisabled = false;
  document.removeEventListener("wheel", preventDefault, false);
};

export const disableBodyScroll = () => {
  isDisabled = true;
  document.addEventListener("wheel", preventDefault, { passive: false });
};

const switchBodyScroll = flag => {
  if (flag) {
    !isDisabled && disableBodyScroll();
    return;
  }
  isDisabled && enableBodyScroll();
};

const raw = new Subject();
const processed = raw.pipe(
  bufferCount(2, 2),
  map(([v1, v2]) => (
    (!!v1 ? Array.from(v1).findIndex(c => c === 'alltrip-section-list-container') >= 0 : !!v1) ||
    (!!v2 ? Array.from(v2).findIndex(c => c === 'alltrip-section-list-container') >= 0 : !!v2)
  )),
  distinctUntilChanged((p, c) => p === c)
);

export const pushClassList = classList => {
  raw.next(classList);
};

processed.subscribe(switchBodyScroll);

import { createTheme } from '@mui/material/styles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@style/tw.conf';
import { koKR } from '@mui/material/locale';
import { koKR as DateKoKR } from '@mui/x-date-pickers';


const {
  theme: {
    colors,
    fontFamily,
    height,
    fontSize,
  },
} = resolveConfig(tailwindConfig);

const muiTheme = createTheme({
  typography: {
    fontFamily: fontFamily['sans'].join(','),
  },
  palette: {
    primary: {
      main: '#1E1E1E',
      light: '#1E1E1E',
      dark: '#1E1E1E',
      contrastText: colors['white']['100'],
    },
    secondary: {
      main: colors['hot-pink']['500'],
      light: colors['hot-pink']['100']['100'],
      dark: colors['hot-pink']['700'],
      contrastText: colors['white']['100'],
    },
    success: {
      main: colors['sky-blue']['400'],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          height: height['major-button'],
        },
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        sizeLarge: {
          height: height['major-button'],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          minHeight: height['input-sm'],
          fontSize: fontSize['sub-link'][0],
          boxSizing: 'border-box',
        },
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        }
      }
    },
  },
}, koKR, DateKoKR);

export default muiTheme;

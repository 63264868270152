import React, { useLayoutEffect, useState } from 'react';
import { Button } from '@mui/material';

function InputToggleButton({
  toggleCallback,
  subscribeCaptionSource,
  getCaptionSource,
  formatCaption = v => v,
  initialIsOn=false,
}) {
  const [isOn, setIsOn] = useState(initialIsOn);
  const [captionSource, setCaptionSource] = useState(getCaptionSource());
  const toggle = () => {
    setIsOn(prev => {
      toggleCallback && toggleCallback(!prev);
      return !prev;
    });
  };

  useLayoutEffect(() => {
    subscribeCaptionSource && subscribeCaptionSource(setCaptionSource);
  }, [subscribeCaptionSource]);

  return (
    <Button
      disableElevation
      elevation={0}
      variant={isOn ? 'contained' : 'outlined'}
      sx={{ borderRadius: '24px', flexGrow: '1' }}
      color="info"
      size="small"
      onClick={toggle}
    >
      {formatCaption(captionSource)}
    </Button>
  );
}

export default InputToggleButton;

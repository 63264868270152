import React from 'react';
import PropTypes from 'prop-types';


function HorizontalScrollMinimap({
  mapWidth,
  viewWidth,
  height=3,
  progress=0,
}) {
  return (
    <div
      className={`
        rounded-full
        bg-[#E9E9E9]
        overflow-hidden
        relative
      `}
      style={{
        width: `${mapWidth}px`,
        minWidth: `${mapWidth}px`,
        maxWidth: `${mapWidth}px`,
        height: `${height}px`,
        minHeight: `${height}px`,
        maxHeight: `${height}px`,
      }}
    >
      <div
        className={`
          rounded-full
          bg-[#4A90E2]
          relative
        `}
        style={{
          width: `${viewWidth}px`,
          minWidth: `${viewWidth}px`,
          maxWidth: `${viewWidth}px`,
          height: `${height}px`,
          minHeight: `${height}px`,
          maxHeight: `${height}px`,
          left: `${(mapWidth - viewWidth) * progress}px`
        }}
      />
    </div>
  );
}

HorizontalScrollMinimap.propTypes = {
  /**
   * 전체 스크롤에서 어느정도의 위치까지 이동했는지를 나타내는 실수 입니다. 0~1
   */
  progress: PropTypes.number,
  /**
   * 미니맵 전체의 너비 크기를 지정합니다.
   */
  mapWidth: PropTypes.number.isRequired,
  /**
   * 스크롤의 보이는 부분의 너비 크기를 지정합니다.
   */
  viewWidth: PropTypes.number.isRequired,
  /**
   * 스크롤의 높이 크기를 지정합니다.
   */
  height: PropTypes.number,
};

HorizontalScrollMinimap.defaultProps = {
  height: 3,
  progress: 0,
}

export default HorizontalScrollMinimap;

import * as _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { appendSearchedHistory, setSearchedSuggestion } from '@stream/searchedSuggestionHistory';
import { getRoomInfo } from '@stream/roomInfo';
import { getSchedule } from '@stream/schedule';
import { getSortingType } from '@store/sortingType';
import { getStayGrade } from '@store/stayGrade';
import { getPriceRange } from '@store/priceRange';
import { getStayCategory } from '@store/stayCategory';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';
import normalizeCityCode from '@util/normalizeCityCode';
import { setRawSearchedWord } from '@stream/searchedWord';

export const Type = ({ isIcon, children, className }) => {
  switch (children) {
    case 'city': return isIcon ? (<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.48634 15L7.93479 14.6142C11.7059 11.3696 13.6227 8.53659 13.6227 6.04634C13.6227 2.51975 10.8293 0 7.48634 0C4.14342 0 1.34998 2.51975 1.34998 6.04634C1.34998 8.53659 3.26678 11.3696 7.03789 14.6142L7.48634 15ZM7.48643 13.209C4.2864 10.3673 2.71371 7.95931 2.71371 6.04515C2.71371 3.28843 4.87106 1.34244 7.48643 1.34244C10.1018 1.34244 12.2592 3.28843 12.2592 6.04515C12.2592 7.95931 10.6865 10.3673 7.48643 13.209ZM7.4861 2.68881C9.36889 2.68881 10.8952 4.19272 10.8952 6.04788C10.8952 7.90305 9.36889 9.40696 7.4861 9.40696C5.60331 9.40696 4.07701 7.90305 4.07701 6.04788C4.07701 4.19272 5.60331 2.68881 7.4861 2.68881ZM5.44074 6.04382C5.44074 4.93072 6.35652 4.02837 7.4862 4.02837C8.61587 4.02837 9.53165 4.93072 9.53165 6.04382C9.53165 7.15692 8.61587 8.05926 7.4862 8.05926C6.35652 8.05926 5.44074 7.15692 5.44074 6.04382Z" fill="black" />
    </svg>
    ) : <span className={className}>도시</span>;
    case 'hotel': return isIcon ? (<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_101_11693)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 14.5V0.5H9.40909V3.04554H14.5V14.5H0.5ZM8.13637 1.7728H1.77273V13.2277H3.04546V10.6821H6.86364V13.2277H8.13637V1.7728ZM13.2272 13.2277H9.40907V4.3183H13.2272V13.2277ZM5.59093 13.2277H4.3182V11.9549H5.59093V13.2277ZM4.31819 4.31833H3.04547V3.04557H4.31819V4.31833ZM5.59093 4.31833H6.86366V3.04557H5.59093V4.31833ZM4.31819 6.86387H3.04547V5.5911H4.31819V6.86387ZM5.59093 6.86387H6.86366V5.5911H5.59093V6.86387ZM4.31819 9.4094H3.04547V8.13663H4.31819V9.4094ZM5.59093 9.4094H6.86366V8.13663H5.59093V9.4094ZM11.9545 6.86387H10.6818V5.5911H11.9545V6.86387ZM10.6818 9.4094H11.9545V8.13663H10.6818V9.4094ZM11.9545 11.955H10.6818V10.6822H11.9545V11.955Z" fill="#1E1E1E" />
      </g>
      <defs>
        <clipPath id="clip0_101_11693">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
    ) : <span className={className}>숙소</span>;
    case 'airport': return isIcon ? (<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13 10.3V8.9L7.52632 5.4V1.55C7.52632 0.969 7.06789 0.5 6.5 0.5C5.93211 0.5 5.47368 0.969 5.47368 1.55V5.4L0 8.9V10.3L5.47368 8.55V12.4L4.10526 13.45V14.5L6.5 13.8L8.89474 14.5V13.45L7.52632 12.4V8.55L13 10.3Z" fill="#1E1E1E" />
    </svg>
    ) : <span className={className}>항공</span>;
    case 'spot': return isIcon ? (<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.99998 0.5V13.3926H1.5V14.6818H5.78571V13.3926H4.28568V8.66529H13.5L11.7857 5.22728L13.5 1.78927H4.28571V0.5H2.99998ZM4.28571 3.07852H11.4197L10.3796 5.24623H4.28571V3.07852ZM4.28571 5.24622H10.3796L11.4197 7.37601H4.28571V5.24622Z" fill="#1E1E1E" />
    </svg>
    ) : <span className={className}>명소</span>;
    default: return isIcon ? children : <span className={className}>???</span>;
  }
}

function SuggestedWordItem({
  type,
  name,
  code,
  name_kr,
  city_name,
  country_code,
  city_code,
  hotel_id
}) {
  const [sp, setSp] = useState(getSearchParams());
  
  useEffect(() => {
    subscribeSearchParams(setSp);
  }, []);

  const to = useMemo(() => {
    if (type === 'hotel') {
      return `${type}/${country_code}/${normalizeCityCode(city_code)}/${hotel_id}?${sp}`
    }
    return  `/search/${country_code}/${type}/${code}?${sp}`; 
  }, [type, country_code, city_code, code, hotel_id, sp]);
  
  const nameList = _.split(name_kr || name, ',');

  const handleClick = () => {
    setRawSearchedWord('');
    if (type === 'hotel') return;
    const selected = [{
      caption: name_kr || name,
      to: `/search/${country_code}/${type}/${code}`,
    }];

    setSearchedSuggestion(selected);
    appendSearchedHistory({
      selected,
      type,
      roomInfo: getRoomInfo(),
      schedule: getSchedule(),
      sortingType: getSortingType(),
      stayGrade: getStayGrade(),
      priceRange: getPriceRange(),
      category: getStayCategory(),
    });
  };

  return (
    <NavLink
      to={to}
      className="flex gap-[10px] items-start border-b border-[#EEEEEE] pb-[16px]"
      onClick={handleClick}
    >
      <Type isIcon={true}>{type}</Type>
      <div className="flex flex-col grow">
        <span className="whitespace-normal text-[13px] font-regular leading-[160%] text-[#1E1E1E]">{type === 'airport' ? _.last(nameList) : _.first(nameList)}</span>
        <span className="whitespace-normal text-[12px] font-regular leading-[160%] text-[#777777]">{_.last(city_name) || (nameList.length > 1 ? name_kr || name : null)}</span>
      </div>
      <div className="flex justify-end w-10 text-[13px] font-regular leading-[160%] text-[#1E1E1E]">
        <Type>{type}</Type>
      </div>
    </NavLink>
  );
}

export default SuggestedWordItem;

import * as _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { InterestedItem, InterestedList } from '@comp/Interested';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { appendFavoriteList, subscribeStayFavorite, delFavoriteItem, getFavoriteList, registerFavoriteItem, getCurrentStayFavoriteList } from '@store/favoraite';
import { Button, Snackbar } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { resetModal, setModalIsOpened } from '@store/modal';
import { subscribeIsScrolledToBottom } from '@stream/scroll';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import { rmItem, subscribeRecent, undoRmItem, getRecent } from '@store/recent';
import { getRecent as reqRecent } from '@service/recent';

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { Helmet } from 'react-helmet';

function Stay() {
  const {
    INTEREST_TYPE,
    filterInactiveClass,
    filterActiveClass,
  } = useOutletContext();

  const navigate = useNavigate();
  const [type, setType] = useState(INTEREST_TYPE.FAVORITES);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [favoritePages, setFavoritePages] = useState(getCurrentStayFavoriteList());
  const [recent, setRecent] = useState(getRecent());
  const [remoteRecent, setRemoteRecent] = useState(userInfo ? recent : []);
  const [removedId, setRemovedId] = useState();
  const [hotelName, setHotelName] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useLayoutEffect(() => {
    subscribeIsScrolledToBottom(setIsScrolledToBottom);
    subscribeUserInfo(setUserInfo);
    subscribeStayFavorite(setFavoritePages);
    subscribeRecent(setRecent);
  }, []);

  useLayoutEffect(() => {
    if (checkIsLogedInUser(userInfo)) {
      setIsFetching(true);
      getFavoriteList(userInfo.access_token, true, 'hotel').then(() => setIsFetching(false));
    }
  }, [userInfo]);

  useLayoutEffect(() => {
    if (!checkIsLogedInUser(userInfo)) {
      setRemoteRecent([]);
      return;
    }
    const ids = _.map(recent, 'id')
    if (_.head(ids)) {
      reqRecent(ids)
        .then(({
          data,
          isOK
        }) => {
          if (isOK) {
            setRemoteRecent(data);
            return;
          }
          throw new Error('원격 최근 확인 숙소 불러오기 실패');
        }).catch((err) => {
          setRemoteRecent(recent);
        });
      return;
    }
    setRemoteRecent(recent);
  }, [recent, userInfo]);

  useLayoutEffect(() => {
    if (!isScrolledToBottom || isFetching || !userInfo.access_token) return;
    setIsScrolledToBottom(false);
    appendFavoriteList(userInfo.access_token, 'hotel').then(() => setIsFetching(false));
  }, [isScrolledToBottom, userInfo, isFetching]);

  const handleClickRm = ({ id, hotel, hotel_i18ns, name }) => {
    if (INTEREST_TYPE.FAVORITES === type) {
      resetModal();
      setModalIsOpened(true);
      delFavoriteItem(id, userInfo.access_token).then(res => {
        if (!!res && res.isOK) {
          appendFavoriteList(userInfo.access_token, 'hotel').finally(() => {
            setRemovedId(hotel.id);
            setHotelName(_.find(hotel.hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || hotel.name);
            setModalIsOpened(false);
            setOpen(true);
            setIsFetching(true);
            getFavoriteList(userInfo.access_token, true, 'hotel').then(() => setIsFetching(false));
          });
        }
      });
    } else if (INTEREST_TYPE.RECENTLY === type) {
      rmItem(id);
      setRemovedId(id);
      setHotelName(_.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || name);
      setOpen(true);
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleUndo = () => {
    if (INTEREST_TYPE.FAVORITES === type) {
      registerFavoriteItem('hotel', removedId, userInfo.access_token)
        .then(res => {
          appendFavoriteList(userInfo.access_token, 'hotel').finally(() => {
            setIsFetching(true);
            getFavoriteList(userInfo.access_token, true, 'hotel').then(() => setIsFetching(false));
          });
        });
    } else if (INTEREST_TYPE.RECENTLY === type) {
      undoRmItem(removedId);
    }
    handleClose();
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleUndo}>
        실행취소
      </Button>
    </>
  );

  const handleTypeChange = changeType => {
    if (type === changeType) return
    setType(changeType);
  }

  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState} />
      <div className="grow flex flex-col">
        <div className="flex gap-[5px] pt-[30px] px-[20px] text-[13px]">
          <button className={type === INTEREST_TYPE.FAVORITES ? `${filterActiveClass}` : `${filterInactiveClass}`} onClick={() => handleTypeChange(INTEREST_TYPE.FAVORITES)}> 찜한 숙소 </button>
          <button className={type === INTEREST_TYPE.RECENTLY ? `${filterActiveClass}` : `${filterInactiveClass}`} onClick={() => handleTypeChange(INTEREST_TYPE.RECENTLY)}> 최근 본 숙소 </button>
        </div>
        {
          checkIsLogedInUser(userInfo) ? (
            (type === INTEREST_TYPE.FAVORITES && !!favoritePages && (!!favoritePages.bookmarks ? favoritePages.bookmarks.length > 0 : false)) ||
              (type === INTEREST_TYPE.RECENTLY && !!remoteRecent && (remoteRecent.length > 0)) ?
              (
                type === INTEREST_TYPE.FAVORITES ?
                  <InterestedList>
                    {favoritePages.bookmarks.reduce((acc, cur) => {
                      if (acc.findIndex(({ id }) => cur.hotel.id === id) < 0) return [...acc, cur];
                      return acc;
                    }, []).filter(({ id }) => typeof id !== 'undefined')
                      .map(item => {
                        return <InterestedItem
                          type="favorite"
                          {...item.hotel}
                          id={item.hotel.id}
                          key={item.id}
                          handleClickRm={() => handleClickRm(item)}
                        />;
                      })}
                  </InterestedList>
                  :
                  <InterestedList>
                    {remoteRecent.map(item => {
                      const { country_code } = recent.find(({ id }) => `${id}` === `${item.id}`) || {};
                      return (
                        <InterestedItem
                          type="recently-viewed"
                          key={`recent-item-${item.id}`}
                          {...item}
                          country_code={country_code}
                          handleClickRm={() => handleClickRm(item)}
                        />
                      );
                    })}
                  </InterestedList>
              ) :
              (
                <div className="grow flex flex-col justify-center items-center h-max gap-8">
                  <FavoriteBorderIcon fontSize="large" />
                  <div className="text-center">
                    <p>아직 찜한 숙소가 없어요.</p>
                    <p>마음에 드는 숙소를 찜하세요!</p>
                  </div>
                </div>
              )
          ) :
            (
              <div className="grow flex flex-col justify-center items-center h-max gap-8">
                <FavoriteBorderIcon fontSize="large" />
                <div className="text-center">
                  <p>지금 바로 올스테이에 로그인하고</p>
                  <p>마음에 드는 숙소를 찜하세요!</p>
                </div>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    width: '75%',
                  }}
                  onClick={() => navigate('/auth')}
                >
                  로그인하기
                </Button>
              </div>
            )
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={(hotelName.length > 8 ? `"${hotelName.slice(0, 8)}..."` : `"${hotelName}"`) + (type === INTEREST_TYPE.FAVORITES ? ' 찜을 해제했어요' : ' 확인 기록을 삭제했어요.')}
          action={action}
        />
      </div>
    </>
  );
}

export default Stay;

import React, { useMemo } from 'react';
import { closeResetModal } from "@store/modal";
import { IconButton } from "@mui/material";

import { ReactComponent as KakaoIcon } from '@svg/Icons/SNS/kakao.svg';
// import { ReactComponent as InstagramIcon } from '@svg/Icons/SNS/instagram.svg';
import { ReactComponent as BlogIcon } from '@svg/Icons/SNS/blog.svg';
import { ReactComponent as facebookIcon } from '@svg/Icons/SNS/facebook.svg';
import { ReactComponent as twitterIcon } from '@svg/Icons/SNS/twitter.svg';
import { ReactComponent as bandIcon } from '@svg/Icons/SNS/band.svg';
import { ReactComponent as lineIcon } from '@svg/Icons/SNS/line.svg';
import { ReactComponent as naverIcon } from '@svg/Icons/SNS/naver.svg';
import { ReactComponent as emailIcon } from '@svg/Icons/SNS/email.svg';
import { ReactComponent as ClearIcon } from '@svg/ButtonIcons/clear.svg';

const shareKakao = (title, url, img) => {
  const { Kakao } = window;

  if (!Kakao.isInitialized()) {
    Kakao.init(process.env.REACT_APP__KAKAO_APP_KEY)
  };
  const imgUrlFallback = `${window.location.protocol}//${window.location.host}/allstay_logo_og_img_original.png`;
  Kakao.Share.sendDefault({
    objectType: 'feed',
    content: {
      title: typeof title === 'string' ? title : (Array.isArray(title) ? title[0] : ''),
      imageUrl: typeof img === 'string' ? img : img?.url || imgUrlFallback,
      link: {
        mobileWebUrl: url,
        webUrl: url
      },
    },
    buttons: [{
      title: '자세히 보기',
      link: {
        mobileWebUrl: url,
        webUrl: url
      }
    }]
  });
}

const copyClipbord = (url, setToastMsg, setIsToastOpen) => {
  try {
    window.navigator.clipboard.writeText(url);
    setToastMsg('url 주소를 클립보드로 복사했습니다.');
  } catch (err) {
    setToastMsg('url 주소를 클립보드로 복사하지 못했어요.');
    console.error('url 주소를 클립보드로 복사하지 못했어요.', err);
  }
  setIsToastOpen(true);
}

const Share = ({
  title,
  url,
  img,
  setToastMsg, 
  setIsToastOpen,
}) => {
  
  const trackEventName = useMemo(() => {
    let event = 'search_stay__shareButton';
    const pathArr = window.location.pathname?.split('/')?.slice(1)
    if (pathArr?.[0] === 'alltrip') {
      if (pathArr?.[1] === 'post') event = 'alltrip_post__shareButton';
      else if (pathArr?.[1]?.endsWith('-curation')) event = 'alltrip_curation__shareButton';
    }
    return event;
  }, []);

  const SNS_LIST = [
    { name: '카카오톡', key: 'kakao', url, Icon: KakaoIcon, color: '!bg-yellow-300' },
    { name: '블로그', key: 'blog', url: `http://blog.naver.com/openapi/share?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`, Icon: BlogIcon, color: '!bg-[#3BAA3E]' },
    // { name: '인스타그램', key: 'instagram', url: 'https://www.instagram.com', Icon: InstagramIcon, color: '!bg-white-100' },
    { name: '트위터', key: 'twitter', url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title + '\n')}`, Icon: twitterIcon, color: '!bg-[#3DABE1]' },
    { name: '페이스북', key: 'facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, Icon: facebookIcon, color: '!bg-[#50659C]' },
    { name: '네이버', key: 'naver', url: `https://share.naver.com/web/shareView?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`, Icon: naverIcon, color: '!bg-[#3BAA3E]' },
    { name: '밴드', key: 'band', url: `https://band.us/plugin/share?body=${encodeURIComponent(url)}`, Icon: bandIcon, color: '!bg-[#3BAA3E]' },
    { name: '라인', key: 'line', url: `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}`, Icon: lineIcon, color: '!bg-[#3BAA3E]' },
    { name: '이메일', key: 'email', url: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`, Icon: emailIcon, color: '!bg-gray-200' },
  ]

  const onClick = () => {
    closeResetModal();
  };

  return (
    <div className="bg-white-100 rounded flex flex-col justify-end items-stretch text-center gap-[10px] p-[20px] max-w-[300px]">
      <div className="flex justify-end">
      <div className="w-[30px] p-[10px] cursor-[pointer]" onClick={onClick}>
        <ClearIcon />
      </div>
      </div>
      <div className="flex flex-col justify-center gap-6 text-gray-900-100">
        <h2 className="text-modal-h">공유하기</h2>
        <div className="flex flex-wrap justify-between align-around px-[10px]">
          {
            SNS_LIST.map(({ key, name, url, Icon, color }) => {
              return (
                <div key={`post-share-${key}`} className="flex flex-col items-center gap-[5px] w-[52px] mb-[10px]">
                  <IconButton
                    onClick={() => {
                      window.dataLayer.push({
                        event: trackEventName,
                        sharetype: name,
                        sharetype_click: 1,
                        shareButton_click: 0,
                      });

                      if (name === '카카오톡') {
                        shareKakao(title, url, img);
                        return
                      }

                      const width = 600;
                      const height = 600;
                      const left = window.screen.width / 2 - width / 2;
                      const top = window.screen.height / 2 - height / 2;
                      const isOpenWindow = window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

                      if (!isOpenWindow) {
                        window.open(url, '_blank');
                      };
                    }}
                    size="large"
                    className={`share-button share-button--${key} ${color}`}
                    style={{ padding: 0, height: '45px', width: '45px', borderRadius: '15px', border: name === '인스타그램' ? '1px solid #cdd3d8' : '' }}>
                    <span className="sr-only">{name} 공유</span>
                    <Icon />
                  </IconButton>
                  <p className="text-[12px]">
                    {name}
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="border-t border-gray-100-100" />

      <div className="flex items-center h-[38px] mt-[10px] px-[10px] border border-gray-100-100 cursor-[pointer]" style={{ borderRadius: '10px' }}>
        <p className="flex text-[12px] grow text-[#CCCED2]">{(url?.length || 0) > 28 ? `${url.slice(0, 28)}...` : `${url}`}</p>
        <p className="border-l h-full mx-[10px]"></p>
        <button
          onClick={() => {
            window.dataLayer.push({
              event: trackEventName,
              sharetype: 'URL 복사',
              sharetype_click: 1,
              shareButton_click: 0,
            });
            copyClipbord(url, setToastMsg, setIsToastOpen); closeResetModal();
          }}
          className="share-button share-button--url-copy flex grow text-[12px]"
        >
          URL 복사
        </button>
      </div>
    </div>
  );
};

export default Share;


import {
  go,
  mapC,
  map,
  mapL,
  chunkL,
  object,
  filter,
  entries,
  each,
  goS,
  stopIf,
  stop,
  reduceS,
} from 'fxjs';
import { getUserInfo } from './userInfo';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import { getTokenRequestingBody } from './tokenRequestingBody';
import checkIsMobileWeb from '@util/isMobileWeb';
import { allstayHttpService } from "@service/api";
import { getSchedule } from '@stream/schedule';
import { format } from 'date-fns';
import { ko } from 'react-date-range/dist/locale';
import {
  getLastRouterHistory,
  subscribeRouterHistory,
  isRouterHistoryChanged,
} from '@stream/routerHistory';
import { SOURCES } from '@util/otaSource';


export const getPricesOfHotels = ({setter, hotel_list, hotelChunkSize, srcChunkSize, setNumOfPriceLoadedHotels, check_in, check_out, room_info, historyStateAtBegining }) => {
  if (!hotel_list || !hotel_list.length) return;

  goS(
    hotel_list,
    mapL(({ hotel_id }) => hotel_id),
    chunkL(hotelChunkSize || 200),
    mapL(hotel_id => go(
      SOURCES,
      chunkL(srcChunkSize || 1),
      map(source => ({ hotel_id, source })),
    )),
    mapC(hotelIdSourceSet => {
      goS(
        hotelIdSourceSet,
        mapL(({
          hotel_id,
          source,
        }) => {
          const userInfo = getUserInfo();
          const user = checkIsLogedInUser(userInfo) ? 0 : 1;
          const deviceInfo = getTokenRequestingBody();
          let device_kind = 'D00';
          if (/aos/i.test(deviceInfo?.channel)) {
            device_kind = 'D01';
          } else if (/ios/i.test(deviceInfo?.channel)) {
            device_kind = 'D02';
          } else if (checkIsMobileWeb()) { // 네이티브앱이 웹뷰이기에 이 블럭이 먼저 검사될 경우 무조건 모바일 웹으로 표시될 가능성이 높음
            device_kind = 'D03';
          }

          if (!check_in || !check_out) {
            const {
              startDate,
              endDate,
            } = getSchedule()[0];
            check_in = format(startDate, 'yyyy-MM-dd', { locale: ko });
            check_out = format(endDate, 'yyyy-MM-dd', { locale: ko });
          }
          
          const params = {
            hotel_ids: hotel_id.join(','),
            sources: source.join(','),
            room_info,
            check_in,
            check_out,
            device_kind,
            is_user: user,
          };

          let historySubscription;

          const promiseExecuter = (resolve, reject) => {
            const reqSubscription = allstayHttpService
              .get('/public/hotel/plan', params, {
                headers: {
                  'Cache-Control': 'public, max-age=900'
                }
              })
              .subscribe({
                next: res => resolve({...res, chunkedHotelLength: hotel_id.length }),
                error: err => reject(err),
                complete: () => historySubscription?.unsubscribe(),
              });
            
            const cancel = () => {
              historySubscription?.unsubscribe?.();
            };
            historySubscription = subscribeRouterHistory(() => {
              const lastHistoryState = getLastRouterHistory();
              const isGonnaStop = isRouterHistoryChanged(lastHistoryState, historyStateAtBegining);
              if (isGonnaStop) {
                reject({ msg: 'canceled' });
                allstayHttpService.cancel(true);
                reqSubscription?.unsubscribe?.();
                cancel();
              }
            });
          };

          return new Promise(promiseExecuter).catch(() => ({ msg: 'canceled' }));
        }),
        reduceS((acc, { data={}, msg }) => {
          if (!!msg && msg === 'canceled') return stop('canceled');
          const newAcc = { ...(acc?.data || acc || {}) };
          const curEntries = entries(data);
          each(([k, v]) => {
            newAcc[k] = [...(newAcc[k] || []), ...v];
          }, curEntries);
          return newAcc;
        }),
        stopIf((msg) => typeof msg === 'string' && msg === 'canceled'),
        entries,
        filter(([, prices]) => Array.isArray(prices)),
        mapC(([hotelId, prices]) => [hotelId, prices.map((p) => ({ ...p, price: parseFloat(p.price) })).sort((a, b) => a.price - b.price)]),
        object,
        prices => {
          setter(prices);
          setNumOfPriceLoadedHotels(v => v + hotelIdSourceSet[0].hotel_id.length);
        },
      );
    }),
  );
};

export default getPricesOfHotels;

import { HorizontalScrollContainer } from '@comp/HorizontalScroll';
import FeaturedAtMainTabItem from './AtMainTabItem';
import FeaturedAtMainContent from './AtMainContent';

export { default as useFeatured } from './useFeatured';
export { default as cityImage } from './cityImage';

export const FeaturedAtMain = {
  List: HorizontalScrollContainer,
  Item: FeaturedAtMainTabItem,
  Content: FeaturedAtMainContent,
};

export const Featured = {
  AtMain: FeaturedAtMain,
};

export default Featured;

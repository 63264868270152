import React from 'react';

function FareList({ children }) {
  return (
    <ul className="flex flex-col items-stretch">
      {children}
    </ul>
  );
}

export default FareList;

import React from 'react';

function SectionHeading({ children }) {
  return (
    <h2
      className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]"
    >
      {children}
    </h2>
  );
}

export default SectionHeading;

import { format, isBefore, subDays } from 'date-fns';
import { BehaviorSubject, map } from 'rxjs';

const ssKeySearchedSuggestion = 'searchedSuggestion';
const _ = new BehaviorSubject([...(JSON.parse(sessionStorage.getItem(ssKeySearchedSuggestion)) || [])]);

export const searchedSuggestion = _;
export const subscribeSearchedSuggestion = subscriber => _.subscribe(subscriber);
export const getSearchedSuggestion = () => _.getValue();
export const setSearchedSuggestion = v => {
  sessionStorage.setItem(ssKeySearchedSuggestion, JSON.stringify(v));
  _.next(v);
};
export default searchedSuggestion;

const validHistory = arr => (
  [
    ...arr.map(
      ({schedule: [{startDate, endDate, ...scheduleRest}, ...schedulesRest], ...rest}) => ({
        ...rest,
        schedule: [{
          ...scheduleRest,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        }, ...schedulesRest]
      })
    ).filter(
      ({schedule: [{startDate}]}) => !isBefore(new Date(startDate), subDays(new Date(), 1))
    ).slice(0, 20)
  ].sort(({id: a}, {id: b}) => b - a)
);
const normalizeSuggestion = h => {
  const {
    selected,
    schedule: [{
      startDate,
      endDate,
    }],
    roomInfo
  } = h;
  return [
    selected.map(({ caption }) => caption).join('-'),
    format(new Date(startDate), 'yyyy-MM-dd'),
    format(new Date(endDate), 'yyyy-MM-dd'),
    roomInfo.reduce(([
      r, a, m
    ], {
      adult, minor
    }) => [
      r + 1,
      a + adult,
      m + minor.length
    ], [0, 0, 0]).join('-')
  ].join('--');
};
const lsKeyHistory = 'searchedHistory';
const __ = new BehaviorSubject(validHistory([...(JSON.parse(localStorage.getItem(lsKeyHistory)) || [])]));
export const searchedHistory = __;
export const getSearchedHistory = () => validHistory(__.getValue());
export const appendSearchedHistory = newSearchedSuggestion => {
  const id = new Date().getTime();
  const oldHistory = __.getValue();
  const normalizedNew = normalizeSuggestion(newSearchedSuggestion);
  const newHistory = validHistory([
    {
      ...newSearchedSuggestion,
      id,
    },
    ...oldHistory.filter(v => normalizedNew !== normalizeSuggestion(v))
  ]);
  localStorage.setItem(lsKeyHistory, JSON.stringify(newHistory));
  __.next(newHistory);
};
export const deleteSearchedHistory = id => {
  const newHistory = validHistory(__.getValue().filter(h => h.id !== id));
  localStorage.setItem(lsKeyHistory, JSON.stringify(newHistory));
  __.next(newHistory);
};
export const setSearchedHistory = arr => __.next(validHistory(arr));
export const subscribeSearchedHistory = f => __.pipe(
  map(v => validHistory(v))
).subscribe(f);

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect, useMemo, useState } from 'react';
import { countryCode } from '@service/system';
import { useParams, Navigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Search() {
  const { type, allstayCode } = useParams();
  const [sp] = useSearchParams();

  const [targetCode, setTargetCode] = useState(null);

  useEffect(() => {
    countryCode(type, allstayCode).then(res => {
      setTargetCode(res);
    });
  }, [type, allstayCode]);

  const renewalSp = useMemo(() => {
    const newSp = new URLSearchParams(sp);
    const checkin = newSp.get('checkin');
    newSp.set('check_in', checkin);
    const checkout = newSp.get('checkout');
    newSp.set('check_out', checkout);
    const room = newSp.getAll('room');
    if (room.length > 0) {
      newSp.set('room_info', room.map(r => {
        const [adult, ...minor] = r.split(',');
        return `${adult}${minor.length > 0 ? `-${minor.join('_')}` : ''}`;
      }).join(','));
    }
    newSp.delete('checkin');
    newSp.delete('checkout');
    newSp.delete('room');

    return newSp.toString();
  }, [sp]);
  

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <main id="allstay-main">
      <div className="allstay-contents">
        <div>잠시만 기다려주세요.</div>
        {
          targetCode && typeof renewalSp === 'string' && <Navigate
            to={`/search/${targetCode.country_code}/${type}/${allstayCode}${renewalSp ? `?${renewalSp}` : ''}`}
            replace={true}
          />
        }
      </div>
    </main>
    </>
  );
};

export default Search;

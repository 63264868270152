import * as _ from 'lodash';
import { blogPosts } from '@service/blog';
import { Subject } from 'rxjs';

const createPostsStore = (categoryCode, initialPage=1) => { 
  const postsSubject = new Subject();
  const DEFAULT = [...Array(10).keys()].map(() => ({}));
  const INITIAL = DEFAULT;
  let state = INITIAL;
  const idSet = new Set();
  let curPage = initialPage;

  let isFetching = false;
  
  const _setState = newState => state = [...newState];
  const _appendIdSet = ids => {
    ids.forEach(({ id }) => idSet.add(id));
  };
  const _distinctState = newState => {
    const filtered = state.filter(({ id }) => !!id);
    _appendIdSet(filtered);
    _setState([...filtered, ...newState.filter(({ id }) => !idSet.has(id))]);
  };
  const _getState = () => [...state];
  const _initState = () => _setState(INITIAL);
  const _subscribe = read => postsSubject.subscribe(read);
  
  const getPosts = _getState;
  const initPosts = _initState;

  const updateBookmarkId = (postId, bookmarkId) => {
    const newState = _.map(_getState(), post => {
      if(post.id === postId) {
        post.bookmark_id = bookmarkId
      }

      return post
    })

    _setState(newState)
  }
  const subscribePosts = read => {
    const _ = _subscribe(read);
    return _;
  };

  const fetchPosts = isReq => {
    if (isFetching) return;
    isFetching = true;

    if (!isReq) {
      postsSubject.next(state);
      isFetching = false;
      return;
    }

    blogPosts(categoryCode, curPage)
    .then(res => {
      isFetching = false;
      if (!!res.error) return;
      curPage += 1;
      _distinctState(res);
      postsSubject.next(state);
    });

  };

  const getCurPage = () => curPage;

  return {
    DEFAULT,
    getPosts,
    initPosts,
    subscribePosts,
    fetchPosts,
    getCurPage,
    updateBookmarkId,
  };
};

// NEW
export const newStore = createPostsStore(null);

// 529: 호텔TMI
export const hotelTMIStore = createPostsStore(529);

// 286: 국내여행
export const domesticStoreOptions = {
  'all': {
    code: 286,
    store: createPostsStore(286),
    caption: '전체'
  },
  'seoul': {
    code: 662,
    store: createPostsStore(662),
    caption: '서울'
  },
  'busan': {
    code: 663,
    store: createPostsStore(663),
    caption: '부산'
  },
  'jeju': {
    code: 664,
    store: createPostsStore(664),
    caption: '제주'
  },
};

// 178: 해외여행
export const internationalStoreOptions = {
  'all': {
    code: 178,
    store: createPostsStore(178),
    caption: '전체',
  },
  'eu': {
    code: 645,
    store: createPostsStore(645),
    caption: '유럽',
  },
  'jp': {
    code: 641,
    store: createPostsStore(641),
    caption: '일본',
  },
  'vn': {
    code: 647,
    store: createPostsStore(647),
    caption: '베트남',
  },
  'cn': {
    code: 642,
    store: createPostsStore(642),
    caption: '중국',
  },
  'th': {
    code: 648,
    store: createPostsStore(648),
    caption: '태국',
  },
  'hk': {
    code: 649,
    store: createPostsStore(649),
    caption: '홍콩/마카오',
  },
  'usa': {
    code: 646,
    store: createPostsStore(646),
    caption: '미국',
  },
  'gu': {
    code: 644,
    store: createPostsStore(644),
    caption: '괌/사이판/대양주',
  },
  'sg': {
    code: 650,
    store: createPostsStore(650),
    caption: '싱가포르',
  },
  'au': {
    code: 3966,
    store: createPostsStore(3966),
    caption: '호주',
  },
  'id': {
    code: 6543,
    store: createPostsStore(6543),
    caption: '인도네시아',
  },
};

// 479: Travel Tips
export const travelTipsStore = createPostsStore(479);

export const updateStoreBookmarkId = (postId, bookmarkId) => {
  hotelTMIStore.updateBookmarkId(postId, bookmarkId);
  _.map(domesticStoreOptions, value => {
    value.store.updateBookmarkId(postId, bookmarkId);
  })
  _.map(internationalStoreOptions, value => {
    value.store.updateBookmarkId(postId, bookmarkId);
  })
  travelTipsStore.updateBookmarkId(postId, bookmarkId);
}

import { closeResetModal } from "@store/modal";
import React from "react";

const Modal = ({ isClosable, children, closeReset, close }) => {
  const handleClickBG = () => {
    if (!isClosable) return;
    if (closeReset) {
      closeReset();
      return;
    } else if (close) {
      close();
      return;
    }
    closeResetModal();
  };

  const handleClickContent = e => {
    e.stopPropagation();
  };

  return (
    <div
      className={`
        inset-0
        fixed
        flex
        flex-col
        bg-black-50
        justify-center
        items-center
        z-900
      `}
      style={{
        minWidth: '100vw',
        minHeight: '100vh',
      }}
      onClick={handleClickBG}
    >
      <div onClick={handleClickContent}>
        {children}
      </div>
    </div>
  );
}

export default Modal;

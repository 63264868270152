import { setTokenRequestingBody } from '@stream/tokenRequestingBody';
import { getPostingContext } from '@util/getPostingContext';


export const getMsgRN = () => {
  window.addEventListener('message', e => {
    if (!e || !e.data || !e.data.eventName || !e.data.data) return;

    switch (e.data.eventName) {
      case 'RES_APP_META':
        setTokenRequestingBody({ ...e.data.data });
        break;
      default:
        console.log(e.data.eventName)
    }
  });
};

export const postMsgRN = (payload) => {
  const postingContext = getPostingContext();
  if (!postingContext) return;
  postingContext.ReactNativeWebView.postMessage(typeof payload === 'string' ? payload : (typeof payload === 'object' ? JSON.stringify(payload) : `${payload}`));
};

export const doesPostMsgExist = () => !!getPostingContext();

import React, { useMemo } from 'react';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { ReactComponent as HomeIconInactive } from '@asset/icon/home-bd.svg';
import { ReactComponent as HomeIconActive } from '@asset/icon/home-fill-1e.svg';
import { ReactComponent as SearchIconInactive } from '@asset/icon/magnifier-bd.svg';
import { ReactComponent as SearchIconActive } from '@asset/icon/magnifier-fill-1e.svg';
import { ReactComponent as DomesticIconInactive } from '@asset/icon/mountain-bd.svg';
import { ReactComponent as DomesticIconActive } from '@asset/icon/mountain-fill-1e.svg';
import { ReactComponent as GlobalIconInactive } from '@asset/icon/globe-bd.svg';
import { ReactComponent as GlobalIconActive } from '@asset/icon/globe-fill-1e.svg';
import { ReactComponent as MyIconInactive } from '@asset/icon/profile-bd.svg';
import { ReactComponent as MyIconActive } from '@asset/icon/profile-fill-1e.svg';
import { ReactComponent as MagazineIconInactive } from '@asset/icon/magazine-bd.svg';
import { ReactComponent as MagazineIconActive } from '@asset/icon/magazine-fill-1e.svg';

const classBase = 'w-10 h-10 flex-col justify-start items-center gap-0.5 inline-flex';

function GnbBtn({
  to,
  ActiveIcon,
  InactiveIcon,
  defaultMatchingPattern={
    path: '/',
    end: false,
  },
  matchingPattern,
  children,
}) {
  const loc = useLocation();
  const isMatched = useMatch(defaultMatchingPattern);
  const isActive = useMemo(() => {
    return matchingPattern instanceof RegExp ? matchingPattern.test(loc.pathname) : isMatched;
  }, [loc, matchingPattern, isMatched]);
  return <NavLink
    to={to}
    className={() => {
      return `${classBase} ${
        isActive ?
          'active text-allstay-gray-1e fill-allstay-gray-1e' :
          'text-allstay-gray-9e fill-allstay-gray-9e'
        }`;
    }}
  >
    <div>
      {isActive ? <ActiveIcon/> : <InactiveIcon/>}
    </div>
    <div className="text-center text-2xs font-normal leading-none">
      {children}
    </div>
  </NavLink>;
};

export default GnbBtn;

export const GnbHomeBtn = (p) => <GnbBtn
  to="/"
  ActiveIcon={HomeIconActive}
  InactiveIcon={HomeIconInactive}
  defaultMatchingPattern={{
    path: '/',
    end: true,
  }}
  {...p}
>홈</GnbBtn>;

export const GnbSearchBtn = (p) => <GnbBtn
  to="/search"
  ActiveIcon={SearchIconActive}
  InactiveIcon={SearchIconInactive}
  defaultMatchingPattern={{
    path: '/search/',
    end: false,
  }}
  matchingPattern={/^\/(search|hotel)(\/*$|\/+)/}
  {...p}
>검색</GnbBtn>;

export const GnbDomesticBtn = (p) => <GnbBtn
  to="/alltrip/domestic-curation"
  ActiveIcon={DomesticIconActive}
  InactiveIcon={DomesticIconInactive}
  defaultMatchingPattern={{
    path: '/alltrip/domestic-curation/',
    end: false,
  }}
  {...p}
>국내추천</GnbBtn>;

export const GnbGlobalBtn = (p) => <GnbBtn
  to="/alltrip/overseas-curation"
  ActiveIcon={GlobalIconActive}
  InactiveIcon={GlobalIconInactive}
  defaultMatchingPattern={{
    path: '/alltrip/overseas-curation/',
    end: false,
  }}
  {...p}
>해외추천</GnbBtn>;

export const GnbMyBtn = (p) => <GnbBtn
  to="/my-profile"
  ActiveIcon={MyIconActive}
  InactiveIcon={MyIconInactive}
  defaultMatchingPattern={{
    path: '/my-profile/',
    end: false,
  }}
  {...p}
>마이</GnbBtn>;

export const GnbMagazineBtn = (p) => <GnbBtn
  to="/alltrip/magazine/domestic"
  ActiveIcon={MagazineIconActive}
  InactiveIcon={MagazineIconInactive}
  defaultMatchingPattern={{
    path: '/alltrip/magazine/',
    end: false,
  }}
  matchingPattern={/^\/alltrip\/(magazine|post)(\/*$|\/+)/}
  {...p}
>매거진</GnbBtn>
import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

function Profile() {
  return (
    <>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <main id="allstay-main">
      <div className="allstay-contents">
        <Outlet />
      </div>
    </main>
    </>
  );
}

export default Profile;

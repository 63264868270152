import React, { useLayoutEffect, useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { appendFavoriteList, subscribeContentFavorite, delFavoriteItem, getFavoriteList, registerFavoriteItem, getCurrentContentFavoriteList } from '@store/favoraite';
import { Button, Snackbar, Skeleton } from '@mui/material';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import { resetModal, setModalIsOpened } from '@store/modal';
import { subscribeIsScrolledToBottom } from '@stream/scroll';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import { rmItem, subscribeRecent, undoRmItem, getRecent } from '@store/recentContent';
import { updateBookmarkId } from '@store/tags';
import { updateStoreBookmarkId } from '@store/blogPosts';
import decode from '@util/decode';

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { Helmet } from 'react-helmet';

function Content() {
  const {
    INTEREST_TYPE,
    filterInactiveClass,
    filterActiveClass,
  } = useOutletContext();

  const navigate = useNavigate();
  const [type, setType] = useState(INTEREST_TYPE.FAVORITES);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [favoritePages, setFavoritePages] = useState(getCurrentContentFavoriteList());
  const [recent, setRecent] = useState(userInfo ? getRecent(): []);
  const [removedId, setRemovedId] = useState();
  const [title, setTitle] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useLayoutEffect(() => {
    subscribeIsScrolledToBottom(setIsScrolledToBottom);
    subscribeUserInfo(setUserInfo);
    subscribeContentFavorite(setFavoritePages);
    subscribeRecent(setRecent);
  }, []);

  useLayoutEffect(() => {
    if (checkIsLogedInUser(userInfo)) {
      setIsFetching(true);
      getFavoriteList(userInfo.access_token, true, 'content').then(() => setIsFetching(false));
    }
  }, [userInfo]);

  useLayoutEffect(() => {
    if (!isScrolledToBottom || isFetching || !userInfo.access_token) return;
    setIsScrolledToBottom(false);
    appendFavoriteList(userInfo.access_token, 'content').then(() => setIsFetching(false));
  }, [isScrolledToBottom, userInfo, isFetching]);

  const handleClickRm = ({ id, content }) => {
    if (INTEREST_TYPE.FAVORITES === type) {
      resetModal();
      setModalIsOpened(true);
      delFavoriteItem(id, userInfo.access_token).then(res => {
        if (!!res && res.isOK) {
          appendFavoriteList(userInfo.access_token, 'content').finally(() => {
            setRemovedId(content.id);
            setTitle(content.title);
            setModalIsOpened(false);
            setOpen(true);
            setIsFetching(true);
            getFavoriteList(userInfo.access_token, true, 'content').then(() => setIsFetching(false));
            updateBookmarkId(content.id, null);
            updateStoreBookmarkId(content.id, null);
          });
        }
      });
    } else if (INTEREST_TYPE.RECENTLY === type) {
      rmItem(id);
      setRemovedId(id);
      setTitle(content.title);
      setOpen(true);
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleUndo = () => {
    if (INTEREST_TYPE.FAVORITES === type) {
      registerFavoriteItem('content', removedId, userInfo.access_token)
        .then(res => {
          appendFavoriteList(userInfo.access_token, 'content').finally(() => {
            setIsFetching(true);
            getFavoriteList(userInfo.access_token, true, 'content').then(() => setIsFetching(false));
          });
          updateBookmarkId(removedId, res.bookmark_id);
          updateStoreBookmarkId(removedId, res.bookmark_id);
        });
    } else if (INTEREST_TYPE.RECENTLY === type) {
      undoRmItem(removedId);
    }
    handleClose();
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleUndo}>
        실행취소
      </Button>
    </>
  );

  const handleTypeChange = changeType => {
    if (type === changeType) return
    setType(changeType);
  }


  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState} />
      <>
      <Helmet onChangeClientState={handleChangeHelmetState} />
      <div className="grow flex flex-col">
          <div className="flex gap-[5px] pt-[30px] px-[20px] text-[13px]">
            <button className={type === INTEREST_TYPE.FAVORITES ? `${filterActiveClass}` : `${filterInactiveClass}`} onClick={() => handleTypeChange(INTEREST_TYPE.FAVORITES)}> 찜한 컨텐츠 </button>
            <button className={type === INTEREST_TYPE.RECENTLY ? `${filterActiveClass}` : `${filterInactiveClass}`} onClick={() => handleTypeChange(INTEREST_TYPE.RECENTLY)}> 최근 본 컨텐츠 </button>
          </div>
          {
            checkIsLogedInUser(userInfo) ? (
              (type === INTEREST_TYPE.FAVORITES && !!favoritePages && (!!favoritePages.bookmarks ? favoritePages.bookmarks.length > 0 : false)) ||
                (type === INTEREST_TYPE.RECENTLY && !!recent && (recent.length > 0)) ?
                (
                  type === INTEREST_TYPE.FAVORITES ?
                    <div className={`grid grid-cols-2 gap-x-[15px] gap-y-[20px] text-[13px] leading-[160%] px-[20px] pt-[20px]`}>
                      {favoritePages.bookmarks.reduce((acc, cur) => {
                        if (acc.findIndex(({ id }) => cur.content.id === id) < 0) return [...acc, cur];
                        return acc;
                      }, []).filter(({ id }) => typeof id !== 'undefined')
                        .map((item, i) => {
                          const { id, title, image, slug } = item.content;
                          return (
                            <NavLink
                              to={`/alltrip/post/${slug}`}
                              key={`section-item-${id || i}`}
                              className="flex flex-col gap-[10px] w-full"
                            >
                              <div className="relative">
                                {(!!image) && <>
                                  <button
                                    className="absolute top-[10px] right-[10px] z-[1]"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleClickRm(item);
                                    }} >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="#FF3755" />
                                      <mask id="mask0_695_11536" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="16">
                                        <path d="M13.7498 2.5C12.2998 2.5 10.9082 3.16213 9.99984 4.20845C9.0915 3.16213 7.69984 2.5 6.24984 2.5C3.68317 2.5 1.6665 4.4782 1.6665 6.99591C1.6665 10.0858 4.49984 12.6035 8.7915 16.4292L9.99984 17.5L11.2082 16.421C15.4998 12.6035 18.3332 10.0858 18.3332 6.99591C18.3332 4.4782 16.3165 2.5 13.7498 2.5Z" fill="white" />
                                      </mask>
                                      <g mask="url(#mask0_695_11536)">
                                        <rect width="20" height="20" fill="#FF3755" />
                                      </g>
                                    </svg>
                                    <span className="sr-only">찜 취소 하기</span>
                                  </button>
                                  <div className={`items-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] absolute flex flex-col z-[2] w-[auto] h-[auto] gap-[10px]`}>
                                    <p className={`w-[100px] text-[15px] font-bold leading-[140%] text-[#FFFFFF]`} style={{ textAlign: 'center', wordBreak: 'keep-all' }}>{decode(title)}</p>
                                  </div>
                                  <div
                                    className="absolute w-full rounded-[10px] bg-no-repeat bg-cover bg-center"
                                    style={(!!image) ? {
                                      background: `rgba(0, 0, 0, 0.4)`,
                                      height: '180px'
                                    } : {}}
                                  >
                                  </div>
                                  {image && <img className={`w-full rounded-[10px] bg-no-repeat bg-cover bg-center`} src={`${typeof image === 'string' ? image : image?.url}`} alt={`${decode(title)}의 대표 이미지`}
                                    style={(!!image) ? {
                                      background: `rgba(0, 0, 0, 0.4)`,
                                      height: '180px'
                                    } : {}} />}
                                  <span className="sr-only">{`${decode(title)}의 대표 이미지`}</span>
                                </>
                                }
                                {(!image) && <Skeleton variant="rounded" width="100%" height={'180px'} />}
                              </div>
                            </NavLink>
                          );
                        })}
                    </div>
                    :
                    <div className={`grid grid-cols-2 gap-x-[15px] gap-y-[20px] text-[13px] leading-[160%] px-[20px] pt-[20px]`}>
                      {recent.map((item, i) => {
                        const { id, title, image, slug } = item
                        return (
                          <NavLink
                            to={`/alltrip/post/${slug}`}
                            key={`section-item-${id || i}`}
                            className="flex flex-col gap-[10px] w-full"
                          >
                            <div className="relative">
                              {(!!image) && <>
                                <button
                                  className="absolute top-[10px] right-[10px] z-[1]"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleClickRm({  id, content: item  });
                                  }}
                                >
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="9.5" stroke="white" />
                                    <g clipPath="url(#clip0_1820_5393)">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M14.6666 6.27337L13.7266 5.33337L9.99992 9.06004L6.27325 5.33337L5.33325 6.27337L9.05992 10L5.33325 13.7267L6.27325 14.6667L9.99992 10.94L13.7266 14.6667L14.6666 13.7267L10.9399 10L14.6666 6.27337Z" fill="white" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1820_5393">
                                        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                                      </clipPath>
                                    </defs>
                                  </svg>

                                  <span className="sr-only">최근 확인한 숙소 삭제 하기</span>
                                </button>
                                <div className={`items-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] absolute flex flex-col z-[2] w-[auto] h-[auto] gap-[10px]`}>
                                  <p className={`w-[100px] text-[15px] font-bold leading-[140%] text-[#FFFFFF]`} style={{ textAlign: 'center', wordBreak: 'keep-all' }}>{decode(title)}</p>
                                </div>
                                <div
                                  className="absolute w-full rounded-[10px] bg-no-repeat bg-cover bg-center"
                                  style={(!!image) ? {
                                    background: `rgba(0, 0, 0, 0.4)`,
                                    height: '180px'
                                  } : {}}
                                >
                                </div>
                                {image && <img className={`w-full rounded-[10px] bg-no-repeat bg-cover bg-center`} src={`${typeof image === 'string' ? image : image?.url}`} alt={`${decode(title)}의 대표 이미지`}
                                  style={(!!image) ? {
                                    background: `rgba(0, 0, 0, 0.4)`,
                                    height: '180px'
                                  } : {}} />}
                                <span className="sr-only">{`${decode(title)}의 대표 이미지`}</span>
                              </>
                              }
                              {(!image) && <Skeleton variant="rounded" width="100%" height={'180px'} />}
                            </div>
                          </NavLink>
                        );
                      })}
                    </div>
                ) :
                (
                  <div className="grow flex flex-col justify-center items-center h-max gap-8">
                    <FavoriteBorderIcon fontSize="large" />
                    <div className="text-center">
                      <p>아직 찜한 컨텐츠가 없어요.</p>
                      <p>마음에 드는 컨텐츠를 찜하세요!</p>
                    </div>
                </div>
                  )
            ) :
              (
                <div className="grow flex flex-col justify-center items-center h-max gap-8">
                  <FavoriteBorderIcon fontSize="large" />
                  <div className="text-center">
                    <p>지금 바로 올스테이에 로그인하고</p>
                    <p>마음에 드는 컨텐츠를 찜하세요!</p>
                  </div>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      width: '75%',
                    }}
                    onClick={() => navigate('/auth')}
                  >
                    로그인하기
                  </Button>
                </div>
              )
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={(title.length > 8 ? `"${title.slice(0, 8)}..."` : `"${title}"`) + (type === INTEREST_TYPE.FAVORITES ? ' 찜을 해제했어요' : ' 확인 기록을 삭제했어요.')}
            action={action}
          />
        </div>
    </>
    </>
  );
}

export default Content;

import React from 'react';

function HeroRest({
  children,
  onClick,
}) {
  return (
    <button
      className="rounded bg-navy-600 text-white-100 text-xs font-bold"
      onClick={onClick}
    >
      <span>+ {children}</span>
    </button>
  );
}

export default HeroRest;

import React, { useCallback, useLayoutEffect, useState } from 'react';
import { IconButton, Slider } from '@mui/material';
import { Clear } from '@mui/icons-material';
import {
  getMinorDefault,
  getGuestInfoDefault,
  subscribeRoomInfo,
  setRoomInfo,
  getRoomInfo,
} from '@stream/roomInfo';

const reNotNum = /^0+|[^\d]/g;

export function SingleRoomInfoInput({
  id,
  index,
  removeGuestInfo,
  updateGuestInfo,
  adult,
  minor,
  isRemovable,
}) {
  const getMinorDefaultCallback = useCallback(() => getMinorDefault(id), [id]);

  const createClickAdultControlHandler = op => () => {
    let newAdult;
    let newVal = op === '+' ? adult + 1 : adult - 1;
    newAdult = newVal < 1 ? 1 : (newVal >= 9999 ? 9999 : newVal);
    updateGuestInfo({ adult: newAdult });
  };

  const handleDirectlyChangeInput = e => {
    const parsed = parseInt(e.target.value.replace(reNotNum, '') || 1);
    const adult = parsed >= 9999 ? 9999 : parsed;
    updateGuestInfo({ adult });
  };

  const handleChangeMinorAge = id => v => {
    const newMinor = [...minor];
    const foundIndex = newMinor.findIndex(m => m.id === id);
    if (foundIndex < 0) return;
    newMinor[foundIndex].age = v;
    updateGuestInfo({ minor: [...newMinor] });
  };

  const handleRemoveMinor = () => updateGuestInfo({ minor: [...minor.slice(0, minor.length - 1)] });

  const handleAddMinor = () => updateGuestInfo({ minor: [...minor, getMinorDefaultCallback()] });


  return (
    <div className="flex flex-col px-[20px] gap-[30px]">
      <div className="flex flex-col border-b border-b-[#EEEEEE] w-full pb-[25px] gap-[20px]">
        <div className="flex justify-between items-center">
          <h3 className="font-regular text-[13px] leading-[160%] text-[#777777]">객실 {index + 1}</h3>
          <IconButton disabled={!isRemovable} onClick={removeGuestInfo}>
            <Clear fontSize="small" />
          </IconButton>
        </div>
        <div className="flex justify-between items-center">
          <label className="font-regular text-[15px] leading-[160%] text-[#1E1E1E]">성인</label>
          <div className="flex items-center gap-[7px]">
            <button
              className="flex justify-center items-center w-[32px] h-[32px] border border-[#E0E0E0] rounded-[4px] drop-shadow-[0_2px_8px_2px_rgba(0,0,0,0.02)]"
              onClick={createClickAdultControlHandler('-')}
            >
              <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0V2H0V0H8Z" fill="black" />
              </svg>
              <span className="sr-only">객실 {index + 1}의 성인 인원수 1 감소 시키기</span>
            </button>
            <input
              id={`guest-info-${id}-adult-num`}
              name={`guest-info-${id}-adult-num`}
              className="pt-[3px] w-[32px] h-[32px] text-center border-0 font-bold text-[16px] leading-[160%] text-[#1E1E1E]"
              value={adult}
              onChange={handleDirectlyChangeInput}
            />
            <button
              className="flex justify-center items-center w-[32px] h-[32px] border border-[#E0E0E0] rounded-[4px] drop-shadow-[0_2px_8px_2px_rgba(0,0,0,0.02)]"
              onClick={createClickAdultControlHandler('+')}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 3V0H5V3H8V5H5V8H3V5H0V3H3Z" fill="black" />
              </svg>
              <span className="sr-only">객실 {index + 1}의 성인 인원수 1 증가 시키기</span>
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col font-regular text-[15px] leading-[160%] text-[#1E1E1E]">
            <label>아동</label>
            <span className="text-[11px] text-[#777777]">만 0세 ~ 11세</span>
          </div>
          <div className="flex items-center">
            <button
              className="flex justify-center items-center w-[32px] h-[32px] border border-[#E0E0E0] rounded-[4px] drop-shadow-[0_2px_8px_2px_rgba(0,0,0,0.02)]"
              onClick={handleRemoveMinor}
            >
              <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0V2H0V0H8Z" fill="black" />
              </svg>
              <span className="sr-only">객실 {index + 1}의 아동 인원수 1 감소 시키기</span>
            </button>
            <span className="pt-[3px] flex justify-center items-center min-w-[46px] h-[32px] font-bold text-[16px] leading-[160%] text-[#1E1E1E]">
              {minor.length}
            </span>
            <button
              className="flex justify-center items-center w-[32px] h-[32px] border border-[#E0E0E0] rounded-[4px] drop-shadow-[0_2px_8px_2px_rgba(0,0,0,0.02)]"
              onClick={handleAddMinor}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 3V0H5V3H8V5H5V8H3V5H0V3H3Z" fill="black" />
              </svg>
              <span className="sr-only">객실 {index + 1}의 아동 인원수 1 증가 시키기</span>
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-[20px]">
          {
            minor.map(({ id: minorId, age }, minorIndex) => {
              return (
                <div key={`guest-info-${id}-minor-${minorId}`} className="flex flex-col gap-[10px]">
                  <div className="flex flex-col font-regular text-[15px] leading-[160%] text-[#1E1E1E]">
                    <label>아동 {minorIndex + 1}</label>
                    {minorIndex === 0 && <span className="text-[11px] text-[#777777]">아동 나이는 투숙일 기준 만 나이로 선택해주세요.</span>}
                  </div>
                  <div className="flex justify-between">
                    <Slider key={`guest-info-${id}-minor-${minorId}-age`}
                      value={age || 0} step={1} marks min={0} max={11} valueLabelDisplay="on"
                      valueLabelFormat={value => `${value}세`}
                      sx={{
                        '& .MuiSlider-track': {
                          height: '8px',
                          color: '#4A90E2',
                        },
                        '& .MuiSlider-rail': {
                          height: '8px',
                          color: '#DADADA'
                        },
                        '& .MuiSlider-thumb': {
                          color: '#FFFFFF',
                        },
                        '& .MuiSlider-mark': {
                          width: '1px',
                          height: '4px',
                          color: 'rgba(0, 0, 0, 0.12)',
                        },
                        '& .MuiSlider-valueLabelOpen.MuiSlider-valueLabel': {
                          top: '43px',
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: '#777777',
                          padding: 'auto 0px',
                          fontSize: '10px',
                        }
                      }}
                      onChange={event => {
                        const onClickCallback = handleChangeMinorAge(minorId);
                        onClickCallback(event.target.value)
                      }} />
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}


function RoomInfoInput() {
  const [guestInfo, setGuestInfo] = useState(getRoomInfo());

  const getGuestInfo = useCallback(() => [...guestInfo], [guestInfo]);

  useLayoutEffect(() => {
    subscribeRoomInfo(setGuestInfo);
  }, [setGuestInfo]);

  const createUpdateGuestInfoHandler = id => ({ adult: newAdult, minor: newMinor, id: newId }) => {
    const _ = getGuestInfo();
    const foundIndex = _.findIndex(g => id === g.id);

    const targetGuestInfo = { ..._[foundIndex] };
    const defaultGuestInfo = getGuestInfoDefault();

    if (foundIndex < 0) {
      setRoomInfo([..._]);
      return;
    }

    _[foundIndex] = {
      adult: newAdult || targetGuestInfo.adult || defaultGuestInfo.adult,
      minor: [...(newMinor || targetGuestInfo.minor || defaultGuestInfo.minor)],
      id: newId || targetGuestInfo.id || defaultGuestInfo.id,
    };

    setRoomInfo([..._]);
  };
  const createRemoveGuestInfoHandler = id => () => {
    const _ = getGuestInfo();
    if (_.length === 1) return;
    setRoomInfo(_.filter(g => id !== g.id));
  };
  const addGuestInfo = () => {
    const _ = getGuestInfo();
    setRoomInfo([..._, { ...getGuestInfoDefault() }])
  };

  return (
    <div className="flex flex-col gap-[30px] pb-[50px]">
      {guestInfo.map(({ id, adult, minor }, i) => (
        <SingleRoomInfoInput
          id={id}
          index={i}
          key={`guest-info-${id}`}
          adult={adult}
          minor={minor}
          updateGuestInfo={createUpdateGuestInfoHandler(id)}
          removeGuestInfo={createRemoveGuestInfoHandler(id)}
          isRemovable={guestInfo.length > 1}
        />
      ))}
      <button
        className="
          flex
          justify-center
          items-center
          bg-white-100
          font-bold
          text-[13px]
          leadint-[160%]
          text-[#4A90E2]
        "
        onClick={addGuestInfo}
      >
        + 객실 추가하기
      </button>
      <ul className='mx-[20px] py-[10px] px-[30px] bg-[#f4f4f4] text-[12px]' style={{ borderRadius: '8px', listStyleType: 'disc' }}>
        <li>나이는 체크인 시점 만 나이 기준입니다.</li>
        <li>보조침대 추가 시 호텔에 문의해야 합니다.</li>
        <li>아동 추가 시 별도의 침대는 추가되지 않습니다.</li>
      </ul>
    </div>
  );
}

export default RoomInfoInput;
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const calcClassName = ({ isActive }) => {
  const colorClass = isActive ? 'font-bold text-[#4A90E2]' : 'font-regular text-[#1E1E1E]';
  const borderClass = isActive ? 'border-b-2 border-[#4A90E2] border-solid' : 'border-b border-[#F5F5F5] border-solid';
  return ['h-full basis-1 grow flex justify-center items-center leading-[160%] text-[13px]', colorClass, borderClass ].join(' ');
};

const filterInactiveClass = 'py-[4px] px-[14px] border border-[#E0E0E0] bg-[#FFFFFF] text-[#1E1E1E] rounded-full';
const filterActiveClass = 'py-[4px] px-[14px] bg-[#4A90E2] text-[#FFFFFF] rounded-full';

const INTEREST_TYPE = {
  FAVORITES: 'favorites',
  RECENTLY: 'recently-viewed',
};

function Interest() {
  
  return (
    <main id="allstay-main">
      <div className="allstay-contents">
        <div className="min-h-contents-container flex flex-col">
          <div className="flex flex-col sticky top-0 bg-white-100 z-100">
            <div className="relative flex w-full justify-center items-center pt-[30px] pb-[16px]">
              <NavLink to="/" className="absolute left-[20px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 17V9.28L10 3L17 9.28V17H3Z" stroke="black" strokeWidth="1.5"/>
                  <path d="M10 12L10 17" stroke="black" strokeWidth="1.5"/>
                </svg>
                <span className="sr-only">홈으로 돌아가기</span>
              </NavLink>
              <h1 className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">관심 목록</h1>
            </div>
            <nav className="flex justify-evenly items-center w-full h-[42px]">
              <NavLink to="/favorites/stay" className={calcClassName}>숙소</NavLink>
              <NavLink to="/favorites/contents" className={calcClassName}>컨텐츠</NavLink>
            </nav>
          </div>
          <Outlet context={{INTEREST_TYPE, filterInactiveClass, filterActiveClass}}/>
        </div>
      </div>
    </main>
  );
}

export default Interest;

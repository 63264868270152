import { Route } from 'react-router-dom';
import {
  Interest,
  Stay,
  Content,
} from '@page/interest'

const IterestRouteGroup = (
  <Route path="/favorites" element={<Interest />}>
    <Route path="stay" element={<Stay />} replace={true} />
    <Route path="contents" element={<Content />} replace={true} />
  </Route>
);

export default IterestRouteGroup;

export const interestActivatingRootPaths = [
  'favorites',
  'recently-viewed',
];

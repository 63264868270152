import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { BackButton } from '@comp/BackButton';

function Service() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="allstay-main">
      <div className="allstay-contents allstay-contents-box">
        <div className="flex px-6 py-[20px] sticky top-0 bg-white-100 allstay-about-toolbar">
          <BackButton />
        </div>
        <div className="px-6">
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default Service;

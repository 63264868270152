import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import { differenceInDays, addDays, addMonths } from 'date-fns';
import { ko as koLocale } from 'react-date-range/dist/locale';
import {
  subscribeSchedule,
  getSchedule,
  setSchedule,
} from '@stream/schedule';
import { useLayoutEffect } from 'react';

function ScheduleInput() {
  const [dateRange, setDateRange] = useState(getSchedule());

  useLayoutEffect(() => {
    subscribeSchedule(setDateRange);
  }, [setDateRange]);

  const handleChange = ({ schedule: {startDate, endDate, ...rest } }) => {
    setSchedule([{
      startDate: new Date(startDate),
      endDate: new Date(differenceInDays(endDate, startDate) < 1 ? addDays(startDate, 1) : endDate),
      ...rest,
    }]);
  };

  return (
    <div className="h-fit overflow-hidden transition-all duration-300">
      <DateRange
        dateDisplayFormat="yyyy-MM-dd"
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
        months={2}
        direction="vertical"
        ranges={dateRange}
        locale={koLocale}
        minDate={new Date()}
        maxDate={addMonths(new Date(), 13)}
        className="allstay-date-range-wrapper"
        showDateDisplay={true}
        onChange={handleChange}
      />
    </div>
  );
}

export default ScheduleInput;

import { useMemo, useState } from 'react';

export function useFeatured(data) {
  const [_activeIndex, setActiveIndex] = useState(0);
  const nations = useMemo(() => data.map(({ nation_name, nation_code, seo_meta }) => ({ nation_name, nation_code, seo_meta })), [data]);
  const cities = useMemo(() => data.map(({ content_list, nation_name, nation_code }) => content_list.map(c => ({
    ...c,
    nation_name,
    nation_code,
  }))), [data]);
  const activeIndex = useMemo(() => _activeIndex, [_activeIndex]);
  const activeNation = useMemo(() => nations[_activeIndex], [nations, _activeIndex]);
  const activeCities = useMemo(() => cities[_activeIndex], [cities, _activeIndex]);
  
  return {
    nations,
    cities,
    activeIndex,
    activeNation,
    activeCities,
    setActiveIndex,
  };
}

export default useFeatured;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getProfile } from '@service/auth'
import { setUserInfo, getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { checkIsLogedOutUser } from '@util/checkUserLogState';
import { H2 } from '@comp/StyledParagraph';
import { ReactComponent as FavoriteIcon } from '@asset/icon/favorite-1e.svg';
import { ReactComponent as BellIcon } from '@asset/icon/bell-1e.svg';
import { ReactComponent as InfoIcon } from '@asset/icon/info-1e.svg';
import { ReactComponent as QuestionIcon } from '@asset/icon/question-1e.svg';
import { ReactComponent as ChatIcon } from '@asset/icon/chat_alt_add-1e.svg';
import { ReactComponent as OrderIcon } from '@asset/icon/order-1e.svg';
import { ReactComponent as SignOutIcon } from '@asset/icon/sign_out_square-1e.svg';
import { ReactComponent as ArrowLeftIcon } from '@asset/icon/arrow_left.svg'

import { Helmet } from 'react-helmet';
import FullWidthBtn from '@comp/FullWidthBtn/FullWidthBtn';

function MyPage() {
  const navigate = useNavigate();

  const [isLogedOutUser, setIsLogedOutUser] = useState(checkIsLogedOutUser(getUserInfo()));
  const [subscribe, setSubscribe] = useState();

  useEffect(() => {
    subscribeUserInfo(u => {
      setIsLogedOutUser(checkIsLogedOutUser(u));
    });
  }, []);

  useLayoutEffect(() => {
    if (!isLogedOutUser) {
      getProfile().then((data) => { setSubscribe(data); })
    }
  }, [isLogedOutUser]);

  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState} />
      <div className="min-h-contents-container">
        <div className="px-5 py-2">
          <div className="flex w-full h-12 justify-center items-center gap-3 pr-9">
            <button onClick={() => window.history.length <= 2 ? navigate('/') : navigate(-1)} >
              <ArrowLeftIcon />
            </button>
            <h2 className="w-full font-bold text-lg text-allstay-gray-1e text-center">마이 페이지</h2>
          </div>
        </div>
        <div className="w-full h-48 px-5 pt-16 pb-7 bg-allstay-gray-ee flex-col justify-start items-start gap-10 inline-flex">
          {isLogedOutUser ?
            (
              <>
                <H2>로그인하고 <br /> 올스테이 할인 혜택을 누리세요!</H2>
                <Link to="/auth">
                  <strong className="font-bold text-[14px] leading-[160%] text-[#4A90E2]">로그인 / 회원가입</strong>
                </Link>
              </>
            ) :
            (
              <>
                <div className="text-allstay-gray-1e text-base font-bold leading-normal">{getUserInfo().payload.name}님, <br /> 다시 오셔서 반가워요!</div>
                <NavLink to="/my-profile/edit" className="text-blue-500 text-sm font-bold leading-tight">
                  회원정보 수정
                </NavLink>
              </>
            )}

        </div>
        <div className="w-full bg-allstay-gray-ee flex-col justify-start items-start gap-3 inline-flex">
          <FullWidthBtn Icon={FavoriteIcon} to="/favorites/stay">관심 목록</FullWidthBtn>
          {!isLogedOutUser && (
            <FullWidthBtn
              Icon={BellIcon}
              SubFuncUi={() => <NavLink to="/my-profile/alarm" state={{ ...subscribe }} className={subscribe?.subscribe !== '1' ? 'text-allstay-gray-9e' : ''}>{subscribe?.subscribe === '1' ? '수신 동의' : '수신 비동의'}</NavLink>}
            >
              마케팅 푸쉬
            </FullWidthBtn>
          )}
          <div className="w-full flex-col justify-start items-start inline-flex">
            <FullWidthBtn to="/notice" Icon={InfoIcon}>공지사항</FullWidthBtn>
            <FullWidthBtn Icon={QuestionIcon} to="/faq">FAQ</FullWidthBtn>
            <FullWidthBtn Icon={ChatIcon} to="https://pf.kakao.com/_xdxmEQxd" target="_blank">카카오톡 문의하기</FullWidthBtn>
          </div>
          <div className="w-full flex-col justify-start items-start inline-flex">
            <FullWidthBtn Icon={OrderIcon} to="/terms-of-use">이용약관</FullWidthBtn>
            <FullWidthBtn Icon={OrderIcon} to="/privacy-policy">개인정보 처리방침</FullWidthBtn>
          </div>
          {!isLogedOutUser && <FullWidthBtn Icon={SignOutIcon} onClick={() => {navigate('/');setUserInfo();}}>로그아웃</FullWidthBtn>}
        </div>
      </div>
    </>
  );
};

export default MyPage;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  TextField,
  IconButton,
  Button,
  // ToggleButtonGroup,
  // ToggleButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionActions,
  Select,
  MenuItem,
  // Switch,
} from '@mui/material';
import {
  ArrowBack,
  Check,
  VisibilityOff,
  Visibility,
  // CalendarMonth,
  TaskAltOutlined,
  CheckCircleOutline,
  PanoramaFishEye,
  RemoveCircleOutline,
  ExpandMore,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
// import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import * as dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import { closeResetModal, openModal, setModal } from '@store/modal';
import { checkSerial, sendSerial, submitingEmailSignup } from '@service/auth';

import serviceTermMD from '@md/terms-of-use.md';
import privacyInfoMD from '@md/privacy-info.md';

import privacy20221122 from '@md/privacy-policy/20221122.md';
import privacy20200616 from '@md/privacy-policy/20200616.md';
import privacy20171120 from '@md/privacy-policy/20171120.md';
import privacy20151101 from '@md/privacy-policy/20151101.md';
import { PasswordKeyDownHelperText } from '@comp/PasswordKeyDownHelperText';
import { Helmet } from 'react-helmet';

const privacyArchive = {
  privacy20221122,
  privacy20200616,
  privacy20171120,
  privacy20151101,
}


const PrivacyModal = () => {
  const [ver, setVer] = useState('privacy20221122');

  return (
    <div className="bg-white-100 rounded flex flex-col w-full max-w-modal h-fullmodal">
      <FormControl fullWidth variant="outlined">
        <Select
          id="privacy-term-ver-select"
          value={ver}
          onChange={e => setVer(e.target.value)}
        >
          <MenuItem value={'privacy20221122'}>20221122 (현재 버전)</MenuItem>
          <MenuItem value={'privacy20200616'}>20200616</MenuItem>
          <MenuItem value={'privacy20171120'}>20171120</MenuItem>
          <MenuItem value={'privacy20151101'}>20151101</MenuItem>
        </Select>
      </FormControl>
      <section className="term overflow-auto"
        style={{
          overflow: 'auto',
        }}
      >
        <ReactMarkdown>{privacyArchive[ver]}</ReactMarkdown>
      </section>
      <Button
        onClick={closeResetModal}
        size="large"
        color="success"
      >
        <span className="w-full">확인</span>
      </Button>
    </div>
  );
};

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  gap: 0,
  padding: 0,
  '&:before': {
    display: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'stretch',
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ExpandMore />} {...props}/>
))(() => ({
  margin: 0,
  padding: '4px 16px',
  width: '100%',
  backgroundColor: 'white',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  maxHeight: '300px',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionDetails-content': {
    margin: 0,
    minWidth: '100%',
  },
}));

const letCountDownTimer = (read, m=5, s=0) => {
  let time = dayjs().set('minute', m).set('second', s).valueOf();
  const r = () => {
    typeof read === 'function' && read(dayjs(time).format('mm:ss'));
  };
  r();

  const timerId = setInterval(() => {
    time = dayjs(time).subtract(1, 'second');
    r();
  }, 1000);

  return timerId;
};

const reName = /(^[가-힣]+$)|(^[a-z|A-Z]+(\s[a-z|A-Z]+)+$)/;
const reEng = /[a-z|A-Z]/;
const reKor = /[가-힣]/;
const reMultipleSpace = /\s{2,}/;
const reKorSpace = /[가-힣]\s[가-힣]/;
const reNonAlpha = /[^가-힣|a-z|A-Z|\s]/;
const reAnEngWord = /^[a-z|A-Z]+$/;
const reForUpdatePw = /\s|[ㄱ-ㅎ|가-힣|ㅏ-ㅣ]/g;

function EmailSignUp() {
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);

  // 이름
  const [name, setName] = useState('');
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isNameErr, setIsNameErr] = useState(false);
  const [nameHelperTexts, setNameHelperTexts] = useState([]);
  
  // 이메일
  const [email, setEmail] = useState('');
  const reqEmailBtnRef = useRef();
  const [isRequestingEmail, setIsRequestingEmail] = useState(false);
  const [isRequestedEmail, setIsRequestedEmail] = useState(false);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [emailHelperTexts, setEmailHelperTexts] = useState([]);
  
  // 인증번호
  const [serial, setSerial] = useState('');
  const [isSerialDirty, setIsSerialDirty] = useState(false);
  const confirmSerialBtnRef = useRef();
  const [isSerialErr, setIsSerialErr] = useState(false);
  const [serialHelperTexts, setSerialHelperTexts] = useState([]);
  const [isCheckingSerial, setIsCheckingSerial] = useState(false);
  // const [isCheckedSerial, setIsCheckedSerial] = useState(false);
  const [isPassedSerial, setIsPassedSerial] = useState(false);
  const [countdown, setCountdown] = useState('');
  const [timerId, setTimerId] = useState();
  const [isTimeOut, setIsTimeOut] = useState(false);

  // 비밀번호
  const [pw, setPw] = useState('');
  const [isPwDirty, setIsPwDirty] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [confirmPw, setConfirmPw] = useState('');
  const [pwHelperTexts, setPwHelperTexts] = useState([]);
  const [isPwErr, setIsPwErr] = useState(false);
  const [howManyTypesInvalidCharacterPw, setHowManyTypesInvalidCharacterPw] = useState(0);

  // 생년월일
  // const [birthday, setBirthday] = useState(null);
  
  // 성별
  // const [gender, setGender] = useState();

  // 동의
  const [serviceTerm, setServiceTerm] = useState(false);
  const [ecommerceTerm, setEcommerceTerm] = useState(false);
  const [privacyInfo, setPrivacyInfo] = useState(false);
  const [marketingAgree, setMarketingAgree] = useState(false);
  const [overFourteen, setOverFourteen] = useState(false);
  const [allAgree, setAllAgree] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  // accordions
  const [expAccordion, setExpAccordion] = useState('');
  const accordionData = [{
    name: 'serviceTerm',
    label: '올스테이 이용약관 동의 (필수)',
    content: serviceTermMD,
    state: serviceTerm,
    setState: e => setServiceTerm(e.target.checked),
  }, {
    name: 'privacyInfo',
    label: '개인정보 수집/이용에 대한 동의 (필수)',
    content: privacyInfoMD,
    state: privacyInfo,
    setState: e => setPrivacyInfo(e.target.checked),
    action: {
      actionName: '개인정보처리방침 보기',
      actionHandler: () => {
        setModal({
          isClosable: true,
          isOpened: true,
          template: PrivacyModal,
        });
      }
    },
  }];

  // submit button
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  // navigation instance




  // controls
  // const [isPanelOpen, setIsPanelOpen] = useState(false);
  // const [passingEmail, setPassingEmail] = useState(false);
  // const [emailFailedCase, setEmailFailedCase] = useState('just-failed');
  // const [passingSerial, setPassingSerial] = useState(false);
  // const [successSignUp, setSuccessSignUp] = useState(false);


  // 이름
  useEffect(() => {
    if (!isNameDirty && !name.length) return;
    const _name = name.trim();
    const texts = [];
    let err = false;
    if (!_name.length) {
      err = err || true;
      texts.push({ text: '이름을 입력해 주세요', key: 'not-filled'});
    } else if (!reName.test(_name) || reAnEngWord.test(_name)) {
      err = err || true;
      if (reKor.test(_name) && reEng.test(_name)) texts.push({ text: '영문자와 한글을 섞어 사용할 수 없습니다.', key: 'mixed-locale' });
      if (reMultipleSpace.test(_name)) texts.push({ text: '공백을 연속으로 사용할 수 없습니다.', key: 'multiple-space' });
      if (reKorSpace.test(_name)) texts.push({ text: '한글 이름에는 공백을 사용할 수 없습니다.', key: 'spaced-kor' });
      if (reNonAlpha.test(_name)) texts.push({ text: '특수문자는 사용할 수 없습니다.', key: 'non-alpha' });
      if (reAnEngWord.test(_name)) texts.push({ text: '영문 이름은 "이름 성" 순서로 공백 구분해 주세요.', key: 'non-valid-eng-name' });
    };
    setNameHelperTexts(texts);
    setIsNameErr(err);
  }, [name, isNameDirty]);


  // 이메일
  const handleEnterUpEmail = e => {
    if (e.key === 'Enter' && !!reqEmailBtnRef && !!reqEmailBtnRef.current) reqEmailBtnRef.current.click();
  };

  const handleClickRequestEmail = e => {
    e.preventDefault();
    setIsTimeOut(false);
    setIsRequestingEmail(true);
    sendSerial(email)
    .then(res => {
      if (res.isOK) {
        setIsRequestedEmail(true);
        setEmailHelperTexts([]);
        resetSerial();
        setTimerId(letCountDownTimer(setCountdown));
        return;
      }
      setIsRequestedEmail(false);
      if (!!res.error) {
        let { code, message } = res.error;
        if (code === 'USED_EMAIL') {
          setIsEmailErr(true);
          setEmailHelperTexts([{ text: message, key: code.toLowerCase().replace('_', '-') }]);
        } else {
          setIsEmailErr(false);
          setEmailHelperTexts([{ text: '알수 없는 이유로 발송에 실패했습니다. 잠시후 다시 시도해 주세요.', key: 'error-unknown' }]);
        }
      } else {
        setIsEmailErr(false);
        setEmailHelperTexts([{ text: '인증번호 발송에 실패했습니다. 잠시후 다시 시도해 주세요.', key: 'just-failed' }]);
      }
    }).finally(() => {
      setIsRequestingEmail(false);
    });
  };

  const handleClickCancelEmail = () => {
    setIsRequestingEmail(false);
    setIsRequestedEmail(false);
    setEmailHelperTexts([]);
    setIsCheckingSerial(false);
    // setIsCheckedSerial(false);
    setIsPassedSerial(false);
    setIsTimeOut(false);
    setIsEmailDirty(false);
    stopTimer();
    setEmail('');
    setSerial('');
    setCountdown('');
    setTimerId();
  };

  useEffect(() => {
    if (!isEmailDirty && !email.length) return;
    const texts = [];
    let err = false;
    const _email = email.trim();
    if (!_email.length) {
      err = err || true;
      texts.push({ text: '이메일 주소를 입력해 주세요.', key: 'not-filled' });
    } else if (!isEmail(_email)) {
      err = err || true;
      texts.push({ text: '올바른 이메일 주소 형식이 아닙니다.', key: 'non-email-form' });
    }
    setEmailHelperTexts(texts);
    setIsEmailErr(err);
  }, [email, isEmailDirty]);


  // 인증번호
  const handleEnterUpSerial = e => {
    if (e.key === 'Enter' && !!confirmSerialBtnRef && !!confirmSerialBtnRef.current) confirmSerialBtnRef.current.click();
  };

  const resetSerial = () => {
    stopTimer();
    setIsCheckingSerial(false);
    // setIsCheckedSerial(false);
    setIsPassedSerial(false);
    setIsSerialDirty(false);
    setSerialHelperTexts([]);
    setIsSerialErr(false);
    setSerial('');
  };

  const handleClickCheckSerial = e => {
    e.preventDefault();
    setIsCheckingSerial(true);
    // 인증번호 확인 요청
    checkSerial(email, serial)
    .then(res => {
      if (res.isOK) {
        setIsPassedSerial(true); setIsSerialErr(false); stopTimer();
        return;
      }
      setIsPassedSerial(false); setIsSerialErr(true); setSerialHelperTexts([{ text: '인증번호를 다시 확인해 주세요.', key: 'not-passed'}]);
    }).finally(() => {
      setIsCheckingSerial(false);
    });
  };

  useEffect(() => {
    if (!isSerialDirty && !serial.length) return;
    const texts = [];
    let err = false;
    const _serial = serial.trim();
    if (!_serial.length) {
      err = err || true;
      texts.push({ text: '인증번호를 입력해 주세요.', key: 'not-filled' });
    } else if (!/^[a-z|A-Z|0-9]{6}$/.test(_serial)) {
      err = err || true;
      texts.push({ text: '인증번호 형식에 맞지 않습니다.', key: 'non-alpha-numeric' });
    }
    setIsSerialErr(err);
    setSerialHelperTexts(texts);
  }, [serial, isSerialDirty]);

  const stopTimer = useCallback(() => {
    if (timerId) {
      clearInterval(timerId);
    }
  }, [timerId]);

  useEffect(() => {
    if (countdown === '00:00') {
      stopTimer();
      setIsTimeOut(true);
      setIsPassedSerial(false);
      setIsSerialErr(true);
      setSerialHelperTexts([{ text: '인증 가능 기한이 만료 되었습니다. 재발송 또는 취소 후 재시도 해주세요.', key: 'timeout' }]);
    }
  }, [countdown, stopTimer, setIsTimeOut, setIsPassedSerial, setSerialHelperTexts]);


  // 비밀번호
  const handleClickShowPw = () => setShowPw(showPw => !showPw);

  const handleMouseDownPw = e => e.preventDefault();

  const handleKeyDownPws = (e = {}) => {
    if(reForUpdatePw.test(e.key)) {
      setHowManyTypesInvalidCharacterPw(prev => prev + 1);
      return;
    }
    setHowManyTypesInvalidCharacterPw(0);
  };

  useEffect(() => {
    if (!isPwDirty && (!pw.length || !confirmPw.length)) return;
    const helperTexts = [];
    let isErr = false;
    if (!isStrongPassword(pw, {
      minLength: 10,
    })) {
      isErr = isErr || true;
      helperTexts.push({ text: '암호는 영문 소문자, 대문자, 숫자, 기호의 조합으로 10자 이상이어야 합니다.', key: 'not-valid' });
    }
    if (pw !== confirmPw) {
      isErr = isErr || true;
      helperTexts.push({ text: '암호가 일치하지 않습니다.', key: 'not-confirmed' });
    }
    setIsPwErr(isErr);
    setPwHelperTexts(helperTexts);
  }, [pw, confirmPw, isPwDirty]);


  // 동의
  const toggleAllAgrees = () => {
    setAllAgree(prev => {
      const toggled = !prev;
      setServiceTerm(toggled);
      setEcommerceTerm(toggled);
      setPrivacyInfo(toggled);
      setMarketingAgree(toggled);
      setOverFourteen(toggled);
      return toggled;
    });
  };

  useEffect(() => {
    const agrees = [serviceTerm, ecommerceTerm, privacyInfo, marketingAgree, overFourteen];
    setAllAgree(agrees.reduce((acc, cur) => acc && cur, true));
    setIndeterminate(-1 < agrees.findIndex((v, _, arr) => v !== arr[0]));
  }, [
    serviceTerm,
    ecommerceTerm,
    privacyInfo,
    marketingAgree,
    overFourteen,
  ]);


  // accordions
  const handleClickAccordion = panelName => (e, newExpanded) => {
    setExpAccordion(newExpanded ? panelName : '');
  };


  // submit button
  useEffect(() => {
    setIsSubmitDisabled(
      !isNameDirty ||
      isNameErr ||
      !isEmailDirty ||
      isEmailErr ||
      !isSerialDirty ||
      isSerialErr ||
      !isPassedSerial ||
      !isPwDirty ||
      isPwErr ||
      !serviceTerm ||
      !ecommerceTerm ||
      !privacyInfo ||
      !overFourteen
    );
  }, [
    isNameDirty,
    isNameErr,
    isEmailDirty,
    isEmailErr,
    isSerialDirty,
    isSerialErr,
    isPassedSerial,
    isPwDirty,
    isPwErr,
    serviceTerm,
    ecommerceTerm,
    privacyInfo,
    overFourteen
  ]);


  // page-global
  useEffect(() => {
    return () => {
      if(timerId) clearInterval(timerId);
    };
  }, [timerId]);

  const handleClickSubmitButton = e => {
    e.preventDefault();
    const body = {
      user: {
        name,
        email,
        password: pw,
        // birthday: dayjs(birthday).format('YYYY-MM-DD'),
        // gender,
        // service_term: serviceTerm,
        // ecommerce_term: ecommerceTerm,
        // privacy_info: privacyInfo,
        subscribe: marketingAgree,
        // over_fourteen: overFourteen,
      },
    };
    setIsSubmiting(true);
    openModal();
    submitingEmailSignup(body)
    .then(res => {
      if (!!res.error) {
        setModal({
          isClosable: true,
          isOpened: true,
          template: () => (
            <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
              <div className="flex flex-col w-56 gap-6 text-gray-900-100">
                <h2 className="text-modal-h">회원 가입 실패</h2>
                <p className="text-modal-desc whitespace-normal">회원 가입에 실패하였습니다. 잠시 후 다시 시도해 주세요.</p>
              </div>
              <button
                className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
                onClick={closeResetModal}
              >
                <span className="w-full">확인</span>
              </button>
            </div>
          ),
        });
        return;
      }
      setModal({
        isClosable: false,
        isOpened: true,
        template: () => (
          <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
            <div className="flex flex-col w-56 gap-6 text-gray-900-100">
              <h2 className="text-modal-h">회원 가입 완료</h2>
              <p className="text-modal-desc whitespace-normal">회원 가입에 성공하였습니다. 확인을 누르면 이메일 로그인 화면으로 이동합니다.</p>
            </div>
            <button
              className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
              onClick={() => { navigate('/auth/email-sign-in'); closeResetModal(); }}
            >
              <span className="w-full">확인</span>
            </button>
          </div>
        ),
      });
    }).finally(() => {
      setIsSubmiting(false);
    });
  };

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div
      className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        pb-12
      "
    >
      <div className="px-6 mt-4">
        <IconButton
          onClick={() => navigate('..')}
          size="large"
          style={{ padding: 0 }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div
        className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-14
        "
      >
        <h2 className="text-screen-title">이메일로 회원가입</h2>
        <form className="flex flex-col">
          <div className="mb-10 flex flex-col gap-2.5">
            <TextField
              id="fullname"
              required
              name="fullname"
              label="이름"
              type="text"
              autoComplete="fullname"
              variant="outlined"
              value={name}
              onChange={e => {
                setIsNameDirty(true);
                setName(e.target.value);
              }}
              onBlur={e => {
                setIsNameDirty(true);
                setName(e.target.value.trim());
              }}
              disabled={isSubmiting}
              error={isNameErr}
            />
            {nameHelperTexts.map(({text, key}) =>
              <p className={`text-sub-link ${isNameErr ? 'text-red-500' : 'text-gray-600'}`} key={`name-${key}`}>
                {text}
              </p>
            )}
          </div>
          <div className="mb-10 flex flex-col gap-2.5">
            <TextField
              id="username"
              required
              name="username"
              label="이메일"
              type="text"
              autoComplete="username"
              variant="outlined"
              value={email}
              onChange={e => {
                setIsEmailDirty(true);
                setEmail(e.target.value);
              }}
              onBlur={e => {
                setIsEmailDirty(true);
                setEmail(e.target.value.trim());
              }}
              onKeyUp={handleEnterUpEmail}
              disabled={isSubmiting || isRequestedEmail || isRequestedEmail}
              error={isEmailErr}
              InputProps={{
                endAdornment: (<>
                  <div className="flex items-center gap-4 shrink">
                    {
                      isRequestedEmail &&
                      <Button
                        onClick={handleClickCancelEmail}
                        variant="outlined"
                        color="secondary"
                        className="whitespace-nowrap"
                      >
                        취소
                      </Button>
                    }
                    {
                      !isPassedSerial &&
                      <LoadingButton
                        onClick={handleClickRequestEmail}
                        variant="outlined"
                        loading={isRequestingEmail}
                        disabled={!isEmailDirty || isRequestingEmail || isEmailErr}
                        className="whitespace-nowrap"
                        ref={reqEmailBtnRef}
                      >
                        {`${isRequestedEmail ? '재' : ''}발송`}
                      </LoadingButton>
                    }
                  </div>
                </>)
              }}
            />
            {
              !isEmailErr && !isRequestedEmail && !isPassedSerial && !emailHelperTexts.length &&
              <p className="text-gray-600 text-sub-link">
                {
                  !isEmailDirty ?
                  '인증번호를 받을 수 있는 이메일 주소를 입력해주세요.' :
                  '"발송"버튼을 눌러주세요.'
                }
              </p>
            }
            {
              emailHelperTexts.map(({ text, key }) =>
                <p className="text-red-500 text-sub-link" key={`email-${key}`}>
                  {text}
                </p>
            )}
          </div>
          {
            isRequestedEmail &&
            <div className="mb-10 flex flex-col gap-2.5">
              <TextField
                id="serial-field"
                required
                name="serial"
                label="인증번호"
                type="text"
                variant="outlined"
                value={serial}
                onChange={e => {
                  setIsSerialDirty(true);
                  setSerial(e.target.value);
                }}
                onBlur={e => {
                  setIsSerialDirty(true);
                  setSerial(e.target.value.trim());
                }}
                onKeyUp={handleEnterUpSerial}
                disabled={isSubmiting || isPassedSerial || isTimeOut}
                error={isSerialErr}
                InputProps={{
                  endAdornment: (<>{
                    !!email && (isPassedSerial ? <Check color="success" /> : (
                      <div className="flex items-center gap-4">
                        <span className="text-timer text-gray-600">
                          {countdown}
                        </span>
                        {!isTimeOut && <LoadingButton
                          onClick={handleClickCheckSerial}
                          variant="outlined"
                          loading={isCheckingSerial}
                          disabled={!isRequestedEmail || isCheckingSerial || isPassedSerial || !serial || isSerialErr}
                          ref={confirmSerialBtnRef}
                        >
                          확인
                        </LoadingButton>}
                      </div>
                    ))
                  }</>)
                }}
              />
              {
                isRequestedEmail && (isSerialErr ?
                serialHelperTexts.map(({ text, key }) =>
                  <p className="text-red-500 text-sub-link" key={`serial-${key}`}>
                    {text}
                  </p>) :
                <p className="text-gray-600 text-sub-link">
                  {isPassedSerial ? '이메일 인증이 완료되었습니다.' : '이메일로 수신 된 인증번호를 입력해 주세요.'}
                </p>)
              }
            </div>
          }
          <div className="mb-10 flex flex-col gap-2.5">
            <FormControl variant="outlined">
              <InputLabel htmlFor="new-password" required>비밀번호</InputLabel>
              <OutlinedInput
                id="new-password"
                required
                name="new-password"
                type={showPw ? 'text' : 'password'}
                autoComplete="new-password"
                value={pw}
                onChange={e => {
                  setIsPwDirty(true);
                  setPw(e.target.value.replace(reForUpdatePw, ''));
                }}
                onBlur={e => {
                  handleKeyDownPws();
                  setIsPwDirty(true);
                  setPw(e.target.value.replace(reForUpdatePw, ''));
                }}
                onKeyDown={handleKeyDownPws}
                disabled={isSubmiting}
                error={isPwErr}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPw}
                      onMouseDown={handleMouseDownPw}
                    >
                      {showPw ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="비밀번호"
              />
            </FormControl>
            {
              isPwErr && pwHelperTexts.map(({ text, key }) =>
              <p className={`text-sub-link ${isPwErr ? 'text-red-500' : 'text-gray-600'}`} key={`pw-${key}`} >
                {text}
              </p>)
            }
            {
              !isPwDirty && <p className="text-sub-link text-gray-600">
                암호는 영문 소문자, 대문자, 숫자, 기호의 조합으로 8자 이상이어야 합니다.
              </p>
            }
            {
              showPw &&
              <PasswordKeyDownHelperText howManyTyped={howManyTypesInvalidCharacterPw}>한글이나 공백을 치고 계신것 같네요</PasswordKeyDownHelperText>
            }
          </div>
          <div className="mb-10 flex flex-col gap-2.5">
            <FormControl variant="outlined">
              <InputLabel htmlFor="confirm-password" required>비밀번호 확인</InputLabel>
              <OutlinedInput
                id="confirm-password"
                required
                name="confirm-password"
                type={showPw ? 'text' : 'password'}
                autoComplete="confirm-password"
                value={confirmPw}
                onChange={e => {
                  setIsPwDirty(true);
                  setConfirmPw(e.target.value.replace(reForUpdatePw, ''));
                }}
                onBlur={e => {
                  handleKeyDownPws();
                  setIsPwDirty(true);
                  setConfirmPw(e.target.value.replace(reForUpdatePw, ''));
                }}
                onKeyDown={handleKeyDownPws}
                disabled={isSubmiting}
                error={isPwErr}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPw}
                      onMouseDown={handleMouseDownPw}
                    >
                      {showPw ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="비밀번호 확인"
              />
            </FormControl>
          </div>
          {/* <div className="mb-12 flex justify-between items-end">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="ko-KR"
              dateFormats={{
                monthAndYear: 'YYYY년 MM월',
                monthAndDate: 'MM월 DD일',
                normalDate: 'YYYY-MM-DD',
              }}
            >
              <MobileDatePicker
                label="생년월일"
                inputFormat="YYYY-MM-DD"
                mask="____-__-__"
                value={birthday}
                onChange={setBirthday}
                renderInput={params => <TextField
                  {...params}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <CalendarMonth />
                    </InputAdornment>
                  }}
                />}
                closeOnSelect={true}
                dayOfWeekFormatter={day => {
                  let koDay;
                  switch (day.toLocaleLowerCase()) {
                    case 'su': koDay = '일'; break;
                    case 'mo': koDay = '월'; break;
                    case 'tu': koDay = '화'; break;
                    case 'we': koDay = '수'; break;
                    case 'th': koDay = '목'; break;
                    case 'fr': koDay = '금'; break;
                    case 'sa': koDay = '토'; break;
                    default: koDay = ''; break;
                  };
                  return koDay;
                }}
              />
            </LocalizationProvider>
            <ToggleButtonGroup
              value={gender}
              exclusive
              onChange={(_, v) => setGender(v)}
              size="large"
              defaultChecked={true}
              defaultValue="male"
            >
              <ToggleButton value="male">남성</ToggleButton>
              <ToggleButton value="female">여성</ToggleButton>
            </ToggleButtonGroup>
          </div> */}
          <div className="mb-2 flex flex-col gap-1.5 items-start pl-6">
            <FormControlLabel
              label="서비스 이용을 위한 약관 동의"
              control={
                <Checkbox
                  checked={allAgree}
                  indeterminate={indeterminate}
                  onChange={toggleAllAgrees}
                  size="medium"
                  icon={<PanoramaFishEye fontSize="large" color="action" />}
                  checkedIcon={<TaskAltOutlined fontSize="large" color="success" />}
                  indeterminateIcon={<RemoveCircleOutline fontSize="large" color="action" />}
                />
              }
            />
          </div>
          {accordionData.map(({ name, label, content, state, setState, action }) => (
            <Accordion expanded={expAccordion === name} onChange={handleClickAccordion(name)} key={`term-${name}`}>
              <AccordionSummary>
                <FormControlLabel
                  disableTypography={true}
                  label={label}
                  control={
                    <Checkbox size="small" checked={state} required
                      onChange={setState}
                      icon={<PanoramaFishEye fontSize="medium" color="action" />}
                      checkedIcon={<CheckCircleOutline fontSize="medium" color="success" />}
                    />
                  }
                  onClick={e => e.stopPropagation()}
                />
              </AccordionSummary>
              <AccordionDetails>
                <section className="term">
                  <ReactMarkdown>{content}</ReactMarkdown>
                </section>
              </AccordionDetails>
              {action && (
                <AccordionActions>
                  <Button onClick={action.actionHandler}>{action.actionName}</Button>
                </AccordionActions>
              )}
            </Accordion>
          ))}
          <div className="mb-10 flex flex-col items-start gap-1.5 pl-4 pt-1">
            <FormControlLabel
              disableTypography={true}
              label="마케팅 정보 메일, SMS, 푸시 수신동의 (선택)"
              control={
                <Checkbox
                  checked={marketingAgree}
                  onChange={e => setMarketingAgree(e.target.checked)}
                  size="small"
                  icon={<PanoramaFishEye fontSize="medium" color="action" />}
                  checkedIcon={<CheckCircleOutline fontSize="medium" color="success" />}
                />
              }
            />
            <FormControlLabel
              disableTypography={true}
              label="만 14세 이상입니다. (필수)"
              control={
                <Checkbox
                  checked={overFourteen}
                  onChange={e => setOverFourteen(e.target.checked)} required
                  size="small"
                  icon={<PanoramaFishEye fontSize="medium" color="action" />}
                  checkedIcon={<CheckCircleOutline fontSize="medium" color="success" />}
                />
              }
            />
          </div>
          <Button
            variant="contained"
            size="large"
            disabled={isSubmitDisabled || isSubmiting}
            onClick={handleClickSubmitButton}
          >
            가입 하기
          </Button>
        </form>
      </div>
    </div>
    </>
  );
}

export default EmailSignUp;

import { setSchedule } from "@stream/schedule";
import { parameterizeValidRoomInfo, parseValidRoomInfoStr } from "./roomInfoValidation";
import { arrangeValidScheduleSp, formatDate, parameterizeDateArr, streamizeDateArr } from "./scheduleValidation";
import { setRoomInfo } from "@stream/roomInfo";
import { redirect } from "react-router-dom";

export const validScheduleAndRoomInfo = ({ request: { url }}) => {
  const urlInst = new URL(url);
  const sp = urlInst.searchParams;
  const in1 = sp.get('check_in');
  const out1 = sp.get('check_out');
  const validCheckInOutArr = arrangeValidScheduleSp(sp);
  const [in2, out2] = validCheckInOutArr.map(formatDate);

  const r1 = sp.get('room_info');
  const validRoomInfo = parseValidRoomInfoStr(sp);
  const r2 = parameterizeValidRoomInfo(validRoomInfo);

  setSchedule(streamizeDateArr(validCheckInOutArr));
  setRoomInfo(validRoomInfo);

  if ((in1 !== in2) || (out1 !== out2) || (r1 !== r2)) {
    const newSp = parameterizeDateArr(validCheckInOutArr);
    newSp.set('room_info', r2);
    return redirect(`${urlInst.pathname}?${newSp.toString()}`);
  }

  return null;
};

import React from 'react';
import ReactMarkdown from 'react-markdown';

import ecommerceTermMD from '@md/ecommerce-terms.md';


function Ecommerce() {
  return (
    <>
      <section className="term bg-white-100">
        <ReactMarkdown>{ecommerceTermMD}</ReactMarkdown>
      </section>
    </>
  );
}

export default Ecommerce;

import { BehaviorSubject } from 'rxjs';

const OPTIONS = [
  { name: '추천순', value: 'recommend' },
  { name: '낮은가격', value: 'price-asc' },
  { name: '높은가격', value: 'price-desc' },
  { name: '높은등급', value: 'grade-desc' },
  { name: '낮은등급', value: 'grade-asc' },
  // { name: '평점', value: 'review-score' },
  // { name: '거리', value: 'distance' },
];
const DEFAULT = OPTIONS[0].value;
const INITIAL = DEFAULT;

const _ = new BehaviorSubject(INITIAL);

const sortingType = _;
export const subscribeSortingType = subscriber => _.subscribe(subscriber);
export const getSortingType = () => _.getValue();
export const setSortingType = newValue => _.next(newValue);
export const sortingTypeOption = [...OPTIONS];
export const getSortingNameByValue = (value) => {
  const v = value || _.getValue();
  return OPTIONS.find(({ value }) => value === v).name;
};

export default sortingType;

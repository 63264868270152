import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import { ReactComponent as CheckIcon } from '@img/profile/check.svg';
import { useLocation } from 'react-router-dom';
import { editUserInfo } from '@service/auth';
import * as Moment from 'moment';
import { BackButton } from '@comp/BackButton';
import { Helmet } from 'react-helmet';

function Alarm() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [subscribe, setSubscribe] = useState(location.state);

  const handleClickMarketingPush = (isSubscribe) => {
    if (subscribe.subscribe === isSubscribe) return;
    const body = {
      user: {
        subscribe: isSubscribe,
      },
    };

    editUserInfo(body).then(() => {
      setOpen(true);
      setSubscribe({
        subscribe: isSubscribe,
        subscribe_time: '1' === isSubscribe ? Moment().utcOffset(9).format('YYYY년 MM월 DD일 HH시 mm분') : undefined,
      });
    });
  }

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState} />
      <div className="min-h-contents-container">
        <div className="flex flex-col gap-2 px-6 text-stay-default">
          <div className="flex flex-col gap-4 sticky top-0 py-[20px] z-800 bg-white-100">
            <div className="flex justify-between items-start">
              <div className="flex justify-start items-start w-full">
                <BackButton />
                <div className="flex mx-[auto] my-[auto] font-bold text-[15px] leading-[160%] text-[#1E1E1E]">
                  알림 설정
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-[#F5F5F5]" />
        <div className="px-[20px] py-[30px]">
          <p className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">마케팅 푸시</p>
          <p className="font-regular text-[12px] leading-[160%] text-[#777777] pt-[10px]">마케팅 푸시 수신에 동의하시면 특가 및 할인코드 발급 등 각종 혜택에 대한 푸시 알림을 받을 수 있어요.</p>
          {subscribe.subscribe === '1' && <p className="font-regular text-[12px] leading-[160%] text-[#777777] pt-[10px]">[수신 동의 일시: {subscribe?.subscribe_time}]</p>}

          <div className="mt-[16px]">
            <div className="flex justify-between py-[14px] cursor-[pointer] active:bg-gray-100 active:text-white-100 active:border-0 hover:bg-gray-100-50" onClick={() => handleClickMarketingPush("1")}>
              <p className="font-regular text-[14px] leading-[160%] text-[#1E1E1E]">수신 동의</p> {subscribe.subscribe === '1' && <CheckIcon />}
            </div>
            <hr className="border-[#F5F5F5]" />
            <div className="flex justify-between py-[14px] cursor-[pointer] active:bg-gray-100 active:text-white-100 active:border-0 hover:bg-gray-100-50" onClick={() => handleClickMarketingPush("0")}>
              <p className="font-regular text-[14px] leading-[160%] text-[#1E1E1E]">수신 비동의</p> {subscribe.subscribe !== '1' && <CheckIcon />}
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div className='bg-[#323232] py-[16px] px-[16px] rounded'>
          {subscribe.subscribe === '1' ? (<p className="font-regular text-[14px] leading-[160%] text-[#FFFFFF]">전송자 : 올스테이 <br /> 수신 동의 일시 : {subscribe.subscribe_time} <br /> 처리 내용 : 수신동의 (설정 &gt; 마케팅 푸시에서 변경 가능)</p>) : <p className="font-regular text-[14px] leading-[160%] text-[#FFFFFF]">수신 동의를 거부하셨습니다.</p>}
        </div>
      </Snackbar>
    </>
  );
}

export default Alarm;

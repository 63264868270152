import api, { allstayHttpService } from './api';
import Axios from 'axios';
import { getBodyEmailLogin, getBodySnsLogin } from '@stream/tokenRequestingBody';

export const initPassword = async email => {
  let data;
  await api.get(`/public/auth/init-password/${email}`)
  .then(res => data = { isOK: res.status === 200 })
  .catch(err => data = err.response.data);
  return data;
};

export const verifyAccessToken = async jwt => {
  let data;
  await api.post('/public/auth/token/verify', {
    jwt
  }).then(res => data = res.data)
  .catch(err => data = err.response.data);
  return data;
};

export const refreshAuth = async refresh_token => {
  let data;
  await api.post('/public/auth/token/refresh', {
    refresh_token,
  }).then(res => data = res.data )
  .catch(err => data = err.response.data);
  return data;
};

export const naverServiceTerms = async accessToken => {
  let data;
  const naverAPI = Axios.create({
    baseURL: "https://openapi.naver.com",
    timeout: 10000,
  });
  await naverAPI.get('/v1/nid/agreement',{
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json;charset=utf-8",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    }
  }).then(res => data = res.data )
    .catch(err => data = err.response.data);
  return data;
}

export const kakaoToken = async code => {
  let data;
  const kakaoAPI = Axios.create({
    baseURL: "https://kauth.kakao.com",
    timeout: 10000,
  });
  await kakaoAPI.post('/oauth/token',{},{
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=ISO-8859-1"
    },
    params: {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP__KAKAO_REST_KEY,
      redirct_uri: process.env.REACT_APP__SNS_LOGIN_REDIRECT_URI + '/auth/kakao-sign-in',
      code: code
    }
  }).then(res => data = res.data )
    .catch(err => data = err.response.data);
  return data;
}

export const login = body => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .post('/public/auth/token/login', body)
    .subscribe({
      next: res => resolve(res.data),
      error: err => {
        console.error('login err', err);
        reject(err);
      },
    });
  }
  return new Promise(promiseExecuter);
};

export const sendSerial = async email => {
  let data;
  await api.get(`/public/auth/signup/email/certification/${email}`)
  .then(res => data = { isOK: res.status === 200 })
  .catch(err => data = err.response.data);
  return data;
};

export const checkSerial = async (email, code) => {
  let data;
  await api.post(`/public/auth/signup/email/certification/${email}`, { code })
  .then(res => data = { isOK: res.status === 200 })
  .catch(err => data = err.response.data);
  return data;
};

export const submitingEmailSignup = async body => {
  let data;
  await api.post('/public/auth/signup/email', {...body, ...getBodyEmailLogin() })
  .then(res => data = res.data)
  .catch(err => data = err.response.data);
  return data;
};

export const editUserInfo = async (body) => {
  let data;
  await api.put('/private/user/profile', body).then(res => {
    data = { isOK: res.status === 200 };
  })
  .catch(err => data = err.response.data);
  return data;
};

export const getProfile = async () => {
  let data;
  await api.get('/private/user/profile')
  .then(res => data = res.data)
  .catch(err => data = err.response.data);
  return data;
};

export const withdraw = async (password, accessToken) => {
  let data;
  await api.post('/private/user/withdraw', {
    password
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }).then(res => {
    data = { isOK: res.status === 200 };
  })
  .catch(err => data = err.response.data);
  return data;
};

export const submitingSnsSignup = async body => {
  let data;
  await api.post('/public/auth/signup/sns', { ...body, ...getBodySnsLogin() })
    .then(res => data = res.data)
    .catch(err => data = err.response.data);
  return data;
}

export const checkPw = async (password, accessToken) => {
  let data;
  await api.post('/private/user/check-password', { password }, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(res => data = {
    data: res,
    isOK: true,
  }).catch(err => data = {
    data: err,
    isOK: false,
  });
  return data;
};

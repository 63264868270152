import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  IconButton
} from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ReactComponent as KakaoIcon } from '@svg/Icons/SNS/kakao.svg';
import { ReactComponent as AppleIcon } from '@svg/Icons/SNS/apple.svg';
import { ReactComponent as NaverIcon } from '@svg/Icons/SNS/naver.svg';
import { Helmet } from "react-helmet";

function SignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const { naver, Kakao, AppleID } = window;

    const naverLogin = new naver.LoginWithNaverId({
        clientId: process.env.REACT_APP__NAVER_CLIENT_ID,
        callbackUrl: process.env.REACT_APP__SNS_LOGIN_REDIRECT_URI + '/auth/naver-sign-in',
        callbackHandle: true,
        isPopup: false,
      });
    naverLogin.init();

    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP__KAKAO_APP_KEY)
    };

    AppleID.auth.init({
      clientId : process.env.REACT_APP__APPLE_CLIENT_ID,
      scope : 'name email',
      redirectURI : process.env.REACT_APP__API_URL + '/public/auth/login/apple/redirect',
      usePopup : false
    });

  }, []);

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div className="flex flex-col justify-end bg-screen-logo bg-no-repeat bg-center-top-200 h-contents-container gap-16 pb-[54px]">
      <div className="flex flex-col px-12 items-stretch">
        <div className="relative h-[21px]">
          <div className="absolute bg-[#F5F5F5] w-full h-[1px] pt-[auto]"  style={ { top:'50%', left:'50%', transform: 'translate(-50%, -50%)' }} />
          <div className="absolute items-center bg-[#ffffff] px-[20px] font-bold text-[13px] leading-[20.8px] text-[#000000]" style={ { top:'50%', left:'50%', transform: 'translate(-50%, -50%)' }} >간편하게 로그인</div>
        </div>
        <div className="flex justify-center mt-[40px] mb-[30px] gap-[25px]">
          <IconButton
            id="naverIdLogin_loginButton"
            size="large"
            className="!bg-[#3EAF0E]"
            style={{ padding: 0, height: '48px', width: '48px', borderRadius: '26px' }}
          >
            <span className="sr-only">네이버 로그인</span>
            <NaverIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.Kakao.Auth.authorize({
                redirectUri: process.env.REACT_APP__SNS_LOGIN_REDIRECT_URI + '/auth/kakao-sign-in',
              });
            }}
            size="large"
            className="!bg-yellow-300"
            style={{ padding: 0, height: '48px', width: '48px', borderRadius: '26px' }}
          >
            <span className="sr-only">카카오 로그인</span>
            <KakaoIcon />
          </IconButton>
          <IconButton
            onClick={ async () => {
              //[TO-BE] 아직 여러가지 테스팅을 진행하면서 Apple login 구현 중
              const { AppleID } = window;
              try {
                await AppleID.auth.signIn()
              } catch (error) {
                console.log(error)
              };
            }}
            size="large"
            className="!bg-black-100"
            style={{ padding: 0, height: '48px', width: '48px', borderRadius: '26px' }}
          >
            <span className="sr-only">애플 로그인</span>
            <AppleIcon />
          </IconButton>
        </div>
        <Button
          variant="outlined"
          size="large"
          startIcon={<MailOutlineIcon />}
          onClick={() => navigate('/auth/email-sign-in')}
        >
          이메일로 시작하기
        </Button>
      </div>
      <div className="text-sub-link text-gray-600 flex justify-center">
        <Link to="/auth/email-sign-up">
          혹시 올스테이가 처음이신가요?<span className="font-bold ml-2.5 text-[#000000]">회원가입</span>
        </Link>
      </div>
    </div>
    </>
  );
}

export default SignIn;

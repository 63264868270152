import React from 'react';
import {
  HorizontalItemContainer,
  HorizontalScrollContainer,
  HorizontalScrollMinimap,
  useProgress,
  useViewWidth
} from '@comp/HorizontalScroll';
import { H2, H3 } from '@comp/StyledParagraph';
import { NavLink } from 'react-router-dom';
import placeholderImg from '@img/placeholder-img.png';
import decode from '@util/decode';

function Recommended({ data, children }) {
  const {
    viewWidth,
    setScrollWidth,
    setClientWidth,
  } = useViewWidth({
    clampWidth: 60,
  });
  const {
    progress,
    setRawProgress,
  } = useProgress();

  return (
    <div className="flex flex-col gap-[20px]">
      <div className="flex justify-between items-center px-[20px]">
        <H2>{children}</H2>
        <HorizontalScrollMinimap
          progress={progress}
          viewWidth={viewWidth}
          mapWidth={60}
        />
      </div>
      <div>
        <HorizontalScrollContainer
          setScrollWidth={setScrollWidth}
          setClientWidth={setClientWidth}
          setProgress={setRawProgress}
        >
          {data.map(({ id, title, image, slug, isFromNewCMS }, i) => {
            return (
              <HorizontalItemContainer
                key={`main-recommend-${id}`}
                paddingRight={15}
                paddingLeft={i === 0 ? 20 : 15}
              >
                <NavLink
                  to={`${isFromNewCMS ? `/alltrip/posts/${slug}` : `/alltrip/post/${slug}`}`}
                  className="flex flex-col gap-[10px] w-[230px] min-w-[230px] max-w-[230px]"
                >
                  <div
                    className="w-full h-[140px] rounded-[2px] bg-center bg-no-repeat bg-cover"
                    style={{
                      backgroundImage: `url("${image || placeholderImg}")`,
                    }}
                  >
                    <span className="sr-only">{title} 대표 이미지</span>
                  </div>
                  <H3>{decode(title)}</H3>
                </NavLink>
              </HorizontalItemContainer>
            );
          })}
        </HorizontalScrollContainer>
      </div>
    </div>
  );
};

export default Recommended;

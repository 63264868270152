import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import { delFavoriteItem, registerFavoriteItem } from '@store/favoraite';
import { closeResetModal, setModal } from '@store/modal';

import { ReactComponent as FavoriteIconFill } from '@asset/icon/favorite-1e-fill-p8.svg';
import { ReactComponent as FavoriteIcon } from '@asset/icon/favorite-1e.svg';

const FavoriteButton = ({
  className,
  bookmark_id,
  ref_model,
  ref_id,
  userInfo,
  updateDate,
  size=24,
  onClick: onClickCallback,
}) => {
  const navigate = useNavigate()
  const [bookmarkId, setBookmarkId] = useState(bookmark_id)

  return <button
    className={className}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      const isLogedIn = !!checkIsLogedInUser(userInfo)
      if (!isLogedIn) {
        setModal({
          isClosable: true,
          isOpened: true,
          template: () => (
            <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
              <div className="flex flex-col w-56 gap-6 text-gray-900-100">
                <h2 className="text-modal-h">로그인</h2>
                <div className="flex flex-col">
                  <p className="text-modal-desc whitespace-normal">지금 바로 올스테이에 로그인하고</p>
                  <p className="text-modal-desc whitespace-normal">마음에 드는 숙소를 찜하세요!</p>
                </div>
              </div>
              <div className="flex w-full justify-around border-t border-gray-100-100">
                <button
                  className="text-modal-btn text-gray-400 h-major-button"
                  onClick={() => {
                    closeResetModal();
                  }}
                >
                  <span className="w-full">닫기</span>
                </button>
                <button
                  className="text-modal-btn text-sky-blue-400 h-major-button"
                  onClick={() => { navigate('/auth'); closeResetModal(); }}>
                  <span className="w-full">로그인하러 가기</span>
                </button>
              </div>
            </div>
          ),
        });
      } else {
        ((!!bookmarkId) ? delFavoriteItem(bookmarkId) : registerFavoriteItem(ref_model, ref_id)).then(res => {
          setBookmarkId(res.bookmark_id || null)
          updateDate(res.bookmark_id || null)
        });
      }

      if (typeof onClickCallback === 'function') onClickCallback({ is_loged_in: isLogedIn, turning_on_off: isLogedIn ? (!!bookmarkId ? 'off' : 'on') : 'undefined' });
    }}
  >
    {
      !!bookmarkId ? <FavoriteIconFill width={size} height={size}/> : <FavoriteIcon width={size} height={size} />
    }
    <span className="sr-only">{!!bookmarkId ? '찜 취소 하기' : '찜 하기'}</span>
  </button>;
};

export default FavoriteButton;
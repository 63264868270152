import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import { Helmet } from 'react-helmet';

function Auth() {
  const [isLogedInUser, setIsLogedInUser] = useState(checkIsLogedInUser(getUserInfo()));

  useEffect(() => {
    subscribeUserInfo(u => {
      setIsLogedInUser(checkIsLogedInUser(u));
    });
  }, []);

  return (
    <>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <main id="allstay-main">
      <div className="allstay-contents allstay-contents-box">
        {isLogedInUser && <Navigate to="/my-profile" replace={true} />}
        <Outlet />
      </div>
    </main>
    </>
  );
};

export default Auth;

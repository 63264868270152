import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
} from 'rxjs';

const formatNum = new Intl.NumberFormat('ko-KR').format;

const MIN = 0;
const MAX = 100;
const STEP = 5;
const UNIT = 10000;
const DEFAULT = [MIN, MAX];
const INITIAL = [...DEFAULT];
const CLAMP = value => {
  let v = value;
  if (v < 0) v = 0;
  else if (v > 100) v = 100 * UNIT;

  if (v <= 50) return v * 2 * UNIT;
  if (v <= 95) return ((v - 50) / STEP + 1) * 100 * UNIT;
  return 1000000000;
};
const UNCLAMP = value => {
  let v = value;
  if (v >= 1000000000) return 100;
  else if (v >= 1000000) {
    v = Math.round(v / UNIT / 100) * STEP + (STEP * 9);
  } else {
    v = Math.round(v / UNIT / 10) * STEP;
  }
  return v;
}
const UNITIZE = v => v ? v / UNIT : 0;
const LABELED = _v => {
  const v = UNITIZE(CLAMP(_v));
  if (!v) return `${v}`;
  if (v > 1000) return '무제한';
  return `${formatNum(v)}`;
};
const LABELIZE = _v => {
  const v = UNITIZE(_v);
  if (!v) return `${v}`;
  if (v > 1000) return '무제한';
  return `${formatNum(v)}`;
};
const OPTIONS = Array.from(new Array(MAX / STEP + 1))
.map((_, i) => ({value: i * STEP, label: i * STEP}))
.map(({value, label}) => ({
  value,
  label: LABELED(label),
}));

const _ = new BehaviorSubject([...INITIAL]);
const __ = new BehaviorSubject(INITIAL.map(CLAMP));

_.pipe(
  distinctUntilChanged(([p1, p2], [c1, c2]) => p1 === c1 && p2 === c2),
  map(r => r.map(CLAMP)),
).subscribe(__);

export const slider = _;
export const subscribeSlider = subscriber => _.subscribe(subscriber);
export const getSlider = () => _.getValue();
export const setSlider = newValue => _.next(newValue);
export const clampSlider = CLAMP;
export const sliderOption = [...OPTIONS];
export const sliderMin = MIN;
export const sliderMax = MAX;
export const sliderStep = STEP;
export const labelize = LABELIZE;

const priceRange = __;
export const subscribePriceRange = subscriber => __.subscribe(subscriber);
export const getPriceRange = () => __.getValue();
export const setPriceRange = range => {
  const [low, high] = range.map(UNCLAMP).sort((a, b) => a - b);
  _.next([low, high === low ? high + STEP : high]);
};

export default priceRange;

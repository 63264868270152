import React from 'react';
import {
  GnbHomeBtn,
  GnbSearchBtn,
  GnbDomesticBtn,
  GnbGlobalBtn,
  GnbMagazineBtn,
} from "./GnbBtn";

function Gnb() {
  return <nav className="flex max-w-mobile min-w-96 h-14 bg-white border-t border-neutral-200 justify-center items-center inline-flex bg-white">
    <div className="grow shrink basis-0 h-14 px-7 justify-between items-center flex">
      <GnbHomeBtn/>
      <GnbSearchBtn/>
      <GnbDomesticBtn/>
      <GnbGlobalBtn/>
      <GnbMagazineBtn/>
    </div>
  </nav>;
}

export default Gnb;

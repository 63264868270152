import { BehaviorSubject } from 'rxjs';


export const foramtRoomInfoCaption = roomInfo => `${roomInfo.length} 객실 - `
  + `${roomInfo.reduce((acc, { adult }) => acc + adult, 0)} 성인, `
  + `${roomInfo.reduce((acc, { minor }) => acc + minor.length, 0)} 어린이`;

export const getMinorDefault = (id, age=0) => ({
  id: `${id}-minor-${Date.now()}-${parseInt(Math.random() * 100)}`,
  age: parseInt(age)
});

export const getGuestInfoDefault = (adult=2, minor=[]) => {
  const id = `guest-info-${Date.now()}-${parseInt(Math.random() * 100)}`;
  return ({ id, adult: parseInt(adult), minor: minor.map(age => getMinorDefault(id, age)) });
};

const DEFAULT = {...getGuestInfoDefault()};
const INITIAL = [{...DEFAULT}];
const _ = new BehaviorSubject([...INITIAL]);

const roomInfo = _;
export const subscribeRoomInfo = subscriber => _.subscribe(subscriber);
export const getRoomInfo = () => _.getValue();
export const setRoomInfo = newValue => _.next(newValue);

export default roomInfo;

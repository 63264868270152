import { BehaviorSubject } from 'rxjs';
import { format, differenceInDays } from 'date-fns';
import { DEFAULT_CHECK_IN, DEFAULT_CHECK_OUT } from '@util/scheduleValidation';


export const formatScheduleCaption = ([{
  startDate,
  endDate,
}]) => {
  const s =new Date(startDate);
  const e = new Date(endDate);
  return `${format(s, 'yyyy-MM-dd')} ~ ${format(e, 'yyyy-MM-dd')} ${Math.abs(differenceInDays(s, e))}박`;
};


export const DEFAULT = {
  startDate: DEFAULT_CHECK_IN,
  endDate: DEFAULT_CHECK_OUT,
  key: 'schedule',
};
const INITIAL = [{...DEFAULT}];
const _ = new BehaviorSubject([...INITIAL]);

const schedule = _;
export const subscribeSchedule = subscriber => _.subscribe(subscriber);
export const getSchedule = () => _.getValue();
export const setSchedule = newValue => _.next(newValue);
export const setDecodedSchedule = (startDate, endDate) => _.next([{startDate, endDate}]);

export default schedule;

import { baseProcSearchStay, procSearchStayPvCountRank } from './stayPreProc';


const events = {
  'search_stay__pv_count-rank': {
    getTrackingParams: (data) => ({
      ...baseProcSearchStay(data),
      ...procSearchStayPvCountRank(data),
    }),
  },
};

export default events;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { checkPw } from '@service/auth';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { resetModal, closeResetModal, setModalIsOpened, setModal } from '@store/modal';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { Helmet } from 'react-helmet';

const ModalEmailLoginFailed = ({
  heading,
  description,
  caption='확인',
  onClick,
}) => {
  const _onClick = () => {
    closeResetModal();
    onClick && onClick();
  };

  return (
    <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
      <div className="flex flex-col w-56 gap-6 text-gray-900-100">
        <h2 className="text-modal-h">{heading}</h2>
        <p className="text-modal-desc whitespace-normal">{description}</p>
      </div>
      <button
        className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
        onClick={_onClick}
      >
        <span className="w-full">{caption}</span>
      </button>
    </div>
  );
};


function ConfirmPassword() {
  const logBtnRef = useRef();
  const [user, setUser] = useState(getUserInfo());
  const [showPassword, setShowPassword] = useState(false);
  const [pw, setPw] = useState('');
  const [isLogingIn, setIsLogingIn] = useState(false);
  const [isOK, setIsOK] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    subscribeUserInfo(setUser);
    return () => setIsOK(false);
  }, []);

  useEffect(() => {
    if (!!user.payload.provider) setIsOK(true);
  }, [user]);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => e.preventDefault();

  const handleClickLoginButton = e => {
    e.preventDefault();
    const { access_token } = user;

    setIsLogingIn(true);
    resetModal();
    setModalIsOpened(true);

    checkPw(pw, access_token)
    .then(({ isOK }) => {
      if (isOK) {
        setIsOK(true);
        closeResetModal();
        return;
      }
      throw new Error('비밀번호 확인 실패');
    }).catch(err => {
      setIsOK(false);
      setModal({
        isClosable: true,
        isOpened: true,
        template: ModalEmailLoginFailed,
        data: {
          heading: '비밀번호 확인 실패',
          description: '비밀번호를 다시 확인해 주세요.',
        },
      });
    }).finally(() => {
      setIsLogingIn(false);
    });
  };

  const handleEnterUp = e => {
    if (e.key === 'Enter' && !!logBtnRef && !!logBtnRef.current) logBtnRef.current.click();
  };

  return (
    <>{
    isOK ?
    <Outlet /> :
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div
      className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        min-h-contents-container
      "
    >
      <div className="px-6 mt-4">
        <IconButton
          onClick={() => navigate('..')}
          size="large"
          style={{ padding: 0 }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div
        className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-14
        "
      >
        <h2 className="text-screen-title">{pathname.startsWith('/my-profile/edit') ? '회원정보 수정' : '회원 탈퇴'} - 비밀번호 확인</h2>
        <form className="flex flex-col" onSubmit={e => e.preventDefault()}>
          <div className="mb-5 flex flex-col gap-2.5">
            <FormControl variant="outlined">
              <InputLabel htmlFor="password-field">비밀번호</InputLabel>
              <OutlinedInput
                id="current-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                value={pw}
                onChange={e => setPw(e.target.value)}
                onKeyUp={handleEnterUp}
                disabled={isLogingIn}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className="flex justify-end">
              <Link
                to="/auth/find-password"
                className="text-sub-link text-gray-600"
              >
                비밀번호를 잊으셨나요?
              </Link>
            </div>
          </div>
          <Button
            variant="contained"
            size="large"
            disabled={!pw}
            onClick={handleClickLoginButton}
            ref={logBtnRef}
          >
            {pw ? '비밀번호 확인' : '비밀번호를 입력하세요'}
          </Button>
        </form>
      </div>
    </div>
    </>
    }</>
  );
}

export default ConfirmPassword;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

function LegacyNoIndex() {

  return <>
    <Helmet onChangeClientState={handleChangeHelmetState}>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Outlet />
  </>;
}

export default LegacyNoIndex;

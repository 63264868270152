import { goS, rangeL, mapL, mapC, delay } from 'fxjs';
import { getRegionResult } from '@service/search';
export const paginizeData = (rawData, page) => ({ ...rawData, page });

const chainPaginationSync = async (
  search_type,
  region_code,
  limit,
  initialPage=1,
  searchParams,
  theme_id,
  setData
) => {
  let {
    maxPage,
    curPage,
  } = await getRegionResult({
    search_type,
    region_code,
    limit,
    page: initialPage,
    searchParams,
    theme_id,
  }).then(res => {
    const {
      isOK,
      data
    } = res;
    if (!isOK) return { maxPage: -1, curPage: 0 };
    const paginized = paginizeData(data, initialPage);
    setData(paginized);
    const { page_count } = data;
    return { maxPage: page_count + 1, curPage: initialPage + 1 };
  });

  try {
    goS(
      rangeL(curPage, maxPage),
      mapL((page) => {
        if (curPage >= maxPage) return [];
        delay(10);
        return getRegionResult({
          search_type,
          region_code,
          limit,
          page,
          searchParams,
          theme_id,
        }).then(res => paginizeData(res.data, page))
        .catch(err => {
          console.error('호텔 리스트를 받아오는 중 오류 발생', err);
          return [];
        })
      }),
      mapC(v => {
        setData(v);
      }),
    );
  } catch (err) {
    console.error('호텔 리스트를 받아오는 중 오류 발생', err);
  };
};

export default chainPaginationSync;

import {
  BehaviorSubject,
} from 'rxjs';

const OPTIONS = [
  {value: '', name: '전체'},
  {value: '20200009', name: '호텔'},
  {value: '20200002', name: '콘도'},
  {value: '20200003', name: '펜션'},
  {value: '20200004', name: '리조트'},
  {value: '20200005', name: '레지던스'},
  {value: '20200006', name: '민박'},
  {value: '20200007', name: '료칸'},
  {value: '20200008', name: '호스텔'},
  {value: '20200001', name: '샬레'},
  {value: '20200010', name: '빌라'},
  {value: '20200011', name: '인'},
  {value: '20200012', name: '올인클루시브'},
  {value: '20200013', name: '로지2'},
  {value: '20200014', name: '아파토텔'},
  {value: '20200015', name: '비앤비'},
  {value: '20200016', name: '기타'},
  {value: '20200017', name: '아파트먼트'},
  {value: '20200018', name: '캐라반'},
  {value: '20200019', name: '글램핑'},
  {value: '20200020', name: '한옥'},
  {value: '20200021', name: '캠핑'},
  {value: '20200022', name: '게스트하우스'},
  {value: '20200023', name: '모텔'},
  {value: '20200024', name: '풀빌라'},
];

const DEFAULT = OPTIONS[0].value;
const INITIAL = DEFAULT;

const _ = new BehaviorSubject(INITIAL);

export const stayCategory = _;
export const subscribeStayCategory = subscriber => _.subscribe(subscriber);
export const getStayCategory = () => _.getValue();
export const setStayCategory = v => _.next(v);
export const stayCategoryOption = [...OPTIONS];

const encodedCategory = _;
export const subscribeEncodedCategory = subscriber => _.subscribe(subscriber);
export const getEncodedCategory = () => _.getValue();

export default encodedCategory;

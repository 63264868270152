import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect, useState } from 'react';
import {
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { setModal, closeResetModal } from '@store/modal';
import { initPassword } from '@service/auth';
import isEmail from 'validator/es/lib/isEmail';
import { Helmet } from 'react-helmet';

const ModalInitPassword = ({
  heading,
  description,
}) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white-100 rounded flex flex-col items-stretch gap-8 p-modal">
      <div className="flex flex-col w-56 gap-6 text-gray-900-100">
        <h2 className="text-modal-h text-center">{heading}</h2>
        {
          description.map((d, i) => 
            <div
              key={`find-pw-modal-p-${i}`}
              className="text-modal-desc whitespace-pre-wrap"
            >
              {d}
            </div>
          )
        }
      </div>
      <div
        className="flex w-full justify-around border-t border-gray-100-100"
      >
        <button
          className="text-modal-btn text-sky-blue-400 h-major-button"
          onClick={() => {
            navigate('/auth/email-sign-in');
            closeResetModal();
          }}
        >
          <span className="w-full">이메일로 로그인하러 가기</span>
        </button>
        <button
          className="text-modal-btn text-sky-blue-400 h-major-button"
          onClick={() => {
            closeResetModal();
          }}
        >
          <span className="w-full">닫기</span>
        </button>
      </div>
    </div>
  );
};

function FindPassword() {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [emailHelperTexts, setEmailHelperTexts] = useState([]);

  const navigate = useNavigate();

  const handleClickSubmitButton = e => {
    e.preventDefault();
    setIsSubmiting(true);
    setModal({
      isOpened: true,
      isClosable: false,
    });
    initPassword(email)
    .then(res => {
      setModal({
        isOpened: true,
        isClosable: false,
        data: {
          heading: '임시 비밀번호가 전송되었어요.',
          description: ['이메일을 확인해 주세요.', '도착한 이메일이 없나요? 다시 시도해 주세요.'],
        },
        template: ModalInitPassword,
      });
    })
    .finally(() => setIsSubmiting(false));
  };

  useEffect(() => {
    if (!isEmailDirty && !email.length) return;
    const texts = [];
    let err = false;
    const _email = email.trim();
    if (!_email.length) {
      err = err || true;
      texts.push({ text: '이메일 주소를 입력해 주세요.', key: 'not-filled' });
    } else if (!isEmail(_email)) {
      err = err || true;
      texts.push({ text: '올바른 이메일 주소 형식이 아닙니다.', key: 'non-email-form' });
    }
    setEmailHelperTexts(texts);
    setIsEmailErr(err);
  }, [email, isEmailDirty]);

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div
      className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        min-h-contents-container
      "
    >
      <div className="px-6 mt-4">
        <IconButton
          onClick={() => navigate('..')}
          size="large"
          style={{ padding: 0 }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div
        className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-14
        "
      >
        <h2 className="text-screen-title">비밀번호 찾기</h2>
        <form className=" flex flex-col">
          <div className="mb-10 flex flex-col gap-2.5">
            <TextField
              id="email-field"
              name="username"
              label="이메일"
              type="text"
              autoComplete="username"
              variant="outlined"
              value={email}
              onChange={e => {
                setIsEmailDirty(true);
                setEmail(e.target.value);
              }}
              onBlur={e => {
                setIsEmailDirty(true);
                setEmail(e.target.value.trim());
              }}
              disabled={isSubmiting}
            />
            {
              isEmailErr ?
              emailHelperTexts.map(({ text, key }) =>
                <p className="text-red-500 text-sub-link" key={`email-${key}`}>
                  {text}
                </p>
              ) : <p className="text-sub-link text-gray-600">
                임시 비밀번호를 받기 위해 가입한 이메일 주소를 입력해 주세요.
              </p>
            }
          </div>
          <Button
            variant="contained"
            size="large"
            disabled={!email || isSubmiting || isEmailErr}
            onClick={handleClickSubmitButton}
          >
            {!!email ? '임시 비밀번호 받기' : '이메일을 입력하세요'}
          </Button>
        </form>
      </div>
    </div>
    </>
  );
}

export default FindPassword;

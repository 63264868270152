// import { getUserInfo } from "@stream/userInfo";

const replaceHotelDetailSecondaryTab = (pathname) => (typeof pathname !== 'string' ? '' : pathname).replace(/\/(home|price|location|introduction|review)\/*$/, '');

export const injectTeadsScript = (history) => {
  if (history && history?.page_location && history?.page_referrer) {
    try {
      const prv = new URL(history.page_referrer);
      const cur = new URL(history.page_location);
      const prvPath = replaceHotelDetailSecondaryTab(prv.pathname);
      const curPath = replaceHotelDetailSecondaryTab(cur.pathname);

      if (prvPath === curPath) throw new Error('in same hotel.');

      

      const $existingSlot = window.document.querySelector('div#teads-slot');
      if ($existingSlot) {
        const $newSlot = window.document.createElement('div');
        $newSlot.setAttribute('id', 'teads-slot');
        $newSlot.setAttribute('style', 'display: block;');
        $existingSlot.replaceWith($newSlot);
      }

    } catch(err) {
      return;
    }
  }

  const $existingScript = window.document.querySelector('script.teads');
  if ($existingScript) {
    $existingScript.remove();
  }
  
  // const { channel } = getUserInfo() || {};
  const teadsScriptCodeNum = 215730; //channel === 'ios' ? '216772' : (channel === 'aos' ? '216773' : '215730');
  const teadsScriptSrc = `//a.teads.tv/page/${teadsScriptCodeNum}/tag`;
  
  let $teadsScript;
  let $teadsSlot;
  const intervalId = setInterval(() => {
    $teadsSlot = window.document.querySelector('div#teads-slot');
    if (!$teadsSlot) return;
    $teadsScript = window.document.createElement('script');
    $teadsScript.type = 'text/javascript';
    $teadsScript.className = 'teads';
    $teadsScript.async = true;
    $teadsScript.src = teadsScriptSrc;
    window.document.body.appendChild($teadsScript);

    return clearInterval(intervalId);
  }, 100);
};

import { HorizontalScrollContainer } from '@comp/HorizontalScroll';
import AtMainItem from './AtMainItem';
import AtSearchList from './AtSearchList';
import AtSearchItem from './AtSearchItem';

export const RecentVisitedStayAtMain = {
  List: HorizontalScrollContainer,
  Item: AtMainItem,
};

export const RecentVisitedStayAtSearch = {
  List: AtSearchList,
  Item: AtSearchItem,
};

export const RecentVisitedStay = {
  AtMain: RecentVisitedStayAtMain,
  AtSearch: RecentVisitedStayAtSearch,
};

export default RecentVisitedStay;

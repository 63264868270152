const _ = {
  content: [
    "./public/**/*.html",
    "./src/**/*.{js,jsx}",
  ],
  theme: {
    fontFamily: {
      sans: [
        'Pretendard',
        '"Noto Sans KR"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        '"Fira Sans"',
        '"Droid Sans"',
        '"Helvetica Neue"',
        '"Malgun Gothic"',
        'sans-serif',
      ],
    },
    extend: {
      colors: {
        white: {
          DEFAULT: 'rgba(255, 255, 255, 1)',
          100: 'rgba(255, 255, 255, 1)',
          1  : 'rgba(255, 255, 255, 0.01)',
        },
        black: {
          100: 'rgba(0, 0, 0, 1)',
          50 : 'rgba(0, 0, 0, 0.5)',
          15  : 'rgba(0, 0, 0, 0.15)',
          1  : 'rgba(0, 0, 0, 0.01)',
          0  : 'rgba(0, 0, 0, 0)',
        },
        gray: {
          50 : '#FAFAFA',
          100: {
            100: 'rgba(236, 236, 236, 1)',
            DEFAULT:  'rgba(236, 236, 236, 1)',
            50 : 'rgba(236, 236, 236, 0.5)',
          },
          200: '#DADADA',
          300: {
            100: 'rgba(207, 207, 207, 1)',
            DEFAULT: 'rgba(207, 207, 207, 1)',
            80 : 'rgba(207, 207, 207, 0.8)',
          },
          400: '#BDBDBD',
          500: '#9E9E9E',
          600: '#777777',
          700: '#333333',
          800: '#242424',
          900: {
            100: 'rgba(30, 30, 30, 1)',
            DEFAULT: 'rgba(30, 30, 30, 1)',
            30 : 'rgba(30, 30, 30, 0.3)',
          },
        },
        yellow: {
          200: {
            100: 'rgba(255, 159, 0, 1)',
            50 : 'rgba(255, 159, 0, 0.5)',
          },
          300: '#FFDC41',
          400: '#FFCC2E',
          500: '#F5B623',
          600: '#FF9F00',
        },
        'blue-gray': {
          500: {
            100: 'rgba(128, 133, 142, 1)',
            90 : 'rgba(128, 133, 142, 0.9)',
            70 : 'rgba(128, 133, 142, 0.7)',
            60 : 'rgba(128, 133, 142, 0.6)',
            40 : 'rgba(128, 133, 142, 0.4)',
            30 : 'rgba(128, 133, 142, 0.3)',
            20 : 'rgba(128, 133, 142, 0.2)',
            10 : 'rgba(128, 133, 142, 0.1)',
          }
        },
        red: {
          300: '#F05D2A',
          400: '#E83927',
          500: '#DD2C00',
          600: '#BF2F38',
          900: '#381E1F',
        },
        'hot-pink': {
          100: {
            100: 'rgba(255, 139, 139, 1)',
            DEFAULT: 'rgba(255, 139, 139, 1)',
            70 : 'rgba(255, 139, 139, 0.7)',
          },
          500: '#FF3470',
          600: '#EA1F62',
          700: '#D83963',
        },
        green: {
          500: '#66BB6A',
        },
        purple: {
          600: '#6553BF',
        },
        navy: {
          500: '#575A7C',
          600: '#444868',
          700: '#004B85',
          800: '#273B7D',
          900: '#2B2E4A',
        },
        'sky-blue': {
          400: '#499FDD',
          500: '#58A9FE',
        },
        
        'allstay-primary': {
          '2': '#fffafb',
          '4': '#ff94a5',
          '6': '#ff627b',
          '8': '#ff3755',
        },
        'allstay-secondary': {
          '2': '#F0F1F5',
          '4': '#AEB0BF',
          '6': '#444868',
          '8': '#2B2E4A',
        },
        'allstay-gray': {
          'fa': '#fafafa',
          'f5': '#f5f5f5',
          'ee': '#eeeeee',
          'e0': '#e0e0e0',
          'bd': '#bdbdbd',
          '9e': '#9e9e9e',
          '77': '#777777',
          '1e': '#1e1e1e',
        },

      },
      minWidth: {
        mobile: '320px',
        'nav-btn': '64px',
        'search-input': '230px',
        'alltrip-item': '140px',
        'interest-img': '110px',
      },
      minHeight: {
        'nav-btn': '50px',
        'contents-container': 'calc(100vh - 50px)',
        'interest-item': '152px',
        'interest-img': '112px',
        'search-bar-wrapper': '40px',
      },
      maxWidth: {
        mobile: '480px',
        modal: '420px',
        'alltrip-item': '158px',
      },
      width: {
        mobile: '480px',
        'h1-logo': '94px',
        'alltrip-item': '140px',
      },
      height: {
        'nav': '50px',
        'major-button': '56px',
        'contents-container': 'calc(100vh - 50px)',
        'fullmodal': '90vh',
        'h1-logo': '14px',
        'input-sm': '40px',
        'post-hero': '500px',
        'alltrip-item': '100px',
        'stay-nav-tab': '42px',
      },
      backgroundPosition: {
        'nav-btn': 'center top 7px',
        'center-top-4': 'center top 10rem',
        'center-top-200': 'center top 200px',
      },
      fontSize: {
        'nav': ['0.625rem', {
          lineHeight: '120%',
          letterSpacing: '-0.01em',
          fontWeight: '400',
        }],
        'screen-title': ['1.125rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'sub-link': ['0.813rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'modal-desc': ['0.875rem', {
          lineHeight: '158%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'modal-h': ['1rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'modal-btn': ['0.875rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'timer': ['0.75rem', {
          lineHeight: '117%',
          letterSpacing: '0',
          fontWeight: '800',
        }],
        'body-1': ['0.875rem', {
          lineHeight: '157%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'nav-tab': ['0.875rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'nav-tab-active': ['0.875rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '900',
        }],
        'alltrip-section-list-item': ['0.813rem', {
          lineHeight: '153%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'alltrip-section-heading': ['1.25rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'alltrip-nav-heading': ['1.875rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'post-hero-heading': ['1.5rem', {
          lineHeight: '125%',
          letterSpacing: '-0.3px',
          fontWeight: '700',
        }],
        'post-hero-desc': ['0.938rem', {
          lineHeight: '153%',
          letterSpacing: 'auto',
          fontWeight: '400',
        }],
        'interest-h1': ['0.875rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'interest-nav': ['0.813rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'interest-nav-active': ['0.813rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '600',
        }],
        'interest-item-h3': ['0.875rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'interest-item-desc': ['0.75rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'stay-title': ['1rem', {
          lineheight: '118%',
          letterSpacing: 'auto',
          fontWeight: '700',
        }],
        'stay-sub-title': ['0.938rem', {
          lineheight: '118%',
          letterSpacing: 'auto',
          fontWeight: '700',
        }],
        'stay-grade': ['0.625rem', {
          lineheight: '118%',
          letterSpacing: 'auto',
          fontWeight: '400',
        }],
        'stay-list-item-desc': ['0.688rem', {
          lineHeight: '145%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'stay-list-item-title': ['0.813rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'stay-list-item-price': ['0.873rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '700',
        }],
        'stay-default': ['0.813rem', {
          lineHeight: '162%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        'search-result-item-title': ['1.063rem', {
          lineHeight: '100%',
          letterSpacing: '-0.3px',
          fontWeight: '500',
        }],
        'search-result-item-price': ['1.125rem', {
          lineHeight: '100%',
          letterSpacing: '-0.3px',
          fontWeight: '600',
        }],
        'stay-nav-tab': ['0.813rem', {
          lineHeight: '100%',
          letterSpacing: '0',
        }],
        'fare-item-desc': ['0.688rem', {
          lineHeight: '100%',
          letterSpacing: '0',
          fontWeight: '400',
        }],
        '2xs': ['10px', {
          lineHeight: '14px',
          letterSpacing: 0,
        }],
      },
      backgroundImage: {
        'screen-logo': 'url("@img/logo.svg")',
        'hero-broken': 'url("@img/placeholder-img.png")'
      },
      padding: {
        'modal': '40px 30px 0',
        'modal-with-btn': '40px 30px',
      },
      gap: {
        'alltrip-section': '3.313rem',
      },
      zIndex: {
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
        200: 200,
        300: 300,
        400: 400,
        500: 500,
        600: 600,
        700: 700,
        800: 800,
        900: 900,
        999: 999,
      },
      spacing: {
        'alltrip-item': '8.75rem',
      },
      backgroundSize: {
        'broken': '32px 32px'
      },
      boxShadow: {
        'guest-info': '0px 0px 4px rgba(0, 0, 0, 0.25)',
        'up-line-2px-ee': 'inset 0 2px 0 0 #eeeeee',
      },
    },
  },
  plugins: [],
};

module.exports = _;

import React, { useLayoutEffect, useState } from 'react';
import { subscribeSearchedSuggestion, getSearchedSuggestion } from '@stream/searchedSuggestionHistory';


const endIndexMap = {
  'parent-city': 1,
  'city': 2,
  'hotel': 2,
};

function SelectedWord({ division }) {
  const [selectedWord, setSelectedWord] = useState(getSearchedSuggestion());

  useLayoutEffect(() => {
    subscribeSearchedSuggestion(setSelectedWord);
  }, [setSelectedWord]);

  return (
    selectedWord.slice(0, endIndexMap[division]).map(
      ({ caption }, i, arr) => <div
        key={`bread-crumb-${i}`}
        className="
          flex
          justify-center
          items-center
          w-fit
          h-[22px]
          text-[#1E1E1E]
          text-[16px]
          leading-[140%]
        "
      >
        <span
          className={`${i === arr.length - 1 ? 'font-bold' : 'font-regular'} h-full text-ellipsis overflow-hidden`}
        >
          {caption}
        </span>
      </div>
    )
  );
}

export default SelectedWord;

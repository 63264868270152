import { HorizontalItemContainer } from '@comp/HorizontalScroll';
import React from 'react';


const baseClass = 'block curation-btn-main px-[16px] py-[7px] text-[13px] leading-[160%] rounded-full cursor-[pointer] ';
const inActiveClass = 'text-[#1E1E1E] font-regular border border-[#E0E0E0]';
const activeClass = 'text-[#FFFFFF] font-bold bg-[#4A90E2]';

function FeaturedAtMainTabItem({
  isActive,
  onClick,
  index,
  children,
}) {
  return (
    <HorizontalItemContainer
      paddingRight={10}
      paddingLeft={index === 0 ? 20 : 0}
    >
      <div
        onClick={onClick}
        className={baseClass + (isActive ? activeClass : inActiveClass)}
      >
        {children}
      </div>
    </HorizontalItemContainer>
  );
}

export default FeaturedAtMainTabItem;

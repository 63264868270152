import React, {useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useLoaderData, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InterSection from '@comp/Layouts/InterSection';
import Section from '@comp/Layouts/Section';
import SectionHeading from '@comp/Headings/SectionHeading';
import { HorizontalScrollContainer } from '@comp/HorizontalScroll';

import PostThumbnail from '@comp/PostThumbnail/PostThumbnail';
import StayGoodBtn from '@comp/StayGoodBtn/StayGoodBtn';
import { PhillWhenToggled } from '@comp/TogglePhill';
import { TogglePlainText, TogglePlainTextUpLine } from '@comp/TogglePlainText';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { updateBookmarkId } from '@store/tags';
import { updateStoreBookmarkId } from '@store/blogPosts';
import FavoriteButtonLight from '@comp/Interested/FavoriteButtonLight';
import FavoriteButtonDark from '@comp/Interested/FavoriteButtonDark';
import { ShareBtn } from '@comp/Share';
import imgUnavailable from '@asset/img/img_unavailable_placeholder_bg.png';
import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { makeReachScrollDepthThresholdsStream } from '@stream/instanceScroll';
import { isOpenPromotion } from '@util/promotion';

import { ReactComponent as ArrowLeftIcon } from '@asset/icon/arrow_left.svg'
import { injectTeadsScript } from '@util/injectTeadsScript';

// ----- 스크롤 로딩 관련 의존성 주석처리 시작 -----
// import { subscribeIsScrolledToBottom } from '@stream/scroll';
// import { curationStayPaging } from '@service/curation';
// import { ModalSpinner } from '@comp/ModalSpinner';
// ----- 스크롤 로딩 관련 의존성 주석처리 끝 -----


function Contents() {
  const data = useLoaderData();
  const navigate = useNavigate();
  const [isMetaRendered, setIsMetaRendered] = useState(false);
  const [curationData, setCurationData] = useState();
  
  useEffect(() => {
    if (!isMetaRendered) return;
    window.dataLayer.push({
      event: `search_curation__pv`,
      page_title: data?.seo_meta?.title
    });
  }, [data, isMetaRendered]);

  useEffect(() => {
    setCurationData(data);
    try {
      const _data = JSON.parse(window.sessionStorage.getItem(`${data.tag_id}-${data.region_id}-stay-data`));
      if (!_data) throw new Error('no more data');
      setCurationData(_data);
    } catch (err) {
      setCurationData(data);
    }
    // ----- 스크롤 로딩 관련 초기화 주석처리 시작 -----
    // try {
    //   const _page = parseInt(JSON.parse(window.sessionStorage.getItem(`${data.tag_id}-${data.region_id}-stay-page`)));
    //   if (!_page) throw new Error('no more page');
    //   setStayPage(_page);
    // } catch (err) {
    //   setStayPage(1);
    // }
    // ----- 스크롤 로딩 관련 초기화 주석처리 끝 -----
  }, [data]);
  
  const [userInfo, setUserInfo] = useState(getUserInfo());
  useLayoutEffect(() => {
    subscribeUserInfo(setUserInfo);
  }, []);

  const updateDate = (index, postId, bookmark_id) => {
    const newData = curationData;
    newData.curation.posts[index].bookmark_id = bookmark_id;
    setCurationData(newData);
    updateBookmarkId(postId, bookmark_id);
    updateStoreBookmarkId(postId, bookmark_id);
  };

  // ----- 스크롤 로딩 주석처리 시작 -----
  // const [isFetching, setIsFetching] = useState(false);
  // const [stayPage, setStayPage] = useState(1);
  // const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  // useEffect(() => {
  //   subscribeIsScrolledToBottom(setIsScrolledToBottom);
  // }, []);
  // useEffect(() => {
  //   if (!isScrolledToBottom || isFetching || stayPage < 0) return;
  //   setIsScrolledToBottom(false);
  //   setIsFetching(true);
  //   const newPage = stayPage + 1;
  //   curationStayPaging({
  //     location_type: curationData?.location_type,
  //     region_id: curationData?.region_id,
  //     tag_id: curationData?.tag_id,
  //     page: newPage,
  //   }).then((res) => {
  //     if (!res.isOK) {
  //       throw new Error('큐레이션 페이징 추가 실패');
  //     }
  //     setStayPage(v => {
  //       const newV = (res.data.posts?.length > 0) ? newPage : -1;
  //       window.sessionStorage.setItem(`${curationData.tag_id}-${curationData.region_id}-stay-page`, newV); // api 바뀐 후 재수정 필요
  //       return newV;
  //     });
  //     setCurationData(v => {
  //       const newV = {
  //         ...curationData,
  //         curation: {
  //           ...(curationData.curation),
  //           hotels: [
  //             ...(curationData.curation.hotels),
  //             ...(res?.data?.hotels || [])
  //           ]
  //         }
  //       };

  //       window.sessionStorage.setItem(`${curationData.tag_id}-${curationData.region_id}-stay-data`, JSON.stringify(newV)); // api 바뀐 후 재수정 필요
  //       return newV;
  //     });

  //   }).finally(() => setIsFetching(false));
  // }, [isScrolledToBottom, isFetching, stayPage, curationData]);
  // ----- 스크롤 로딩 주석처리 끝 -----

  useEffect(() => {
    window.scroll(0, 0);
    const {
      subscribe,
      unsubscribe,
    } = makeReachScrollDepthThresholdsStream();
    subscribe((depth) => {
      if (depth === 0) return;
      window.dataLayer.push({
        event: 'alltrip_curation__scroll_scroll-depth',
        scroll_depth_percentage: parseInt(depth),
        page_title: window.document.title,
        page_path: window.location.pathname,
        page_location: window.location.href,
        page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <>
    <Helmet onChangeClientState={(a, b, c) => handleChangeHelmetState(a, b, c, () => {
      injectTeadsScript();
      setIsMetaRendered(true);
    })}>
      <title>{curationData?.seo_meta?.title}</title>
      <meta property="og:title" content={curationData?.seo_meta?.title.replace(' | 올스테이', '')} />
      <meta name="description" content={curationData?.seo_meta?.description} />
      <meta property="og:description" content={curationData?.seo_meta?.description} />
      <meta property="og:url" content={curationData?.canonical || curationData?.seo_meta?.url} />
      <link rel="canonical" href={curationData?.canonical || curationData?.seo_meta?.url} />
      <meta name="twitter:title" content={curationData?.seo_meta?.title.replace(' | 올스테이', '')} />
      <meta name="twitter:description" content={curationData?.seo_meta?.description} />
      <meta property="og:image" content={curationData?.seo_meta?.image} />
      <meta name="twitter:image" content={curationData?.seo_meta?.image} />
    </Helmet>
    <main id="allstay-main">
      <div className="allstay-contents allstay-contents-box">
        <div className="flex flex-col">
        <div className="flex w-full px-5 justify-center items-center py-2">
            <div className="flex w-full h-full items-center">
              <div className="flex w-full h-12 justify-center items-center gap-3">
                <button onClick={() => window.history.length <= 2 ? navigate('/') : navigate(-1)} >
                  <ArrowLeftIcon />
                </button>
                <h2 className="w-full font-bold text-lg text-allstay-gray-1e text-center">{curationData?.isArchive ? '' : (curationData?.location_type?.startsWith('domestic') ? '국내 ' : '해외 ')}추천</h2>
                <ShareBtn
                  className="w-8 h-8 flex justify-center items-center"
                  size={32}
                  iconSize={24}
                  url={curationData?.canonical || curationData?.seo_meta?.url}
                  image={curationData?.seo_meta?.image}
                  onClick={() => {
                    // 공유하기 팝업 창을 띄우는 공유하기 버튼 클릭 이벤트 트래킹
                    const pathArr = window.location.pathname.split('/');
                    window.dataLayer.push({
                      event: 'alltrip_curation__shareButton',
                      as_d1: pathArr[1],
                      as_d2: pathArr[2],
                      // as_context: `shareButton`,
                      tag_keyword: curationData?.curation?.tag?.tag,
                      tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                      tag_region: curationData?.curation?.city?.city,
                      tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                      page_title: window.document.title,
                      page_path: window.location.pathname,
                      page_location: window.location.href,
                      page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                      sharetype: '',
                      sharetype_click: 0,
                      shareButton_click: 1,
                    });
                  }}
                >
                  {curationData?.seo_meta?.title}
                  <span className="sr-only">공유하기</span>
                </ShareBtn>
              </div>
            </div>
          </div>
          <InterSection>
            <Section gapToken={6}>
              {
                curationData?.isArchive &&
                <div className="flex justify-stretch w-full w-max-mobile px-5">
                  <NavLink to="/alltrip/domestic-curation?is_archive=true" className="grow text-center">
                    <TogglePlainText isToggled={curationData?.location_type === 'domestic'}>국내</TogglePlainText>
                  </NavLink>
                  <NavLink to="/alltrip/overseas-curation?is_archive=true" className="grow text-center">
                    <TogglePlainText isToggled={curationData?.location_type === 'overseas'}>해외</TogglePlainText>
                  </NavLink>
                </div>
              }
              <div className="w-full w-max-mobile">
                <HorizontalScrollContainer
                  centeredSlidesBounds={true}
                  initialSlide={curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code)}
                  slidesOffsetAfter={20}
                  slidesOffsetBefore={20}
                  gap={12}
                  key={`${curationData?.location_type}-curation-tag-navs`}
                  id={`${curationData?.location_type}-curation-tag-navs`}
                  speed={150}
                  onClickSlides={curationData?.tags // ?.filter(({ tag }) => tag !== '드라이브') // 2024/02/02 filter manually
                    // ?.slice(0, 8)
                    ?.map(({ tag_code }) => {
                    return (e) => {
                      if (e.width > document.querySelector('.allstay-contents').clientWidth) {
                        e.activeIndex = e.clickedIndex;
                        e.slideTo(e.clickedIndex);
                      }
                      navigate(`/alltrip/${curationData.location_type}-curation/${tag_code}`);
                    }
                  })}
                >
                  {
                    curationData
                      ?.tags
                      // ?.filter(({ tag }) => tag !== '드라이브') // 2024/02/02 filter manually
                      // ?.slice(0, 8)
                      ?.map(({
                        tag,
                        tag_code,
                      }, i) => {
                        const elKey = `curation-tag-nav-${tag_code}}`;
                        return <swiper-slide
                          class="w-fit"
                          key={elKey}
                        >
                          <button
                            onClick={(e) => {
                              e?.preventDefault?.();
                              window.dataLayer.push({
                                event: 'curation-tag-click',
                                tag_keyword: tag,
                                tag_keyword_order: i + 1,
                                page_title: window.document.title,
                                page_path: window.location.pathname,
                                page_location: window.location.href,
                                page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                              });
                            }}
                            className="curation-nav-tag"
                            key={`${curationData?.location_type}-curation-nav-tag-${tag_code}`}
                            data-tag-index={i}
                            data-tag-active={(curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code)) === i}
                          >
                            <PhillWhenToggled isToggled={tag_code === curationData?.curation?.tag?.tag_code}>{tag}</PhillWhenToggled>
                          </button>
                        </swiper-slide>;
                      }
                    )
                  }
                </HorizontalScrollContainer>
              </div>
              <div className="w-full w-max-mobile shadow-up-line-2px-ee">
                <HorizontalScrollContainer
                    centeredSlidesBounds={true}
                    initialSlide={curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id)))}
                    slidesOffsetAfter={20}
                    slidesOffsetBefore={20}
                    gap={20}
                    key={`${curationData?.location_type}-curation-city-navs-${curationData?.curation?.tag?.tag_code}`}
                    id={`${curationData?.location_type}-curation-city-navs-${curationData?.curation?.tag?.tag_code}`}
                    speed={150}
                    onClickSlides={curationData?.cities
                      // ?.slice(0, 7)
                      ?.map(({ country_code, city_code, region_id }) => {
                      return (e) => {
                        e?.preventDefault?.();
                        if (e.width > document.querySelector('.allstay-contents').clientWidth) {
                          e.activeIndex = e.clickedIndex;
                          e.slideTo(e.clickedIndex);
                        }
                        navigate(`/alltrip/${curationData.location_type}-curation/${country_code}/city/${city_code}/${curationData?.curation?.tag?.tag_code}${typeof region_id === 'string' ? `?region_id=${region_id}` : ''}`);
                      }
                    })}
                  >
                    {curationData?.cities
                    // ?.slice(0, 7)
                    ?.map(({
                      region_id,
                      name,
                      country_code,
                      city_code,
                      city, // 보다 자세한 행정구역
                      // distance,
                    }, i) => {
                      const elKey = `curation-city-nav-${region_id}-${city_code}}`;
                      return <swiper-slide
                        class="w-fit"
                        key={elKey}
                      >
                        <button
                          onClick={() => {
                            window.dataLayer.push({
                              event: 'curation-region-click',
                              tag_keyword: curationData?.curation?.tag?.tag,
                              tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                              tag_region: city,
                              tag_region_order: i + 1,
                              page_title: window.document.title,
                              page_path: window.location.pathname,
                              page_location: window.location.href,
                              page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                            });
                          }}
                          className="curation-nav-region"
                          key={`${curationData?.location_type}-curation-nav-tag-${country_code}-${city_code}`}
                          data-region-index={i}
                          data-region-active={(curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id)))) === i}
                        >
                          <TogglePlainTextUpLine isToggled={((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))}>{curationData.location_type === 'overseas' ? city : (name || city)}</TogglePlainTextUpLine>
                        </button>
                      </swiper-slide>;
                    })}
                </HorizontalScrollContainer>
              </div>
            </Section>
            {
              curationData?.curation?.posts?.length ?
              <Section>
                <SectionHeading
                  // SubFuncUi={() => { // "더/전체 보기" 링크 다음 mvp 버전에 활용 위해 주석 처리 함.
                  //   const canonical = `/${curationData?.location_type}-curation/${'country-code'}/city/${'city-code'}/${curationData?.curation?.tag?.tag_code}?location_type=${curationData?.location_type}&tag_id=${curationData?.curation?.tag?.tag_id}&region_id=${curationData?.curation?.city?.region_id}`;
                  //   return <NavLink
                  //     to={canonical}
                  //     state={{
                  //       canonical,
                  //       location_type: curationData?.location_type,
                  //       cities: curationData?.cities,
                  //       tag: curationData?.curation?.tag,
                  //       city: curationData?.curation?.city,
                  //     }}
                  //     className="text-right text-blue-500 text-sm font-bold leading-tight"
                  //   >
                  //     더보기
                  //   </NavLink>;
                  // }}
                >
                  이런 여행 좋아하실 것 같아요 👍{/* {curationData?.curation?.tag?.tag} */}
                </SectionHeading>
                <div className="w-full w-max-mobile">
                  {
                    curationData?.curation?.hotels?.length ?
                      <HorizontalScrollContainer
                        gap={12}
                        className={`px-5`}
                        key={`curation-post-container-${curationData?.curation?.tag?.tag_id}-${curationData?.curation?.city?.city_code}}`}
                      >
                        {curationData?.curation?.posts.map(({
                          id,
                          bookmark_id,
                          slug,
                          ...rest
                          // region_id,
                          // title,
                          // distance,
                          // image, class={`${i ? `ml-2` : ''}`}
                          // publisher,
                        }, i) => <swiper-slide class={curationData?.curation?.posts?.length === 1 ? `w-full` : `w-auto`} key={`curation-post-${id}-${i}`}>
                          <PostThumbnail
                            onClick={() => {
                              window.dataLayer.push({
                                event: 'curation-post-click',
                                tag_keyword: curationData?.curation?.tag?.tag,
                                tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                                tag_region: curationData?.curation?.city?.city,
                                tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                                post_title: rest?.title,
                                post_order: i + 1,
                                page_title: window.document.title,
                                page_path: window.location.pathname,
                                page_location: window.location.href,
                                page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                              });
                            }}
                            fullWidth={curationData?.curation?.posts?.length === 1}
                            to={`/alltrip/post/${slug}${slug.endsWith('/') ? '' : '/'}`}
                            i={i}
                            postId={id}
                            {...rest}
                            SubFuncUi={() => <FavoriteButtonLight
                              ref_model="content"
                              ref_id={id}
                              userInfo={userInfo}
                              bookmark_id={bookmark_id}
                              updateDate={bid => updateDate(i, id, (bid || bookmark_id))}
                              onClick={({ is_loged_in, turning_on_off }) => {
                                // 페이보릿 버튼 클릭 이벤트 트래킹
                                const pathArr = window.location.pathname.split('/');
                                window.dataLayer.push({
                                  event: 'curation_post__favoriteButton',
                                  as_d1: pathArr[1],
                                  as_d2: pathArr[2],
                                  // as_context: `favoriteButton`,
                                  page_title: window.document.title,
                                  page_path: window.location.pathname,
                                  page_location: window.location.href,
                                  page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                                  tag_keyword: curationData?.curation?.tag?.tag,
                                  tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                                  tag_region: curationData?.curation?.city?.city,
                                  tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                                  post_title: rest?.title,
                                  post_order: i + 1,
                                  is_loged_in,
                                  turning_on_off,
                                });
                              }}
                            />}
                          />
                        </swiper-slide>)}
                    </HorizontalScrollContainer> :
                    <div className="flex flex-col gap-3 px-5">
                      {curationData?.curation?.posts.map(({
                        id,
                        bookmark_id,
                        slug,
                        ...rest
                        // region_id,
                        // title,
                        // distance,
                        // image,
                        // publisher,
                      }, i) => <PostThumbnail
                        fullWidth={true}
                        onClick={() => {
                          window.dataLayer.push({
                            event: 'curation-post-click',
                            tag_keyword: curationData?.curation?.tag?.tag,
                            tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                            tag_region: curationData?.curation?.city?.city,
                            tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                            post_title: rest?.title,
                            post_order: i + 1,
                            page_title: window.document.title,
                            page_path: window.location.pathname,
                            page_location: window.location.href,
                            page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                          });
                        }}
                        to={`/alltrip/post/${slug}`}
                        key={`curation-post-${id}-${i}`}
                        i={i}
                        postId={id}
                        {...rest}
                        SubFuncUi={() => <FavoriteButtonLight
                          ref_model="content"
                          ref_id={id}
                          userInfo={userInfo}
                          bookmark_id={bookmark_id}
                          updateDate={bid => updateDate(i, id, (bid || bookmark_id))}
                          onClick={({ is_loged_in, turning_on_off }) => {
                            // 페이보릿 버튼 클릭 이벤트 트래킹
                            const pathArr = window.location.pathname.split('/');
                            window.dataLayer.push({
                              event: 'curation_post__favoriteButton',
                              as_d1: pathArr[1],
                              as_d2: pathArr[2],
                              // as_context: `favoriteButton`,
                              page_title: window.document.title,
                              page_path: window.location.pathname,
                              page_location: window.location.href,
                              page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                              tag_keyword: curationData?.curation?.tag?.tag,
                              tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                              tag_region: curationData?.curation?.city?.city,
                              tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                              post_title: rest?.title,
                              post_order: i + 1,
                              is_loged_in,
                              turning_on_off,
                            });
                          }}
                        />}
                      />)}</div>
                  }
                </div>
              </Section> : ''
            }
            <div id="teads-slot" style={{display: 'block'}}></div>
            <Section>
              {
                curationData?.curation?.hotels?.length ? 
                <SectionHeading>지금 인기 숙소는요 🎉</SectionHeading> : ''
                // <div className="px-5 text-center">
                //   <p className="text-lg"><strong>이런...</strong></p>
                //   <p>지금은 소개 드릴 숙소가 없어요 🥹</p>
                // </div>
              }
              <div className="w-full px-5 flex flex-col gap-5">
                {
                  curationData?.curation?.hotels?.map(({
                    images,
                    hotel_id,
                    bookmark_id,
                    country_code,
                    city_code,
                    imageNotFound=false,
                    ...rest
                    // hotel,
                    // price,
                    // channels,
                    // region_id,
                    // code,
                    // distance,
                  }, i) => {
                    const to = `/hotel/${country_code}/${city_code}/${hotel_id}`;
                    const [firstImg, ...imgs] = images;

                    const onClick = () => {
                      const gtmParams = {
                        hotel_name: rest?.hotel,
                        hotel_order: i + 1,
                        hotel_price: rest?.price,
                        page_title: window?.document?.title,
                        page_path: window?.location?.pathname,
                        page_location: window?.location?.href,
                        page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                      };
                      window.dataLayer.push({
                        event: 'curation-hotel-click',
                        ...gtmParams,
                        tag_keyword: curationData?.curation?.tag?.tag,
                        tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                        tag_region: curationData?.curation?.city?.city,
                        tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                      });

                      const promotions = rest?.promotions?.filter(promotion => isOpenPromotion({promotion}));
                      const pathArr = window.location.pathname.split('/');
                      if (promotions?.length) {
                        promotions?.forEach(({
                          name: promotion_name,
                          promotion_period: {
                            start_on,
                            end_on,
                          },
                          fixed_discount: promotion_fixed_discount,
                          percentage_discount: promotion_percentage_discount,
                        }) => {
                          const [promotion_start_date, promotion_start_time] = start_on.split('T').map(t => t?.trim() || t);
                          const [promotion_end_date, promotion_end_time] = end_on.split('T').map(t => t?.trim() || t);

                          window?.dataLayer?.push({
                            event: 'promotion-hotel-clicks',
                            as_d1: pathArr[1],
                            as_d2: pathArr[2],
                            as_context: `curation-promotion-hotel-clicks`,
                            ...gtmParams,
                            promotion_name,
                            promotion_start_date,
                            promotion_start_time,
                            promotion_end_date,
                            promotion_end_time,
                            promotion_fixed_discount,
                            promotion_percentage_discount,
                          });
                        });
                      }
                    }

                    return <div className="w-full flex flex-col gap-1" key={`curation-hotel-${hotel_id}-${i}`}>
                      <StayGoodBtn onClick={onClick}
                        index={i}
                        roundnessToken="rounded-tl-lg"
                        image={firstImg}
                        to={to}
                        {...rest}
                        SubFuncUi={() => <FavoriteButtonDark
                          ref_model="hotel"
                          ref_id={hotel_id}
                          userInfo={userInfo}
                          bookmark_id={bookmark_id}
                          updateDate={bid => updateDate(i, hotel_id, (bid || bookmark_id))}
                          onClick={({ is_loged_in, turning_on_off }) => {
                            // 페이보릿 버튼 클릭 이벤트 트래킹
                            const pathArr = window.location.pathname.split('/');
                            window.dataLayer.push({
                              event: 'curation_stay__favoriteButton',
                              as_d1: pathArr[1],
                              as_d2: pathArr[2],
                              // as_context: `favoriteButton`,
                              page_title: window.document.title,
                              page_path: window.location.pathname,
                              page_location: window.location.href,
                              page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                              tag_keyword: curationData?.curation?.tag?.tag,
                              tag_keyword_order: curationData?.tags?.findIndex(({ tag_code }) => tag_code === curationData?.curation?.tag?.tag_code) + 1,
                              tag_region: curationData?.curation?.city?.city,
                              tag_region_order: curationData?.cities?.findIndex(({ city_code, region_id }) => ((city_code === curationData?.curation?.city?.city_code) && (region_id === curationData?.curation?.city?.region_id))) + 1,
                              hotel_name: rest?.hotel,
                              hotel_order: i + 1,
                              hotel_price: rest?.price,
                              is_loged_in,
                              turning_on_off,
                            });
                          }}
                        />}
                      />
                      <NavLink onClick={onClick} to={to} className="w-full h-72 bg-white grid grid-cols-3 grid-rows-2 gap-1" data-index={i}>
                        <div className="w-full h-full relative rounded-bl-lg bg-center bg-no-repeat bg-cover col-span-2 row-span-2" style={{ backgroundImage: `${imgs?.[0] ? `url('${imgs[0]}'), ` : ''}url(${imgUnavailable})` }}></div>
                        <div className="w-full h-full relative bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `${imgs?.[1] ? `url('${imgs[1]}'), ` : ''}url(${imgUnavailable})` }}></div>
                        <div className="w-full h-full relative rounded-br-lg bg-center bg-no-repeat bg-cover overflow-hidden" style={{ backgroundImage: `${imgs?.[2] ? `url('${imgs[2]}'), ` : ''}url(${imgUnavailable})` }}>
                          <div className="w-full h-full bg-gradient-to-b from-allstay-gray-1e opacity-70 absolute top-0 left-0 flex justify-center items-center" />
                          <div className="w-full h-full text-center text-white text-2xl font-semibold leading-loose absolute top-0 left-0 flex justify-center items-center">{images.length > 3 ? `+${images.length - 3}` : ''}</div>
                        </div>
                        <span className="hotel-name sr-only">{rest.hotel}</span>
                      </NavLink>
                    </div>;
                  })
                }
              </div>              
            </Section>
          </InterSection>
        </div>
        {/* <div className="flex justify-center items-center h-20">
          <p className="text-allstay-gray-bd text-sm">{curationData?.curation?.hotels?.length ? '인기 숙소를 모두 불러왔어요! 💯' : '이 테마의 인기 숙소는 전부 매진인가 봐요'}</p>
        </div> */}
        {/* {isFetching ? <ModalSpinner /> : <p className={`text-allstay-gray-1e transition-opacity duration-500 ${stayPage < 0 ? 'opacity-100' : 'opacity-0'}`}>인기 숙소를 모두 불러왔어요! 💯</p>} */}
      </div>
    </main>
  </>;
}

export default Contents;

import React, { useState, useLayoutEffect } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  subscribeSortingType,
  getSortingType,
  setSortingType,
  sortingTypeOption,
} from '@store/sortingType';


function SortingTypeInput() {
  const [val, setVal] = useState(getSortingType());

  useLayoutEffect(() => {
    subscribeSortingType(setVal);
  }, [setVal]);

  const handleChange = ({target: {value}}) => setSortingType(value);

  return (
    <div className="flex flex-col gap-[20px]">
      <label className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">정렬 방식</label>
      <RadioGroup
        aria-labelledby="sorting-type-radio-buttons-group-label"
        name="sorting-type-radio-buttons-group"
        value={val}
        onChange={handleChange}
        sx={{
          minWidth: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          justifyItems: 'start',
          gap: '10px',
        }}
      >
        {sortingTypeOption.map(({ name, value }) =>
          <FormControlLabel
            key={`sort-type-range-label-${value}`}
            value={value}
            sx={{
              marginLeft: 0,
            }}
            control={<Radio
              size="small"
              sx={{
                padding: 0,
                marginRight: '10px',
              }}
            />}
            label={name}
            componentsProps={{
              typography : {
                color: '#1E1E1E',
                fontWeight: 'regular',
                fontSize: '14px',
                lineHeight: '160%',
              }
            }}
          />)
        }
      </RadioGroup>
    </div>
  );
}

export default SortingTypeInput;

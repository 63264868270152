import { adjustPromoPrice, simulateAugSep2024Ssf } from '@util/promotion';
import { allstayHttpService } from './api';
import { filterByRule } from '@util/taggedCurationRuleBasedFilter';

const removeDuplicatedElements = (arr, key) => {
  if (!arr) return [];
  return arr.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t[key] === item[key]
    ))
  );
}

export const curation = ({
  location_type,
  tag_id,
  region_id,
  country_code,
  city_code,
  tag_code,
  searchParams,
}) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}`, {
      tag_id: tag_id || tag_code?.split('-')?.pop(),
      region_id,
      country_code,
      city_code,
      tag_code,
    }) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          const result = {
            data: {
              ...res?.data,
              cities: removeDuplicatedElements(res?.data?.cities, 'city'),
              tags: removeDuplicatedElements(res?.data?.tags, 'tag'),
              curation: {
                ...res?.data?.curation,
                hotels: removeDuplicatedElements(res?.data?.curation?.hotels, 'hotel').map((hotel, i) => {
                  // 2024 05, 2024 06 숙세페 강제 시뮬레이트
                  const newHotel = { ...hotel };
                  const { isEnabled, dummyPromo } = simulateAugSep2024Ssf(searchParams);
                  if (isEnabled && !(i % 2)) newHotel.promotions = [dummyPromo];
                  newHotel.promotions = newHotel?.promotions?.map(promo => ({ ...promo, ...adjustPromoPrice(promo, newHotel.price) }));
                  return newHotel;
                }),
                posts: removeDuplicatedElements(res?.data?.curation?.posts, 'title'),
              },
              seo_meta: {
                ...res?.data?.seo_meta,
                image: res?.data?.curation?.posts?.[0]?.image?.url || res?.data?.curation?.posts?.[0]?.image,
              }
            },
            isOK: true,
          };

          // 태그, 지역, 숙소, 포스트 룰베이스 필터링과 임시 필터링.
          // 아래 함수가 정의된 js 파일 내에서 모든 룰을 관리하도록 리팩토링.
          // 발리/우붓 관련 임시 필터링 코드도 해당 파일로 이전 함. 2024-05-28.
          result.data = filterByRule(result.data);

          resolve(result);
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};

export const curationContentsPaging = (location_type, tag_id, region_id, page=1) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}/${tag_id}/${region_id}/post`, {
      page,
    }) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: res.data,
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curationPaging err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};

export const curationStayPaging = ({
  location_type,
  tag_id,
  region_id, // will be deprecated
  country_code,
  city_code,
  tag_code,
  page=1,
}) => {
  const _tag_id = tag_id || tag_code?.split('-')?.pop();
  const epUrl = (!country_code || !city_code) ? `/public/curation/${location_type}/${_tag_id}/${region_id}/hotel` : 'api 미정...';
  
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(epUrl, {
      page
    }) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: res.data,
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
}


// 태그 리스트
export const getTagList = ({
  location_type='domestic',
}) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}/tag`) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: removeDuplicatedElements(res?.data?.tags, 'tag'),
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};


// 더보기 태그 리스트
export const getPastTagList = ({
  location_type='domestic',
}) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}/past/tag`) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: removeDuplicatedElements(res?.data?.tags, 'tag'),
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};


// 지역 리스트
export const getRegionList = ({
  location_type='domestic',
  tag_id,
}) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}/${tag_id}/region`) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: removeDuplicatedElements(res?.data, 'city'),
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};


// tagged 포스트 리스트
export const getTaggedPostList = ({
  location_type='domestic',
  tag_id,
  region_id,
  country_code,
}) => {
  let params = {};
  if (region_id) params.region_id = region_id;
  if (country_code) params.country_code = country_code;

  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}/${tag_id}/post`, params) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: removeDuplicatedElements(res?.data, 'title'),
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};

// tagged 숙소 리스트
export const getTaggedHotelList = ({
  location_type='domestic',
  tag_id,
  region_id,
  country_code,
}) => {
  let params = {};
  if (region_id) params.region_id = region_id;
  if (country_code) params.country_code = country_code;

  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/curation/${location_type}/${tag_id}/hotel`, params) 
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            data: removeDuplicatedElements(res?.data, 'hotel'),
            isOK: true,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('curation err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};

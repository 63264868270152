import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import handleChangeHelmetState from '@util/handleChangeHelmetState';
import Gnb from '@comp/Gnb/Gnb';

function NotFound404() {
  const [isNotFound, setIsNotFound] = useState(/is_not_found=true/.test(window.location.search));
  useEffect(() => {
    const sp = new URLSearchParams(window.location.search);
    const _isNotFound = sp.get('is_not_found') || isNotFound;
    if (_isNotFound) {
      setIsNotFound(_isNotFound);
    } else {
      console.log('404', (window.location.href));
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('is_not_found', 'true');
      window.location.replace(newUrl);
    }
  }, [isNotFound]);

  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState}>
        <title>404 Not Found | 올스테이</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {
        isNotFound &&
        <div>
          <div id="layout">
            <div id="default-layout">
              <div className="allstay">
              <div className="allstay-layer">
                <div className="ease-linear duration-50 transition-opacity allstay-partial gnb z-700">
                  <Gnb />
                </div>
              </div>
                <div>
                  <main id="allstay-main" className="h-screen max-h-screen">
                    <div className="p-0 mx-auto w-full max-w-[480px] bg-allstay-gray-ee h-full flex flex-col gap-20">
                      <div className={`flex flex-col gap-2 top-0 bg-[#FFFFFF] z-[102]`}>
                        <div className="w-full gap-[10px] py-[30px] px-[20px]">
                          <div className="flex flex-col max-w-full">
                            <div className="relative flex justify-center items-center">
                              <button
                                onClick={() => window.location.assign('/')}
                                className="absolute left-0"
                              >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3 17.409V9.68901L10 3.40901L17 9.68901V17.409H3Z" stroke="black" strokeWidth="1.5" />
                                  <path d="M10 12.409L10 17.409" stroke="black" strokeWidth="1.5" />
                                </svg>
                              </button>
                              <h2 className="font-bold text-[15px] leading-[160%] text-[#1E1E1E] justify-self-center">404 Not Found</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="max-h-[300px] h-full flex flex-col justify-between">
                        <div className="w-full px-5 text-center text-allstay-gray-1e text-xl font-light leading-7">
                          이런! 💦<br />
                          원하시는 페이지를<br />
                          찾을 수가 없네요<br />
                          😢<br />
                        </div>
                        <div className="w-full px-5 flex flex-col gap-6">
                          {
                            window?.history?.length > 2 && !/from_legacy_blog=true/.test(window.location.search) &&
                            <button onClick={() => window.history.back()} className="w-full px-5 rounded border border-allstay-gray-1e justify-center items-center inline-flex">
                              <div className="grow shrink basis-0 h-12 pr-[30px] justify-start items-center gap-1 flex">
                                <div className="w-full justify-start items-center gap-2 flex">
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.91069 10.7465L10.6951 15.4443L9.61986 16.5L3 10L9.61986 3.5L10.6951 4.55573L5.91069 9.25348H17V10.7465H5.91069Z" fill="#1E1E1E" />
                                  </svg>
                                  <div className="text-center text-allstay-gray-1e text-sm font-semibold leading-tight">이전 페이지로 돌아갈까요?</div>
                                </div>
                              </div>
                          </button>
                          }
                          <button onClick={() => window.location.assign('/')} className="w-full px-5 rounded border border-allstay-gray-1e justify-center items-center inline-flex">
                            <div className="grow shrink basis-0 h-12 pr-[30px] justify-start items-center gap-1 flex">
                              <div className="w-full justify-start items-center gap-2 flex">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3 17.409V9.68901L10 3.40901L17 9.68901V17.409H3Z" stroke="black" strokeWidth="1.5" />
                                  <path d="M10 12.409L10 17.409" stroke="black" strokeWidth="1.5" />
                                </svg>
                                <div className="text-center text-allstay-gray-1e text-sm font-semibold leading-tight">홈 페이지로 돌아갈까요?</div>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
            <footer className="w-full bg-white-100">
            </footer>
          </div>
          {/* <div className={`${searchPanelIsOpen ? '' : 'hidden'}`}>
            <SearchPanel />
          </div>
          {
            modal.isOpened &&
            <Modal isClosable={modal.isClosable}>
              {modal.template && <modal.template {...modal.data} />}
            </Modal>
          } */}
        </div>
      }
    </>
  );
}

export default NotFound404;

import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  tap,
} from "rxjs";
import { diff } from 'deep-object-diff';
import hasKeyFactory from "@util/hasKeyFactory";
import { isAfter, subSeconds } from "date-fns";
import jwtDecode from "jwt-decode";


const LS_KEY = process.env.REACT_APP__LS_KEY_USER;
export const ACC_TOKEN_KEY = 'access_token';
export const RFS_TOKEN_KEY = 'refresh_token';
export const PAYLOAD_KEY = 'payload';

const hasAccessToken = hasKeyFactory(ACC_TOKEN_KEY);
const isNotExpired = exp => isAfter(subSeconds(new Date(exp * 1000), 10), new Date());
const decodeJWT = (token, expKey='exp') => {
  try {
    const _ = jwtDecode(token);
    return isNotExpired(_[expKey]) ? _ : { ..._, [ACC_TOKEN_KEY]: '' };
  } catch (err) { // not valid JWT
    return null;
  }
};
const getStoredUserInfo = () => {
  let stored = null;
  try {
    stored = JSON.parse(localStorage.getItem(LS_KEY) || null);
  } catch {
    stored = null;
  }
  return stored;
};
const mapper = (u=null) => {
  if (!!u) { // 유저 정보가 있는가?
    if (hasAccessToken(u)) { // 유저 정보에 액세스 토큰이 있는가?
      const jwt = decodeJWT(u[ACC_TOKEN_KEY]);
      if (!!jwt) { // 정상적인 형태의 비만료 토큰인가?
        localStorage.setItem(LS_KEY, JSON.stringify(u));
        return u;
      }
    }
  }
  // 모두 아니라면 null 반환 // localStorage.removeItem(LS_KEY);
  return null;
};

const _ = new BehaviorSubject(getStoredUserInfo());
const __ = new BehaviorSubject(_.getValue());
_.pipe(
  map(mapper),
  distinctUntilChanged((p, c) => {
    const d = diff(p || {}, c || {});
    const isChanged = Object.keys(d).length;
    return !isChanged;
  }),
  tap(u => localStorage.setItem(LS_KEY, JSON.stringify(u))),
).subscribe(__);

const userInfo = __;
export default userInfo;
export const subscribeUserInfo = f => __.subscribe(f);
export const getUserInfo = () => {
  const c = __.getValue();
  _.next(c);
  return __.getValue();
};
export const getAccessToken = () => (getUserInfo() || {})[ACC_TOKEN_KEY];
export const getRefreshToken = () => (getUserInfo() || {})[RFS_TOKEN_KEY];
export const getPayload = () => (getUserInfo() || {})[PAYLOAD_KEY];
export const setUserInfo = (u=null) => _.next(u);

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getRouterHistory } from '@stream/routerHistory';

function BackButton({clickBackbutton}) {
    const navigate = useNavigate();
    return (
        <button onClick={() => {
            if (!!clickBackbutton) {
                clickBackbutton();
                return;
            };

            if (getRouterHistory().length > 1) {
                navigate(-1);
            } else {
                navigate('/');
            };
        }} className="shrink">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.91069 10.7465L10.6951 15.4443L9.61986 16.5L3 10L9.61986 3.5L10.6951 4.55573L5.91069 9.25348H17V10.7465H5.91069Z" fill="#1E1E1E" />
            </svg>
        </button>
    );
}

export default BackButton;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@style/MuiTheme';
import '@stream/scroll';
import { RouterProvider } from 'react-router-dom';
import browserRouter from '@/route';
import { routerListener } from '@stream/routerHistory';
import './firebase';
import '@stream/tokenRequestingBody';
// import '@stream/userInfo';
// import reportWebVitals from '@/reportWebVitals';


const container = document.getElementById('root');
const $root = createRoot(container);
browserRouter.subscribe(routerListener);

$root.render(
  <ThemeProvider theme={theme}>
    <RouterProvider router={browserRouter} />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

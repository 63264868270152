import React, { useLayoutEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  stayCategoryOption,
  getStayCategory,
  subscribeStayCategory,
  setStayCategory,
} from '@store/stayCategory';

function CategoryInput() {
  const [category, setCategory] = useState(getStayCategory());

  useLayoutEffect(() => {
    subscribeStayCategory(setCategory);
  }, [setCategory]);

  const handleChange = ({target: {value}}) => setStayCategory(value);

  return (
    <FormControl>
      <FormLabel>숙소 카테고리</FormLabel>
      <RadioGroup
        value={category}
        onChange={handleChange}
      >
        <div className="flex flex-wrap justify-between">
          {stayCategoryOption.map(({value, name}) =>
            <FormControlLabel
              key={`hotel-category-${name}`}
              value={value}
              control={<Radio size="small" />}
              label={name}
            />)
          }
        </div>
      </RadioGroup>
    </FormControl>
  );
}

export default CategoryInput;

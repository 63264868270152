import { bufferCount, distinctUntilChanged, map, Subject } from "rxjs";

let TOLERANCE = 300;

const source = new Subject();

const $html = document.querySelector('html');

const handleScroll = () => {
  const clientHeight = $html.clientHeight;
  const scrollHeight = $html.scrollHeight;
  const diff = scrollHeight - clientHeight;

  if (Math.abs(diff) < 1) return;

  const scrollTop = $html.scrollTop;
  const delta = diff - scrollTop;
  source.next(delta);
};
window.addEventListener('scroll', handleScroll);
$html.addEventListener('wheel', handleScroll);


const isScrolledToBottom = source.pipe(
  bufferCount(4, 1),
  map(buff => buff.map(v => Math.abs(v) < TOLERANCE).reduce((acc, cur) => acc && cur, true)),
  distinctUntilChanged(),
);

export const subscribeIsScrolledToBottom = listener => isScrolledToBottom.subscribe(listener);

import Heading from './Heading';
import React from 'react';

const headingClass = 'text-allstay-gray-1e text-base font-bold leading-normal';

function SectionHeading({
  level=2,
  SubFuncUi,
  children,
}) {
  return <div className="w-full px-5 flex justify-between items-baseline">
    <Heading level={level} className={headingClass}>{children}</Heading>
    {SubFuncUi && (typeof SubFuncUi === 'string' ? SubFuncUi : <SubFuncUi/>)}
  </div>;
}

export default SectionHeading;

import * as _ from 'lodash';
import { format } from 'date-fns';

export const getOutLink = (tokenRequestingBody, schedule, roomInfo, name, hotelId, outLinkChannel, target_url) => {
  const [{ startDate, endDate }] = schedule;
  const checkin = format(new Date(startDate), 'yyyy-MM-dd');
  const checkout = format(new Date(endDate), 'yyyy-MM-dd');
  const room_info = _.map(roomInfo, info => {
    let result = info.adult
    if(!_.isEmpty(info.minor)){
      result += `-${_.map(info.minor, 'age').join('_')}`
    }
    return result
  }).join(',');
  const {
    device_id,
    os_version,
    app_version,
    device_model,
  } = tokenRequestingBody;
  const sp = new URLSearchParams();
  target_url && sp.set('target_url', target_url);
  checkin && sp.set('checkin', checkin);
  checkout && sp.set('checkout', checkout);
  room_info && sp.set('room_info', room_info);
  name && sp.set('name', name);
  os_version && sp.set('os_version', os_version);
  app_version && sp.set('app_version', app_version);
  device_model && sp.set('device_model', device_model);
  device_id && sp.set('device_id', device_id);
  const spStr = sp.toString();

  return `/outlink/${hotelId}/${outLinkChannel.toLowerCase()}${!!spStr ? `?${spStr}` : ''}`;
}
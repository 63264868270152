import {
  BehaviorSubject,
  debounceTime,
  distinct,
  map,
} from 'rxjs';
import { subscribeSchedule, getSchedule } from '@stream/schedule';
import { subscribeRoomInfo, getRoomInfo } from '@stream/roomInfo';
import { format } from 'date-fns';

const FORMAT_STR = 'yyyy-MM-dd';
const sp = new URLSearchParams();

export const parameterizeSchedule = ([{startDate, endDate}], spObj=sp) => {
  spObj.set('check_in', format(new Date(startDate), FORMAT_STR));
  spObj.set('check_out', format(new Date(endDate), FORMAT_STR));
  return spObj;
};
export const parameterizeRoomInfo = (roomInfo, spObj=sp) => {
  const serialized = roomInfo.map(({adult, minor}) => {
    if (minor.length < 1) return `${adult}`;
    return `${adult}-${minor.map(({age}) => age).join('_')}`;
  }).join(',');
  spObj.set('room_info', serialized);
  return spObj;
};
const INIT = () => {
  parameterizeSchedule(getSchedule());
  parameterizeRoomInfo(getRoomInfo());
};
INIT();

const toStringParams = searchParams => searchParams.toString();

const searchParamStream = new BehaviorSubject(sp);
const searchParams = new BehaviorSubject(toStringParams(sp));

searchParamStream.pipe(
  map(toStringParams),
  distinct(),
).subscribe(searchParams);
subscribeSchedule(v => {
  parameterizeSchedule(v);
  searchParamStream.next(sp);
});
subscribeRoomInfo(v => {
  parameterizeRoomInfo(v);
  searchParamStream.next(sp);
});

export const subscribeSearchParams = subscriber => searchParams.subscribe(subscriber);
export const getSearchParams = () => searchParams.getValue();

const throttledHotelParams = searchParams.pipe(
  debounceTime(2000)
);

export const subscribeThrottledHotelParams = subscriber => throttledHotelParams.subscribe(subscriber);

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, {
  useEffect,
  useState
} from 'react';
import {
  IconButton,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionActions,
  Select,
  MenuItem,
} from '@mui/material';
import {
  ArrowBack,
  TaskAltOutlined,
  CheckCircleOutline,
  PanoramaFishEye,
  RemoveCircleOutline,
  ExpandMore,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { closeResetModal, openModal, setModal } from '@store/modal';

import serviceTermMD from '@md/terms-of-use.md';
import privacyInfoMD from '@md/privacy-info.md';

import privacy20221122 from '@md/privacy-policy/20221122.md';
import privacy20200616 from '@md/privacy-policy/20200616.md';
import privacy20171120 from '@md/privacy-policy/20171120.md';
import privacy20151101 from '@md/privacy-policy/20151101.md';
import { submitingSnsSignup } from '@service/auth';
import { setUserInfo } from '@stream/userInfo';
import { setBodySnsLogin } from '@stream/tokenRequestingBody';
import { Helmet } from 'react-helmet';

const privacyArchive = {
  privacy20221122,
  privacy20200616,
  privacy20171120,
  privacy20151101,
}


const PrivacyModal = () => {
  const [ver, setVer] = useState('privacy20221122');

  return (
    <div className="bg-white-100 rounded flex flex-col w-full max-w-modal h-fullmodal">
      <FormControl fullWidth variant="outlined">
        <Select
          id="privacy-term-ver-select"
          value={ver}
          onChange={e => setVer(e.target.value)}
        >
          <MenuItem value={'privacy20221122'}>20221122 (현재 버전)</MenuItem>
          <MenuItem value={'privacy20200616'}>20200616</MenuItem>
          <MenuItem value={'privacy20171120'}>20171120</MenuItem>
          <MenuItem value={'privacy20151101'}>20151101</MenuItem>
        </Select>
      </FormControl>
      <section className="term overflow-auto"
        style={{
          overflow: 'auto',
        }}
      >
        <ReactMarkdown>{privacyArchive[ver]}</ReactMarkdown>
      </section>
      <Button
        onClick={closeResetModal}
        size="large"
        color="success"
      >
        <span className="w-full">확인</span>
      </Button>
    </div>
  );
};

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  gap: 0,
  padding: 0,
  '&:before': {
    display: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'stretch',
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ExpandMore />} {...props}/>
))(() => ({
  margin: 0,
  padding: '4px 16px',
  width: '100%',
  backgroundColor: 'white',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  maxHeight: '300px',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionDetails-content': {
    margin: 0,
    minWidth: '100%',
  },
}));

function SnsSignUp() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);

  // 동의
  const [serviceTerm, setServiceTerm] = useState(false);
  const [ecommerceTerm, setEcommerceTerm] = useState(false);
  const [privacyInfo, setPrivacyInfo] = useState(false);
  const [marketingAgree, setMarketingAgree] = useState(false);
  const [overFourteen, setOverFourteen] = useState(false);
  const [allAgree, setAllAgree] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  // accordions
  const [expAccordion, setExpAccordion] = useState('');
  const accordionData = [{
    name: 'serviceTerm',
    label: '올스테이 이용약관 동의 (필수)',
    content: serviceTermMD,
    state: serviceTerm,
    setState: e => setServiceTerm(e.target.checked),
  }, {
    name: 'privacyInfo',
    label: '개인정보 수집/이용에 대한 동의 (필수)',
    content: privacyInfoMD,
    state: privacyInfo,
    setState: e => setPrivacyInfo(e.target.checked),
    action: {
      actionName: '개인정보처리방침 보기',
      actionHandler: () => {
        setModal({
          isClosable: true,
          isOpened: true,
          template: PrivacyModal,
        });
      }
    },
  }];

  // submit button
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  // 동의
  const toggleAllAgrees = () => {
    setAllAgree(prev => {
      const toggled = !prev;
      setServiceTerm(toggled);
      setEcommerceTerm(toggled);
      setPrivacyInfo(toggled);
      setMarketingAgree(toggled);
      setOverFourteen(toggled);
      return toggled;
    });
  };

  useEffect(() => {
    const agrees = [serviceTerm, ecommerceTerm, privacyInfo, marketingAgree, overFourteen];
    setAllAgree(agrees.reduce((acc, cur) => acc && cur, true));
    setIndeterminate(-1 < agrees.findIndex((v, _, arr) => v !== arr[0]));
  }, [
    serviceTerm,
    ecommerceTerm,
    privacyInfo,
    marketingAgree,
    overFourteen,
  ]);

  // accordions
  const handleClickAccordion = panelName => (e, newExpanded) => {
    setExpAccordion(newExpanded ? panelName : '');
  };

  // submit button
  useEffect(() => {
    setIsSubmitDisabled(
      !serviceTerm ||
      !ecommerceTerm ||
      !privacyInfo ||
      !overFourteen
    );
  }, [
    serviceTerm,
    ecommerceTerm,
    privacyInfo,
    overFourteen
  ]);

  const handleClickSubmitButton = e => {
    e.preventDefault();
    const snsInfo = {
      uid: state.sns.uid,
      provider: state.sns.provider,
    };
    setBodySnsLogin(snsInfo);
    const body = {
      user: {
        name: state.user.name,
        email_sns: state.user.email,
        subscribe: marketingAgree,
      },
      sns: snsInfo
    };
    setIsSubmiting(true);
    openModal();
    submitingSnsSignup(body)
    .then((res) => {
      if (!res || !res.access_token) {
        setUserInfo();
        setModal({
          isClosable: true,
          isOpened: true,
          template: () => (
            <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
              <div className="flex flex-col w-56 gap-6 text-gray-900-100">
                <h2 className="text-modal-h">소셜로그인 실패</h2>
                <p className="text-modal-desc whitespace-normal">소셜로그인에 실패하였습니다. 잠시 후 다시 시도해 주세요.</p>
              </div>
              <button
                className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
                onClick={() => {navigate('/my-profile'); closeResetModal();}}
              >
                <span className="w-full">확인</span>
              </button>
            </div>
          ),
        });
        return;
      }
      setUserInfo(res);
    }).finally(() => {
      setIsSubmiting(false);
    });
  };

  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState} />
      <div
        className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        pb-12
      "
      >
        <div className="px-6 mt-4">
          <IconButton
            onClick={() => navigate('..')}
            size="large"
            style={{ padding: 0 }}
          >
            <ArrowBack />
          </IconButton>
        </div>
        <div
          className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-14
        "
        >
          <form className="flex flex-col">
            <div className="mb-2 flex flex-col gap-1.5 items-start pl-6">
              <FormControlLabel
                label="서비스 이용을 위한 약관 동의"
                control={
                  <Checkbox
                    checked={allAgree}
                    indeterminate={indeterminate}
                    onChange={toggleAllAgrees}
                    size="medium"
                    icon={<PanoramaFishEye fontSize="large" color="action" />}
                    checkedIcon={<TaskAltOutlined fontSize="large" color="success" />}
                    indeterminateIcon={<RemoveCircleOutline fontSize="large" color="action" />}
                  />
                }
              />
            </div>
            {accordionData.map(({ name, label, content, state, setState, action }) => (
              <Accordion expanded={expAccordion === name} onChange={handleClickAccordion(name)} key={`term-${name}`}>
                <AccordionSummary>
                  <FormControlLabel
                    disableTypography={true}
                    label={label}
                    control={
                      <Checkbox size="small" checked={state} required
                                onChange={setState}
                                icon={<PanoramaFishEye fontSize="medium" color="action" />}
                                checkedIcon={<CheckCircleOutline fontSize="medium" color="success" />}
                      />
                    }
                    onClick={e => e.stopPropagation()}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <section className="term">
                    <ReactMarkdown>{content}</ReactMarkdown>
                  </section>
                </AccordionDetails>
                {action && (
                  <AccordionActions>
                    <Button onClick={action.actionHandler}>{action.actionName}</Button>
                  </AccordionActions>
                )}
              </Accordion>
            ))}
            <div className="mb-10 flex flex-col items-start gap-1.5 pl-4 pt-1">
              <FormControlLabel
                disableTypography={true}
                label="마케팅 정보 메일, SMS, 푸시 수신동의 (선택)"
                control={
                  <Checkbox
                    checked={marketingAgree}
                    onChange={e => setMarketingAgree(e.target.checked)}
                    size="small"
                    icon={<PanoramaFishEye fontSize="medium" color="action" />}
                    checkedIcon={<CheckCircleOutline fontSize="medium" color="success" />}
                  />
                }
              />
              <FormControlLabel
                disableTypography={true}
                label="만 14세 이상입니다. (필수)"
                control={
                  <Checkbox
                    checked={overFourteen}
                    onChange={e => setOverFourteen(e.target.checked)} required
                    size="small"
                    icon={<PanoramaFishEye fontSize="medium" color="action" />}
                    checkedIcon={<CheckCircleOutline fontSize="medium" color="success" />}
                  />
                }
              />
            </div>
            <Button
              variant="contained"
              size="large"
              disabled={isSubmitDisabled || isSubmiting}
              onClick={handleClickSubmitButton}
            >
              가입 하기
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SnsSignUp;

import React from 'react';
import { NavLink } from 'react-router-dom';
import imgUnavailable from '@asset/img/img_unavailable_placeholder_bg.png';
import { ReactComponent as PromotionIcon } from '@svg/Icons/Promotion/promotion.svg';
import { isOpenPromotion } from '@util/promotion';


const formatNum = Intl.NumberFormat('ko-KR').format;

function StayGoodBtn({
  roundnessToken,
  SubFuncUi,
  to, // built at service
  // remote data
  hotel,
  image,
  channels,
  price,
  index,
  promotions,
  onClick,
  currency='원',
  
  onImageError
}) {
  return <NavLink
    data-index={index}
    className="w-full justify-start items-center gap-5 flex"
    onClick={onClick}
    to={to}
  >
    <div className={`${roundnessToken ? roundnessToken : 'rounded'} w-24 h-24 grow-0 shrink-0 basis-24 bg-center bg-no-repeat bg-cover`} style={{ backgroundImage: `url(${image}), url(${imgUnavailable})`}} />
    <div className="min-w-0 flex grow shrink basis-0 flex-col justify-center items-start gap-1" style={{ textWrap: 'nowrap'}}>
        {
          promotions?.filter(promotion => isOpenPromotion({promotion}))?.map(({
            name,
          }) => {
            return <div className="flex items-center gap-[2px] pl-[5px] pr-[8px] bg-[#FFE000] rounded-[20px]" key={Date.now()}>
              <PromotionIcon />
              <span className="text-[11px] leading-[160%] font-bold text-[#000000]">{name}</span>
            </div>;
          })?.[0]
        }
      <div className="self-stretch h-5 text-allstay-gray-9e text-sm font-medium leading-tight overflow-hidden text-ellipsis flex items-center gap-1">
        <div>{channels?.length || 0}개 사이트 최저가</div>
      </div>
      <div className="self-stretch h-12 flex-col justify-start items-start flex">
        <div className="hotel-name self-stretch h-6 text-allstay-gray-1e text-base font-bold leading-normal overflow-hidden text-ellipsis">{hotel}</div>
        <div className="self-stretch justify-start items-baseline gap-2 flex">
          <div className="text-allstay-gray-1e text-base font-bold leading-normal overflow-hidden text-ellipsis">
            {formatNum(Math.floor(/* promotions?.[0]?.adjusted_price || */(typeof price === 'number' ? price : '??')))}
            {currency === 'KRW' ? '원' : currency}
            </div>
            {/*
              promotions?.[0]?.adjusted_price &&
              <div className="flex">
                <span className={`flex justify-end min-w-fit line-through text-sm text-allstay-gray-9e`}>{formatNum(Math.floor(price))}{currency === 'KRW' ? '원' : currency}</span>
              </div>
            */}
        </div>
      </div>
    </div>
    {SubFuncUi ? (typeof SubFuncUi === 'string' ? SubFuncUi : <SubFuncUi />) : ''}
  </NavLink>;
}

export default StayGoodBtn;

import * as _ from 'lodash';
import { blogPosts } from '@service/blog';
import { Subject } from 'rxjs';
import { closeResetModal, setModal } from './modal';

const ERR_MODAL = ({
  heading,
  description,
}) => (
  <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
    <div className="flex flex-col w-56 gap-6 text-gray-900-100">
      <h2 className="text-modal-h">{heading}</h2>
      <p className="text-modal-desc whitespace-normal">{description}</p>
    </div>
    <button
      className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
      onClick={closeResetModal}
    >
      <span className="w-full">확인</span>
    </button>
  </div>
);

const subject = new Subject();

const SEED = [...Array(5).keys()].map(() => ({}));
export const DEFAULT = SEED.map(() => ({ posts: [...SEED] }));

const INITIAL = DEFAULT;

let state = INITIAL;
let isFetching = false;

const _setState = newState => state = [...newState];
// const _distinct = (newState, oldState=state) => {
//   const distincted = [...oldState, ...newState.filter(({ id }) => {
//     if(oldState.findIndex(s => s.id === id) < 0) return true;
//     return false;
//   })];
//   return distincted;
// };
// const _appendState = newState => state = [...state, ...newState];
// const _appendFrontState = newState => state = [...newState, ...state];
// const _appendDistinctState = newState => state = [..._distinct(newState)];
const _getState = () => [...state];
const _initState = () => _setState(INITIAL);
const _subscribe = read => subject.subscribe(read);

const _fetchTags = async () => {
  if (!Array.isArray(state)) _initState();

  const oldTags = _getState();
  let newTags = [];

  if (isFetching) return oldTags;

  isFetching = true;
  newTags = await blogPosts();
  isFetching = false;

  if (Array.isArray(newTags) && !!newTags.length) return newTags;

  if (!!newTags.error && window.location.pathname === '/') {
    setModal({
      isClosable: true,
      isOpened: true,
      data: {
        heading: '숙소 정보 불러오기 실패',
        description: '숙소 정보를 불러오는 중 오류가 발생했어요. 잠시 후 새로고침 해 주세요.',
        caption: '확인',
      },
      template: ERR_MODAL,
    });
  }

  return oldTags;
};

export const getTags = _getState;
export const setTags = newState => _setState(newState);
export const updateBookmarkId = (postId, bookmarkId) => {
  const newState = _.map(_getState(), tag => {
    tag.posts = _.map(tag.posts, post => {
      if (post.id === postId) {
        post.bookmark_id = bookmarkId;
      };
      return post
    })
    return tag
  })
  
  _setState(newState)
};
export const initTags = _initState;
export const subscribeTags = read => {
  const _ = _subscribe(read);
  subject.next(state);
  return _;
};
export const subscribeAndPullOnce = read => {
  const _ = _subscribe(read);
  _fetchTags()
  .then(stateReplacement => {
    _setState(stateReplacement);
    subject.next(state);
  });
  return _;
};

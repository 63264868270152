import React from 'react';

function HeroGallery({ children }) {
  return (
    <div className="as-gallery grid grid-rows-3 grid-cols-4 place-content-stretch gap-3 w-full">
      {children}
    </div>
  );
}

export default HeroGallery;

import React from 'react';
import ReactMarkdown from 'react-markdown';

import serviceTermMD from '@md/terms-of-use.md';
import { Helmet } from 'react-helmet';

function Service() {
  const siteName = '이용약관 | 올스테이';
  const siteDescription = `${serviceTermMD.slice(0, 161)}...`;

  return (
    <>
    <Helmet>
      <title>{siteName}</title>
      <meta property="og:title" content={siteName.replace(' | 올스테이', '')} />
      <meta name="description" content={siteDescription} />
      <meta property="og:description" content={siteDescription} />
      <meta name="twitter:title" content={siteName.replace(' | 올스테이', '')} />
      <meta name="twitter:description" content={siteDescription} />
    </Helmet>
    <section className="term bg-white-100">
      <ReactMarkdown>{serviceTermMD}</ReactMarkdown>
    </section>
    </>
  );
}

export default Service;

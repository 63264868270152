const normalizeCityCode = (cityCode) => {
  if (!cityCode) return;
  const splited = cityCode.split('-');
  if (/^\d+$/.test(splited[splited.length - 1])) {
    return splited.slice(0, splited.length - 1).join('-');
  }
  return cityCode;
};

export default normalizeCityCode;

import Container from './HorizontalScrollContainer';
import ItemContainer from './HorizontalItemContainer';
import Minimap from './HorizontalScrollMinimap';
export { useProgress, useViewWidth, useTransition } from './MinimapHook';

export const HorizontalScrollContainer = Container;
export const HorizontalItemContainer = ItemContainer;
export const HorizontalScrollMinimap = Minimap;

const HorizontalScroll = {
  Container,
  ItemContainer,
  Minimap,
};

export default HorizontalScroll;

import { BehaviorSubject } from 'rxjs';

export const history$ = new BehaviorSubject([]);

export const routerListener = h => history$.next([h, ...history$.getValue()]);

export const subscribeRouterHistory = (fn) => history$.subscribe(fn);
export const getRouterHistory = () => history$.getValue();
export const getLastRouterHistory = () => ({...history$.getValue()[0]});

export const isRouterHistoryChanged = (history1, history2) => {
  const { location: loc1 } = history1;
  const { location: loc2 } = history2;
  const result = (loc1?.pathname !== loc2?.pathname) || (loc1?.hash !== loc2?.hash) || (loc1?.search !== loc2?.search);
  return result;
};

subscribeRouterHistory(() => {
  window.__as_prev_url = window.location.href;
  window.__as_prev_pathname = window.location.pathname;
  window.__as_prev_search = window.location.search;
});

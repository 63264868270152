import React from 'react';

export function FooterLinkLookRegular({ children }) {
  return (
    <span
      className={"text-[14px] leading-[160%] text-[#777777] font-regular"}
    >
      {children}
    </span>
  );
}

export function FooterLinkLookBold({ children }) {
  return (
    <span
      className="text-[14px] leading-[160%] text-[#777777] font-bold"
    >
      {children}
    </span>
  );
}

const FooterLinkLook = {
  Regular: FooterLinkLookRegular,
  Bold: FooterLinkLookBold,
};

export default FooterLinkLook;

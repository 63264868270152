// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const appId = process.env.REACT_APP__FIREBASE_APP_ID;
const measurementId = process.env.REACT_APP__FIREBASE_MEASUREMENT_ID;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  appId,
  measurementId,
  apiKey: "AIzaSyBERUdEmtj5D1gR0pPNfQvDfTPAOfMXt44",
  authDomain: "allstay-1f923.firebaseapp.com",
  databaseURL: "https://allstay-1f923.firebaseio.com",
  projectId: "allstay-1f923",
  storageBucket: "allstay-1f923.appspot.com",
  messagingSenderId: "1004897053426",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;

export const firebaseAnalytics = getAnalytics(firebaseApp);

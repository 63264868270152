import React from 'react'

const PwKeyDownHelperText = ({ howManyTyped, children }) => {
  const _children = children.replace('.', '').replace('!', '');
  if (howManyTyped <= 0)  return <></>;
  if (howManyTyped <= 5)  return <p className="text-sub-link text-red-500 font-normal">{_children}.</p>;
  if (howManyTyped <= 10) return <p className="text-sub-link text-red-500 font-medium">{_children}.</p>;
  if (howManyTyped <= 15) return <p className="text-sub-link text-red-500 font-semibold">{_children}.</p>;
  if (howManyTyped <= 20) return <p className="text-sub-link text-red-500 font-bold">{_children}.</p>;
  if (howManyTyped <= 25) return <p className="text-sub-link text-red-500 font-extrabold">{_children}.</p>;
  if (howManyTyped <= 30) return <p className="text-sub-link text-red-500 font-black">{_children}.</p>;
  if (howManyTyped <= 35) return <p className="text-sub-link text-red-500 font-black">{_children}!</p>;
  if (howManyTyped <= 40) return <p className="text-sub-link text-red-500 font-black">{_children}!!</p>;
  if (howManyTyped <= 45) return <p className="text-sub-link text-red-500 font-black">{_children}!!!</p>;
  if (howManyTyped <= 50) return <p className="text-base text-red-500 font-black underline">{_children}!!!</p>;
  if (howManyTyped <= 55) return <p className="text-lg text-red-500 font-black underline">{_children}!!!</p>;
  if (howManyTyped <= 60) return <p className="text-xl text-red-500 font-black underline">{_children}!!!</p>;
  if (howManyTyped <= 65) return <p className="text-2xl text-red-500 font-black underline">{_children}!!!</p>;
  if (howManyTyped <= 70) return <p className="text-3xl text-red-500 font-black underline">{_children}!!!</p>;
  return <p className="text-4xl text-red-500 font-black underline">{_children}!!!</p>;
};

export default PwKeyDownHelperText;

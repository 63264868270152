import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import placeholderImg from '@img/placeholder-img.png';
import { Detail10, H3 } from '@comp/StyledParagraph';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';


function FeaturedAtMainContent({ data }) {
  const {
    region_code,
    city_name_kr,
    city_name_en,
    nation_name,
    nation_code,
    medium_data,
    theme_id,
    title,
  } = data;
  const [sp, setSp] = useState(getSearchParams());

  useEffect(() => {
    const subscription = subscribeSearchParams(setSp);
    return () => subscription.unsubscribe();
  }, []);

  return (
    <Link
      to={`/search/${nation_code}/city/${region_code}/curation/${theme_id}?${sp.toString()}`}
      className="curation-item-main flex flex-col gap-[10px] w-[160px] grow"
      style={{ maxWidth: 'calc(50% - 7.5px)' }}
    >
      <div className="relative">
        <div className={'absolute items-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col z-[1] w-[auto] h-[auto] gap-[10px]'}>
          <p className={`w-full text-[13px] font-bold leading-[20.8px] text-[#FFFFFF]`} style={{ textAlign: 'center', wordBreak: 'keep-all' }}>{title}</p>
        </div>
        <div className="absolute w-full h-[150px] bg-center bg-no-repeat bg-cover" style={{ background: `rgba(0, 0, 0, 0.3)` }} >
          <span className="sr-only">{city_name_kr} 대표 이미지</span>
        </div>
        <div
          className="w-full h-[150px] bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url("${medium_data || placeholderImg}")`,
          }}
        >
          <span className="sr-only">{city_name_kr} 대표 이미지</span>
        </div>
      </div>
      <div
        className="flex flex-col items-center whitespace-normal pb-[5px]"
      >
        <H3>{city_name_kr}, {nation_name}</H3>
        <Detail10>{city_name_en}</Detail10>
      </div>
    </Link>
  );
}

export default FeaturedAtMainContent;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { useEffect } from 'react';
import { setSearchPanelVisibility } from '@stream/searchPanelVisibility';
import { Helmet } from 'react-helmet';

function Search() {
  useEffect(() => {
    setSearchPanelVisibility(true)
  }, [])

  return <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
  </>
}

export default Search;
import React from 'react';
import placeholderImg from '@img/placeholder-img.png';

function SecondaryImg({ 
  id, // 207351701,
  medium_data, // "https://i.travelapi.com/hotels/20000000/19140000/19133800/19133778/8870396b_z.jpg",
  description, // "기본 이미지",
  disp_order, // "Y"
  onClick,
 }) {
  return (
    <button
      className={`${disp_order === 0 ? 'row-start-1 col-start-1 row-span-3 col-span-2' : 'h-14'} rounded`}
      style={{
        backgroundImage: `url("${medium_data}"), url("${placeholderImg}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={onClick}
    >
      <span className="sr-only">{description} 클릭하여 크게 보기</span>
    </button>
  );
}

export default SecondaryImg;

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FooterLinkLook, Detail12 } from '@comp/StyledParagraph';
import { Helmet } from 'react-helmet';
import { BackButton } from '@comp/BackButton';
import handleChangeHelmetState from '@util/handleChangeHelmetState';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const siteName = '회사 소개 - 최저가 숙소, 한눈에 비교하기 | 올스테이';


  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState}>
        <title>{siteName}</title>
        <meta property="og:title" content={siteName.replace(' | 올스테이', '')} />
        <meta name="twitter:title" content={siteName.replace(' | 올스테이', '')} />
      </Helmet>
      <main id="allstay-main">
        <div className="allstay-contents allstay-contents-box">
          <div className="flex px-6 py-[20px] sticky top-0 bg-white-100 allstay-about-toolbar">
            <BackButton />
          </div>

          <div className="pt-2.5 flex flex-col py-10 gap-alltrip-section overflow-x-hidden allstay-about-div">
            <section>
              <div>
                <header>
                  <h1><NavLink to="/"><img alt="allstay-logo" src="https://s3.ap-northeast-2.amazonaws.com/static.allstay.com/images/about_web/logo.svg" className="logo" /></NavLink></h1>
                </header>
              </div>
            </section>

            <section>
              <div>
                <section className='allstay-about-title'>
                  <p className='text-black-100'>누구나 자신만의<br />인생호텔이 있다!</p>
                  <img alt="about-main" src="https://s3-ap-northeast-1.amazonaws.com/image.allstay.kr/allstay_web/assets/img/top-img.png" />
                </section>
              </div>
            </section>

            <section>
              <p className='text-gray-500'>
                오늘날 우리는 여행을 통해 누구나 쉽게 온 세상을 경험할 수 있는 시대에 살고 있습니다.<br />
                그럼에도 불구하고 수백, 수천 개의 숙소 중에서 나에게 가장 잘 맞는 곳을 찾기란 여전히 쉽지 않죠. <br /><br />
                올스테이는 여행을 준비하는 고객이 검색을 통해 마음에 쏙 드는 숙소를 찾아 특별한 여행 경험을 만들 수 있도록 돕기 위해 존재합니다.<br />
                누구나 자신만의 인생호텔을 찾을 수 있도록, 올스테이가 세상의 모든 숙소 정보와 가격을 검색하고 비교하여 도와드리겠습니다.
              </p>
            </section>

            <hr />

            <section>
              <div>
                <div>
                  <h4 className='text-black-100'>
                    대한민국 유일의<br />
                    Hotel Meta Search Engine
                  </h4>
                  <p>
                    올스테이는 대한민국의 숙박 검색 시장에서 자체 Core Engine을 보유한 유일한 국내 사업자로서 독보적인 기술력과 경험을 바탕으로 해마다 발전을 거듭해나가고 있습니다.
                    <br /><br />
                    우리의 기술력으로 시장에 더 높은 수준의 검색 환경을 제공하고 업계의 선도적인 파트너사들과 협업하여 국내 Travel Tech의 발전에 기여하고자 합니다.
                  </p>
                </div>

              </div>
            </section>
          </div>
          <footer className="flex flex-col gap-4 px-[20px] border-t-[1px] border-t-[#EEEEEE] pt-[20px] pb-[60px]">
            <div className="flex justify-start gap-6">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/allstay.kr"><img src="https://static.allstay.com/images/web_v2/fb.svg" alt="open-allstay-meta" /><span className="sr-only">올스테이 페이스북</span></a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/allstay_kr/"><img src="https://static.allstay.com/images/web_v2/insta.svg" alt="open-allstay-instagram" /><span className="sr-only">올스테이 인스타그램</span></a>
              <a target="_blank" rel="noreferrer" href="https://post.naver.com/allstay_kr"><img src="https://static.allstay.com/images/web_v2/naver.svg" alt="open-allstay-naver-blog" /><span className="sr-only">올스테이 네이버 블로그</span></a>
            </div>
            <div className="flex flex-col gap-[30px]">
              <div className="flex gap-2 items-end">
                <NavLink to="/notice"><FooterLinkLook.Regular>공지사항</FooterLinkLook.Regular></NavLink>
                <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                <NavLink to="/faq"><FooterLinkLook.Regular>FAQ</FooterLinkLook.Regular></NavLink>
                <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                <NavLink to="/terms-of-use"><FooterLinkLook.Regular>이용약관</FooterLinkLook.Regular></NavLink>
                <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                <NavLink to="/privacy-policy"><FooterLinkLook.Bold>개인정보 처리방침</FooterLinkLook.Bold></NavLink>
              </div>
              <div className="flex gap-2 items-end">
                <FooterLinkLook.Bold>올스테이 주식회사</FooterLinkLook.Bold>
                <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                <NavLink to="/about"><FooterLinkLook.Regular>회사소개</FooterLinkLook.Regular></NavLink>
              </div>
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-col">
                <Detail12>04542</Detail12>
                <Detail12>서울특별시 중구 명동3길 6 개양빌딩 8층 올스테이</Detail12>
              </div>
              <div className="flex flex-col">
                <Detail12>대표 : 공연수</Detail12>
                <Detail12>사업자등록번호 : 114 - 87 - 22959</Detail12>
                <Detail12>통신판매업신고번호 : 제2022-서울중구-2278호</Detail12>
                <Detail12>관광사업등록증: 제2022- 000119호</Detail12>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
};

export default About;

import * as _ from 'lodash';
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  subscribeRawSearchedWord,
  getRawSearchedWord,
  setRawSearchedWord,
} from '@stream/searchedWord';
import {
  getSearchPanelVisibility,
  subscribeSearchPanelVisibility,
} from '@stream/searchPanelVisibility';

function SearchInput({
  placeholder='도시, 숙소 이름으로 찾아보세요.',
}) {
  const [searchPanelIsOpen, setSearchPanelIsOpen] = useState(getSearchPanelVisibility());
  const $inputRef = useRef();
  const [word, setWord] = useState(getRawSearchedWord());
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    subscribeSearchPanelVisibility(setSearchPanelIsOpen);
  }, []);

  useLayoutEffect(() => {
    subscribeRawSearchedWord(setWord);
  }, [setWord]);

  useEffect(() => {
    $inputRef?.current?.focus();
  }, [$inputRef, searchPanelIsOpen]);

  const handleChange = ({target: {value}}) => setRawSearchedWord(value);
  const handleClickDelete = () => {
    setRawSearchedWord('');
    $inputRef?.current?.focus();
  };


  return (
    <div className={`flex items-center w-full h-[45px] pl-[16px] pr-[12px] bg-[#F5F5F5] rounded-[2px] ${isFocused ? 'outline outline-2 outline-[#1E1E1E]' : 'outline-0'}`}>
      <input
        type="text"
        id="raw-search-word"
        name="raw-search-word"
        autoComplete="off"
        placeholder={placeholder}
        ref={$inputRef}
        value={word}
        onChange={handleChange}
        className="
          grow
          font-regular
          text-[13px]
          leading-[140%]
          text-[#1E1E1E]
          placeholder:text-[#AEAEAE]
          bg-transparent
          focus:outline-none
        "
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <button className="h-full right-[12px] top-0 flex justify-center items-center gap-[10px] w-[20px] h-[20px]" disabled={_.isEmpty(word)} onClick={handleClickDelete}>
        {
          _.isEmpty(word) ?
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.68323 14.3665C5.54447 14.3665 3 11.822 3 8.68323C3 5.54447 5.54447 3 8.68323 3C11.822 3 14.3665 5.54447 14.3665 8.68323C14.3665 9.99656 13.921 11.2058 13.1729 12.1682L17 15.9953L15.9953 17L12.1682 13.1729C11.2058 13.921 9.99656 14.3665 8.68323 14.3665ZM12.9457 8.68323C12.9457 11.0373 11.0373 12.9457 8.68323 12.9457C6.32916 12.9457 4.42081 11.0373 4.42081 8.68323C4.42081 6.32916 6.32916 4.42081 8.68323 4.42081C11.0373 4.42081 12.9457 6.32916 12.9457 8.68323Z" fill="#9E9E90"/>
          </svg> :
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 0C2.9055 0 0 2.9055 0 6.5C0 10.0945 2.9055 13 6.5 13C10.0945 13 13 10.0945 13 6.5C13 2.9055 10.0945 0 6.5 0ZM9.75 8.8335L8.8335 9.75L6.5 7.4165L4.1665 9.75L3.25 8.8335L5.5835 6.5L3.25 4.1665L4.1665 3.25L6.5 5.5835L8.8335 3.25L9.75 4.1665L7.4165 6.5L9.75 8.8335Z" fill="#9E9E9E"/>
          </svg>
        }
        <span className="sr-only">입력 취소</span>
      </button>
    </div>
  );
}

export default SearchInput;

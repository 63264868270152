
const handleChangeHelmetState = (newState, addedTags, removedTags, callback) => {
  /**
   * --- newState structure ---
   * baseTag any[]
   * bodyAttributes object
   * defer boolean
   * encode boolean
   * htmlAttributes object
   * linkTags object[]
   * metaTags object[]
   * noscriptTags any[]
   * onChangeClientState function
   * scriptTags any[]
   * styleTags any[]
   * title string
   * titleAttributes object
   **/

  const ALLSTAY_GLOBAL = (window || global);
  const curUrl = ALLSTAY_GLOBAL?.location?.href || '';
  const curTitle = newState?.title || '';
  if (
    (ALLSTAY_GLOBAL?.ALLSTAY_HELMET_PREV_URL !== curUrl && ALLSTAY_GLOBAL?.ALLSTAY_HELMET_PREV_TITLE !== curTitle && typeof curTitle !== 'undefined') ||
    (ALLSTAY_GLOBAL?.ALLSTAY_HELMET_PREV_URL === curUrl && typeof ALLSTAY_GLOBAL?.ALLSTAY_HELMET_PREV_TITLE === 'undefined' && typeof curTitle !== 'undefined')
  ) {
    const page_title = newState?.title || '';
    const page_location = ALLSTAY_GLOBAL?.location?.href || '';
    const newHistory = {
      event: 'seoValidHistoryChanged',
      page_title,
      page_location,
      page_referrer: ALLSTAY_GLOBAL?.ALLSTAY_HELMET_PREV_URL,
    };
    ALLSTAY_GLOBAL?.dataLayer?.push(newHistory);
    callback && callback(newHistory);
  }

  ALLSTAY_GLOBAL.ALLSTAY_HELMET_PREV_URL = curUrl;
  ALLSTAY_GLOBAL.ALLSTAY_HELMET_PREV_TITLE = curTitle;
}

export default handleChangeHelmetState;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ModalLogingIn = () => (
  <div
    className="
      bg-white-100
      rounded
      flex
      p-4
    "
  >
    <CircularProgress />
  </div>
);

export default ModalLogingIn;

import React from 'react';


function InterestedList({ children }) {
  return (
    <div className="flex flex-col pt-[20px] px-[20px] gap-[20px] items-stretch w-full">
      {children}
    </div>
  );
}

export default InterestedList;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ClearIcon } from '@svg/ButtonIcons/clear.svg';
import { ReactComponent as ClockIcon } from '@svg/Icons/clock.svg';
import { rmItem } from '@store/recent';
import normalizeCityCode from '@util/normalizeCityCode';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';


function AtSearchItem({
  data,
}) {

  const [sp, setSp] = useState(getSearchParams());
  
  useEffect(() => {
    subscribeSearchParams(setSp);
  }, []);

  const {
    id,
    name,
    // star_rating,
    // region,
    // medium_data,
    // hotel_i18ns,
    country_code,
    city_code,
    pathname,
  } = data;

  return (
    <li className="flex gap-[10px] items-start">
      <div className="flex items-center h-[20px]">
        <ClockIcon />
      </div>
      <NavLink
        to={`${pathname || `/hotel/${country_code}/${normalizeCityCode(city_code)}/${id}`}?${sp}`}
        className="
          relative
          flex
          flex-col
          grow
        "
      >
        <span className="text-[#1E1E1E] text-[13px] leading-[160%] font-regular">{name}</span>
      </NavLink>
      <button
        onClick={() => rmItem(id)}
        className="flex items-center w-[20px] h-[20px]"
      >
        <ClearIcon />
        <span className="sr-only">이 검색 기록 지우기</span>
      </button>
    </li>
  );
}

export default AtSearchItem;

import { Route } from 'react-router-dom';
import { Term, Privacy, Service } from '@page/term';

const TermRouteGroup = (
  <Route element={<Term />}>
    <Route path="privacy-policy" element={<Privacy />} />
    <Route path="terms-of-use" element={<Service />} />
  </Route>
);

export default TermRouteGroup;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect, useState } from 'react';
import { PasswordKeyDownHelperText } from '@comp/PasswordKeyDownHelperText';
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { closeResetModal, openModal, setModal } from '@store/modal';
import { Link, useNavigate } from 'react-router-dom';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { getUserInfo, setUserInfo } from '@stream/userInfo';
import { editUserInfo } from '@service/auth';
import { Helmet } from 'react-helmet';

const reName = /(^[가-힣]+$)|(^[a-z|A-Z]+(\s[a-z|A-Z]+)+$)/;
const reEng = /[a-z|A-Z]/;
const reKor = /[가-힣]/;
const reMultipleSpace = /\s{2,}/;
const reKorSpace = /[가-힣]\s[가-힣]/;
const reNonAlpha = /[^가-힣|a-z|A-Z|\s]/;
const reAnEngWord = /^[a-z|A-Z]+$/;
const reForUpdatePw = /\s|[ㄱ-ㅎ|가-힣|ㅏ-ㅣ]/g;

const ModalEdit = ({
  heading,
  description,
  caption,
  onClick,
}) => {
  const _onClick = () => {
    closeResetModal();
    onClick && onClick();
  };

  return (
    <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
      <div className="flex flex-col w-56 gap-6 text-gray-900-100">
        <h2 className="text-modal-h">{heading}</h2>
        <p className="text-modal-desc whitespace-normal">{description}</p>
      </div>
      <button
        className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
        onClick={_onClick}
      >
        <span className="w-full">{caption}</span>
      </button>
    </div>
  );
};

function EditProfile() {
  // global
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSubmitable, setIsSubmitable] = useState(false);
  const [provider, setProvider] = useState();

  // 이름
  const [initialName, setInitialName] = useState('');
  const [name, setName] = useState('');
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isNameErr, setIsNameErr] = useState(false);
  const [nameHelperTexts, setNameHelperTexts] = useState([]);

  // 비밀번호
  const initialPw = '';
  const [pw, setPw] = useState('');
  const [isPwDirty, setIsPwDirty] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [confirmPw, setConfirmPw] = useState('');
  const [pwHelperTexts, setPwHelperTexts] = useState([]);
  const [isPwErr, setIsPwErr] = useState(false);
  const [howManyTypesInvalidCharacterPw, setHowManyTypesInvalidCharacterPw] = useState(0);



  // 이름
  useEffect(() => {
    if (!isNameDirty && !name.length) return;
    const _name = name.trim();
    const texts = [];
    let err = false;
    if (!_name.length) {
      err = err || true;
      texts.push({ text: '이름을 입력해 주세요', key: 'not-filled'});
    } else if (!reName.test(_name) || reAnEngWord.test(_name)) {
      err = err || true;
      if (reKor.test(_name) && reEng.test(_name)) texts.push({ text: '영문자와 한글을 섞어 사용할 수 없습니다.', key: 'mixed-locale' });
      if (reMultipleSpace.test(_name)) texts.push({ text: '공백을 연속으로 사용할 수 없습니다.', key: 'multiple-space' });
      if (reKorSpace.test(_name)) texts.push({ text: '한글 이름에는 공백을 사용할 수 없습니다.', key: 'spaced-kor' });
      if (reNonAlpha.test(_name)) texts.push({ text: '특수문자는 사용할 수 없습니다.', key: 'non-alpha' });
      if (reAnEngWord.test(_name)) texts.push({ text: '영문 이름은 "이름 성" 순서로 공백 구분해 주세요.', key: 'non-valid-eng-name' });
    };
    setNameHelperTexts(texts);
    setIsNameErr(err);
  }, [name, isNameDirty]);


  // 비밀번호
  const handleClickShowPw = () => setShowPw(showPw => !showPw);

  const handleMouseDownPw = e => e.preventDefault();

  const makeUpdateHandlerPw = (setter, dirtySetter) => e => {
    const v = e.target.value.replace(reForUpdatePw, '');
    dirtySetter && dirtySetter(v !== initialPw);
    setter(v);
  };
  const handleChangePw = makeUpdateHandlerPw(setPw, setIsPwDirty);
  const handleBlurPw = handleChangePw;
  const handleChangeConfirmPw = makeUpdateHandlerPw(setConfirmPw);
  const handleBlurConfirmPw = handleChangeConfirmPw;
  const handleKeyDownPws = (e = {}) => {
    if(reForUpdatePw.test(e.key)) {
      setHowManyTypesInvalidCharacterPw(prev => prev + 1);
      return;
    }
    setHowManyTypesInvalidCharacterPw(0);
  };


  useEffect(() => {
    if (!isPwDirty) {
      setIsPwErr(false);
      setPwHelperTexts([]);
      return
    };
    const helperTexts = [];
    let isErr = false;
    if (!isStrongPassword(pw, {
      minLength: 10,
    })) {
      isErr = isErr || true;
      helperTexts.push({ text: '암호는 영문 소문자, 대문자, 숫자, 기호의 조합으로 10자 이상이어야 합니다.', key: 'not-valid' });
    }
    if (pw !== confirmPw) {
      isErr = isErr || true;
      helperTexts.push({ text: '암호가 일치하지 않습니다.', key: 'not-confirmed' });
    }
    setIsPwErr(isErr);
    setPwHelperTexts(helperTexts);
  }, [pw, confirmPw, isPwDirty]);


   // global
  useEffect(() => {
    const flag = !isNameErr && !isPwErr && (isPwDirty || isNameDirty);
    setIsSubmitable(flag);
  }, [isNameErr, isNameDirty, isPwErr, isPwDirty]);

  const handleClickRequestEdit = e => {
    e.preventDefault();
    const body = {
      user: {
        name,
        password: !!pw.length ? pw : undefined,
      },
    };
    setIsSubmiting(true);
    openModal();
    editUserInfo(body)
    .then(res => {
      if (res.isOK) {
        setModal({
          isClosable: false,
          isOpened: true,
          data: {
            heading: '회원 정보 수정 성공',
            description: '회원 정보 수정에 성공하였습니다. 수정사항을 반영하기 위하여 다시 로그인 해주십시오.',
            caption: '다시 로그인 하기',
            onClick: () => {
              setUserInfo();
              navigate('..');
            }
          },
          template: ModalEdit,
        });
        return;
      }
      const {
        error: {
          code, message
        }
      } = res;
      if (code === '401' && message === 'UNAUTHORIZED') {
        setModal({
          isClosable: false,
          isOpened: true,
          data: {
            heading: '본인이 맞으신가요?',
            description: '로그인 정보가 정확치 않아 회원 정보 수정에 실패했습니다. 다시 로그인 후 시도해 주세요.',
            caption: '다시 로그인 하기',
            onClick: () => {
              setUserInfo();
              navigate('..');
            }
          },
          template: ModalEdit,
        });
        return;
      }
      setModal({
        isClosable: true,
        isOpened: true,
        data: {
          heading: '회원 정보 수정 실패',
          description: '회원 정보 수정에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
          caption: '확인',
        },
        template: ModalEdit,
      });
    }).finally(() => setIsSubmiting(false));
  };

  useEffect(() => {
    const user = getUserInfo();
    if (!!user && !!user.payload && !!user.payload.name) {
      setInitialName(user.payload.name);
      setName(user.payload.name);
      setProvider(user.payload.provider);
    }
  }, []);

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div
      className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        pb-12
        min-h-contents-container
      "
    >
      <div className="px-6 mt-4">
        <IconButton
          onClick={() => navigate('/my-profile')}
          size="large"
          style={{ padding: 0 }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div
        className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-14
        "
      >
        <h2 className="text-screen-title">회원 정보 변경</h2>
        <form className="flex flex-col">
          <div className="mb-10 flex flex-col gap-2.5">
            <TextField
              id="fullname"
              required
              name="fullname"
              label="이름"
              type="text"
              autoComplete="fullname"
              variant="outlined"
              value={name}
              onChange={e => {
                setIsNameDirty(e.target.value !== initialName);
                setName(e.target.value);
              }}
              onBlur={e => {
                const v = e.target.value.trim();
                setIsNameDirty(v !== initialName);
                setName(v);
              }}
              disabled={isSubmiting}
              error={isNameErr}
            />
            {!isNameErr &&
              <p className="text-sub-link text-gray-600">
                {isNameDirty ? '새로운 이름이 입력되었습니다.' : '변경 사항이 없습니다.'}
              </p>
            }
            {isNameErr && nameHelperTexts.map(({text, key}) =>
              <p className="text-sub-link text-red-500" key={`name-${key}`}>
                {text}
              </p>
            )}
          </div>
          {
            !provider &&
            <div className="mb-10 flex flex-col gap-2.5">
              <FormControl variant="outlined">
                <InputLabel htmlFor="new-password" required>비밀번호</InputLabel>
                <OutlinedInput
                  id="new-password"
                  required
                  name="new-password"
                  type={showPw ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={pw}
                  onChange={handleChangePw}
                  onBlur={handleBlurPw}
                  onKeyDown={handleKeyDownPws}
                  disabled={isSubmiting}
                  error={isPwErr}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPw}
                        onMouseDown={handleMouseDownPw}
                      >
                        {showPw ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="비밀번호"
                />
              </FormControl>
              {
                isPwErr && pwHelperTexts.map(({ text, key }) =>
                <p className={`text-sub-link ${isPwErr ? 'text-red-500' : 'text-gray-600'}`} key={`pw-${key}`} >
                  {text}
                </p>)
              }
              {
                !isPwErr && <p className="text-sub-link text-gray-600">
                  {isPwDirty ? '새 비밀번호가 입력되었습니다.' : '변경 사항이 없습니다.'}
                </p>
              }
              {
                showPw &&
                <PasswordKeyDownHelperText howManyTyped={howManyTypesInvalidCharacterPw}>한글이나 공백을 치고 계신것 같네요</PasswordKeyDownHelperText>
              }
            </div>
            }
          {
            isPwDirty &&
            <div className="mb-10 flex flex-col gap-2.5">
              <FormControl variant="outlined">
                <InputLabel htmlFor="confirm-password" required>비밀번호 확인</InputLabel>
                <OutlinedInput
                  id="confirm-password"
                  required
                  name="confirm-password"
                  type={showPw ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={confirmPw}
                  onChange={handleChangeConfirmPw}
                  onBlur={handleBlurConfirmPw}
                  onKeyDown={handleKeyDownPws}
                  disabled={isSubmiting}
                  error={isPwErr}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPw}
                        onMouseDown={handleMouseDownPw}
                      >
                        {showPw ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="비밀번호 확인"
                />
              </FormControl>
            </div>
          }
          <LoadingButton
            onClick={handleClickRequestEdit}
            variant="contained"
            color="primary"
            loading={isSubmiting}
            disabled={isSubmiting || !isSubmitable}
            size="large"
          >
            회원 정보 수정
          </LoadingButton>
        </form>
        <div className="text-sub-link text-gray-600 grow flex shrink justify-end">
          <Link to="/auth/remove-account">
            회원을 탈퇴하고 싶으세요?<span className="font-bold ml-2.5">회원 탈퇴</span>
          </Link>
        </div>
      </div>
    </div>
    </>
  );
}

export default EditProfile;

// 태그, 지역, 숙소, 포스트 룰베이스 필터링과 임시 필터링.

const reFilterPredMaker = (re, key, negative=true) => (v) => {
  const result = re?.test(v?.[key]);
  return negative ? !result : result;
};

export const refererMaker = (refPath) => (a) => {
  if (!refPath?.length) return;
  let _a = a;
  for (let i = 0; i < refPath.length; i++) {
    if (typeof _a === 'undefined') return;
    _a = _a?.[refPath[i]];
  }
  return _a;
};

const replacerMaker = (refPath) => (a, b) => {
  if (!refPath?.length) return;
  const rp = [...refPath];
  const replaceeKey = rp.pop();

  let _a = a;
  for (let i = 0; i < rp.length; i++) {
    if (typeof _a === 'undefined') return;
    _a = _a?.[rp[i]];
  }
  _a[replaceeKey] = b;
};

export const filterByRule = (data, rules=filterRules) => {
  const curation = data?.curation;
  const tag = curation?.tag;
  const region = curation?.city;
  const rule = rules?.[tag?.tag_id]?.[region?.region_id] || rules?.[tag?.tag_id]?.['*'];
  if (!rule?.length) return data;

  const newData = { ...data };
  rule.forEach(({ refer, replace, pred }) => {
    const v = refer(newData);
    if (Array.isArray(v)) {
      const newV = v.filter(pred);
      if (typeof newV !== 'undefined') replace(newData, newV);
    }
  });

  return newData;
};

const curationPostsPath = ['curation', 'posts'];
const curationPostsReferer = refererMaker(curationPostsPath);
const curationPostsReplacer = replacerMaker(curationPostsPath);
const citiesPath = ['cities'];
const citiesReferer = refererMaker(citiesPath);
const citiesReplacer = replacerMaker(citiesPath);
const filterRules = {
  'T2024041814570730': { // 해외추천 > 바다여행
    '177970': [{ // > 산토리니, 포스트 제목에 특정 단어가 들어간 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/코타키나/, 'title'),
    }],
  },
  'T2024041814570527': { // 해외추천 > 신혼여행숙소
    '*': [{ // 지역무관, 지역 중 우붓 제거
      'refer': citiesReferer,
      'replace': citiesReplacer,
      'pred': reFilterPredMaker(/^12094$/, 'region_id'),
    }]
  },
  'T2024042308212026': { // 국내추천 > 리조트양실
    '44180': [{ // > 보령시, 특정 제목의 포스트 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/^2024년 여름 국내 해수욕장 개장 리스트 & 게스트 하우스 추천/, 'title'),
    }],
  },
  'T2024042308211616': { // 국내추천 > 수영장호캉스
    '11000': [{ // > 서울, 특정 제목의 포스트 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/^국내 호캉스 추천 5곳, 미리 준비하는 여름휴가 호텔/, 'title'),
    }],
  },
  'T2024041814571004': { // 해외추천 > 가족여행지
    '3593': [{ // > 도쿄, 포스트 제목에 특정 단어가 들어간 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/(다카야마|도야마)/, 'title'),
    }, { // > 도쿄, 특정 제목의 포스트 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/신비로운 풍경의 일본 명소 BEST 7/, 'title'),
    }],
  },
  'T2024042308210384': { // 국내추천 > 한달살기숙소
    '42830': [{// > 양양군, 특정 제목의 포스트 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/너무나 만족스러웠던 그랑블루글램핑/, 'title'),
    }],
  },
  'T2024041814571037': { // 국내추천 > 가성비숙소 
    '31000': [{// > 울산, 포스트 제목에 특정 단어가 들어간 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/경주 마우나/, 'title'),
    }],
  },
  'T2024042308211283': { // 국내추천 > 애견동반숙소
    '11000': [{// > 서울, 특정 제목의 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/내방에 복붙 하고파|찾았다 2024년 벚꽃 호캉스 명당|가성비 좋은 성수동 호텔|청담에서 즐기는 한강뷰 호캉스|이태원에서 제일 핫한 여기|가로수길 가성비 먹캉스 성지/, 'title'),
    }],
    '28000': [{// > 인천, 특정 제목의 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/인천펜션 top 3 봄입맛 돋울|이리오너라~ 분위기 대박|영종도 호텔 BEST 5 여행의 설렘을|황금연휴 가족 여행 숙소로 제격인|🏖 이번 여름 호캉스 하러 가기 좋은|인천 을왕리 더위크앤리조트|특별한 기념일에 딱인 뷰캉스 파티룸/, 'title'),
    }],
    '42760': [{// > 평창군, 특정 제목의 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/휘닉스 평창에서 가을 제대로 느끼기|짚라인 등 가성비 좋은 가족여행|아이와 호캉스 즐기기 좋은 국내 리조트 추천|코시국 아이들과 안전하게 즐기는 강원도키즈펜션 추천|알파인코스터 & 근처 숙소 추천/, 'title'),
    }],
  },
  'T2024041814570998': { // 국내추천 > 감성숙소
    '42210': [{// > 속초시, 포스트 제목에 특정 단어가 들어간 포스트들 제거
      'refer': curationPostsReferer,
      'replace': curationPostsReplacer,
      'pred': reFilterPredMaker(/강남역/, 'title'),
    }],
  }
};
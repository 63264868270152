import { allstayHttpService } from './api';

export const getRecent = async ids => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/hotel?hotel_ids=${JSON.stringify(ids)}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            data: res.data.view_hotels,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('getRecent err', err);
        reject(err);
      },
    });
  }
  return new Promise(promiseExecuter);
};

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import * as _ from 'lodash';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { addItem } from '@store/recent';
import { delFavoriteItem, registerFavoriteItem } from '@store/favoraite';
import { HeroGallery, HeroImg, HeroRest } from '@comp/HeroGallery';
import { BackButton } from '@comp/BackButton';
import { Share } from '@comp/Share';
import ImageGallery from 'react-image-gallery';
import { closeResetModal, setModal } from '@store/modal';
import placeholderImg from '@img/placeholder-img.png';
import { Button, Snackbar } from '@mui/material';
import { getSearchParams, parameterizeRoomInfo, parameterizeSchedule } from '@stream/searchParams';
import { RoomInfoInput, ScheduleInput } from '@comp/SearchInputGroup';
import { formatScheduleCaption, getSchedule, subscribeSchedule } from '@stream/schedule';
import { foramtRoomInfoCaption, getRoomInfo, subscribeRoomInfo } from '@stream/roomInfo';
import { getTokenRequestingBody } from '@stream/tokenRequestingBody';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { checkIsLogedInUser } from '@util/checkUserLogState';
import checkIsMobileWeb from '@util/isMobileWeb';
import { getOutLink } from '@util/outlink';
import { differenceInDays, format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { fromEvent, interval, throttle } from 'rxjs';
import { subscribeRecent, getRecent } from '@store/recent';
import { getRecent as reqRecent } from '@service/recent';
import { Desc, TipTitle } from '@comp/StyledParagraph';

import { ReactComponent as GlobIcon } from '@svg/Images/glob.svg';
import { ReactComponent as MicIcon } from '@svg/Images/mic.svg';
import { ReactComponent as MailIcon } from '@svg/Images/mail.svg';

import { Helmet } from 'react-helmet';
import axios from 'axios';
import { postMsgRN } from '@util/postMsgRN';

import { makeReachScrollDepthThresholdsStream } from '@stream/instanceScroll';
import { setSearchPanelVisibility } from '@stream/searchPanelVisibility';
import { RecentVisitedStayAtMain } from '@comp/RecentVisitedStay';
import { InterestedItem, InterestedList } from '@comp/Interested';
import { FareListItem } from '@comp/FareList';
import { getLastRouterHistory, isRouterHistoryChanged, subscribeRouterHistory } from '@stream/routerHistory';
import { isOpenPromotion } from '@util/promotion';
import { subscribeIsScrolledToBottom } from '@stream/scroll';
import { getHotelReviews } from '@service/search';
import { ModalSpinner } from '@comp/ModalSpinner';
import { injectTeadsScript } from '@util/injectTeadsScript';
const mapAPIKey = process.env.REACT_APP__GOOGLE_MAP_API_KEY;
const formatNum = Intl.NumberFormat('ko-KR').format;

function Stay() {
  const data = useRouteLoaderData('route-stay-detail');
  const [showImgLen, setShowImgLen] = useState(((typeof data?.image_list?.length === 'number') && (data?.image_list?.length > 6)) ? 6 : (data?.image_list?.length || 0));
  
  const {
    searchParams,
    navigate,
    pathParams,
  } = useOutletContext();
  const [sp, setSp] = searchParams;
  const {
    country_code,
    city_code,
    hotelId,
    tap = '',
  } = pathParams;
  const [curTapPath, setCurTapPath] = useState(tap || '');

  const seoMeta = useMemo(() => {
    const url = new URL(data?.seo_meta?.url || window.location.href);
    url.search = '';
    return {
      ...(data?.seo_meta || {}),
      ...(data?.tap_seo_meta?.[(curTapPath || 'home')] || {}),
      canonical: url.href,
    };
  }, [data, curTapPath]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data, curTapPath]);

  const [aff, setAff] = useState();
  useEffect(() => {
    if (!data) return;

    setAff(null);
    const sp = new URLSearchParams(getSearchParams());
    const ep = `${process.env.REACT_APP__API_URL}/public/hotel/${data.id}/tripadvisor?${sp.toString()}`;

    async function* reConfirmAffiliates() {
      let negContinueFlag = null;
      while (!negContinueFlag || !Object.keys({ ...negContinueFlag }).length) {
        negContinueFlag = yield await axios.get(ep).then(res => {
          if (!!res && !!res.data && !_.isEmpty(res.data)) return res.data;
          throw new Error('no affiliates');
        }).catch(() => null);
      }
    }

    (new Promise(async (resolve) => {
      const reConfirmGen = reConfirmAffiliates();
      let newAff = null;
      let i = 0;
      while (true) {
        const res = await reConfirmGen.next(newAff);
        newAff = newAff || res.value;
        i = i + 1;
        if (!!newAff || i > 2) {
          break;
        }
        await new Promise((resolve) => setTimeout(() => resolve(), 3000));
      }
      reConfirmGen.return(); // 명시적으로 generator 종료.
      resolve(newAff);
    })).then(async newAff => {
      let dataLayerPayload = {
        tripadvisor: 0,
        tripadvisor_price: undefined,
      };
      if (!!newAff) {
        newAff.clickUrl = await getOutLink(
          getTokenRequestingBody(),
          getSchedule(),
          getRoomInfo(),
          newAff?.adText,
          data.id,
          'tripadvisor',
          newAff?.clickUrl
        );
        dataLayerPayload = {
          tripadvisor: 0.1,
          tripadvisor_price: parseInt(newAff?.price)
        };
      }
      window.dataLayer.push(dataLayerPayload);
      setAff(newAff);
    });
  }, [data]);

  const [isMetaRendered, setIsMetaRendered] = useState(false);
  
  useEffect(() => {
    isMetaRendered && window.dataLayer.push({
      event: 'search_stay__pv_hotelDetail',
      page_title: window.document.title,
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
      hotel_name: data.name,
    });
  }, [data, isMetaRendered]);

  useEffect(() => {
    if (!data || !aff || !isMetaRendered) return;
    if ((curTapPath || 'home') !== 'home' && curTapPath !== 'price') return;
    window.dataLayer.push({ event: 'search_stay__pv_count-rank' });
  }, [data, aff, curTapPath, isMetaRendered]);

  const [user, setUser] = useState(getUserInfo());
  useLayoutEffect(() => {
    subscribeUserInfo(setUser);
  }, []);


  const [checkInOut, setCheckInOut] = useState(getSchedule());
  const [roomAndGuestInfo, setRoomAndGuestInfo] = useState(getRoomInfo());
  const [schedule, setSchedule] = useState(getSchedule());
  const [roomInfo, setRoomInfo] = useState(getRoomInfo());
  const [isScheduleInputOpened, setIsScheduleInputOpened] = useState(false);
  const [isRoomInfoInputOpened, setIsRoomInfoInputOpened] = useState(false);

  const isInputsClosed = useMemo(() => !isScheduleInputOpened && !isRoomInfoInputOpened, [isScheduleInputOpened, isRoomInfoInputOpened]);

  useEffect(() => {
    subscribeSchedule(setSchedule);
    subscribeRoomInfo(setRoomInfo);
    const {
      subscribe,
      unsubscribe,
    } = makeReachScrollDepthThresholdsStream();
    subscribe((depth) => {
      if (depth === 0) return;
      window.dataLayer.push({
        event: 'stay_home__scroll_scroll-depth',
        scroll_depth_percentage: parseInt(depth),
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [
    nights,
    checkin,
    checkout,
    fullFormatCheckin,
    fullFormatCheckout,
  ] = useMemo(() => {
    const {
      startDate,
      endDate,
    } = schedule[0];
    return [
      Math.abs(differenceInDays(startDate, endDate)),
      format(startDate, 'yy-MM-dd (EEEEE)', { locale: ko }),
      format(endDate, 'yy-MM-dd (EEEEE)', { locale: ko }),
      format(startDate, 'yyyy.MM.dd', { locale: ko }),
      format(endDate, 'yyyy.MM.dd', { locale: ko }),
    ];
  }, [schedule]);

  const [
    room,
    adult,
    minor,
  ] = useMemo(() => [
    roomInfo.length,
    roomInfo.reduce((acc, cur) => acc + cur.adult, 0),
    roomInfo.reduce((acc, cur) => acc + cur.minor.length, 0),
  ], [roomInfo]);

  const toggleScheduleInputOpened = () => setIsScheduleInputOpened(v => {
    setIsRoomInfoInputOpened(false);
    return !v;
  });
  const toggleRoomInfoInputOpened = () => setIsRoomInfoInputOpened(v => {
    setIsScheduleInputOpened(false);
    return !v;
  });

  const confirmSchedule = () => {
    setIsScheduleInputOpened(false);
    const newSchedule = getSchedule();
    if (formatScheduleCaption(newSchedule) === formatScheduleCaption(checkInOut)) return;
    setCheckInOut(newSchedule);
    const newRoomInfo = getRoomInfo();
    let _sp = new URLSearchParams();
    _sp = parameterizeSchedule(newSchedule, _sp);
    _sp = parameterizeRoomInfo(newRoomInfo, _sp);
    setSp(_sp);
  };
  const confirmRoomInfo = () => {
    setIsRoomInfoInputOpened(false);
    const newRoomInfo = getRoomInfo();
    if (foramtRoomInfoCaption(newRoomInfo) === foramtRoomInfoCaption(roomAndGuestInfo)) return;
    setRoomAndGuestInfo(newRoomInfo);
    const newSchedule = getSchedule();
    let _sp = new URLSearchParams();
    _sp = parameterizeSchedule(newSchedule, _sp);
    _sp = parameterizeRoomInfo(newRoomInfo, _sp);
    setSp(_sp);
  };

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  const [isToastNeedAction, setIsToastNeedAction] = useState(true);
  const [bookmarkId, setBookmarkId] = useState(data.bookmark_id);

  let subscriptionOfRouterHistory;
  const makeClickHeroImgHandler = startIndex => () => {
    
    const atTheTimeLoc = {
      location: {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      },
    };
    subscriptionOfRouterHistory = subscribeRouterHistory(() => {
      if (isRouterHistoryChanged(getLastRouterHistory(), atTheTimeLoc)) {
        closeResetModal();
      }
    });

    setModal({
      closeCallback: () => subscriptionOfRouterHistory?.unsubscribe(),
      isOpened: true,
      isClosable: true,
      data: {
        items: data.image_list.map(({ medium_data }) => ({ original: medium_data || '', loading: 'lazy' })),
      },
      template: data => {
        return <ImageGallery
          {...data}
          startIndex={startIndex}
          showNav={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
          infinite={true}
          showIndex={true}
          onErrorImageURL={placeholderImg}
          onClick={() => closeResetModal()}
        />;
      },
    });
  };

  useLayoutEffect(() => {
    if (data) {
      const {
        name,
        star_rating,
        id,
        address,
        image_list
      } = data;

      const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      const addrArr = korean.test(address) ? address.split(' ') : _.reverse(address.split(' '));

      addItem({
        id,
        region: `${addrArr[1]}, ${addrArr[0]}`,
        star_rating: star_rating,
        country_code,
        name: name,
        medium_data: image_list?.[0]?.medium_data || '',
        schedule: getSchedule(),
        room_info: getRoomInfo(),
        pathname: window.location.pathname.replace(/\/(review|introduction|home|location|price)$/, ''),
      });
    }
  }, [data, country_code]);

  const handleClickBookmarkBtn = () => {
    const is_loged_in = checkIsLogedInUser(getUserInfo());
    const turning_on_off = is_loged_in ? 'on' : 'undefined'
    window.dataLayer.push({
      event: 'search_stay__favoriteButton',
      page_title: window.document.title,
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
      is_loged_in,
      turning_on_off,
    });

    if (!is_loged_in) {
      setModal({
        isClosable: false,
        isOpened: true,
        template: () => (
          <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
            <div className="flex flex-col w-56 gap-6 text-gray-900-100">
              <h2 className="text-modal-h">로그인</h2>
              <div className="flex flex-col">
                <p className="text-modal-desc whitespace-normal">지금 바로 올스테이에 로그인하고</p>
                <p className="text-modal-desc whitespace-normal">마음에 드는 숙소를 찜하세요!</p>
              </div>
            </div>
            <button
              className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
              onClick={() => { navigate('/auth'); closeResetModal(); }}>
              <span className="w-full">로그인하러 가기</span>
            </button>
          </div>
        ),
      });
      return
    }
    setIsToastOpen(false);
    registerFavoriteItem('hotel', data.id, user.access_token)
      .then(({ bookmark_id }) => {
        if (!!bookmark_id || bookmark_id === 0) {
          setToastMsg(
            (data.name.length > 8 ?
              `"${data.name.slice(0, 8)}..."` :
              `"${data.name}"`)
            + ', 찜! 했어요.'
          );
          setBookmarkId(bookmark_id);
          setIsToastNeedAction(true);
          setIsToastOpen(true);
        }
      });
  };

  const handleClickUnBookmarkBtn = () => {
    setIsToastOpen(false);
    const is_loged_in = true;
    const turning_on_off = 'off';
    window.dataLayer.push({
      event: 'search_stay__favoriteButton',
      page_title: window.document.title,
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
      is_loged_in,
      turning_on_off,
    });
    delFavoriteItem(bookmarkId, user.access_token)
      .then(res => {
        if (res.isOK) {
          setToastMsg(
            (data.name.length > 8 ?
              `"${data.name.slice(0, 8)}..."` :
              `"${data.name}"`)
            + ', 찜! 해제 했어요.'
          );
          setBookmarkId(null);
          setIsToastNeedAction(true);
          setIsToastOpen(true);
        }
      });
  }

  const handleCloseToast = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsToastOpen(false);
  };

  const handleUndoRegister = () => {
    delFavoriteItem(bookmarkId, user.access_token)
      .then(res => {
        if (res.isOK) {
          setToastMsg(
            (data.name.length > 8 ?
              `"${data.name.slice(0, 8)}..."` :
              `"${data.name}"`)
            + ', 찜! 해제 했어요.'
          );
          setBookmarkId(null);
          setIsToastNeedAction(true);
          handleCloseToast();
        }
      });
  };

  const handleUndoUnRegister = () => {
    registerFavoriteItem('hotel', data.id, user.access_token)
      .then(({ bookmark_id }) => {
        if (!!bookmark_id || bookmark_id === 0) {
          setToastMsg(
            (data.name.length > 8 ?
              `"${data.name.slice(0, 8)}..."` :
              `"${data.name}"`)
            + ', 찜! 해제 했어요.'
          );
          setBookmarkId(bookmark_id);
          setIsToastNeedAction(true);
          handleCloseToast();
        }
      });
  };

  const $hotelNameH2 = useRef();
  const [isHotelNameH2ScrolledOut, setIsHotelNameH2ScrolledOut] = useState(false);
  useEffect(() => {
    let subscription;
    if (!!$hotelNameH2 && !!$hotelNameH2.current) {
      subscription = fromEvent(document, 'scroll')
        .pipe(
          throttle(() => interval(5)),
        ).subscribe(() => {
          if ($hotelNameH2?.current?.getBoundingClientRect().top < 61) {
            if (!isHotelNameH2ScrolledOut) setIsHotelNameH2ScrolledOut(true);
            return;
          }
          if (isHotelNameH2ScrolledOut) setIsHotelNameH2ScrolledOut(false);
        });
    }
    return () => subscription?.unsubscribe?.();
  }, [$hotelNameH2, isHotelNameH2ScrolledOut]);

  const $stayNavTab = useRef();
  const [isNavTabScrolledOut, setIsNavTabScrolledOut] = useState(false);
  useEffect(() => {
    let subscription;
    if (!!$stayNavTab && !!$stayNavTab.current) {
      subscription = fromEvent(document, 'scroll')
        .pipe(
          throttle(() => interval(5)),
        ).subscribe(() => {
          if ($stayNavTab?.current?.getBoundingClientRect().top < 80) {
            if (!isNavTabScrolledOut) setIsNavTabScrolledOut(true);
            return;
          }
          if (isNavTabScrolledOut) setIsNavTabScrolledOut(false);
        });
    }
    return () => subscription?.unsubscribe?.();
  }, [$stayNavTab, isNavTabScrolledOut]);

  const $tapContentTop = useRef();

  const [recent, setRecent] = useState(getRecent());
  const [remoteRecent, setRemoteRecent] = useState(recent);
  useLayoutEffect(() => {
    subscribeRecent(setRecent);
  }, []);
  useLayoutEffect(() => {
    const ids = _.map(recent, 'id');

    if (_.head(ids)) {
      reqRecent(ids)
        .then(({
          data: resData,
          isOK
        }) => {
          if (isOK) {
            setRemoteRecent(resData.filter(r => r.id !== data.id).map(rr => {
              const foundRecent = recent.find(lr => lr.id === rr.id);
              if (foundRecent?.pathname) {
                return { ...rr, pathname: foundRecent.pathname };
              }
              return rr;
            }));
            return;
          }
          throw new Error('원격 최근 확인 숙소 불러오기 실패');
        }).catch((err) => {
          setRemoteRecent(recent);
        });
      return;
    }
    setRemoteRecent(recent);
  }, [recent, data.id]);

  const description = useMemo(() => {
    if (!data || !data.description) return undefined;
    let desc = data.description;
    try {
      while (typeof desc != 'object') {
        desc = JSON.parse(desc);
      }
    } catch {
      desc = typeof data.description === 'string' ? data.description : undefined;
    }

    return desc;
  }, [data]);

  const [review, setReview] = useState();
  useEffect(() => {
    setReview(data.review);
  }, [data.review]);

  const [rating, setRating] = useState({});
  
  useEffect(() => {
    if (review && review?.rating_count_list?.length) {
      const _rating = review?.rating_count_list?.reduce((
        { totalRating, totalCount },
        { rating, ratingCount, rating_count } // 리뷰 데이터의 필드 이름이 어떨때는 스네이크 케이스, 어떨때는 카멜 케이스
      ) => {
        const rtCnt = ratingCount || rating_count;
        return {
          totalRating: totalRating + rating * rtCnt,
          totalCount: totalCount + rtCnt
        }
      }, { totalRating: 0, totalCount: 0 });

      setRating({
        ..._rating,
        avgRating: (_rating?.totalRating / _rating?.totalCount).toFixed(2),
      })
    }
  }, [review]);

  
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isReviewFetching, setIsReviewFetching] = useState(false);
  useEffect(() => {
    const subscription = subscribeIsScrolledToBottom(setIsScrolledToBottom);
    return () => subscription.unsubscribe();
  }, [setIsScrolledToBottom, ]);

  useEffect(() => {
    if (!review || curTapPath !== 'review' || !isScrolledToBottom || isReviewFetching || (review?.pagination?.page >= (review?.pagination?.page_count || review?.pagination?.pageCount))) return;
    setIsReviewFetching(true);
    setIsScrolledToBottom(false);
    getHotelReviews({
      hotelId: review?.pagination?.hotelId,
      page: review?.pagination?.page + 1,
      limit: 20,
    }).then(({ isOK, data }) => {
      if (!isOK) throw new Error();
      setReview({
        pagination: data?.pagination || review?.pagination,
        rating_count_list: data?.rating_count_list || data?.ratingCountList,
        review_list: [
          ...(review?.review_list || []),
          ...(data?.review_list || data?.reviewList || []),
        ]
      });
    }).catch((err) => console.error(err))
    .finally(() => {
      setIsReviewFetching(false);
    });
  }, [isScrolledToBottom, isReviewFetching, review, curTapPath]);

  

  return (
    <>
      {!!seoMeta && <Helmet onChangeClientState={(a, b, c) => handleChangeHelmetState(a, b, c, (history) => {
        injectTeadsScript(history);
        setIsMetaRendered(true);
      })}>
        <title>{seoMeta.title}</title>
        {seoMeta.description && <meta name="description" content={seoMeta.description} />}
        <meta property="og:title" content={seoMeta.og_title || seoMeta.title?.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta property="og:description" content={seoMeta.og_description || seoMeta.description} />}
        <meta property="og:url" content={seoMeta.url} />
        <meta property="og:image" content={seoMeta.image} />
        <link rel="canonical" href={seoMeta.canonical} />
        <meta name="twitter:title" content={seoMeta.title?.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta name="twitter:description" content={seoMeta.description} />}
        <meta name="twitter:image" content={seoMeta.image} />
      </Helmet>}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={toastMsg}
        action={
          isToastNeedAction ?
            <Button
              color="secondary"
              size="small"
              onClick={bookmarkId ? handleUndoRegister : handleUndoUnRegister}
            >
              실행취소
            </Button> :
            <></>
        }
      />
      <div id="allstay-main">
        <div className="allstay-contents allstay-contents-box relative">
          <div className="flex flex-col bg-[#F5F5F5]">
            <div className={`flex flex-col gap-2 sticky top-0 bg-[#FFFFFF] z-[102] ${isInputsClosed && !isHotelNameH2ScrolledOut ? '' : 'drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]'}`}>
              <div className={`w-full gap-[10px] px-[20px] ${isHotelNameH2ScrolledOut ? 'pt-[20px] pb-[19px]' : 'pt-[32px] pb-[30px]'}`}>
                {
                  isInputsClosed ?
                    (
                      <div className="flex justify-between items-center gap-[20px] max-w-full">
                        <div className="flex gap-[20px] items-center overflow-hidden">
                          <BackButton />
                          {isHotelNameH2ScrolledOut && <h2 className="font-bold text-[16px] leading-[140%] text-[#1E1E1E] truncate max-w-[100%]">{!!data && data.name}</h2>}
                        </div>
                        <div className="flex gap-[10px] items-center">
                          {
                            <button
                              onClick={bookmarkId ? handleClickUnBookmarkBtn : handleClickBookmarkBtn}
                            >
                              {
                                bookmarkId ?
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="#FF3755" />
                                    <mask id="mask0_695_11536" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="16">
                                      <path d="M13.7498 2.5C12.2998 2.5 10.9082 3.16213 9.99984 4.20845C9.0915 3.16213 7.69984 2.5 6.24984 2.5C3.68317 2.5 1.6665 4.4782 1.6665 6.99591C1.6665 10.0858 4.49984 12.6035 8.7915 16.4292L9.99984 17.5L11.2082 16.421C15.4998 12.6035 18.3332 10.0858 18.3332 6.99591C18.3332 4.4782 16.3165 2.5 13.7498 2.5Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_695_11536)">
                                      <rect width="20" height="20" fill="#FF3755" />
                                    </g>
                                  </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="#CCCED2" />
                                    <mask id="mask0_695_11561" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="16">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_695_11561)">
                                      <rect width="20" height="20" fill="#CCCED2" />
                                    </g>
                                  </svg>
                              }
                              <span className="sr-only">{!!bookmarkId ? '찜 취소 하기' : '찜 하기'}</span>
                            </button>
                          }
                          <button
                            className="share-button share-button--entry share-button--entry--header share-button--entry--header--stay"
                            onClick={() => {
                              window.dataLayer.push({
                                event: 'search_stay__shareButton',
                                sharetype: '',
                                sharetype_click: 0,
                                shareButton_click: 1,
                              });

                              const { channel } = getTokenRequestingBody();
                              if (channel === 'aos' || channel === 'ios') {
                                postMsgRN(JSON.stringify({
                                  eventName: 'WRITE_CLIPBOARD_URL',
                                  data: {
                                    url: seoMeta?.url || window.location.href,
                                  },
                                }));
                                return
                              }

                              const shareModal = () => {
                                setIsToastNeedAction(false);

                                setModal({
                                  isClosable: true,
                                  isOpened: true,
                                  data: {
                                    title: seoMeta?.title?.replace(' | 올스테이', '') || data?.name,
                                    url: seoMeta?.url || window.location.href,
                                    img: seoMeta.image || _.head(data?.image_list),
                                    setToastMsg,
                                    setIsToastOpen,
                                  },
                                  template: Share,
                                })
                              }

                              if (checkIsMobileWeb() && navigator.share) {
                                navigator.share({ title: seoMeta?.title?.replace(' | 올스테이', '') || data?.name, url: seoMeta?.url || window.location.href });
                                return
                              }

                              shareModal()
                            }}
                          >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M11.5 5.5C11.5 5.71557 11.5227 5.92582 11.5659 6.1285L7.76271 8.03012C7.21273 7.39891 6.40295 7 5.5 7C3.84315 7 2.5 8.34315 2.5 10C2.5 11.6569 3.84315 13 5.5 13C6.40295 13 7.21273 12.6011 7.76271 11.9699L11.5659 13.8715C11.5227 14.0742 11.5 14.2844 11.5 14.5C11.5 16.1569 12.8431 17.5 14.5 17.5C16.1569 17.5 17.5 16.1569 17.5 14.5C17.5 12.8431 16.1569 11.5 14.5 11.5C13.5971 11.5 12.7873 11.8989 12.2373 12.5301L8.43406 10.6285C8.47726 10.4258 8.5 10.2156 8.5 10C8.5 9.78443 8.47726 9.57418 8.43406 9.3715L12.2373 7.46988C12.7873 8.10109 13.5971 8.5 14.5 8.5C16.1569 8.5 17.5 7.15685 17.5 5.5C17.5 3.84315 16.1569 2.5 14.5 2.5C12.8431 2.5 11.5 3.84315 11.5 5.5ZM7 10C7 10.8284 6.32843 11.5 5.5 11.5C4.67157 11.5 4 10.8284 4 10C4 9.17157 4.67157 8.5 5.5 8.5C6.32843 8.5 7 9.17157 7 10ZM14.5 7C15.3284 7 16 6.32843 16 5.5C16 4.67157 15.3284 4 14.5 4C13.6716 4 13 4.67157 13 5.5C13 6.32843 13.6716 7 14.5 7ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z" fill="#1E1E1E" />
                              <mask id="mask0_101_12322" style={{ maskType: 'luminance', }} maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.5 5.5C11.5 5.71557 11.5227 5.92582 11.5659 6.1285L7.76271 8.03012C7.21273 7.39891 6.40295 7 5.5 7C3.84315 7 2.5 8.34315 2.5 10C2.5 11.6569 3.84315 13 5.5 13C6.40295 13 7.21273 12.6011 7.76271 11.9699L11.5659 13.8715C11.5227 14.0742 11.5 14.2844 11.5 14.5C11.5 16.1569 12.8431 17.5 14.5 17.5C16.1569 17.5 17.5 16.1569 17.5 14.5C17.5 12.8431 16.1569 11.5 14.5 11.5C13.5971 11.5 12.7873 11.8989 12.2373 12.5301L8.43406 10.6285C8.47726 10.4258 8.5 10.2156 8.5 10C8.5 9.78443 8.47726 9.57418 8.43406 9.3715L12.2373 7.46988C12.7873 8.10109 13.5971 8.5 14.5 8.5C16.1569 8.5 17.5 7.15685 17.5 5.5C17.5 3.84315 16.1569 2.5 14.5 2.5C12.8431 2.5 11.5 3.84315 11.5 5.5ZM7 10C7 10.8284 6.32843 11.5 5.5 11.5C4.67157 11.5 4 10.8284 4 10C4 9.17157 4.67157 8.5 5.5 8.5C6.32843 8.5 7 9.17157 7 10ZM14.5 7C15.3284 7 16 6.32843 16 5.5C16 4.67157 15.3284 4 14.5 4C13.6716 4 13 4.67157 13 5.5C13 6.32843 13.6716 7 14.5 7ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z" fill="white" />
                              </mask>
                              <g mask="url(#mask0_101_12322)">
                                <rect width="20" height="20" fill="#1E1E1E" />
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : (<div className="flex justify-center items-center relative w-full">
                      <button className="absolute left-[3px]" onClick={() => {
                        if (isScheduleInputOpened) toggleScheduleInputOpened();
                        else if (isRoomInfoInputOpened) toggleRoomInfoInputOpened();
                      }}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M13 1.30929L11.6907 0L6.5 5.19071L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z" fill="#1E1E1E" />
                        </svg>
                        <span className="sr-only">{isScheduleInputOpened ? '체크 인/아웃 날짜' : '객실 및 인원'} 선택 닫기</span>
                      </button>
                      <h2>{isScheduleInputOpened ? '체크 인/아웃 날짜' : '객실 및 인원'} 선택</h2>
                    </div>)
                }
              </div>
              <div className={`relative flex justify-stretch items-start w-full h-[70px] ${isInputsClosed ? 'hidden' : ''}`}>
                <button
                  onClick={toggleScheduleInputOpened}
                  className="absolute left-[33.33%] top-[25px] translate-x-[-50%] translate-y-[-50%] px-[6px] rounded-[4px] bg-[#4A90E2]"
                >
                  <h2 className="font-regular text-[10px] leading-[160%] text-[#FFFFFF]">{nights}<span className="font-bold">박</span></h2>
                </button>
                <button
                  onClick={toggleScheduleInputOpened}
                  className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isScheduleInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
                >
                  <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">체크인</h2>
                  <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{checkin}</span>
                </button>
                <div className="h-[50px] w-[1px] bg-[#EEEEEE]" />
                <button
                  onClick={toggleScheduleInputOpened}
                  className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isScheduleInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
                >
                  <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">체크아웃</h2>
                  <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{checkout}</span>
                </button>
                <div className="h-[50px] w-[1px] bg-[#EEEEEE]" />
                <button
                  onClick={toggleRoomInfoInputOpened}
                  className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isRoomInfoInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
                >
                  <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">객실 {room}</h2>
                  <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{!!adult && `성인 ${adult}`}{!!minor && `, 어린이 ${minor}`}</span>
                </button>
              </div>
              {
                isInputsClosed && isNavTabScrolledOut &&
                <nav key={`${hotelId}-tab-1`}>
                  <ul className="flex gap-[24px] pl-[20px] border-y-[1px] border-y-[#EEEEEE] max-h-[43px] overflow-visible">
                    {[{
                      path: 'home',
                      name: '홈',
                    }, {
                      path: 'price',
                      name: '가격',
                    }, {
                      path: 'location',
                      name: '위치',
                    }, {
                      path: 'introduction',
                      name: '소개',
                    }, {
                      path: 'review',
                      name: '리뷰',
                    },
                    ].map(({ path, name }) =>
                      <button
                        key={`stay-catalog-tab-${path === '' ? 'home' : path}`}
                        onClick={() => {
                          window.history.replaceState(null, '', `/hotel/${country_code}/${city_code}/${hotelId}/${path}?${(new URLSearchParams(sp)).toString()}`);
                          setCurTapPath(path);
                          if (!!$tapContentTop && !!$tapContentTop.current) {
                            // 100은 일정 이상 하단으로 가면 상단 고정으로 움직이는 제목, 탭바에 탭 바디가 상단에 가리지 않기 위한 보정값입니다.
                            path === 'home' ? window.scrollTo(0, 0) : window.scrollTo(0, $tapContentTop.current.offsetTop - 100);
                          }
                        }}
                        className={`flex items-center h-[42px] text-[13px] leading-[160%] text-[#1E1E1E] border-b-[#4A90E2] ${(curTapPath || 'home') === path ? 'border-b-[2px] font-bold' : 'font-regular'}`}
                      >
                        {name}
                      </button>
                    )}
                  </ul>
                </nav>
              }
            </div>
            <div className={`px-[20px] pb-[30px] flex flex-col gap-[20px] bg-[#FFFFFF] ${isInputsClosed ? '' : 'hidden'}`}>
              <div className="flex flex-col gap-[6px]">
                <h2 className={`font-bold text-[16px] leading-[140%] text-[#1E1E1E] ${isHotelNameH2ScrolledOut ? 'opacity-0' : ''}`} ref={$hotelNameH2}>{!!data && data.name}</h2>
                <p className="font-regular text-[11px] leading-[160%] text-[#777777]">{`${!!data && !!data.star_rating ? `${data.star_rating}성급 호텔` : '등급 없음'}`}</p>
              </div>
              {
                data?.image_list?.length ? <HeroGallery>
                  {data?.image_list?.slice(0, showImgLen).map((imgProps, i) => <HeroImg key={`stay-thumb-${i}`} {...{ ...imgProps, disp_order: i }} onClick={() => {
                    makeClickHeroImgHandler(i)();
                  }} />)}
                  {
                    (data?.image_list?.length - showImgLen > 0) &&
                    <HeroRest
                      onClick={() => setShowImgLen(v => v + 8)}
                    >
                      {data.image_list.length - showImgLen}
                    </HeroRest>
                  }
                </HeroGallery> : ''
              }
            </div>
            {/* 나중에 자체 프로모션이 가능할 경우를 대비해 주석처리 함.
              checkIsLogedOutUser(user) &&
              <div className="flex items-center justify-between p-[20px] bg-[#FFFFFF] my-[8px]">
                <div className="flex items-center gap-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 7.22221H14.1666V5.63491C14.1666 3.44443 12.3 1.66666 9.99998 1.66666C7.69998 1.66666 5.83331 3.44443 5.83331 5.63491V7.22221H4.99998C4.08331 7.22221 3.33331 7.9365 3.33331 8.80951V16.746C3.33331 17.619 4.08331 18.3333 4.99998 18.3333H15C15.9166 18.3333 16.6666 17.619 16.6666 16.746V8.80951C16.6666 7.9365 15.9166 7.22221 15 7.22221ZM9.99998 13.5714C9.54165 13.5714 9.16665 13.2143 9.16665 12.7778C9.16665 12.3413 9.54165 11.9841 9.99998 11.9841C10.4583 11.9841 10.8333 12.3413 10.8333 12.7778C10.8333 13.2143 10.4583 13.5714 9.99998 13.5714ZM12.5833 7.22221H7.41665V5.63491C7.41665 4.27777 8.57498 3.17459 9.99998 3.17459C11.425 3.17459 12.5833 4.27777 12.5833 5.63491V7.22221Z" fill="#1E1E1E"/>
                    <mask id="mask0_434_11094" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="3" y="1" width="14" height="18">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 7.22221H14.1666V5.63491C14.1666 3.44443 12.3 1.66666 9.99998 1.66666C7.69998 1.66666 5.83331 3.44443 5.83331 5.63491V7.22221H4.99998C4.08331 7.22221 3.33331 7.9365 3.33331 8.80951V16.746C3.33331 17.619 4.08331 18.3333 4.99998 18.3333H15C15.9166 18.3333 16.6666 17.619 16.6666 16.746V8.80951C16.6666 7.9365 15.9166 7.22221 15 7.22221ZM9.99998 13.5714C9.54165 13.5714 9.16665 13.2143 9.16665 12.7778C9.16665 12.3413 9.54165 11.9841 9.99998 11.9841C10.4583 11.9841 10.8333 12.3413 10.8333 12.7778C10.8333 13.2143 10.4583 13.5714 9.99998 13.5714ZM12.5833 7.22221H7.41665V5.63491C7.41665 4.27777 8.57498 3.17459 9.99998 3.17459C11.425 3.17459 12.5833 4.27777 12.5833 5.63491V7.22221Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_434_11094)">
                    <rect width="20" height="20" fill="#1E1E1E"/>
                    </g>
                  </svg>
                  <p className="font-regular text-[12px] leading-[160%] text-[#777777]">
                    로그인하고 올스테이 회원전용<br />프로모션 적용가를 확인하세요!
                  </p>
                </div>
                <button
                  onClick={() => navigate('/auth')}
                  className="flex justify-center items-center min-w-[90px] max-w-[130px] w-full h-[40px] bg-[#FFFFFF] border border-[#2B2E4A] font-bold text-[13px] leading-[160%] text-[#000000] rounded-[4px]"
                >
                  로그인
                </button>
              </div>
              */}
            <div
              className={`pb-[20px] bg-[#FFFFFF] ${isInputsClosed ? '' : 'hidden'}`}
              ref={$stayNavTab}
            >
              <nav  key={`${hotelId}-tab-2`}>
                <ul className="flex gap-[24px] pl-[20px] border-y-[1px] border-y-[#EEEEEE] max-h-[43px] overflow-visible">
                  {[{
                    path: 'home',
                    name: '홈',
                  }, {
                    path: 'price',
                    name: '가격',
                  }, {
                    path: 'location',
                    name: '위치',
                  }, {
                    path: 'introduction',
                    name: '소개',
                  }, {
                    path: 'review',
                    name: '리뷰',
                  },
                    // { 재윤님 제안으로 최근 탭 없앰. 그러나 같은 구조의 다른 컨텐츠가 기획될 예정이라 주석으로 남김
                    //   path: 'recently-viewed',
                    //   name: '최근',
                    // }
                  ].map(({ path, name }) =>
                    <button
                      key={`stay-catalog-tab-${path === '' ? 'home' : path}`}
                      onClick={() => {
                        window.history.replaceState(null, '', `/hotel/${country_code}/${city_code}/${hotelId}/${path}?${(new URLSearchParams(sp)).toString()}`);
                        setCurTapPath(path);
                        if (!!$tapContentTop && !!$tapContentTop.current) {
                          // 100은 일정 이상 하단으로 가면 상단 고정으로 움직이는 제목, 탭바에 탭 바디가 상단에 가리지 않기 위한 보정값입니다.
                          path === 'home' ? window.scrollTo(0, 0) : window.scrollTo(0, $tapContentTop.current.offsetTop - 100);
                        }
                      }}
                      className={`flex items-center h-[42px] text-[13px] leading-[160%] text-[#1E1E1E] border-b-[#4A90E2] ${(curTapPath || 'home') === path ? 'border-b-[2px] font-bold' : 'font-regular'}`}
                    >
                      {name}
                    </button>
                  )}
                </ul>
              </nav>
            </div>
            <div className={`relative flex justify-stretch items-start w-full h-[70px] bg-[#FFFFFF] ${isInputsClosed ? '' : 'hidden'}`}>
              <button
                onClick={toggleScheduleInputOpened}
                className="absolute left-[33.33%] top-[25px] translate-x-[-50%] translate-y-[-50%] px-[6px] rounded-[4px] bg-[#4A90E2]"
              >
                <h2 className="font-regular text-[10px] leading-[160%] text-[#FFFFFF]">{nights}<span className="font-bold">박</span></h2>
              </button>
              <button
                onClick={toggleScheduleInputOpened}
                className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isScheduleInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
              >
                <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">체크인</h2>
                <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{checkin}</span>
              </button>
              <div className="h-[50px] w-[1px] bg-[#EEEEEE]" />
              <button
                onClick={toggleScheduleInputOpened}
                className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isScheduleInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
              >
                <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">체크아웃</h2>
                <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{checkout}</span>
              </button>
              <div className="h-[50px] w-[1px] bg-[#EEEEEE]" />
              <button
                onClick={toggleRoomInfoInputOpened}
                className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isRoomInfoInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
              >
                <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">객실 {room}</h2>
                <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{!!adult && `성인 ${adult}`}{!!minor && `, 어린이 ${minor}`}</span>
              </button>
            </div>
            <div ref={$tapContentTop} className="flex flex-col px-[20px] gap-[30px] bg-[#FFFFFF]">
              {isScheduleInputOpened &&
                <>
                  <ScheduleInput />
                  <div className="fixed left-0 bottom-0 w-full z-800">
                    <div className="m-auto max-w-[480px] h-[80px] py-[15px] px-[20px] bg-[#FFFFFF] border-t border-t-[#F5F5F5] relative z-[201]">
                      <button
                        onClick={confirmSchedule}
                        className="flex justify-center items-center bg-[#2B2E4A] rounded-[4px] w-full h-[50px] font-bold text-[14px] leading-[160%] text-[#FFFFFF]"
                      >
                        확인하기
                      </button>
                    </div>
                  </div>
                </>
              }
              {isRoomInfoInputOpened &&
                <>
                  <RoomInfoInput />
                  <div className="fixed left-0 bottom-0 w-full z-800">
                    <div className="m-auto max-w-[480px] h-[80px] py-[15px] px-[20px] bg-[#FFFFFF] border-t border-t-[#F5F5F5] relative z-[201]">
                      <button
                        onClick={confirmRoomInfo}
                        className="flex justify-center items-center bg-[#2B2E4A] rounded-[4px] w-full h-[50px] font-bold text-[14px] leading-[160%] text-[#FFFFFF]"
                      >
                        확인하기
                      </button>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className={`${!!data && isInputsClosed ? '' : 'hidden'} flex flex-col mt-[8px] gap-[8px]`}>
              {
                ((curTapPath || 'home') === 'home' || curTapPath === 'price') &&
                <div className="flex flex-col gap-[20px] py-[40px] px-[20px] bg-[#FFFFFF]">
                  <h2 className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]">가격 비교</h2>
                  {!!aff && <div className="py-[20px] border-b border-b-[#EEEEEE]">
                    <a
                      href={aff?.target_url}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="flex flex-col w-full gap-[10px]"
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'search_stay__click_ota-clicks',
                          hotel_name: data.name,
                          ota_rank: 0.1,
                          ota_name: 'tripadvisor',
                          ota_price: parseInt(aff?.price),
                        });
                      }}
                    >
                      <div className="flex items-center gap-[6px] font-regular text-[12px] leading-[160%] text-[#777777]">
                        <svg width="32" height="19" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1" y="1" width="30" height="17" rx="8.5" fill="white" />
                          <path d="M12.2637 5.5H7.67383V6.49609H11.0186C11.0088 6.99414 10.9746 7.58008 10.8281 8.32227L12.0488 8.41016C12.2539 7.44824 12.2539 6.65723 12.2637 6.07617V5.5ZM7.14648 9.07422L7.25391 10.0703C8.85547 10.0703 10.8818 10.0166 12.7324 9.69922L12.6641 8.83008C11.8291 8.93262 10.9258 8.99121 10.0371 9.02539V7.48242H8.79688V9.05957C8.21094 9.06934 7.6543 9.07422 7.14648 9.07422ZM8.29883 12.1895C8.29883 13.2637 9.49023 13.8838 11.3945 13.8789C13.2988 13.8838 14.4805 13.2637 14.4805 12.1895C14.4805 11.1689 13.2988 10.5391 11.3945 10.5391C9.49023 10.5391 8.29883 11.1689 8.29883 12.1895ZM9.56836 12.1895C9.56348 11.7402 10.2275 11.4912 11.3945 11.4961C12.5615 11.4912 13.2012 11.7402 13.2012 12.1895C13.2012 12.6924 12.5615 12.9121 11.3945 12.9121C10.2275 12.9121 9.56348 12.6924 9.56836 12.1895ZM13.1426 10.4316H14.4219V8.23438H15.5059V7.19922H14.4219V5.01172H13.1426V10.4316ZM23.2305 5.7832H16.7461V6.76953H21.9805C21.9756 7.81445 21.9609 9.06445 21.6191 10.7441L22.8691 10.8711C23.2256 8.98633 23.2256 7.73633 23.2305 6.62305V5.7832ZM15.9062 12.8145H24.0117V11.7988H20.0469V8.74219H18.8066V11.7988H15.9062V12.8145Z" fill="#777777" />
                          <rect x="1" y="1" width="30" height="17" rx="8.5" stroke="#777777" />
                        </svg>
                        <span>{aff?.ad_text}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <img src={aff?.logo_url} alt="Tripadvisor 광고 로고" width={133} />
                        <div className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]">
                          {aff?.display_price}
                        </div>
                      </div>
                    </a>
                  </div>}
                  <ul className="flex flex-col items-stretch">
                    {
                      data?.primary_plan_list?.length < 1 ?
                        <div className="flex flex-col gap-[20px] items-center">
                          <div className="flex items-center gap-[10px] font-bold text-[15px] leading-[160%] text-[#4A90E2]">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M7 5.98638H5.5V5.3025H4V7.35415H16V5.3025H14.5V5.98638H13V5.3025H7V5.98638ZM4 15.5607V8.72191H16V15.5607H4ZM13 3.93474H7V3.25085H5.5V3.93474H4C3.17157 3.93474 2.5 4.54711 2.5 5.3025V15.5607C2.5 16.3161 3.17157 16.9285 4 16.9285H16C16.8284 16.9285 17.5 16.3161 17.5 15.5607V5.3025C17.5 4.54711 16.8284 3.93474 16 3.93474H14.5V3.25085H13V3.93474ZM11.7197 9.6061L10 11.1742L8.28033 9.6061L7.21967 10.5733L8.93934 12.1413L7.21967 13.7094L8.28033 14.6765L10 13.1085L11.7197 14.6765L12.7803 13.7094L11.0607 12.1413L12.7803 10.5733L11.7197 9.6061Z" fill="#1E1E1E" />
                              <mask id="mask0_434_9668" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="14">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 5.98638H5.5V5.3025H4V7.35415H16V5.3025H14.5V5.98638H13V5.3025H7V5.98638ZM4 15.5607V8.72191H16V15.5607H4ZM13 3.93474H7V3.25085H5.5V3.93474H4C3.17157 3.93474 2.5 4.54711 2.5 5.3025V15.5607C2.5 16.3161 3.17157 16.9285 4 16.9285H16C16.8284 16.9285 17.5 16.3161 17.5 15.5607V5.3025C17.5 4.54711 16.8284 3.93474 16 3.93474H14.5V3.25085H13V3.93474ZM11.7197 9.6061L10 11.1742L8.28033 9.6061L7.21967 10.5733L8.93934 12.1413L7.21967 13.7094L8.28033 14.6765L10 13.1085L11.7197 14.6765L12.7803 13.7094L11.0607 12.1413L12.7803 10.5733L11.7197 9.6061Z" fill="white" />
                              </mask>
                              <g mask="url(#mask0_434_9668)">
                                <rect y="0.971252" width="20" height="18.2369" fill="#1E1E1E" />
                              </g>
                            </svg>
                            <strong>판매 완료!</strong>
                          </div>
                          <p className="font-regular text-[13px] leading-[160%] text-[#777777]">검색하신 조건으로 예약 가능한 객실이 없어요.<br />다른 조건으로 검색해주세요.</p>
                          <button
                            onClick={() => setSearchPanelVisibility(true)}
                            className="w-full h-[40px] mt-[10px] flex justify-center items-center font-bold text-[13px] leading-[160%] text-[#000000] border border-[#2B2E4A] rounded-[4px]"
                          >
                            검색 조건 변경하기
                          </button>
                        </div>
                        : (curTapPath === 'price' ?
                          data?.plan_list :
                          data?.primary_plan_list)?.map(
                            (item, i) => <FareListItem
                              onClick={() => {
                                window.dataLayer.push({
                                  event: 'search_stay__click_ota-clicks',
                                  hotel_name: data.name,
                                  ota_rank: i + 1,
                                  ota_name: item.channel,
                                  ota_price: item.price
                                })

                                item?.promotions?.filter(promotion => isOpenPromotion({promotion}))?.forEach(({
                                  name: promotion_name,
                                  promotion_period: {
                                    start_on,
                                    end_on,
                                  },
                                  fixed_discount: promotion_fixed_discount,
                                  percentage_discount: promotion_percentage_discount,
                                }) => {
                                  const [promotion_start_date, promotion_start_time] = start_on.split('T').map(t => t?.trim() || t);
                                  const [promotion_end_date, promotion_end_time] = end_on.split('T').map(t => t?.trim() || t);

                                  window?.dataLayer?.push({
                                    event: 'promotion-ota-clicks',
                                    as_d1: 'search',
                                    as_d2: 'stay',
                                    as_context: 'promotion-ota-clicks',
                                    hotel_name: data.name,
                                    page_title: window?.document?.title,
                                    page_path: window?.location?.pathname,
                                    page_location: window?.location?.href,
                                    page_referrer: window?.ALLSTAY_HELMET_PREV_URL,
                                    ota_rank: i + 1,
                                    ota_name: item.channel,
                                    ota_price: item.price,
                                    promotion_name,
                                    promotion_start_date,
                                    promotion_start_time,
                                    promotion_end_date,
                                    promotion_end_time,
                                    promotion_fixed_discount,
                                    promotion_percentage_discount,
                                  });
                                });
                              }}
                              key={`stay-price-${data.id}-${i + 1}`}
                              hotelId={data.id}
                              {...item}
                            />)
                    }
                  </ul>
                  {(curTapPath || 'home') === 'home' && (data?.plan_list?.length > 0 || data?.primary_plan_list?.length > 0) && <button
                    className="w-fit font-bold text-[13px] leading-[160%] text-[#4A90E2] underline"
                    onClick={() => {
                      window.history.replaceState(null, '', `/hotel/${country_code}/${city_code}/${hotelId}/price?${(new URLSearchParams(sp)).toString()}`);
                      setCurTapPath('price');
                    }}
                  >
                    {data?.plan_list?.length || data?.primary_plan_list?.length || 0}개의 모든 가격보기
                  </button>}
                </div>
              }
              <div id="teads-slot" style={{display: "block"}}></div>
              {
                ((curTapPath || 'home') === 'home' || curTapPath === 'location') &&
                <div className={`flex flex-col gap-[20px] py-[40px] px-[20px] bg-[#FFFFFF] ${isInputsClosed ? '' : 'hidden'}`}>
                  <h3 className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]">위치</h3>
                  <div className="flex flex-col gap-[10px]">
                    {(data?.latitude >= -90 && data?.latitude <= 90 && data?.longitude >= -180 && data?.longitude <= 180) &&
                      <iframe
                        title={`${data?.name} 구글 지도`}
                        className={`w-full rounded-[4px] overflow-hidden ${curTapPath === 'location' ? 'h-[630px]' : 'h-[140px]'}`}
                        loading="lazy"
                        allowFullScreen={true}
                        src={`https://www.google.com/maps/embed/v1/place?key=${mapAPIKey}&q=${(encodeURIComponent(data?.address) || `${data?.latitude},${data?.longitude}` || '').replace(/\s/g, '+')}&language=ko_KR&zoom=13&center=${data?.latitude},${data?.longitude}`}
                        referrerPolicy="no-referrer-when-downgrade"
                      />}
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.48634 15L7.93479 14.6142C11.7059 11.3696 13.6227 8.53659 13.6227 6.04634C13.6227 2.51975 10.8293 0 7.48634 0C4.14342 0 1.34998 2.51975 1.34998 6.04634C1.34998 8.53659 3.26678 11.3696 7.03789 14.6142L7.48634 15ZM7.48643 13.209C4.2864 10.3673 2.71371 7.95931 2.71371 6.04515C2.71371 3.28843 4.87106 1.34244 7.48643 1.34244C10.1018 1.34244 12.2592 3.28843 12.2592 6.04515C12.2592 7.95931 10.6865 10.3673 7.48643 13.209ZM7.4861 2.68881C9.36889 2.68881 10.8952 4.19272 10.8952 6.04788C10.8952 7.90305 9.36889 9.40696 7.4861 9.40696C5.60331 9.40696 4.07701 7.90305 4.07701 6.04788C4.07701 4.19272 5.60331 2.68881 7.4861 2.68881ZM5.44074 6.04382C5.44074 4.93072 6.35652 4.02837 7.4862 4.02837C8.61587 4.02837 9.53165 4.93072 9.53165 6.04382C9.53165 7.15692 8.61587 8.05926 7.4862 8.05926C6.35652 8.05926 5.44074 7.15692 5.44074 6.04382Z" fill="black" />
                        </svg>
                        <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{data?.address}</span>
                      </div>
                      <button
                        onClick={() => {
                          try {
                            window.navigator.clipboard.writeText(data?.address);
                            const { channel } = getTokenRequestingBody();
                            if (channel === 'aos') {
                              postMsgRN(JSON.stringify({
                                eventName: 'WRITE_CLIPBOARD_ADDRESS',
                                data: {
                                  address: data?.address,
                                },
                              }));
                            }
                            setToastMsg('숙소 주소를 클립보드로 복사했습니다.');
                          } catch (err) {
                            setToastMsg('숙소 주소를 클립보드로 복사하지 못했어요.');
                            console.error('숙소 주소를 클립보드로 복사하지 못했어요.', err);
                          }
                          setIsToastNeedAction(false);
                          setIsToastOpen(true);
                        }}
                        className="flex gap-[10px]"
                      >
                        <span className="sr-only">지도 url 주소 복사</span>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M9.73683 1H3.10526C2.49737 1 2 1.525 2 2.16667V10.3333H3.10526V2.16667H9.73683V1ZM11.3947 3.33332H5.31579C4.70789 3.33332 4.21052 3.85832 4.21052 4.49998V12.6666C4.21052 13.3083 4.70789 13.8333 5.31579 13.8333H11.3947C12.0026 13.8333 12.5 13.3083 12.5 12.6666V4.49998C12.5 3.85832 12.0026 3.33332 11.3947 3.33332ZM11.3947 12.6667H5.31577V4.50003H11.3947V12.6667Z" fill="#1E1E1E" />
                          <mask id="mask0_434_9230" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="2" y="1" width="11" height="13">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.73683 1H3.10526C2.49737 1 2 1.525 2 2.16667V10.3333H3.10526V2.16667H9.73683V1ZM11.3947 3.33332H5.31579C4.70789 3.33332 4.21052 3.85832 4.21052 4.49998V12.6666C4.21052 13.3083 4.70789 13.8333 5.31579 13.8333H11.3947C12.0026 13.8333 12.5 13.3083 12.5 12.6666V4.49998C12.5 3.85832 12.0026 3.33332 11.3947 3.33332ZM11.3947 12.6667H5.31577V4.50003H11.3947V12.6667Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_434_9230)">
                            <rect x="0.25" y="0.416626" width="14" height="14" fill="#1E1E1E" />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              }
              {
                !!description && ((curTapPath || 'home') === 'home' || curTapPath === 'introduction') &&
                <div className="flex flex-col gap-[20px] px-[20px] py-[40px] bg-[#FFFFFF]">
                  <h2 className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]">{curTapPath === 'introduction' ? '시설 및 서비스' : '소개'}</h2>
                  <dl className="grid grid-cols-2 grid-rows-2 grid-flow-col font-regular text-[12px] leading-[160%] text-[#1E1E1E] bg-[#FCFCFC] h-[76px] place-content-stretch overflow-hidden rounded-[4px]">
                    <dt className="flex w-full h-full justify-center items-center font-bold text-[12px] leading-[160%] text-[#1E1E1E] bg-[#F5F5F5]">체크인</dt>
                    <dd className="flex w-full h-full justify-center items-center">{data.checkin_time}</dd>
                    <dt className="flex w-full h-full justify-center items-center font-bold text-[12px] leading-[160%] text-[#1E1E1E] bg-[#F5F5F5]">체크아웃</dt>
                    <dd className="flex w-full h-full justify-center items-center">{data.checkout_time}</dd>
                  </dl>
                  <div className={`flex flex-col gap-[20px] font-regular text-[13px] leading-[160%] text-[#1E1E1E] ${curTapPath === 'introduction' ? '' : 'hidden'}`}>
                    {Object.entries(data.facility).map(([heading, facilties]) => {
                      let korHeading;
                      switch (heading) {
                        case 'Hotel facilities': korHeading = '호텔 시설'; break;
                        case 'Internet': korHeading = '인터넷'; break;
                        case 'Parking': korHeading = '주차장'; break;
                        case 'Service': korHeading = '서비스'; break;
                        case 'Room facilities': korHeading = '객실 시설'; break;
                        case 'Recreation': korHeading = '리크리에이션'; break;
                        default: korHeading = heading;
                      }

                      return <div key={`hotel-desc-fac-${heading.toLowerCase().replace(' ', '')}`} className="flex flex-col pb-[20px] gap-[10px] border-b-[1px] border-b-[#EEEEEE]">
                        <h3 className="font-bold text-[14px] leading-[140%] text-[#1E1E1E]">{korHeading}</h3>
                        <div className="w-full grid grid-cols-2 font-regular text-[13px] leading-[160%] text-[#1E1E1E] font-regular text-[13px] leading-[160%] text-[#1E1E1E]">
                          {facilties.map((f, index) => <span key={`hote-fac-detail-${index}`}>{f}</span>)}
                        </div>
                      </div>;
                    })}
                  </div>
                  <h2 className={`${curTapPath === 'introduction' ? '' : 'hidden'} font-bold text-[16px] leading-[140%] text-[#1E1E1E]`}>숙소 소개</h2>
                  <div className={`flex flex-col gap-[20px] font-regular text-[13px] leading-[160%] text-[#1E1E1E] ${curTapPath === 'introduction' ? '' : 'max-h-[160px] overflow-hidden'}`}>
                    {
                      typeof description === 'object' ?
                        (Array.isArray(description) ? description : Object.entries(description))
                          .map((descItem, index) => {
                            const descKey = `hotel-${data.id}-desc-${index}`;
                            if (!Array.isArray(descItem)) return <p key={descKey}>{descItem}</p>;

                            const [heading, contents] = descItem;
                            if (!contents) return '';

                            let korHeading;
                            switch (heading) {
                              case 'national_ratings': korHeading = '국가 지정 등급'; break;
                              case 'business_amenities': korHeading = '비즈니스, 기타 편의시설'; break;
                              case 'rooms': korHeading = '객실 정보'; break;
                              case 'attractions': korHeading = '주변 명소'; break;
                              case 'location': korHeading = '위치 정보'; break;
                              case 'headline': korHeading = '헤드라인'; break;
                              case 'amenities': korHeading = '편의시설'; break;
                              case 'dining': korHeading = '다이닝'; break;
                              case 'notice': korHeading = '공지'; break;
                              case 'overview': korHeading = '개요'; break;
                              case 'pre': korHeading = '설명'; break;
                              default: korHeading = heading;
                            }

                            return <div
                              className={`flex flex-col gap-[10px] pb-[20px] ${curTapPath === 'introduction' ? 'border-b-[1px] border-b-[#EEEEEE]' : ''}`}
                              key={descKey}
                            >
                              <h3 className={`font-bold text-[14px] leading-[140%] text-[#1E1E1E] ${curTapPath === 'introduction' ? '' : 'hidden'}`}>{korHeading}</h3>
                              {/<\/\s*[a-zA-Z]+\s*>/.test(contents) || /<\s*br\s*\/{0,1}>/.test(contents) ?
                                <p dangerouslySetInnerHTML={{ __html: contents }} /> :
                                <p>{contents}</p>
                              }
                            </div>;
                          }
                          ).slice(0, curTapPath === 'introduction' ? undefined : 1) : <div dangerouslySetInnerHTML={{ __html: description }} />
                    }
                  </div>
                  {(curTapPath || 'home') === 'home' && <button
                    className="w-fit font-bold text-[13px] leading-[160%] text-[#4A90E2] underline"
                    onClick={() => {
                      window.history.replaceState(null, '', `/hotel/${country_code}/${city_code}/${hotelId}/introduction?${(new URLSearchParams(sp)).toString()}`);
                      setCurTapPath('introduction');
                    }}
                  >
                    더보기
                  </button>}
                </div>
              }
              {
                ((curTapPath || 'home') === 'home' || curTapPath === 'review') ? 
                <div className="flex flex-col gap-[20px] px-[20px] py-[40px] bg-[#FFFFFF]">
                  <h2 className="font-bold text-[16px] leading-[140%] text-[#1E1E1E]">리뷰 보기</h2>
                  <div className="py-5 bg-neutral-100 rounded flex-col justify-start items-center gap-3.5 flex text-[#1E1E1E] bg-[#FCFCFC] place-content-stretch overflow-hidden rounded-[4px]">
                    {
                      review && (review?.review_list?.length || review?.reviewList?.length) ? <>
                        <div className="flex-row items-center gap-3.5 flex">
                          <div className="flex flex-row">
                            {[1,2,3,4,5].map((i) => {
                              if (i <= rating?.avgRating) return <svg key={`avg-rating-${i}`} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.34143 0L11.4743 6.56434H18.3765L12.7925 10.6213L14.9254 17.1857L9.34143 13.1287L3.75747 17.1857L5.89035 10.6213L0.306394 6.56434H7.20855L9.34143 0Z" fill="#FACC15"/>
                              </svg>;
                              return <svg key={`avg-rating-${i}`} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.34143 0L11.4743 6.56434H18.3765L12.7925 10.6213L14.9254 17.1857L9.34143 13.1287L3.75747 17.1857L5.89035 10.6213L0.306394 6.56434H7.20855L9.34143 0Z" fill="#D9D9D9"/>
                              </svg>;                          
                            })}
                          </div>
                          <div className="text-sm font-bold"><span>{rating?.avgRating}</span><span className="text-[#777777]">/5</span></div>
                          <div className="text-[#777777] text-xs">{rating?.totalCount} 리뷰</div>
                        </div>
                        <div className="w-full text-center text-xs text-[#9e9e9e]">ℹ️ 외부 제휴사로부터 제공 받는 리뷰 입니다.</div>
                      </> : 'ℹ️가져 올 수 있는 리뷰가 없어요 😢'

                    }
                  </div>
                  <div className="flex flex-col break-words">
                    {
                      review && (review?.review_list?.length || review?.reviewList?.length) ?
                      ((review?.review_list || review?.reviewList)?.slice(0, (curTapPath || 'home') === 'home' ? 1 : undefined).map(({
                        
                        // 리뷰 데이터의 필드 이름이 어떨때는 스네이크 케이스, 어떨때는 카멜 케이스

                        rating,

                        spr_name,
                        sprName,

                        htlGoodContent,
                        htl_good_content,

                        registDt,
                        regist_dt,

                        sprReviewId,
                        spr_review_id,
                      }) => {
                        const reviewContent = htl_good_content || htlGoodContent;
                        const regDt = registDt || regist_dt;
                        const spName = spr_name || sprName;
                        const reviewId = sprReviewId || spr_review_id;
                        return <div key={`${hotelId}-${reviewId}-review}`} className="px-1 py-2.5 w-full flex flex-col gap-2 text-[#1e1e1e] text-normal">
                          <div className="w-full flex flex-row justify-between text-xs text-[#9e9e9e]">
                            <div className="flex flex-row items-center gap-3">
                              <div className="flex flex-row">
                                {[1,2,3,4,5].map((i) => {
                                  if (i <= rating) return <svg key={`${reviewId}-rating-${i}`} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#1e1e1e"/>
                                  </svg>;
                                  return <svg key={`${reviewId}-rating-${i}`} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#D9D9D9"/>
                                  </svg>;
                                })}
                              </div>
                              <span className="font-bold">{spName}</span>
                            </div>
                            <div className="px-1">{regDt}</div>
                          </div>
                          <div className="text-sm">{reviewContent}</div>
                        </div>;
                      })) : ''
                    }
                    </div>
                    {(curTapPath || 'home') === 'home' && <button
                      className="w-fit font-bold text-[13px] leading-[160%] text-[#4A90E2] underline"
                      onClick={() => {
                        window.history.replaceState(null, '', `/hotel/${country_code}/${city_code}/${hotelId}/review?${(new URLSearchParams(sp)).toString()}`);
                        setCurTapPath('review');
                      }}
                    >
                      더보기
                    </button>}
                    <div className="flex justify-center items-center">
                    {
                      isReviewFetching && (curTapPath || 'home') === 'review' && <ModalSpinner />
                    }
                    {
                      (curTapPath || 'home') === 'review' && review?.pagination?.page >= (review?.pagination?.page_count || review?.pagination?.pageCount) && <div className="text-[#9e9e9e] h-20 flex items-center">✨ 리뷰를 모두 가져 왔어요~! 🎈</div>
                    }
                  </div>
                </div> : ''
              }
              { !! remoteRecent?.length &&
                ((curTapPath || 'home') === 'home' || curTapPath === 'recently-viewed') &&
                (curTapPath === 'recently-viewed' ?
                  <div className="flex flex-col py-[40px] bg-[#FFFFFF]">
                    <h2 className="px-[20px] font-bold text-[16px] leading-[140%] text-[#1E1E1E]">최근 확인 숙소</h2>
                    <InterestedList>
                      {remoteRecent.map(item => {
                        const { country_code } = recent.find(({ id }) => `${id}` === `${data.id}`) || {};
                        return (
                          <InterestedItem
                            key={`recent-item-${item.id}`}
                            {...item}
                            country_code={country_code}
                          />
                        );
                      })}
                    </InterestedList>
                  </div>
                  : <div className="flex flex-col gap-[20px] py-[40px] bg-[#FFFFFF]">
                    <h2 className="px-[20px] font-bold text-[16px] leading-[140%] text-[#1E1E1E]">최근 확인 숙소</h2>
                    <div>
                      <RecentVisitedStayAtMain.List>
                        {remoteRecent?.slice(0, 10)
                          .map((data, i) => {
                            const { country_code } = recent.find(({ id }) => `${id}` === `${data.id}`) || {};
                            return <RecentVisitedStayAtMain.Item
                              key={`main-recent-item-${data.id}`}
                              data={{ ...data, country_code }}
                              index={i}
                            />;
                          })}
                      </RecentVisitedStayAtMain.List>
                    </div>
                  </div>)
              }
              {
                isInputsClosed && curTapPath === 'home' && isHotelNameH2ScrolledOut &&
                <div className="sticky bottom-0 flex justify-between pt-[12px] px-[20px] pb-[15px] bg-[#FCFCFC] border-t border-[#F5F5F5] z-[200]">
                  <div className="flex flex-col font-regular text-[11px] leading-[160%]">
                    <div className="flex gap-[4px] items-baseline text-[#1E1E1E]">
                      <span className="font-bold text-[18px] leading-[140%]">
                        {
                          (data?.plan_list?.length > 0 || data?.primary_plan_list?.length > 0) ?
                            formatNum(data?.primary_plan_list[0]?.price) :
                            '판매완료'
                        }
                        {
                          (data?.plan_list?.length > 0 || data?.primary_plan_list?.length > 0) &&
                            data?.primary_plan_list[0]?.currency === 'KRW' ? '원' : data?.primary_plan_list[0]?.currency
                        }
                      </span>
                      {(data?.plan_list?.length > 0 || data?.primary_plan_list?.length > 0) && <span>외 {data?.plan_list?.length || data?.primary_plan_list?.length || 0}개 가격</span>}
                    </div>
                    <div className="flex gap-[4px] items-center text-[#777777]">
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.85 3.025H3.025V2.6125H2.2V3.85H8.8V2.6125H7.975V3.025H7.15V2.6125H3.85V3.025ZM8.8 4.675H2.2V8.8H8.8V4.675ZM7.15 1.7875H3.85V1.375H3.025V1.7875H2.2C1.74437 1.7875 1.375 2.15687 1.375 2.6125V8.8C1.375 9.25564 1.74437 9.625 2.2 9.625H8.8C9.25564 9.625 9.625 9.25564 9.625 8.8V2.6125C9.625 2.15687 9.25564 1.7875 8.8 1.7875H7.975V1.375H7.15V1.7875ZM3.4375 6.325V5.5H4.2625V6.325H3.4375ZM5.0875 6.325H5.9125V5.5H5.0875V6.325ZM7.5625 6.325H6.7375V5.5H7.5625V6.325ZM3.4375 7.15V7.975H4.2625V7.15H3.4375ZM5.9125 7.975H5.0875V7.15H5.9125V7.975Z" fill="#1E1E1E" />
                        <mask id="mask0_434_11789" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="1" width="9" height="9">
                          <path fillRule="evenodd" clipRule="evenodd" d="M3.85 3.025H3.025V2.6125H2.2V3.85H8.8V2.6125H7.975V3.025H7.15V2.6125H3.85V3.025ZM8.8 4.675H2.2V8.8H8.8V4.675ZM7.15 1.7875H3.85V1.375H3.025V1.7875H2.2C1.74437 1.7875 1.375 2.15687 1.375 2.6125V8.8C1.375 9.25564 1.74437 9.625 2.2 9.625H8.8C9.25564 9.625 9.625 9.25564 9.625 8.8V2.6125C9.625 2.15687 9.25564 1.7875 8.8 1.7875H7.975V1.375H7.15V1.7875ZM3.4375 6.325V5.5H4.2625V6.325H3.4375ZM5.0875 6.325H5.9125V5.5H5.0875V6.325ZM7.5625 6.325H6.7375V5.5H7.5625V6.325ZM3.4375 7.15V7.975H4.2625V7.15H3.4375ZM5.9125 7.975H5.0875V7.15H5.9125V7.975Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_434_11789)">
                          <rect width="11" height="11" fill="#1E1E1E" />
                        </g>
                      </svg>
                      <span>{fullFormatCheckin} - {fullFormatCheckout}</span>
                    </div>
                  </div>
                  {
                    (data?.plan_list?.length > 0 || data?.primary_plan_list?.length > 0) ?
                      <button
                        className="flex justify-center items-center max-w-[130px] min-w-[100px] w-full h-[40px] rounded-[4px] font-bold text-[13px] leading-[160%] text-[#FFFFFF] bg-[#4A90E2]"
                        onClick={() => {
                          window.history.replaceState(null, '', `/hotel/${country_code}/${city_code}/${hotelId}/price?${(new URLSearchParams(sp)).toString()}`);
                          setCurTapPath('price');
                        }}
                      >
                        가격 확인하기
                      </button> :
                      <button
                        onClick={() => setSearchPanelVisibility(true)}
                        className="flex justify-center items-center max-w-[130px] min-w-[100px] w-full h-[40px] rounded-[4px] font-bold text-[13px] leading-[160%] text-[#FFFFFF] bg-[#4A90E2]"
                      >
                        검색조건 변경하기
                      </button>
                  }
                </div>
              }
            </div>
          </div>
          {
            isInputsClosed &&
            <div className="flex flex-col gap-[20px] px-[20px] py-[40px] bg-[#FAFAFA]">
              <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] items-center">
                  <GlobIcon />
                  <TipTitle>세상의 모든 숙소를 보여드릴게요.</TipTitle>
                </div>
                <Desc>전세계 유명 여행사의 250만개 숙소를 한번에 비교 검색이 가능해요!</Desc>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] items-center">
                  <MicIcon />
                  <TipTitle>원하는 목적지로 한번에 검색하세요.</TipTitle>
                </div>
                <Desc>도시나 관광지 검색으로 목적지 근처 숙소 모두 비교 검색해보세요.</Desc>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] items-center">
                  <MailIcon />
                  <TipTitle>컨텐츠를 통한 여행의 설렘과 감동을 느껴보세요.</TipTitle>
                </div>
                <Desc>다양한 여행 전문 정보을 확인하고 실시간 숙소 가격 비교까지 경험해보세요.</Desc>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default Stay;

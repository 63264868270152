import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import App from '@/App';
import ProfileRouteGroup from './profile';
import { About } from "@page/about";
import NotFound404 from '@page/NotFound404';
import IterestRouteGroup from './interest';
import SearchRouteGroup from './search';
import { Outlink } from '@page/outlink';
import { FAQ, Notice } from '@page/profile';
import LegacyRoute from "./legacy";
import TermRouteGroup from "./term";
import AuthRouteGroup from './auth';
import Home from '@page/Home';
import ContentsRouteGroup from './contents';

export const routes = createRoutesFromElements(
  <Route errorElement={<NotFound404 />}>
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      {SearchRouteGroup}
      {IterestRouteGroup}
      {ProfileRouteGroup}
      {AuthRouteGroup}
      {LegacyRoute}
      {TermRouteGroup}
      {ContentsRouteGroup}
      <Route path='outlink/:hotelId/:channel' element={<Outlink />} />
      <Route path="notice" element={<Notice />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="about" element={<About />} />
    </Route>
    <Route path='*' loader={() => {
      const sp = new URLSearchParams(window.location.search);
      const isNotFound = sp.get('is_not_found');
      if (!isNotFound) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('is_not_found', 'true');
        window.location.replace(newUrl);
        return {};
      }
      return {
        isNotFound: true
      };
    }} element={<NotFound404 />} />
  </Route>
);

export const browserRouter = createBrowserRouter(routes);

export default browserRouter;

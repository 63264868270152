import { Subject } from 'rxjs';

const subject = new Subject();

const INITIAL = false;

let state;

const _init = () => state = INITIAL;
const _set = newState => state = newState;
const _get = () => state;
const _toggle = () => _set(!state);
const _subscribe = read => subject.subscribe(read);

export const initIsLogingIn = () => {
  _init();
  subject.next(state);
};
export const setIsLogingIn = newState => {
  _set(newState);
  subject.next(state);
};
export const readIsLogingIn = _get;
export const toggleIsLogingIn = () => {
  _toggle();
  subject.next(state);
};
export const subscribeIsLogingIn = _subscribe;

const _ = {
  initIsLogingIn,
  setIsLogingIn,
  toggleIsLogingIn,
  subscribeIsLogingIn,
};

export default _;

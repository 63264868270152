import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import {
  subscribeStayGrade,
  setStayGrade,
  getStayGrade,
  stayGradeOption,
} from '@store/stayGrade';
import {
  CheckCircle,
  CheckCircleOutline
} from '@mui/icons-material';

function GradeInput() {
  const [grades, setGrades] = useState(getStayGrade());
  
  useEffect(() => {
    subscribeStayGrade(setGrades);
  }, []);

  const handleChange = ({target: { checked, name }}) => {
    const newG = [...grades];
    newG[name] = checked;
    setStayGrade(newG);
  };

  const [allChecked, setAllChecked] = useState(grades.every(v => v));
  useEffect(() => setAllChecked(grades.every(v => v === true)), [grades]);
  const handleAllChange = ({target: { checked }}) => setStayGrade(grades.map(() => checked));

  return (
    <div className="flex flex-col gap-[20px]">
      <label className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">숙소 등급</label>
      <FormGroup sx={{
        minWidth: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        justifyItems: 'start',
        gap: '10px',
      }}>
        <div className="min-w-full">
          <FormControlLabel
            control={<Checkbox
              size="small"
              checkedIcon={<CheckCircle />}
              icon={<CheckCircleOutline />}
              sx={{
                padding: 0,
                marginRight: '10px',
              }}
              name="전체"
              checked={allChecked}
              onChange={handleAllChange}
            />}
            sx={{
              marginLeft: 0
            }}
            label="전체"
            componentsProps={{
              typography : {
                color: '#1E1E1E',
                fontWeight: 'regular',
                fontSize: '14px',
                lineHeight: '160%',
              }
            }}
          />
        </div>
        {stayGradeOption.map(({ name, value }) => (
          <FormControlLabel
            key={`grade-checkbox-label-${value}`}
            control={<Checkbox
              size="small"
              checkedIcon={<CheckCircle />}
              icon={<CheckCircleOutline />}
              sx={{
                padding: 0,
                marginRight: '10px',
              }}
              name={`${value}`}
              checked={grades[value]}
              onChange={handleChange}
            />}
            sx={{
              marginLeft: 0
            }}
            label={name}
            componentsProps={{
              typography : {
                color: '#1E1E1E',
                fontWeight: 'regular',
                fontSize: '14px',
                lineHeight: '160%',
              }
            }}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default GradeInput;

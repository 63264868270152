import { allstayHttpService } from './api';

export const getFAQ = () => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/faq`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('req faq error', err);
        reject(err);
      },
    });
  };

  return new Promise(promiseExecuter);
};

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React from "react";
import { kakaoToken } from '@service/auth';
import { closeResetModal, setModal } from "@store/modal";
import { useNavigate, useLocation} from "react-router-dom";
import { getBodySnsLogin, setBodySnsLogin } from "@stream/tokenRequestingBody";
import { login, submitingSnsSignup } from "@service/auth";
import { setUserInfo } from "@stream/userInfo";
import { Helmet } from "react-helmet";


function KakaoSignIn() {
  const { Kakao } = window;
  const location = useLocation();
  const navigate = useNavigate();
  const showLoginFailModal = () => {
    setModal({
      isClosable: true,
      isOpened: true,
      template: () => (
        <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
          <div className="flex flex-col w-56 gap-6 text-gray-900-100">
            <h2 className="text-modal-h">로그인 실패</h2>
            <p className="text-modal-desc whitespace-normal">카카오 로그인에 실패하였습니다. 잠시 후 다시 시도해 주세요.</p>
          </div>
          <button
            className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
            onClick={() => {navigate('/my-profile'); closeResetModal();}}>
            <span className="w-full">확인</span>
          </button>
        </div>
      ),
    });
  }

  const params = new URLSearchParams(location.search);

  if (!Kakao.isInitialized()) {
    Kakao.init(process.env.REACT_APP__KAKAO_APP_KEY)
  };

  kakaoToken(params.get('code')).then(async res => {
    const { access_token } = res;

    Kakao.Auth.setAccessToken(access_token);

    const user = await Kakao.API.request({ url: '/v2/user/me' });
    const snsInfo = {
      uid: user.id,
      provider: 'kakao',
    };
    setBodySnsLogin(snsInfo);

    let loginRes = await login(getBodySnsLogin())
    .then(res => res)
    .catch(err => err);

    if (!!loginRes && !!loginRes.response && !!loginRes.response.status && 404 === loginRes.response.status) {
      //const subscribe = await kakaoServiceTerms(Kakao)
      await Kakao.API.request({
        url: '/v1/user/service/terms',
      }).then(res => {
        const subscribe = res.allowed_service_terms.find(v => v.tag === 'reception') ? "1":"0";
        const body = {
          user: {
            name: user.kakao_account.profile.nickname,
            email_sns: user.kakao_account.email,
            subscribe,
          },
          sns: snsInfo
        };
  
        submitingSnsSignup(body).then(res => {
          if (!res || !res.access_token) {
            setUserInfo();
            showLoginFailModal()
            return;
          };
          setUserInfo(res);
        });
      })

      return;
    }

    if (!loginRes || !loginRes.access_token) {
      setUserInfo();
      showLoginFailModal()
      return;
    };
    navigate('/');
    setUserInfo(loginRes);
  })

  return <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
  </>
}

export default KakaoSignIn;
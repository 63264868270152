import { BehaviorSubject } from 'rxjs';


const OPTIONS = [
  {value: 0, name: '등급없음'},
  {value: 1, name: '1성급'},
  {value: 2, name: '2성급'},
  {value: 3, name: '3성급'},
  {value: 4, name: '4성급'},
  {value: 5, name: '5성급'},
  // {value: 6, name: '6급'},
];
const DEFAULT = OPTIONS.map(() => true);
const INITIAL = [...DEFAULT];

const _ = new BehaviorSubject(INITIAL);

export const stayGradeOption = [...OPTIONS];

export const initialGrade = INITIAL;
export const subscribeStayGrade = (fn) => _.subscribe(fn);
export const setStayGrade = (newGrades) => _.next(newGrades);
export const getStayGrade = () => _.getValue();

function Privacy202404045() {
  return <>
    <style type="text/css">{`
      .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
      }

      .lst-kix_list_5-0>li {
        counter-increment: lst-ctn-kix_list_5-0
      }


      ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0
      }

      .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
      }

      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
      }

      .lst-kix_list_5-2>li {
        counter-increment: lst-ctn-kix_list_5-2
      }

      .lst-kix_list_5-0>li:before {
        content: ""counter(lst-ctn-kix_list_5-0, decimal) " "
      }

      .lst-kix_list_5-4>li {
        counter-increment: lst-ctn-kix_list_5-4
      }

      .lst-kix_list_5-3>li:before {
        content: ""counter(lst-ctn-kix_list_5-3, decimal) ". "
      }

      .lst-kix_list_5-2>li:before {
        content: ""counter(lst-ctn-kix_list_5-2, lower-roman) ". "
      }

      .lst-kix_list_5-1>li:before {
        content: ""counter(lst-ctn-kix_list_5-1, upper-latin) ". "
      }

      .lst-kix_list_5-7>li:before {
        content: ""counter(lst-ctn-kix_list_5-7, upper-latin) ". "
      }

      .lst-kix_list_5-6>li:before {
        content: ""counter(lst-ctn-kix_list_5-6, decimal) ". "
      }

      .lst-kix_list_5-8>li:before {
        content: ""counter(lst-ctn-kix_list_5-8, lower-roman) ". "
      }
      .lst-kix_list_5-4>li:before {
        content: ""counter(lst-ctn-kix_list_5-4, upper-latin) ". "
      }

      .lst-kix_list_5-5>li:before {
        content: ""counter(lst-ctn-kix_list_5-5, lower-roman) ". "
      }
      .lst-kix_list_6-1>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_6-3>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_6-0>li:before {
        content: "\\00203b   "
      }

      .lst-kix_list_6-4>li:before {
        content: "\\0025a0   "
      }

      ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
      }

      .lst-kix_list_6-2>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_6-8>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_6-5>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_6-7>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_6-6>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_2-7>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_7-4>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_7-6>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_2-5>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_7-2>li:before {
        content: "\\0025c6   "
      }

      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
      }

      .lst-kix_list_10-1>li:before {
        content: "\\0025a0   "
      }
      .lst-kix_list_5-7>li {
        counter-increment: lst-ctn-kix_list_5-7
      }

      .lst-kix_list_7-8>li:before {
        content: "\\0025c6   "
      }

      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
      }
      .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
      }

      .lst-kix_list_10-7>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_10-5>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_10-3>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_4-1>li:before {
        content: ""counter(lst-ctn-kix_list_4-1, upper-latin) ". "
      }

      .lst-kix_list_9-2>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_4-3>li:before {
        content: ""counter(lst-ctn-kix_list_4-3, decimal) ". "
      }

      .lst-kix_list_4-5>li:before {
        content: ""counter(lst-ctn-kix_list_4-5, lower-roman) ". "
      }

      ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0
      }

      .lst-kix_list_5-5>li {
        counter-increment: lst-ctn-kix_list_5-5
      }

      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
      }

      .lst-kix_list_9-6>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_9-4>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_9-8>li:before {
        content: "\\0025c6   "
      }


      .lst-kix_list_1-3>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
      }


      .lst-kix_list_1-7>li:before {
        content: "\\0025aa   "
      }

      ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0
      }

      .lst-kix_list_1-5>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_5-6>li {
        counter-increment: lst-ctn-kix_list_5-6
      }

      .lst-kix_list_2-1>li:before {
        content: "o  "
      }

      .lst-kix_list_2-3>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
      }

      .lst-kix_list_5-1>li {
        counter-increment: lst-ctn-kix_list_5-1
      }

      .lst-kix_list_3-0>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_3-1>li:before {
        content: "o  "
      }

      .lst-kix_list_3-2>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_8-1>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_4-0>li {
        counter-increment: lst-ctn-kix_list_4-0
      }

      .lst-kix_list_8-2>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_3-5>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_3-4>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_3-3>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_8-7>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_3-8>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_8-5>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_8-6>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_8-3>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_3-6>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_3-7>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_8-4>li:before {
        content: "\\0025a0   "
      }

      ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0
      }

      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
      }

      .lst-kix_list_8-8>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
      }

      .lst-kix_list_4-8>li:before {
        content: ""counter(lst-ctn-kix_list_4-8, lower-roman) ". "
      }

      .lst-kix_list_4-7>li:before {
        content: ""counter(lst-ctn-kix_list_4-7, upper-latin) ". "
      }

      ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0
      }

      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
      }

      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
      }

      ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0
      }

      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
      }
      .lst-kix_list_2-6>li:before {
        content: "\\0025aa   "
      }


      .lst-kix_list_2-4>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_2-8>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_7-1>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_7-5>li:before {
        content: "\\0025c6   "
      }

      ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0
      }

      .lst-kix_list_7-3>li:before {
        content: "\\0025cf   "
      }
      ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0
      }
      .lst-kix_list_7-7>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_5-8>li {
        counter-increment: lst-ctn-kix_list_5-8
      }

      .lst-kix_list_10-4>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_10-8>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_4-0>li:before {
        content: ""counter(lst-ctn-kix_list_4-0, decimal) " "
      }

      .lst-kix_list_10-2>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
      }

      .lst-kix_list_4-4>li:before {
        content: ""counter(lst-ctn-kix_list_4-4, upper-latin) ". "
      }

      .lst-kix_list_4-2>li:before {
        content: ""counter(lst-ctn-kix_list_4-2, lower-roman) ". "
      }

      .lst-kix_list_4-6>li:before {
        content: ""counter(lst-ctn-kix_list_4-6, decimal) ". "
      }

      .lst-kix_list_9-3>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_10-6>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_9-1>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_9-7>li:before {
        content: "\\0025a0   "
      }

      .lst-kix_list_9-5>li:before {
        content: "\\0025c6   "
      }

      .lst-kix_list_5-3>li {
        counter-increment: lst-ctn-kix_list_5-3
      }

      .lst-kix_list_1-0>li:before {
        content: "\\0025cf   "
      }
      .lst-kix_list_1-2>li:before {
        content: "\\0025aa   "
      }


      .lst-kix_list_1-4>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_1-6>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_2-0>li:before {
        content: "\\0025cf   "
      }

      .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
      }

      .lst-kix_list_1-8>li:before {
        content: "\\0025aa   "
      }

      .lst-kix_list_2-2>li:before {
        content: "\\0025aa   "
      }

      ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0
      }

      ol {
        margin: 0;
        padding: 0
      }

      table td,
      table th {
        padding: 0
      }

      .c27 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 54.1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c47 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c89 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c86 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 106.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c51 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 141.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c68 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 32.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c65 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 117.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c85 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 248.1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c87 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 127.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c61 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 202.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c81 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 102.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c9 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 56.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c64 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 191.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c83 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 113.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c74 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 155.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c50 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 155.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c70 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c32 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 134.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c78 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 181.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c91 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #deebf6;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 98.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c26 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 102.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c60 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 92.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c42 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 32.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c5 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #a6a6a6;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 225.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c45 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c72 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 134.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c30 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #a6a6a6;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 225.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c39 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 98.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c54 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 54.1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c77 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 106.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c66 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #a6a6a6;
        border-top-width: 0pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #a6a6a6;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c34 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #a6a6a6;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 225.4pt;
        border-top-color: #a6a6a6;
        border-bottom-style: solid
      }

      .c29 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 127.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c13 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 175.1pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c56 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 202.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c40 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 141.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c46 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 191.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c49 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 181.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c38 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 205.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c80 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #a6a6a6;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 225.4pt;
        border-top-color: #a6a6a6;
        border-bottom-style: solid
      }

      .c43 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 117.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c55 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 103.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c35 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 155.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c52 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 134.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c82 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #a6a6a6;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #a6a6a6;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c75 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 99.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c84 {
        border-right-style: solid;
        padding: 6.8pt 0pt 6.8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 451.3pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c69 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: middle;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 113.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
      }

      .c10 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
      }

      .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Malgun Gothic";
        font-style: normal
      }

      .c57 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        
        orphans: 2;
        widows: 2;
        
      }

      .c59 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        
        orphans: 2;
        widows: 2;
        
      }

      .c33 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        
        orphans: 2;
        widows: 2;
        
      }

      .c17 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c15 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        orphans: 2;
        widows: 2;
        
      }

      .c4 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        orphans: 2;
        widows: 2;
        
      }

      .c0 {
        orphans: 2;
        widows: 2;
        text-align: left
      }

      .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        orphans: 2;
        widows: 2;
        
      }

      .c23 {
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Malgun Gothic";
        font-style: normal
      }

      .c1 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
      }

      .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        text-align: left
      }

      .c7 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        text-align: left
      }

      .c36 {
        padding: 0;
        margin: 0
      }

      .c12 {
        color: #000000;
        font-size: 9pt
      }

      .c16 {
        border: 1px solid black;
        margin: 5px
      }

      .c48 {
        margin-left: 1rem;
      }

      .c41 {
        margin-left: 2rem;
      }

      .c58 {
        max-width: 451.3pt;
        padding: 85pt 72pt 72pt 72pt
      }

      .c28 {
        font-size: 11pt
      }

      .c18 {
        background-color: #ffff00
      }

      .c24 {
        font-weight: 400
      }

      .c44 {
        font-size: 10pt
      }

      .c76 {
        font-size: 8pt
      }

      .c21 {
        height: 26.4pt
      }

      .c25 {
        height: 25.6pt
      }

      .c90 {
        height: 27.5pt
      }

      .c53 {
        height: 36.5pt
      }

      .c20 {
        color: #000000
      }

      .c73 {
        height: 3.5pt
      }

      .c22 {
        height: 71.5pt
      }

      .c8 {
        background-color: #ffffff
      }

      .c71 {
        height: 29.4pt
      }

      .c67 {
        height: 26.8pt
      }

      .c14 {
        font-weight: 700;
      }

      .c19 {
        height: 49.4pt
      }

      .c31 {
        height: 28.1pt
      }

      .c79 {
        font-size: 14pt
      }

      .c88 {
        height: 31.2pt
      }

      .c6 {
        height: 10pt
      }

      .c37 {
        height: 0pt
      }

      .c62 {
        height: 26.1pt
      }

      .c63 {
        height: 27.2pt
      }

      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid;
        
      }

      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        
        page-break-after: avoid;
        font-style: italic;
        
      }

      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Malgun Gothic"
      }

      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Malgun Gothic"
      }

      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid;
        
      }

      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid;
        
      }

      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid;
        
      }

      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid;
        
      }

      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid;
        
      }

      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Malgun Gothic";
        
        page-break-after: avoid; 
      }

      

      /* reset begin */
      .privacy-reset {
        line-height: 135%;
        white-space: normal;
        text-align: left;
        tab-size: 1;
      }
      .table-wrapper {
        margin: 1rem 0;
        width: 100%;
        overflow-x: auto;
      }
      table {
        min-width: 100%;
      }
      tr.c37 {
        text-align: center;
      }
      ul, ol {
        list-style-type: unset;
      }
      p > span.c28.c20.c14 {
        display: block;
        padding: 1rem 0 .5rem;
      }
      /* reset end */
  `}</style>
  <div className="privacy-reset">
  <p className="c59"><span className="c23 c20 c8 c14 c79">타이드스퀘어 &lt;올스테이&gt; 개인정보 처리방침</span>
  </p>
  <p className="c33 c6"><span className="c23 c12 c8 c14"></span></p>
  <p className="c17"><span className="c2 c8">㈜타이드스퀘어(이하 "회사"라 함)는 개인정보보호법 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.</span></p>
  <p className="c17"><span className="c2 c8">이에 개인정보보호법에 따라 "회사"의 서비스를 이용하는 이용자(이하 ”정보주체”)에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침을 수립 및 공개합니다.</span></p>
  <p className="c59">
    <span className="c12"><br /><br /></span>
    <span className="c23 c20 c8 c14 c28">목차</span>
  </p>
  <p className="c17"><span className="c12"><br /></span><span className="c12 c8">"회사"의
      개인정보처리방침은 다음과 같은
      내용으로 구성되어 있습니다.</span><span
      className="c2"><br /></span></p><a id="t.53b2a2e2dd2ea8d96d1d1da2bb4bb72c898cbdc2"></a><a id="t.0"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c62">
      <td className="c80" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">1. 개인정보의 수집항목
            및 이용목적</span></p>
      </td>
      <td className="c34" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">2. 개인정보의 수집
            방법</span></p>
      </td>
    </tr>
    <tr className="c88">
      <td className="c30" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">3. 개인정보의 이용 및
            보유 기간</span></p>
      </td>
      <td className="c5" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">4. "정보주체"와
            법정대리인의 권리·의무 및
            행사방법</span></p>
      </td>
    </tr>
    <tr className="c71">
      <td className="c30" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">5. 개인정보의 파기절차
            및 방법</span></p>
      </td>
      <td className="c5" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">6. 개인정보의 제3자
            제공에 관한 사항</span></p>
      </td>
    </tr>
    <tr className="c90">
      <td className="c30" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">7. 개인정보 처리의
            위탁에 관한 사항</span></p>
      </td>
      <td className="c5" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">8. 비회원 "정보주체"의
            개인정보수집 및 보호</span></p>
      </td>
    </tr>
    <tr className="c25">
      <td className="c30" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">9. 링크사이트에 대한
            책임</span></p>
      </td>
      <td className="c5" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">10. 개인정보의 안전성
            확보조치</span></p>
      </td>
    </tr>
    <tr className="c31">
      <td className="c30" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">11. 게시물에 포함된
            개인정보</span></p>
      </td>
      <td className="c5" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">12. 게시물에 포함된
            개인정보</span></p>
      </td>
    </tr>
    <tr className="c31">
      <td className="c30" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">13. 개인정보보호 책임자
            및 개인정보보호 담당자</span></p>
      </td>
      <td className="c5" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">14. 개인정보의
            권리행사를 접수·처리하는
            부서</span></p>
      </td>
    </tr>
    <tr className="c67">
      <td className="c82" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">15. 권익침해 구제 방법</span></p>
      </td>
      <td className="c66" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">16. 개인정보 처리방침의
            변경</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c33 c6"><span className="c2"></span></p>
  <p className="c6 c57"><span className="c2"><br /></span></p>
  <p className="c0" id="h.gjdgxs"><span className="c28 c20 c8 c14">1. 개인정보의
      수집항목 및 이용목적</span><span
      className="c12"><br /></span><span className="c12 c8">① "회사"가
      개인정보를 수집하는 목적은
      "정보주체"의 신분과 서비스
      이용 의사를 확인하여
      최적화되고 맞춤화된 서비스를
      제공하기 위함입니다. "회사"는
      최초 회원가입 시 서비스의
      본질적 기능을 수행하기 위해
      반드시 필요한 최소한의
      정보만을 수집하고 있으며
      "회사"가 제공하는 서비스
      이용에 따라 대금결제, 환불 등에
      필요한 정보를 추가로 수집할 수
      있습니다.</span><span className="c12"><br /></span><span className="c12 c8">②
      "회사"는 개인정보를
      수집·이용 목적 이외에 다른
      용도로 이를 이용하거나
      "정보주체"의 동의 없이
      제3자에게 이를 제공하지
      않습니다.</span><span className="c12"><br /></span><span className="c2 c8">③
      "회사"는 코로나19 등 공중위생,
      공공의 안전과 안녕을 위해
      필요한 경우 동의 없이
      개인정보를 처리할 수
      있습니다.</span></p>
  <p className="c0"><span className="c2 c8">④ "회사"는 국민의
      생명 등 급박한 상황의 경우 동의
      없이 개인정보를 처리할 수
      있습니다.</span></p>
  <p className="c0"><span className="c2 c8">⑤ "회사"는
      “정보주체”의 기본적 인권
      침해의 우려가 있는 민감한
      개인정보(인종 및 민족, 사상 및
      신조, 출신지 및 본적지, 정치적
      성향 및 범죄기록, 건강상태 등)는
      수집하지 않습니다.</span></p>
  <p className="c0"><span className="c2 c8">⑥ "회사"는 다음과
      같은 목적으로 개인정보를
      수집하여 이용할 수 있습니다.
      다만, 전자상거래 등에서의
      소비자보호에 관한 법률,
      국세기본법, 전자금융거래법 등
      관련 법령에 따라 개인정보
      항목이 추가되는 경우 수집하는
      화면에서 별도로 고지하고
      동의를 받아 이용할 수
      있습니다.</span></p>
  <p className="c0" id="h.30j0zll"><span className="c12"><br /></span><span className="c23 c12 c14">가. 회원</span>
  </p>
  <p className="c0 c6" id="h.k87g3rmfy9hz"><span className="c23 c12 c14"></span></p>
  <p className="c0"><span className="c2">- 회원 가입 시</span></p><a
    id="t.fd1a917717fdb6f9ac074e3f45a84e99a097c22c"></a><a id="t.1"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c37">
      <td className="c68" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">구분</span></p>
      </td>
      <td className="c81" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">수집목적</span></p>
      </td>
      <td className="c70" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">수집항목</span></p>
      </td>
      <td className="c51" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">보유기간</span></p>
      </td>
    </tr>
    <tr className="c19">
      <td className="c42" colSpan="1" rowSpan="3">
        <p className="c4"><span className="c2">필수</span></p>
      </td>
      <td className="c26" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">홈페이지 회원가입 및
            관리</span></p>
      </td>
      <td className="c13" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">이메일, 비밀번호,
            로그인 ID</span></p>
      </td>
      <td className="c40" colSpan="1" rowSpan="4">
        <p className="c0"><span className="c12">회원탈퇴 시까지
            거래기록 보존을 위해 소비자
            식별정보(CI, DI), 결제, 취소, 환불
            정보는 5년간 보관함(전자상거래
            등에서의 소비자 보호에 관한
            법률)</span></p>
      </td>
    </tr>
    <tr className="c22">
      <td className="c26" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c12 c8">SNS를 통한 "정보주체"
            식별 및 본인여부 확인, 회원제
            서비스 제공</span></p>
      </td>
      <td className="c13" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">(카카오) 카카오
            계정(이메일), 프로필
            정보(닉네임/프로필 사진),</span></p>
        <p className="c0"><span className="c2">(네이버) 성명, 이메일</span></p>
        <p className="c0"><span className="c2">(애플) 성명, 이메일</span></p>
      </td>
    </tr>
    <tr className="c73">
      <td className="c26" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c12">재화 또는 서비스
            제공</span></p>
      </td>
      <td className="c13" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">투숙자명, 전화번호,
            신용카드정보, 생년월일, 서비스
            이용 기록, 결제기록</span></p>
      </td>
    </tr>
    <tr className="c53">
      <td className="c42" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">선택</span></p>
      </td>
      <td className="c26" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2 c8">SNS를 통한 이용자 식별
            및 본인여부 확인, 회원제
            서비스 제공</span></p>
      </td>
      <td className="c13" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2">카카오(연령대, 출생
            연도)</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c6 c15"><span className="c23 c24 c76 c20"></span></p>
  <p className="c15"><span className="c23 c24 c76 c20">※ 개인정보 수집 및
      이용에 대한 동의를 거부할
      권리가 있으며, 동의 거부 시
      회원가입이 제한됩니다.</span></p>
  <p className="c15"><span className="c20 c8 c76">※ "회사"는 만
      14세미만 아동의 회원가입을 받고
      있지 않습니다.</span></p>
  <p className="c0 c6"><span className="c23 c12 c14"></span></p>
  <p className="c0"><span className="c23 c12 c14">나. 자동으로 생성되는
      개인정보</span></p><a id="t.245f0431798be4798ac9bd3550f921a1c1d4a88a"></a><a id="t.2"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c37">
      <td className="c32" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">수집목적</span></p>
      </td>
      <td className="c70" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">수집항목</span></p>
      </td>
      <td className="c51" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">보유기간</span></p>
      </td>
    </tr>
    <tr className="c19">
      <td className="c72" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">부정이용방지, 비인가
            사용 방지, 분쟁조정 해결을
            위한 기록보존, 맞춤형 서비스
            제공</span></p>
      </td>
      <td className="c13" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c12 c8">IP Address, 방문일시, 서비스
            이용기록, 쿠키, ADID/IDFA</span></p>
      </td>
      <td className="c40" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c12">개인정보 이용목적
            달성 시</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c0 c6"><span className="c2"></span></p>
  <ul className="c36 lst-kix_list_7-0 start">
    <li className="c0 c48 li-bullet-0"><span className="c2">쿠키의 사용 목적:
        "정보주체” 식별 및 로그인
        상태 유지, 개인 맞춤 서비스를
        제공하기 위해 사용됩니다.</span></li>
    <li className="c0 c48 li-bullet-0"><span className="c2 c8">쿠키의 설치·운영
        및 거부: </span><span className="c2">"정보주체”</span><span
        className="c2 c8">는 쿠키 설치에 대한
        선택권을 가지고 있으며, 웹
        브라우저 옵션 설정을 통해 쿠키
        허용, 쿠키 차단 등의 설정을 할 수
        있습니다. 다만, 쿠키 설치·운영
        거부할 경우 맞춤형 서비스
        이용에 어려움이 발생할 수
        있습니다.</span></li>
  </ul>
  <ul className="c36 lst-kix_list_8-0 start">
    <li className="c0 c41 li-bullet-0"><span className="c23 c12 c14">Edge</span><span className="c2">: 웹
        브라우저 우측 상단의 설정 메뉴
        &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및
        사이트 데이터 관리 및 삭제</span></li>
    <li className="c0 c41 li-bullet-0"><span className="c23 c12 c14">Chrome</span><span className="c2">: 웹
        브라우저 우측 상단의 설정 메뉴
        &gt; 개인정보 및 보안 &gt; 쿠키 및 기타
        사이트 데이터</span></li>
    <li className="c0 c41 li-bullet-0"><span className="c23 c12 c14">Safari</span><span className="c2">: MacOS 상단
        좌측 메뉴바 Safari &gt; 환경설정 &gt;
        보안</span></li>
    <li className="c0 c41 li-bullet-0"><span className="c23 c12 c14">FireFox</span><span className="c2">: 웹
        브라우저 오른쪽 상단의 메뉴바 &gt;
        설정 &gt; 좌측의 개인 정보 및 보안 &gt;
        쿠키 및 사이트 데이터 항목 예외
        관리</span></li>
  </ul>
  <ul className="c36 lst-kix_list_9-0 start">
    <li className="c0 c48 li-bullet-0"><span className="c2">ADID/IDFA 사용 목적:
        "정보주체”의 맞춤 서비스
        제공이나 더 나은 환경의 광고를
        제공하기 위해 사용됩니다.</span></li>
  </ul>
  <ul className="c36 lst-kix_list_10-0 start">
    <li className="c0 c41 li-bullet-0"><span className="c23 c12 c14">Android:</span><span className="c2"> 설정 &gt;
        구글 &gt; 광고 &gt; 광고 맞춤설정 선택
        해제</span></li>
    <li className="c0 c41 li-bullet-0"><span className="c23 c12 c14">IOS: </span><span className="c2">설정 &gt;
        개인정보보호 &gt; 광고 &gt; 광고 추적
        제한</span></li>
  </ul>
  <p className="c0"><span className="c12"><br /><br /></span><span className="c28 c20 c14">3. 개인정보의
      이용 및 보유기간</span><span
      className="c2"><br />"정보주체"의 개인정보는
      서비스 제공 및 관계 법령에 따른
      보존기간 동안 보유하며, 관계
      법령에 따른 보존기간은 아래와
      같습니다.</span></p>
  <p className="c0 c6"><span className="c2"></span></p><a id="t.bd0cc72d183734dde86fa9b91e17952704d300a1"></a><a id="t.3"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c37">
      <td className="c64" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">보유 목적</span></p>
      </td>
      <td className="c89" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">근거</span></p>
      </td>
      <td className="c27" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">보유 기간</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c46" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">계약 또는 청약철회
            등에 관한 기록</span></p>
      </td>
      <td className="c38" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">전자상거래 등에서의
            소비자 보호에 관한 법률</span></p>
      </td>
      <td className="c54" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">5년</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c46" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">대금결제 및 재화 등의
            공급에 관한 기록</span></p>
      </td>
      <td className="c38" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">전자상거래 등에서의
            소비자 보호에 관한 법률</span></p>
      </td>
      <td className="c54" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">5년</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c46" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">소비자의 불만 또는
            분쟁처리에 관한 기록</span></p>
      </td>
      <td className="c38" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">전자상거래 등에서의
            소비자 보호에 관한 법률</span></p>
      </td>
      <td className="c54" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">3년</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c46" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">표시, 광고에 관한
            기록</span></p>
      </td>
      <td className="c38" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">전자상거래 등에서의
            소비자 보호에 관한 법률</span></p>
      </td>
      <td className="c54" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">6개월</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c46" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">접속에 관한 기록</span></p>
      </td>
      <td className="c38" colSpan="1" rowSpan="1">
        <p className="c3"><span className="c2">통신비밀보호법</span></p>
      </td>
      <td className="c54" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c2">3개월</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c0 c6"><span className="c2"></span></p>
  <p className="c0"><span className="c2">"회사"는 2년 이상
      서비스 미사용 시, 미사용 대상
      정보주체(회원)에게 별도로
      안내한 후 그 개인정보를
      파기하고 있습니다.<br /></span></p>
  <p className="c0"><span className="c12"><br /></span><span className="c28 c20 c14">4.
      "정보주체"와 법정대리인의
      권리·의무 및 행사방법</span><span
      className="c12"><br />① </span><span> </span><span
      className="c2">"정보주체"는 "회사"에
      언제든지 개인정보 열람, 정정,
      삭제, 처리 정지 요구 등의 권리를
      행사할 수 있습니다.<br />②
      권리행사는 "회사"에 대해
      개인정보보호법에 따라 서면,
      전화, 전자우편, 인터넷 등을 통해
      하실 수 있으며, "회사"는 이에
      대해 "정보주체"의
      요청일로부터 10일 이내에
      조치하겠습니다. 개인정보
      열람의 경우, 해당 기간 내에
      열람할 수 없는 정당한 사유가
      있을 경우 그 사유를 알리고
      열람을 연기하고, 그 사유가
      소멸하면 지체 없이 열람할 수
      있도록 하겠습니다.<br /><br />③
      개인정보
      열람 및 처리정지 요구는
      개인정보보호법에 의하여
      "정보주체"의 권리가 제한될 수
      있습니다.</span></p>
  <p className="c0"><span className="c2">④ 개인정보의 정정 및
      삭제 요구는 다른 법령에서 해당
      개인정보가 수집 대상으로
      명시되어 있는 경우에는 삭제를
      요구할 수 없습니다.</span></p>
  <p className="c0"><span className="c2">⑤ "회사"는
      "정보주체" 권리행사 요구를 한
      자가 본인이거나 정당한
      대리인인지 확인합니다.
      권리행사는 정보주체의
      법정대리인이나 위임을 받은 자
      등 대리인을 통하여 하실 수도
      있습니다. 이 경우 "회사"는
      "정보주체"에게 “개인정보
      처리 방법에 관한 고시”에 따른
      위임장 제출을 요구할 수
      있습니다.<br /></span></p>
  <p className="c0"><span className="c28 c20 c14">5. 개인정보의 파기절차
      및 방법</span><span className="c2"><br />"회사"는 수집한
      개인정보의 이용목적이 달성된
      후에는 이용 및 보관기간에 따라
      해당 정보를 지체 없이 파기하며
      파기 절차 및 방법은 다음과
      같습니다.</span></p>
  <p className="c0"><span className="c2">① 파기 절차</span></p>
  <p className="c0"><span className="c2">"정보주체"가 회원가입
      및 여행서비스 이용 등을 위해
      기재한 개인정보는 이용 목적이
      달성된 후 내부방침 및 기타 관계
      법령에 의한 정보보호 사유에
      따라 일정기간 동안 저장된 후
      삭제되거나 파기합니다.</span></p>
  <p className="c0"><span className="c2">② 파기 방법</span></p>
  <p className="c0"><span className="c12">"회사"는 전자적 파일
      형태로 기록·저장된 개인정보는
      기록을 재생할 수 없도록
      파기하며, 종이 문서에
      기록·저장된 개인정보는
      분쇄기로 분쇄하여
      파기합니다.<br /><br /></span><span className="c28 c20 c14">6.
      개인정보의 제3자 제공에 관한
      사항</span><span
      className="c2"><br />"회사"는 "정보주체"의
      개인정보를 처리 목적에서
      명시한 범위 내에서만 처리하며,
      "정보주체"의 동의, 법률의
      특별한 규정 등에 해당하는
      경우에 개인정보를 제3자에게
      제공합니다.</span></p>
  <p className="c0"><span className="c2">① "회사"는 원활한
      서비스 제공을 위해 해외
      사업자에게 개인정보를 제공할
      수 있으며, 이 경우 "정보주체"의
      동의를 얻어 필요 최소한의
      범위로만 제공합니다.</span></p>
  <p className="c0"><span className="c2">② "회사"는 재난,
      감염병, 급박한 생명·신체
      위험을 초래하는 사건·사고,
      급박한 재산 손실 등의
      긴급상황이 발생하는 경우
      "정보주체"의 동의 없이
      관계기관에 개인정보를 제공할
      수 있습니다. 이 경우 "회사"는
      근거 법령에 의거하여 필요한
      최소한의 개인정보만을
      제공하며, 목적과 다르게
      제공하지 않겠습니다.</span></p>
  <p className="c0 c6"><span className="c2"></span></p><a id="t.1aebe24dda1b56316fca09844ec7bf63e4290b0d"></a><a id="t.4"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c37">
      <td className="c87">
        <p className="c4"><span className="c23 c12 c14">제공받는 자</span></p>
      </td>
      <td className="c65">
        <p className="c4"><span className="c12 c14 c23">제공항목</span></p>
      </td>
      <td className="c86">
        <p className="c4"><span className="c23 c12 c14">제공목적</span></p>
      </td>
      <td className="c91">
        <p className="c4"><span className="c23 c12 c14">보유 및 이용기간</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c29">
        <p className="c4"><span className="c2">ONDA</span></p>
      </td>
      <td className="c43">
        <p className="c0"><span className="c2">이름, 이메일,
            전화번호</span></p>
      </td>
      <td className="c77">
        <p className="c0"><span className="c2">재화 및 서비스 이용
            계약 이행</span></p>
      </td>
      <td className="c39">
        <p className="c4"><span className="c2">개인정보 이용목적
            달성 시(제휴업체는 제휴계약
            종료 시) 및 관계법령에 따른
            보관기간까지</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c0 c6"><span className="c2"></span></p>
  <p className="c0"><span className="c28 c20 c14">7. 개인정보 처리의
      위탁에 관한 사항</span>
  </p>
  <p className="c0"><span> </span><span className="c2">"회사"는 원활한
      개인정보 업무 처리를 위하여
      다음과 같이 개인정보 처리
      업무를 위탁하고 있습니다.</span></p>
  <p className="c0 c6"><span className="c2"></span></p><a id="t.8ba0571f33d5d30716fbffc305a2d105a674c3f1"></a><a id="t.6"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c37">
      <td className="c83">
        <p className="c4"><span className="c23 c12 c14">위탁받는
            업체(수탁자)</span></p>
      </td>
      <td className="c74">
        <p className="c4"><span className="c23 c12 c14">위탁하는 업무</span></p>
      </td>
      <td className="c78">
        <p className="c4"><span className="c23 c12 c14">보유 및 이용기간</span></p>
      </td>
    </tr>
    <tr className="c21">
      <td className="c69">
        <p className="c4"><span className="c2">나이스신용평가</span></p>
      </td>
      <td className="c35">
        <p className="c3"><span className="c2">본인인증(휴대폰번호,
            I-PIN)</span></p>
      </td>
      <td className="c49">
        <p className="c4"><span className="c2">개인정보 이용목적
            달성 시(제휴업체는 제휴계약
            종료 시) 및 관계법령에 따른
            보관기간까지</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c0 c6"><span className="c2"></span></p>
  <p className="c0 c6"><span className="c23 c12 c14"></span></p>
  <p className="c0"><span className="c2">① "회사"는 위탁계약
      체결 시 개인정보 보호법에 따라
      위탁업무 수행목적 외 개인정보
      처리금지, 기술적·관리적
      보호조치, 재위탁 제한, 수탁자에
      대한 관리·감독, 손해배상 등
      책임에 관한 사항을 계약서 등
      문서에 명시하고, 수탁자가
      개인정보를 안전하게
      처리하는지를 감독하고
      있습니다.</span></p>
  <p className="c0"><span className="c2">② 위탁업무의 내용이나
      수탁자가 변경될 경우에는
      지체없이 본 개인정보
      처리방침을 통하여 공개하도록
      하겠습니다.</span></p>
  <p className="c0 c6"><span className="c23 c12 c14"></span></p>
  <p className="c0 c6"><span className="c23 c12 c14"></span></p>
  <p className="c17"><span className="c28 c20 c8 c14">8. 비회원
      "정보주체"의 개인정보수집 및
      보호</span><span className="c12"><br /></span><span
      className="c12 c8">"회사"에서는 회원 뿐만
      아니라 비회원 또한 항공권 및
      호텔 예약 등 서비스를 이용 하실
      수 있습니다. "회사"는 비회원
      예약주문 시 항공권 및 숙박 등의
      예약에 반드시 필요한
      개인정보만을 "정보주체"에게
      요청하고 있습니다. "회사"
      서비스에서 비회원으로 예약을
      하신 경우 입력하신 지불(예약)인
      정보 및 수령인 정보는 대금 결제
      및, 예약에 관련한 용도 외에는
      다른 어떠한 용도로도 사용되지
      않습니다.</span><span className="c2"><br /></span></p>
  <p className="c17"><span className="c12"><br /></span><span className="c28 c20 c8 c14">9.
      링크사이트에 대한 책임</span><span
      className="c12"><br /></span><span> </span><span className="c12 c8">"회사"는
      "정보주체"에게 다른 회사 및
      관계사의 웹사이트 또는 자료에
      대한 링크를 제공할 수 있습니다.
      이 경우 "회사"는 외부사이트 및
      자료에 대한 통제권이 없으므로
      그것으로부터 제공받는 서비스나
      자료의 유용성에 대해 책임질 수
      없으며 보증할 수 없습니다.
      "회사"가 포함하고 있는 링크를
      클릭(click)하여 타 사이트(site)의
      페이지로 이동하는 경우 해당
      사이트의 개인정보처리방침은
      "회사"와 무관하므로 새로
      방문한 사이트의
      개인정보처리방침을 살펴보시기
      바랍니다.</span><span className="c2"><br /></span></p>
  <p className="c17"><span className="c12"><br /></span><span className="c28 c20 c8 c14">10. 개인정보의
      안전성 확보조치</span><span className="c12"><br /></span><span
      className="c12 c8">"회사"는 개인정보의
      안전성 확보를 위해 다음과 같은
      조치를 취하고 있습니다.</span><span
      className="c12"><br />① </span><span className="c12 c14">관리적 조치</span><span
      className="c2">: 내부관리계획 수립 및 시행,
      정기적 직원 교육 등</span></p>
  <p className="c17"><span className="c12">② </span><span className="c12 c14">기술적
      조치:</span><span
      className="c2"> 개인정보처리시스템 등의
      접근권한 관리, 개인정보 등의
      암호화, 보안프로그램 설치 등</span></p>
  <p className="c17"><span className="c12">③ </span><span className="c12 c14">물리적
      조치:</span><span className="c2"> 전산실, 자료보관실
      등의 접근통제 등</span></p>
  <p className="c33 c6"><span className="c2"></span></p>
  <p className="c17"><span className="c12"><br /></span><span className="c28 c20 c8 c14">11. 게시물에
      포함된 개인정보</span><span className="c12"><br /></span><span
      className="c12 c8">"회사"는 "정보주체"의
      게시물을 소중하게 생각하며
      변조, 훼손, 삭제되지 않도록
      최선을 다하여 보호합니다.
      그러나 다음의 경우에는 명시적
      또는 개별적인 경고 후 삭제
      조치할 수 있습니다.</span><span className="c12"><br /></span><span
      className="c12 c8">① 스팸(spam)성 게시물(예: 행운의
      편지, 돈 버는 메일 등)</span><span className="c12"><br /></span><span
      className="c12 c8">② 타인을 비방할 목적으로
      허위사실을 유포하여 타인의
      명예를 훼손하는 글</span><span className="c12"><br /></span><span
      className="c12 c8">③ 동의 없는 타인의 개인정보
      공개</span><span className="c12"><br /></span><span className="c12 c8">④ 제3자의
      저작권 등 권리를 침해하는
      내용</span><span className="c12"><br /></span><span className="c12 c8">"회사"는
      바람직한 게시판 문화를
      활성화하고 개인정보를 보호하기
      위하여 동의 없이 타인의
      개인정보를 게시한 경우
      특정부분을 삭제하거나 기호
      등으로 수정하여 게시할 수
      있습니다.</span><span className="c2"><br /></span></p>
  <p className="c17"><span className="c12"><br /></span><span className="c28 c20 c8 c14">12.
      개인정보보호 책임자 및
      개인정보보호 담당자</span><span
      className="c12"><br /></span><span> </span><span className="c12 c8">"회사"는
      개인정보 처리에 관한 업무를
      총괄해서 책임지고, 개인정보
      처리와 관련한 정보주체의
      불만처리 및 피해구제 등을
      위하여 아래와 같이
      개인정보보호 책임자 및
      담당자를 지정하고 있습니다.</span><span
      className="c12"><br /></span></p><a id="t.76c167c4edaa5311411bfded548437d940a0e249"></a><a id="t.8"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c37">
      <td className="c85" colSpan="2" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">개인정보보호
            책임자</span></p>
      </td>
      <td className="c61" colSpan="2" rowSpan="1">
        <p className="c4"><span className="c23 c12 c14">개인정보보호
            담당자</span></p>
      </td>
    </tr>
    <tr className="c63">
      <td className="c60" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">성명: 박상민</span></p>
      </td>
      <td className="c35" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">소속: Risk Management팀</span></p>
      </td>
      <td className="c56" colSpan="2" rowSpan="1">
        <p className="c0"><span className="c2 c8">소속: Risk Management팀
            개인정보보호 파트</span></p>
      </td>
    </tr>
    <tr className="c63">
      <td className="c60" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">직위: 팀장</span></p>
      </td>
      <td className="c35" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c12 c8">이메일: </span><span
            className="c20 break"><a href="mailto:privacy@tidesquare.com">privacy@tidesquare.com</a></span></p>
      </td>
      <td className="c56" colSpan="2" rowSpan="1">
        <p className="c0"><span className="c12 c8">이메일: </span><span
            className="c20 break"><a href="mailto:privacy@tidesquare.com">privacy@tidesquare.com</a></span></p>
      </td>
    </tr>
    <tr className="c63">
      <td className="c60" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">전화: 02-6960-8412</span></p>
      </td>
      <td className="c35" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">FAX: 02-2638-2882</span></p>
      </td>
      <td className="c75" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">전화: 02-6960-8412</span></p>
      </td>
      <td className="c55" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">FAX: 02-2638-2882</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c33 c6"><span className="c2 c8"></span></p>
  <p className="c33 c6"><span className="c2 c8"></span></p>
  <p className="c33"><span className="c23 c28 c20 c8 c14">13. 개인정보의
      권리행사를 접수·처리하는
      부서</span></p>
  <p className="c17"><span className="c2 c8">"정보주체"는
      개인정보보호법에 따른
      개인정보의 권리행사를 아래의
      부서에 할 수 있습니다.</span></p>
  <p className="c33 c6"><span className="c2 c8"></span></p><a id="t.64cbf6547c4f75b5f98cfede60b2c8130ec355ef"></a><a id="t.9"></a>
  <div className="table-wrapper">
<table className="c1"><tbody>
    <tr className="c63">
      <td className="c9" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c20 c14 c44">부서명</span></p>
      </td>
      <td className="c52" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">Risk Management팀</span></p>
      </td>
    </tr>
    <tr className="c63">
      <td className="c9" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c44 c20 c14">전화번호</span></p>
      </td>
      <td className="c52" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">02-6960-8412</span></p>
      </td>
    </tr>
    <tr className="c63">
      <td className="c9" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c44 c20 c14">이메일</span></p>
      </td>
      <td className="c52" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c20 c24 break"><a href="mailto:privacy@tidesquare.com">privacy@tidesquare.com</a></span></p>
      </td>
    </tr>
    <tr className="c63">
      <td className="c9" colSpan="1" rowSpan="1">
        <p className="c4"><span className="c23 c44 c20 c14">FAX</span></p>
      </td>
      <td className="c52" colSpan="1" rowSpan="1">
        <p className="c0"><span className="c2 c8">02-2638-2882</span></p>
      </td>
    </tr>
  </tbody></table>
</div>
  <p className="c33 c6"><span className="c2 c8"></span></p>
  <p className="c33 c6"><span className="c2 c8"></span></p>
  <p className="c33"><span className="c23 c28 c20 c8 c14">14. 권익침해 구제
      방법</span></p>
  <p className="c17"><span className="c2 c8">"정보주체"는
      개인정보 침해로 인한 구제를
      받기 위하여
      개인정보분쟁조정위원회,
      한국인터넷진흥원 개인정보
      침해신고센터 등에 분쟁해결이나
      상담 등을 신청할 수 있습니다. 이
      밖에 기타 개인정보침해의 신고,
      상담에 대해서는 아래 기관에
      문의하시기 바랍니다.</span></p>
  <p className="c17"><span className="c2 c8">▷ 개인정보보호포털:
      (국번없이) 1833-6972 (<a href="https://privacy.go.kr/">privacy.go.kr</a>)</span></p>
  <p className="c17"><span className="c12 c8">▷ 개인정보 침해신고
      센터: (국번없이) 118 (<a href="https://privacy.kisa.or.kr/">privacy.kisa.or.kr</a>)▷
      대검찰청: (국번없이) 1301 (</span><span
      className="c20"><a href="https://www.spo.go.kr/">www.spo.go.kr</a></span><span className="c2 c8">)</span></p>
  <p className="c17"><span className="c12 c8">▷ 경찰청: (국번없이) 182
      (<a href="https://ecrm.cyber.go.kr/">ecrm.cyber.go.kr</a>)</span></p>
  <p className="c17"><span className="c12 c8">▷
      개인정보분쟁조정위원회:
      (국번없이) 1833-6972 (<a href="https://kopico.go.kr/">kopico.go.kr</a>)</span><span className="c12"><br /><br /><br /></span><span
      className="c28 c20 c8 c14">15. 개인정보 처리방침의
      변경</span><span className="c12"><br /></span><span
      className="c2 c8">개인정보처리방침을
      변경하는 경우 "회사"는
      홈페이지 내 공지사항을 통해
      공지하고 변경된 사항을 쉽게
      알아볼 수 있도록
      조치하겠습니다.</span></p>
  <p className="c17"><span className="c12 c8">① 이
      개인정보처리방침은 2024년
      04월 05일 부터
      적용됩니다.</span></p>
  <p className="c17"><span className="c12 c8">② 이전의
      개인정보처리방침은 아래에서
      확인할 수 있습니다.</span></p>
  <p className="c6 c33"><span className="c2"></span></p>
  <p className="c57 c6"><span className="c2"></span></p>
  </div>
  </>;
}

export default Privacy202404045;

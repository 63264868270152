import React, { useState, useLayoutEffect } from 'react';
import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { getNotice } from '@service/notice';
import { Helmet } from 'react-helmet';
import { BackButton } from '@comp/BackButton';
import { Collapse } from '@comp/Collapse';

function Notice() {
  const [seoMeta, setSeoMeta] = useState();
  const [notices, setNotices] = useState();
  const [openedId, setOpenedId] = useState(NaN);

  useLayoutEffect(() => {
    getNotice().then(res => {
      setSeoMeta(res.seo_meta)
      setNotices(res.notices)
    });
  }, []);

  return (
    <>
      {seoMeta && <Helmet onChangeClientState={handleChangeHelmetState}>
        <title>{seoMeta.title}</title>
        {seoMeta.description && <meta name="description" content={seoMeta.description} />}
        <meta property="og:title" content={seoMeta.title.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta property="og:description" content={seoMeta.description} />}
        <meta property="og:url" content={seoMeta.url} />
        <link rel="canonical" href={seoMeta.url} />
        <meta name="twitter:title" content={seoMeta.title.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta name="twitter:description" content={seoMeta.description} />}
      </Helmet>}
      <main id="allstay-main">
        <div className="allstay-contents">
          <div className="min-h-contents-container">
            <div className="flex flex-col gap-2 px-6 text-stay-default">
              <div className="flex flex-col gap-4 sticky top-0 py-[20px] z-600 bg-white-100">
                <div className="flex justify-between items-start">
                  <div className="flex justify-start items-start w-full">
                    <BackButton />
                    <div className="flex mx-[auto] my-[auto] font-bold text-[15px] leading-[160%] text-[#1E1E1E]">
                      공지사항
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-[#F5F5F5]" />
            {notices &&
              <div className="flex flex-col">
                {notices.map(notice => {
                  return <Collapse
                    onClick={setOpenedId}
                    noticeId={notice.id}
                    isOpened={openedId === notice.id}
                    key={`notice-${notice?.id || Date.now()}`}
                    type="notice"
                    title={notice.title}
                    content={notice.content.replaceAll('<br/>', '')}
                    date={notice.posted_on}
                  />;
                })}
              </div>
            }
          </div>
        </div>
      </main>
    </>
  )
}

export default Notice;

import React from 'react';

function HorizontalItemContainer({
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  paddingV,
  paddingH,
  padding=0,
  children,
}) {
  return <swiper-slide
    class="w-fit min-w-fit max-w-fit inline-block"
    style={{
      paddingTop: `${paddingTop || paddingV || padding}px`,
      paddingBottom: `${paddingBottom || paddingV || padding}px`,
      paddingRight: `${paddingRight || paddingH || padding}px`,
      paddingLeft: `${paddingLeft || paddingH || padding}px`,
    }}
  >{children}</swiper-slide>;
}

export default HorizontalItemContainer;

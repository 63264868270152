import { Route } from 'react-router-dom';
import {
  SignIn,
  EmailSignIn,
  NaverSignIn,
  KakaoSignIn,
  AppleSignIn,
  Auth,
  FindPassword,
  EmailSignUp,
  SnsSignUp,
} from '@page/auth';
import {
  ConfirmPassword,
  Profile,
  RemoveAccount,
} from '@page/profile';

const AuthRouteGroup = (
  <Route>
    <Route element={<Auth />} path="auth">
      <Route index element={<SignIn />} />
      <Route path="email-sign-in" element={<EmailSignIn />} />
      <Route path="naver-sign-in" element={<NaverSignIn />} />
      <Route path="kakao-sign-in" element={<KakaoSignIn />} />
      <Route path="apple-sign-in" element={<AppleSignIn />} />
      <Route path="find-password" element={<FindPassword />} />
      <Route path="email-sign-up" element={<EmailSignUp />} />
      <Route path="sns-sign-up" element={<SnsSignUp />} />
    </Route>
    <Route path="auth" element={<Profile />}>
      <Route path="remove-account" element={<ConfirmPassword />}>
        <Route index element={<RemoveAccount />} />
      </Route>
    </Route>
  </Route>
);

export default AuthRouteGroup;

import { BehaviorSubject } from 'rxjs';

let removedBuffer;
let addedIdBuffer;

const lsKey = 'allstayRecent';

const _getLSState = () => localStorage.getItem(lsKey);
const _setLSState = v => {
  localStorage.setItem(lsKey, JSON.stringify(v));
};

const _ = new BehaviorSubject(JSON.parse(_getLSState()) || []);
_.subscribe(_setLSState);

const _rmItem = bookmark_id => _.getValue().filter(r => r.id !== bookmark_id);
const _addItem = item => {
  const oldRecent = [item, ..._.getValue()];
  const oldRecentSet = new Set(oldRecent.map(({ id }) => id));
  return [...oldRecentSet.values()].map(id => oldRecent.find(r => r.id === id)).filter(r => !!r).slice(0, 10);
}
const _getItem = bookmark_id => _.getValue().find(item => item.id === bookmark_id);


export const subscribeRecent = f => _.subscribe(f);
export const getRecent = () => _.getValue();
export const setRecent = v => _.next(v);

export const rmItem = id => {
  let target = _getItem(id);
  if (!target) return;
  removedBuffer = {...target};
  const newState = _rmItem(id);
  _.next(newState);
};

export const rmAll = () => {
  removedBuffer = [..._.getValue()];
  _.next([]);
};

export const addItem = item => {
  const duplicated = _getItem(item.id);
  if (!!duplicated) {
    _rmItem(duplicated.id);
  }
  addedIdBuffer = item.id;
  const newState = _addItem(item);
  _.next(newState);
};

export const undoRmItem = () => {
  if (!removedBuffer) return;
  if (Array.isArray(removedBuffer)) {
    addedIdBuffer = removedBuffer.map(({ id }) => id);
    _.next([...removedBuffer]);
    return;
  }
  addItem(removedBuffer);
};

export const undoAddItem = () => {
  if (typeof addedIdBuffer === 'undefined') return;
  if (Array.isArray(addedIdBuffer)) {
    removedBuffer = _.getValue().filter(({ id }) => {
      const _id = id;
      return addedIdBuffer.findIndex(({ id }) => {
        const _targetId = id;
        return `${_id}` === `${_targetId}`;
      }) >= 0;
    });
    _.next(_.getValue().filter(({ id }) => {
      const _id = id;
      return addedIdBuffer.findIndex(({ id }) => {
        const _targetId = id;
        return `${_id}` === `${_targetId}`;
      }) < 0;
    }));
  }
  rmItem(addedIdBuffer);
};

import React from 'react';

function PhillWhenToggled({isToggled, children }) {
  return isToggled ? <div className="h-7 px-7 py-1 bg-allstay-gray-1e rounded-full border border-allstay-gray-1e justify-center items-center inline-flex">
    <div className="text-white-100 text-sm font-semibold leading-tight text-nowrap">{children}</div>
  </div> : <div className="h-7 px-3 py-1 justify-center items-center inline-flex">
    <div className="text-allstay-gray-9e text-sm font-medium leading-tight text-nowrap">{children}</div>
  </div>;
}

export default PhillWhenToggled;
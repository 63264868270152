import { SOURCES } from '@util/otaSource';

export const baseProcSearchStay = ({ name }) => ({
  hotel_name: name
});

export const getSourcesMapInitial = () => SOURCES.reduce(
  (acc, channelName) => ({ ...acc, [channelName]: 0, [`${channelName}_price`]: undefined }),
  {}
);

export const procSearchStayPvCountRank = (data) => {
  const reduced = data.primary_plan_list.map(({ channel: channelName, price }, i) => ({
    channelName, i: i + 1, price
  })).reduce((acc, { channelName, i, price }) => ({
    ...acc,
    [channelName]: !acc[channelName] ? i : Math.min(acc[channelName], i),
    [`${channelName}_price`]: price,
  }), getSourcesMapInitial());
  return { ...reduced };
};

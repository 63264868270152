import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { withdraw } from '@service/auth';
import { closeResetModal, openModal, setModal } from '@store/modal';
import { getUserInfo, setUserInfo } from '@stream/userInfo';
import { checkIsSnsLogedInUser } from '@util/checkUserLogState';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const ModalEdit = ({
  heading,
  description,
  actions,
}) => {
  const makeOnClick = handler => e => {
    closeResetModal();
    handler && handler();
  };

  return (
    <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
      <div className="flex flex-col w-56 gap-6 text-gray-900-100">
        <h2 className="text-modal-h">{heading}</h2>
        <p className="text-modal-desc whitespace-normal">{description}</p>
      </div>
      <ButtonGroup
        variant="text"
        className="flex justify-stretch"
        size="large"
        color="success"
      >
        {
          actions.map(({ caption, handler, key }) => (
            <Button
              onClick={makeOnClick(handler)}
              key={key}
              className="w-full"
            >
              <span className="w-full">{caption}</span>
            </Button>
          ))
        }
      </ButtonGroup>
    </div>
  );
};


function RemoveAccount() {
  const navigate = useNavigate();
  const [isBtnUnlocked, setIsBtnUnlocked] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const RmBtnRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [pw, setPw] = useState('');
  const isSnsLogin = checkIsSnsLogedInUser(getUserInfo())

  const handleClickToggleBtn = (_, v) => {
    setIsBtnUnlocked(!!v);
  };

  const handleEnterUp = e => {
    if (e.key === 'Enter' && !!RmBtnRef && !!RmBtnRef.current) RmBtnRef.current.click();
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => e.preventDefault();

  const handleClickRemoveAccountBtn = () => {
    const { access_token } = getUserInfo();
    setIsRequesting(true);
    openModal();
    withdraw(pw, access_token)
      .then(res => {
        setIsRequesting(false);
        setPw('');
        if (res.isOK) {
          setModal({
            isClosable: false,
            isOpened: true,
            data: {
              heading: '회원 탈퇴 성공',
              description: '그 동안 이용해 주셔서 감사합니다.',
              actions: [{
                key: 'go-home',
                caption: '홈으로 이동',
                handler: () => {
                  setUserInfo();
                  navigate('/');
                },
              }]
            },
            template: ModalEdit,
          });
          return;
        }

        const {
          error: {
            code, message
          }
        } = res;

        let _code, _msg;
        switch (`${code}`) {
          case '404':
            _code = '비밀번호 오류';
            _msg = '비밀번호가 틀렸습니다.';
            break;
          case '401':
            _code = '로그인 정보 오류';
            _msg = '현재 로그인 되어 있는 회원 정보에 오류가 발견되었습니다. 계속 진행하시려면 다시 로그인 해 주세요.'
            break;
          default:
            _code = `${code}`;
            _msg = message;
        };

        if (`${code}` === '401') {
          setModal({
            isClosable: false,
            isOpened: true,
            data: {
              heading: '회원 탈퇴 실패',
              description: `${_code} - ${_msg}`,
              actions: [{
                key: 're-sign-in',
                caption: '로그인 다시 하기',
                handler: () => {
                  setUserInfo();
                  navigate('..');
                },
              }]
            },
            template: ModalEdit,
          });
          return;
        }

        setModal({
          isClosable: true,
          isOpened: true,
          data: {
            heading: '회원 탈퇴 실패',
            description: `${_code} - ${_msg}`,
            actions: [{
              key: 'retry',
              caption: '다시 시도',
              // handler: () => navigate('/'),
            }, {
              key: 'go-home',
              caption: '홈으로',
              handler: () => navigate('/'),
            }]
          },
          template: ModalEdit,
        });
      });
  };

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div
      className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        pb-12
        min-h-contents-container
      "
    >
      <div className="px-6 mt-4">
        <IconButton
          onClick={() => navigate('/my-profile/edit')}
          size="large"
          style={{ padding: 0 }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div
        className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-16
        "
      >
        <h2 className="text-screen-title">회원 탈퇴</h2>
        <div
          className="
            flex
            flex-col
            items-stretch
            justify-start
            gap-8
            text-gray-900-100
            text-body-1
          "
        >
          <p>회원 탈퇴를 고민 중이시군요.</p>
          <p>회원을 탈퇴하시면 모든 회원 기록이 즉시 삭제됩니다.<br />
            재가입 시 동일한 이메일로 재가입이 가능하나 회원 기록은 복구되지 않습니다.</p>
          <p>올스테이 직접결제 예약 내역이 남아있거나, 아직 예약한 숙소의 체크아웃이 진행되지 않은 경우에는 탈퇴가 제한됩니다.</p>
          <div className="flex justify-between items-center">
            <p className="text-xl">정말로 탈퇴 하시겠습니까?</p>
            <ToggleButtonGroup value={isBtnUnlocked} onChange={handleClickToggleBtn} exclusive color="success">
              <ToggleButton value={true}>예</ToggleButton>
              <ToggleButton value={false}>아니오</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {
          isBtnUnlocked &&
          <form className="flex flex-col">
            <input type="text" autoComplete="username" id="username" className="hidden" />
            {!isSnsLogin &&
              <div className="mb-5 flex flex-col gap-2.5">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="password-field">비밀번호</InputLabel>
                  <OutlinedInput
                    id="current-password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={pw}
                    onChange={e => setPw(e.target.value)}
                    onKeyUp={handleEnterUp}
                    disabled={isRequesting}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
            }
            <Button
              variant="contained"
              size="large"
              onClick={handleClickRemoveAccountBtn}
              disabled={isRequesting || (isSnsLogin ? false : !pw.length)}
              ref={RmBtnRef}
            >
              {!pw && !isSnsLogin ? '비밀번호를 입력하세요' : '회원 탈퇴'}
            </Button>
          </form>
        }
      </div>
    </div>
    </>
  );
};

export default RemoveAccount;

import * as _ from 'lodash';
import React, { useState, useLayoutEffect } from 'react';
import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { getFAQ } from '@service/faq';
import { Helmet } from 'react-helmet';
import { BackButton } from '@comp/BackButton';
import { Collapse } from '@comp/Collapse';

function FAQ() {
  const [seoMeta, setSeoMeta] = useState()
  const [faq, setFaq] = useState()
  const [category, setCategory] = useState()
  const [openedIndex, setOpenedIndex] = useState(-1)

  useLayoutEffect(() => {
    getFAQ().then(res => {
      setSeoMeta(res.seo_meta)
      setFaq(res.faq)
      setCategory(_.first(res.faq)?.category)
    });
  }, []);

  return (
    <>
      {seoMeta && <Helmet onChangeClientState={handleChangeHelmetState}>
        <title>{seoMeta.title}</title>
        {seoMeta.description && <meta name="description" content={seoMeta.description} />}
        <meta property="og:title" content={seoMeta.title.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta property="og:description" content={seoMeta.description} />}
        <meta property="og:url" content={seoMeta.url} />
        <link rel="canonical" href={seoMeta.url} />
        <meta name="twitter:title" content={seoMeta.title.replace(' | 올스테이', '')} />
        {seoMeta.description && <meta name="twitter:description" content={seoMeta.description} />}
        <script type="application/ld+json">
          {`{
  "@context": "http://schema.org",
  "@type": "FAQPage",
  "mainEntity": ${JSON.stringify(_.map(faq, row => {
            return {
              "@type": "Question",
              "name": row.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": row.answer
              }
            }
          }))}
        }`}
        </script>
      </Helmet>}
      <main id="allstay-main">
        <div className="allstay-contents">
          <div className="min-h-contents-container">
            <div className="flex flex-col gap-2 px-6 text-stay-default">
              <div className="flex flex-col gap-4 sticky top-0 py-[20px] z-600 bg-white-100">
                <div className="flex justify-between items-start">
                  <div className="flex justify-start items-start w-full">
                    <BackButton />
                    <div className="flex mx-[auto] my-[auto] font-bold text-[15px] leading-[160%] text-[#1E1E1E]">
                      자주 묻는 질문
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-[#F5F5F5]" />
            {faq && <>
              <div className="flex flex-wrap justify-between bg-[#F5F5F5] gap-[1px] pb-[10px]">
                {
                  _.keys(_.groupBy(faq, 'category')).map((data, i) => <button
                    key={`faq-category-${i}`}
                    className={`grow ${category === data ? 'bg-[#2B2E4A] text-[#ffffff]' : 'bg-[#ffffff]'} py-[12px] w-[30%] text-[14px] leading-[22.4px]`}
                    onClick={(e) => {
                      setCategory(data);
                      setOpenedIndex(-1);
                    }}
                  >
                    {data}
                  </button>)
                }
              </div>
              <div className="flex flex-col">
                {
                  _.filter(faq, { category }).map((faq, i) => <Collapse
                    key={`faq-item-${i}`}
                    isOpened={openedIndex === i}
                    onClick={() => setOpenedIndex(v => v === i ? -1 : i)}
                    type='faq'
                    title={faq.question}
                    content={faq.answer}
                  />)
                }
              </div>
            </>
            }
          </div>
        </div>
      </main>
    </>
  )
}

export default FAQ;

import React, { useEffect, useMemo, useState } from 'react';
import { Detail11 } from '@comp/StyledParagraph';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { HorizontalItemContainer } from '@comp/HorizontalScroll';
import { ReactComponent as ClearIcon } from '@svg/ButtonIcons/clear.svg';
import PropTypes from 'prop-types';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';

function SearchedHistoryAtMainItem({
  data,
  onClick,
  index,
}) {
  const {
    selected,
    schedule: [{
      startDate,
      endDate,
    }],
    roomInfo
  } = data;

  const [sp, setSp] = useState(getSearchParams());
  
  useEffect(() => {
    subscribeSearchParams(setSp);
  }, []);

  const scheduleStr = useMemo(() => {
    const s = format(new Date(startDate), 'MM.dd');
    const e = format(new Date(endDate), 'MM.dd');
    return `${s}~${e}`;
  }, [startDate, endDate]);

  const guestInfoStr = useMemo(() => {
    const guestSumArr = roomInfo.reduce(([
      r, a, m
    ], {
      adult, minor
    }) => [
      r + 1,
      a + adult,
      m + minor.length
    ], [0, 0, 0]);

    return ['객실', '성인', '어린이']
      .map((v, i) => `${v} ${guestSumArr[i]}`)
      .join(', ');
  }, [roomInfo]);

  return (
    <HorizontalItemContainer
      paddingRight={16}
      paddingLeft={index === 0 ? 20 : 0}
    >
      <NavLink
        to={`${selected[selected.length - 1].to}?${sp}`}
        className="
          relative
          flex
          flex-col
          gap-[2px]
          w-[250px]
          h-[65px]
          py-[12px]
          pl-[10px]
          border
          rounded-[2px]
          border-[#EEEEEE]
          bg-white
        "
      >
      <h3 className="font-bold text-[13px] leading-[160%] text-[#1E1E1E] truncate max-w-[80%]">
        {selected.reverse().map(({ caption }) => caption).join(', ')}
      </h3>
        <Detail11>{scheduleStr}, {guestInfoStr}</Detail11>
      </NavLink>
      <button
        onClick={onClick}
        className="absolute top-[9px] right-[26px]"
      >
        <ClearIcon />
        <span className="sr-only">이 검색 기록 지우기</span>
      </button>
    </HorizontalItemContainer>
  );
};

SearchedHistoryAtMainItem.propTypes = {
  /**
   * 로컬 히스토리에 저장 되는 검색 히스토리 아이템 1개
   */
  data: PropTypes.object,
};

export default SearchedHistoryAtMainItem;

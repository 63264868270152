import { parse, isAfter, addDays, subDays, addMonths, isBefore, differenceInDays, format } from 'date-fns';
import { pipe } from 'fxjs';


// 문맥적 상수들...
export const DEFAULT_CHECKIN_OFFSET = 14;
export const DEFAULT_CHECK_IN = addDays(new Date(), DEFAULT_CHECKIN_OFFSET);
export const DEFAULT_CHECK_OUT = addDays(new Date(), DEFAULT_CHECKIN_OFFSET + 1);
export const MIN_DATE = addDays(new Date(), -1);
export const MAX_DATE = addMonths(new Date(), 13);
export const dateFormatStr = 'yyyy-MM-dd';
export const formatDate = d => {
  try {
    return format(new Date(d), dateFormatStr);
  } catch {
    return format(new Date(), dateFormatStr);
  }
}


// 날짜 문자열에 대한 밸리데이션
export const dateStrFormatValidation = (alt) => (dateStr) => {
  try {
    if (!dateStr) throw new Error('no date string');
    const dateInst = parse(dateStr, dateFormatStr, new Date());
    if (!(dateInst instanceof Date)) throw new Error('fail to new Date');
    if (/invalid/ig.test(dateInst.toString())) throw new Error('invalid date');
    return dateInst;
  } catch {
    return alt;
  }
};
export const validateCheckInStrFormat = dateStrFormatValidation(DEFAULT_CHECK_IN);
export const validateCheckOutStrFormat = dateStrFormatValidation(DEFAULT_CHECK_OUT);

// 날짜 객체의 값에 대한 밸리데이션
export const dateValueValidation = (alt) => (dateInst) => (
  !isBefore(dateInst, MIN_DATE) && !isAfter(dateInst, MAX_DATE)
) ? dateInst : alt;
export const validateCheckInVal = dateValueValidation(DEFAULT_CHECK_IN);
export const validateCheckOutVal = dateValueValidation(DEFAULT_CHECK_OUT);

// 체크인 문자열 -> 유효한 체크인 객체
export const instantiateCheckInStr = pipe(
  validateCheckInStrFormat,
  validateCheckInVal,
);
// 체크아웃 문자열 -> 유효한 체크아웃 객체
export const instantiateCheckOutStr = pipe(
  validateCheckOutStrFormat,
  validateCheckOutVal,
);

// 체크 인/아웃 객체 상호 관계에 대한 밸리데이션
export const validateScheduleRelation = (scheduleArr) => {
  const [d1, d2] = scheduleArr;
  const dateInst1 = new Date(d1);
  const dateInst2 = new Date(d2);
  if ((differenceInDays(dateInst1, dateInst2) === 0)) {
    if (differenceInDays(dateInst1, MAX_DATE) === 0) {
      return [subDays(dateInst1, 1), dateInst2];
    }
    return [dateInst1, addDays(dateInst2, 1)];
  }
  if (isBefore(dateInst2, dateInst1)) return [dateInst1, dateInst2];
  return [...scheduleArr];
};


// 서치파라미터 -> Date[]
export const arrangeScheduleSp = (sp) => {
  const spInst = new URLSearchParams(sp);
  return [spInst.get('check_in'), spInst.get('check_out')];
};

// 스트림 데이터 -> Date[]
export const arrangeScheduleStream = (streamVal) => {
  const {
    startDate,
    endDate,
    // key,
  } = Array.isArray(streamVal) ? streamVal[0] : streamVal;
  return [startDate, endDate];
};

// Date[] -> 서치파라미터
export const parameterizeDateArr = (dateInstArr) => {
  const [check_in, check_out] = dateInstArr.map(formatDate);
  return new URLSearchParams({ check_in, check_out });
};

// Date[] -> 스트림 데이터
export const streamizeDateArr = ([d1, d2]) => [{
  startDate: new Date(d1),
  endDate: new Date(d2),
  key: 'schedule'
}];



// --- 유효성 검증된 결과물 산출 하는 함수들 ---

// 스트림 데이터 -> 유효한 Date[]
export const arrangeValidScheduleStream = pipe(
  arrangeScheduleStream,
  ([d1, d2]) => [
    validateCheckInVal(d1),
    validateCheckOutVal(d2)
  ],
  validateScheduleRelation
);

// 스트림 데이터 -> 유효한 서치파라미터
export const parameterizeValidScheduleStream = pipe(
  arrangeValidScheduleStream,
  parameterizeDateArr
);

// 서치파라미터 -> 유효한 Date[]
export const arrangeValidScheduleSp = pipe(
  arrangeScheduleSp,
  ([d1, d2]) => [
    instantiateCheckInStr(d1),
    instantiateCheckOutStr(d2)
  ],
  validateScheduleRelation,
);

// 서치파라미터 -> 유효한 스트림 데이터
export const streamizeValidScheduleSp = pipe(
  arrangeValidScheduleSp,
  streamizeDateArr
);

const disallowedChars = /[^-_0-9,]/g;

export const genMinorDefault = (id, age=0) => ({
  id: `${id}-minor-${Date.now()}-${parseInt(Math.random() * 100)}`,
  age: parseInt(age)
});

export const genGuestInfoDefault = (adult=2, minor=[]) => {
  const id = `guest-info-${Date.now()}-${parseInt(Math.random() * 100)}`;
  return ({ id, adult: parseInt(adult), minor: minor.map(age => genMinorDefault(id, age)) });
};

export const parseValidRoomInfoStr = (sp) => {
  try {
    const s = (((typeof sp === 'string') && (sp.indexOf('room_info=') < 0)) ? sp : (new URLSearchParams(sp)).get('room_info')).replaceAll(' ', '');
    if (s.length < 1) throw new Error('객실 정보 없음.');

    if (disallowedChars.test(s)) throw new Error('객실 정보 포맷에 맞지 않는 문자 섞여 있음. 파싱 실패.');
    
    const splited = s.split(',');
    if (splited[0].length < 1) throw new Error('객실 정보 파악 불가. 파싱 실패.');
    
    const decoded = splited.map(room => {
      const [adult, minors] = room.split('-');
      const splitedMinors = (typeof minors === 'string') ? minors.split('_') : [];
      const result = genGuestInfoDefault(adult, splitedMinors);
      return result;
    });
    return decoded;
  } catch {
    return [genGuestInfoDefault()];
  }
};

export const parameterizeValidRoomInfo = (roomInfo) => {
  try {
    return roomInfo.map(({ adult, minor }) => {
      if (minor?.length < 1) return adult;
      return `${adult}-${minor.map(({ age }) => age).join('_')}`;
    }).join(',');
  } catch {
    return '2';
  }
};


import * as _ from 'lodash';
import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import FavoriteButton from '@comp/Interested/FavoriteButton';
import { subscribeIsScrolledToBottom } from '@stream/scroll';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';
import { ModalSpinner } from '@comp/ModalSpinner';
import { NavLink, useLocation } from 'react-router-dom';
import decode from '@util/decode';
import { Helmet } from 'react-helmet';
import { updateBookmarkId } from '@store/tags';
import handleChangeHelmetState from '@util/handleChangeHelmetState';
import { updateStoreBookmarkId } from '@store/blogPosts';
import * as Moment from 'moment';


function CategorizedPostList({
  heading,
  store,
}) {
  const {
    getPosts,
    subscribePosts,
    fetchPosts,
    getCurPage,
  } = store;
  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [data, setData] = useState(getPosts());
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    fetchPosts(getCurPage() < 2);
  }, [fetchPosts, getCurPage]);

  useEffect(() => {
    window.scroll(0, 0);
    fetchPosts(false);
  }, [pathname, fetchPosts]);

  useEffect(() => {
    subscribePosts(setData);
    subscribeIsScrolledToBottom(setIsScrolledToBottom);
    subscribePosts(() => {
      setIsFetching(false);
    });
    fetchPosts(false);
  }, [subscribePosts, fetchPosts]);

  useEffect(() => {
    if (!isScrolledToBottom || isFetching) return;
    setIsScrolledToBottom(false);
    fetchPosts(true);
    setIsFetching(true);
  }, [isScrolledToBottom, isFetching, fetchPosts]);

  const $listBox = useRef();
  useEffect(() => {
    if ((window.innerHeight < ($listBox?.current?.clientHeight || -1)) || isFetching) return;
    setIsScrolledToBottom(false);
    fetchPosts(true);
    setIsFetching(true);
  }, [data, $listBox, fetchPosts, isFetching]);

  useLayoutEffect(() => {
    subscribeUserInfo(setUserInfo);
  }, []);

  const updateDate = (index, postId, bookmark_id) => {
    const newData = data
    newData[index].bookmark_id = bookmark_id
    setData(newData)
    updateBookmarkId(postId, bookmark_id)
    updateStoreBookmarkId(postId, bookmark_id)
  }

  return (
    <>
      {
        data.length > 0 && !!data[0].image &&
        <Helmet onChangeClientState={handleChangeHelmetState}>
          <meta property="og:image" content={data[0].image} />
          <meta name="twitter:image" content={data[0].image} />
        </Helmet>
      }
      <div ref={$listBox} className="flex flex-col px-[20px] pt-[40px] pb-[60px] gap-[20px]">
        <div className={`grid grid-cols' : 'grid-cols-2'} gap-x-[15px] gap-y-[20px] text-[13px] leading-[160%]`}>
          {data.map(({ id, title, date, publisher, image, slug, tags, bookmark_id }, i) => {
            return (
              <NavLink
                to={`/alltrip/post/${slug}`}
                key={`${pathname}-section-item-${id || i}`}
                className="w-full pt-[100%] overflow-hidden relative rounded-[10px] bg-center bg-no-repeat bg-cover"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,.4), rgba(0,0,0,.4)), url('${image}')`,
                }}
              >
                <FavoriteButton className={`top-[20px] right-[20px] absolute z-[1]`} bookmark_id={bookmark_id} ref_model="content" ref_id={id} userInfo={userInfo} updateDate={bookmark_id => updateDate(i, id, bookmark_id)}/>
                <div className={`top-[40px] left-[20px] absolute flex flex-col z-[2] w-[auto] h-[auto] gap-[10px]`}>
                  <p className={`w-[154px] text-[18px] font-bold leading-[140%] text-[#FFFFFF]`} style={{ wordBreak: 'keep-all' }}>{decode(title)}</p>
                  {
                    (date || publisher) &&
                    <p className="w-full text-[12px] leading-[160%] text-[#FFFFFF]">
                      {date && `${Moment(new Date(date)).format('YYYY-MM-DD')}`}
                      {publisher && publisher}
                    </p>
                  }
                  <div className="flex gap-[5px]">
                    {!!tags && _.take(tags, 3).map(tag => {
                      return (
                        <div className="rounded-[20px] px-[6px] font-bold text-[10px] leading-[160%] text-[#FFFFFF]" style={{ background: 'rgba(255, 255, 255, 0.3)' }}>
                          {tag.name}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </NavLink>
            );
          })}
        </div>
        <div className="flex justify-center items-center h-20">
          {isFetching && <ModalSpinner />}
        </div>
      </div>
    </>
  );
}

export default CategorizedPostList;

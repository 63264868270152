export const getPostingContext = () => {
  if (!!document.ReactNativeWebView && !!document.ReactNativeWebView.postMessage) return document;
  if (!!window.ReactNativeWebView && !!window.ReactNativeWebView.postMessage) return window;
  return false;
};

export const checkPostingContext = () => {
  const postingContext = getPostingContext();
  if (typeof postingContext === 'boolean') return postingContext;
  return true;
};

export const rnDeviceInfo = () => {
  return window?.ReactNativeDeviceInfo?.data || {};
};

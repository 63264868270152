import React, { useState } from 'react';
import MarkDown from 'react-markdown';

import privacy20240405 from './PrivacyContents/Privacy20240405';
import privacy20231121 from '@md/privacy-policy/20231121.md';
import privacy20221122 from '@md/privacy-policy/20221122.md';
import privacy20200616 from '@md/privacy-policy/20200616.md';
import privacy20171120 from '@md/privacy-policy/20171120.md';
import privacy20151101 from '@md/privacy-policy/20151101.md';
import { Helmet } from 'react-helmet';


function Privacy() {
  const [privacy, setPrivacy] = useState(['jsx', privacy20240405]);

  const createClickTermHandler = md => () => {
    setPrivacy(md);
    window.scrollTo(0, 0);
  };
  const siteName = '개인정보처리방침 | 올스테이';

  return (
    <>
    <Helmet>
      <title>{siteName}</title>
      <meta property="og:title" content={siteName.replace(' | 올스테이', '')} />
      <meta name="description" content="올스테이 개인정보처리방침 페이지 입니다." />
      <meta property="og:description" content="올스테이 개인정보처리방침 페이지 입니다." />
      <meta name="twitter:title" content={siteName.replace(' | 올스테이', '')} />
      <meta name="twitter:description" content="올스테이 개인정보처리방침 페이지 입니다." />
    </Helmet>
      <section className="term new-20231121 bg-white-100">
        {
          privacy[0] === 'jsx' ? privacy[1]() : <MarkDown>{privacy[1]}</MarkDown>
        }
        <div className="flex flex-col justify-start gap-4">
          <h2 className="privacy-list">개인정보 처리방침 개정 목록</h2>
          {privacy[1] === privacy20240405 ?
            <p><strong>- 개인정보 처리방침 2024년 04월 05일</strong></p> : <button className="w-fit" onClick={createClickTermHandler(['jsx', privacy20240405])}>- 개인정보 처리방침 2024년 04월 05일</button>
          }
          {privacy[1] === privacy20231121 ?
            <p><strong>- 개인정보 처리방침 2023년 11월 21일</strong></p> : <button className="w-fit" onClick={createClickTermHandler(['md', privacy20231121])}>- 개인정보 처리방침 2023년 11월 21일</button>
          }
          {privacy[1] === privacy20221122 ?
            <p><strong>- 개인정보 처리방침 2022년 11월 22일</strong></p> : <button className="w-fit" onClick={createClickTermHandler(['md', privacy20221122])}>- 개인정보 처리방침 2022년 11월 22일</button>
          }
          {privacy[1] === privacy20200616 ?
            <p><strong>- 개인정보 처리방침 2020년 06월 16일</strong></p> : <button className="w-fit" onClick={createClickTermHandler(['md', privacy20200616])}>- 개인정보 처리방침 2020년 6월 16일</button>
          }
          {privacy[1] === privacy20171120 ?
            <p><strong>- 개인정보 처리방침 2017년 11월 20일</strong></p> : <button className="w-fit" onClick={createClickTermHandler(['md', privacy20171120])}>- 개인정보 처리방침 2017년 11월 20일</button>
          }
          {privacy[1] === privacy20151101 ?
            <p><strong>- 개인정보 처리방침 2015년 11월 01일</strong></p> : <button className="w-fit" onClick={createClickTermHandler(['md', privacy20151101])}>- 개인정보 처리방침 2015년 11월 1일</button>
          }
        </div>
      </section>
    </>
  );
}

export default Privacy;

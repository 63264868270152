import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React from "react";
import { closeResetModal, setModal } from "@store/modal";
import { useNavigate } from "react-router-dom";
import { login, submitingSnsSignup } from "@service/auth";
import { getBodySnsLogin, setBodySnsLogin } from "@stream/tokenRequestingBody";
import { setUserInfo } from "@stream/userInfo";
import { Helmet } from "react-helmet";

function NaverSignIn() {
  const { naver } = window;
  const navigate = useNavigate();
  const showLoginFailModal = () => {
    setModal({
      isClosable: true,
      isOpened: true,
      template: () => (
        <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
          <div className="flex flex-col w-56 gap-6 text-gray-900-100">
            <h2 className="text-modal-h">로그인 실패</h2>
            <p className="text-modal-desc whitespace-normal">네이버 로그인에 실패하였습니다. 잠시 후 다시 시도해 주세요.</p>
          </div>
          <button
            className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
            onClick={() => {navigate('/my-profile'); closeResetModal();}}>
            <span className="w-full">확인</span>
          </button>
        </div>
      ),
    });
  }

  const naverLogin = new naver.LoginWithNaverId({
    clientId: process.env.REACT_APP__NAVER_CLIENT_ID,
    callbackUrl: process.env.REACT_APP__SNS_LOGIN_REDIRECT_URI + '/auth/naver-sign-in',
    callbackHandle: true,
    isPopup: false,
  });
  naverLogin.init();
  naverLogin.getLoginStatus(status => {
    if (status) {
      const { id, email, name } = naverLogin.user;
      const snsInfo = {
        uid: id,
        provider: 'naver'
      };
      setBodySnsLogin(snsInfo);

      login(getBodySnsLogin())
      .then(res => {
        if (!res || !res.access_token) {
          setUserInfo();
          showLoginFailModal();
          return;
        };
        navigate('/');
        setUserInfo(res);
      }).catch(err => {
        if (!!err && !!err.response && !!err.response.status && 404 === err.response.status) {
          const body = {
            user: {
              name,
              email_sns: email,
              subscribe: "0",
            },
            sns: snsInfo
          };
          submitingSnsSignup(body)
          .then(res => {
            if (!res || !res.access_token) {
              setUserInfo();
              showLoginFailModal();
              return;
            };
            setUserInfo(res);
          });
          return;
        }
      });
    } else {
      showLoginFailModal();
    }
  });

  return <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
  </>
}

export default NaverSignIn;
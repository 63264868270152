import * as _ from 'lodash';
import React, { useLayoutEffect, useMemo, useState, Fragment } from 'react';

import { format } from 'date-fns';
import { getSchedule, subscribeSchedule } from '@stream/schedule';
import { getRoomInfo, subscribeRoomInfo } from '@stream/roomInfo';
import { subscribeTokenRequestingBody, getTokenRequestingBody } from '@stream/tokenRequestingBody';

import { ReactComponent as PromotionIcon } from '@svg/Icons/Promotion/promotion.svg';

const formatNum = Intl.NumberFormat('ko-KR').format;

function FareListItem({
  channel: outLinkChannel,
  hotelId,
  target_url,
  name,
  price,
  attrs,
  currency,
  promotions,
  onClick,
}) {
  const [tokenRequestingBody, setTokenRequestingBody] = useState(getTokenRequestingBody());
  const [schedule, setSchedule] = useState(getSchedule());
  const [roomInfo, setRoomInfo] = useState(getRoomInfo());

  useLayoutEffect(() => {
    subscribeTokenRequestingBody(setTokenRequestingBody);
    subscribeSchedule(setSchedule);
    subscribeRoomInfo(setRoomInfo);
  }, []);

  const to = useMemo(() => {
    const [{ startDate, endDate }] = schedule;
    const checkin = format(new Date(startDate), 'yyyy-MM-dd');
    const checkout = format(new Date(endDate), 'yyyy-MM-dd');
    const room_info = _.map(roomInfo, info => {
      let result = info.adult
      if (!_.isEmpty(info.minor)) {
        result += `-${_.map(info.minor, 'age').join('_')}`
      }
      return result
    }).join(',');
    const {
      device_id,
      os_version,
      app_version,
      device_model,
    } = tokenRequestingBody;
    const sp = new URLSearchParams();
    target_url && sp.set('target_url', target_url);
    checkin && sp.set('checkin', checkin);
    checkout && sp.set('checkout', checkout);
    room_info && sp.set('room_info', room_info);
    name && sp.set('name', name);
    os_version && sp.set('os_version', os_version);
    app_version && sp.set('app_version', app_version);
    device_model && sp.set('device_model', device_model);
    device_id && sp.set('device_id', device_id);
    const spStr = sp.toString();

    return `/outlink/${hotelId}/${outLinkChannel.toLowerCase()}${!!spStr ? `?${spStr}` : ''}`;
  }, [tokenRequestingBody, schedule, roomInfo, name, hotelId, outLinkChannel, target_url]);


  return (
    <li className="py-[20px] border-b border-b-[#EEEEEE]">
      <a
        onClick={onClick}
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        className="flex flex-col w-full gap-[10px]"
      >
        <div className="flex justify-between items-center h-[20px]">
          <div
            className="h-[18px] w-[80px]"
            style={{
              backgroundImage: `url(https://static.allstay.com/channel/${outLinkChannel.toLowerCase()}.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
          </div>
          <span className='sr-only'>{outLinkChannel.toLowerCase()} 로고</span>

          {
            promotions?.[0] && [promotions[0]].map(({ // 배열이 비어있지 않은지 검사 할 필요가 없음, 맵의 첫번째 인자인 함수 내에서 분해 할당을 할 수 있음.
              name,
              price,
            }) => {
              return <div key={`${hotelId}--${outLinkChannel}--${price}--promition-name`} className="flex items-center gap-[2px] pl-[5px] pr-[8px] bg-[#FFE000] rounded-[20px]">
                <PromotionIcon />
                <span className="text-[11px] leading-[160%] font-bold text-[#000000]">{name}</span>
              </div>;
            })
          }
        </div>
        <div className="flex justify-between items-end gap-[30px] font-bold text-[16px] leading-[140%] text-[#1E1E1E]">
          <span>{name}</span>
          {
            promotions?.[0]?.adjusted_price ?
            <div key={`${hotelId}--${outLinkChannel}--${price}--promition-price`} className="flex flex-col shrink-0">
              {promotions?.[0]?.adjusted_price && <span className={`flex justify-end min-w-fit line-through text-[12px] text-[#BEBEBE]`}>{formatNum(Math.floor(price))}{currency === 'KRW' ? '원' : currency}</span>}
              <span className="">{formatNum(Math.floor(promotions[0].adjusted_price))}{currency === 'KRW' ? '원' : currency}</span>
            </div>
            : <span className="min-w-fit shrink-0">{formatNum(Math.floor(price))}{currency === 'KRW' ? '원' : currency}</span>
          }
        </div>
        <div className="flex gap-[10px] font-bold text-[11px] leading-[160%]">
          {
            attrs.map((attr, index) => <Fragment key={`attr-${index + 1}`}>
              {!!index && '|'}
              <span className={attr === '환불불가' ? 'text-[#D0021B]' : 'text-[#4A90E2]'}>{attr}</span>
            </Fragment>)
          }
        </div>
      </a>
    </li>
  );
}

export default FareListItem;

import { setTokenRequestingBody, getTokenRequestingBody, reqNativeAppMeta } from '@stream/tokenRequestingBody';
import {
  getAccessToken,
  getRefreshToken,
  setUserInfo
} from '@stream/userInfo';
import axios from 'axios';
import { Observable, retry } from 'rxjs';
import { doesPostMsgExist } from '@util/postMsgRN';


const checkIsUnahthorizedErr = err => {
  if (axios.isAxiosError(err)) {
    if (!!err.response) {
      const res = err.response;
      if (!!res.status && !! res.data && !!res.data.error) {
        const {
          status,
          data: {
            error: {
              code,
              message
            }
          }
        } = res;
        if (status === 401 && code === '401' && message === 'UNAUTHORIZED') {
          return true;
        }
      }
    }
  }
  return false;
};

export const apiConfigBase = {
  baseURL: process.env.REACT_APP__API_URL,
  timeout: 20000,
};

export const allstayHttpClient = axios.create(apiConfigBase);

const rateConfigBase = {
  baseURL: process.env.REACT_APP__API_URL_RATE_PROXY,
  timeout: 20000,
};
export const rateRbHttpClient = axios.create(rateConfigBase);

export const cmsConfigBase = {
  baseURL: `${process.env.REACT_APP__CMS_HOST}/api/`,
  timeout: 20000,
};
export const cmsHttpClient = axios.create(cmsConfigBase);

const updateAppMeta = () => {
  let _listener;
  return new Promise((resolve) => {
    if (!doesPostMsgExist()) {
      resolve(false);
      return;
    }
    if (!!window.ReactNativeDeviceInfo) {
      setTokenRequestingBody(window.ReactNativeDeviceInfo.data);
      resolve(true);
      return;
    };
    _listener = e => {
      if (!e || !e.data || e.data.eventName !== 'RES_APP_META' || !e.data.data) {
        resolve (false);
        return;
      }
      resolve(true);
    };
    window.addEventListener('message', _listener);
    reqNativeAppMeta();
  }).finally(() => {
    window.removeEventListener('message', _listener);
  });
};

const allstayReqInterceptor = async config => {
  let accessToken = getAccessToken();
  
  /* 유저 정보 상태에 액세스 토큰이 없음 */
  if (!accessToken) {
    await updateAppMeta();
    accessToken = await axios.post( // 비로그인 토큰 요청
      '/public/auth/token', {
      ...getTokenRequestingBody(),
    }, apiConfigBase)
    .then(res => {
      setUserInfo(res.data);
      return res.data.access_token;
    })
    .catch(() => {
      setUserInfo();
      return;
    });

    if (!accessToken) return config; // 비로그인 토큰 요청마저 실패했다면 Authorization 헤더 없이 요청
  };

  // Authorization 헤더가 불필요한 요청 거름
  // if (url.startsWith('/public/hotel/')) {
  //   if (
  //     url.indexOf('/price') > -1 ||
  //     url.indexOf('/review') > -1 ||
  //     url.indexOf('/near-hotel') > -1
  //   ) return config;
  // } else if (!url.startsWith('/public/outlink/') && !url.startsWith('/private/')) return config;

  const Authorization = `Bearer ${accessToken}`;
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization,
    }
  };
};

const allstayResInterceptor = res => {
  if (!!res && !!res.data && !!res.data.access_token && !!res.data.payload && !!res.data.refresh_token) {
    setUserInfo(res.data);
  }
  return res;
}

const allstayResInterceptorErrHandler = async err => {
  if (err.response) {
    const res = err.response;

    /* 액세스 토큰이 유효하지 않은 경우 */
    if (res.status === 401 && res.data.error.code === '401' && res.data.error.message === 'UNAUTHORIZED') {
      const refresh_token = getRefreshToken();
      if (refresh_token) { // 로그인 또는 비로그인 토큰을 갱신 시도
        await updateAppMeta();
        await axios.post(
          '/public/auth/token/refresh', {
          refresh_token,
          ...getTokenRequestingBody(),
        }, apiConfigBase)
        .then(res => setUserInfo(res.data)) // 갱신된 토큰을 전역 상태로 브로드캐스트
        .catch(() => setUserInfo()); // 로그아웃에 준하는 액션
      }
    } 

    /* 로그인 토큰을 써야 하는 api에 비로그인 토큰을 사용한 것으로 간주하는 케이스 */
    else if (
      res.status === 500
      && res.data.error.code === 'UNDEFINED_BINDING_S_DETECTED_WHEN_COMPILING_UPDATE_UNDEFINED_COLUMN_S_ID_QUERY_UPDATE_ALLSTAY_MEMBER_USERS_SET_NAME_PASSWORD_WHERE_ID'
    ) {
      setUserInfo();
    }

  }
  return Promise.reject(err);
};

allstayHttpClient.interceptors.request.use(allstayReqInterceptor);
allstayHttpClient.interceptors.response.use(allstayResInterceptor, allstayResInterceptorErrHandler);

cmsHttpClient.interceptors.request.use(allstayReqInterceptor);
cmsHttpClient.interceptors.response.use(allstayResInterceptor, allstayResInterceptorErrHandler);

export default allstayHttpClient;

export class HttpService {
  httpClient;
  cancelTokenSource;
  options;
  completed;

  constructor(client) {
    this.httpClient = client;
  }

  get(url, queryParams, options={}) {
    this.setOptions(options);
    return this.executeRequest({ method: 'GET', url, queryParams });
  }

  post(url, payload, options={}) {
    this.setOptions(options);
    return this.executeRequest({
      method: 'POST',
      url,
      payload,
    });
  }

  put(url, payload, options={}) {
    this.setOptions(options);
    return this.executeRequest({
      method: 'PUT',
      url,
      payload,
    });
  }

  patch(url, payload, options={}) {
    this.setOptions(options);
    return this.executeRequest({
      method: 'PATCH',
      url,
      payload,
    });
  }

  delete(url, options={}) {
    this.setOptions(options);
    return this.executeRequest({
      method: 'DELETE',
      url,
    });
  }

  cancel(forcely=false) {
    if (!this.completed || forcely) {
      this.cancelTokenSource.cancel(`${this.executeRequest.__url__} is aborted`);
    }
  }

  setOptions(options={}) {
    if (this.options) { 
      this.options = { ...this.options, ...options };
    } else {
      this.options = options;
    }
    this.cancelTokenSource = axios.CancelToken.source();
    this.httpClient = axios.create({ ...(this.httpClient || {}).defaults, ...options, cancelToken: this.cancelTokenSource.token });
    this.completed = false;
  }

  createRequest({ method, url, queryParams, payload }) {
    switch (method) {
      default:
      case 'GET':
        return this.httpClient.get(url, { params: queryParams });
      case 'POST':
        return this.httpClient.post(url, payload);
      case 'PUT':
        return this.httpClient.put(url, payload);
      case 'PATCH':
        return this.httpClient.patch(url, payload);
      case 'DELETE':
        return this.httpClient.delete(url);
    }
  }

  executeRequest({ method, url, queryParams, payload }) {
    this.executeRequest.__url__ = url;
    return new Observable(subscriber => {
      this.createRequest({ method, url, queryParams, payload })
        .then(res => {
          subscriber.next(res);
        })
        .catch(err => {
          console.error(err);
          this?.cancel();
          subscriber?.error(err);
        })
        .finally(() => {
          this.completed = true;
          subscriber.complete();
        });
      });
  }
};
export const httpService = new HttpService();

export class AllstayHttpService extends HttpService {
  constructor(client=allstayHttpClient, retryCount=1, delay) {
    super(client);
    this.retryCount = retryCount;
    this.delay = delay;
  }

  setOptions(options={}) {
    super.setOptions(options);
    this.httpClient.interceptors.request.use(allstayReqInterceptor);
    this.httpClient.interceptors.response.use(allstayResInterceptor, allstayResInterceptorErrHandler);
  }

  executeRequest({ method, url, queryParams, payload, delay }) {
    return new Observable(subscriber => {
      this.createRequest({ method, url, queryParams, payload })
        .then(res => {
          subscriber.next(res);
        })
        .catch(err => {
          console.error(err);
          subscriber.error(err);
          if (checkIsUnahthorizedErr(err)) return;
          this.cancel();
        })
        .finally(() => {
          this.completed = true;
          subscriber.complete();
        });
      }).pipe(
        retry({
          count: this.retryCount,
          delay: this.delay || (err => {
            try {
              if (checkIsUnahthorizedErr(err)) return Promise.resolve(true);
              return Promise.reject(err);
            } catch (caughtErr) {
              return Promise.reject(caughtErr);
            }
          }),
        })
      );
  }
};
export const allstayHttpService = new AllstayHttpService();
export const rateRbHttpService = new AllstayHttpService(rateRbHttpClient, 5);
export const cmsHttpService = new AllstayHttpService(cmsHttpClient, 2);

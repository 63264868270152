import {
  distinctUntilChanged,
  fromEvent,
  map,
  tap,
} from "rxjs";


export const makeReachScrollDepthThresholdsStream = (depthThresholds=[25, 50, 75, 90]) => {
  let subscription;
  const $html = document.querySelector('html');

  const minMaxCoupledDepths = [
    ...depthThresholds.slice(0, depthThresholds.length - 1).map((min, index) => [min, depthThresholds[index + 1]]),
    [depthThresholds[depthThresholds.length - 1]],
  ];
  
  const scrollStream$ = fromEvent(window, 'scroll');
  
  const stream$ = scrollStream$.pipe(
    map(() => {
      if (!$html) return;
      const clientHeight = $html.clientHeight;
      const scrollHeight = $html.scrollHeight;
      const diff = scrollHeight - clientHeight;
      
      if (Math.abs(diff) < 1) return;
      
      const scrollTop = $html.scrollTop;
      const curDepth = scrollTop / diff * 100;

      return minMaxCoupledDepths.find(([min, max=Infinity]) => min <= curDepth && max > curDepth)?.[0];
    }),
    map(min => min || 0),
    distinctUntilChanged((prev, cur) => cur === 0 || (prev === cur)),
    tap(min => {
      const index = minMaxCoupledDepths.findIndex((d) => d[0] === min);
      if (index < 0) return;
      minMaxCoupledDepths.splice(index, 1);
      if (!minMaxCoupledDepths.length) {
        setTimeout(() => subscription?.unsubscribe?.(), 500);
      }
    }),
  );

  return {
    subscribe: (fn) => {
      subscription = stream$?.subscribe?.(fn)
    },
    unsubscribe: () => subscription?.unsubscribe?.(),
  };
};

import { allstayHttpService } from './api';


export const blogPosts = (categoryCode, pageNum) => {
  const params = new URLSearchParams();
  categoryCode && params.set('category', categoryCode);
  pageNum && params.set('page', pageNum);
  const paramString = params.toString();
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/blog${!!paramString ? `?${paramString}` : ''}`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          const { tags, posts } = res.data;
          const result = (tags || posts).map((p) => {
            const date = p?.date || p?.published_at || p?.created_at || p?.updated_at;
            return { ...p, date };
          })
          resolve(result);
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('blogPosts err', err);
        reject(err);
      },
    });
  }
  return new Promise(promiseExecuter);
};

export const blogPost = postId => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/blog/${postId}`)
    .subscribe({
      next: res => {
        if (res.status === 200 && !!res.data.post) {
          resolve({
            ...res.data.post,
            date: res.data.post?.date || res.data.post?.published_at || res.data.post?.created_at || res.data.post?.updated_at,
            seo_meta: {
              ...res.data?.post?.seo_meta,
              url: res.data?.post?.seo_meta?.url || `${window.location.protocol}//${window.location.host}/alltrip/post/${postId}`,
              image: res.data?.post?.seo_meta?.image?.url || res?.data?.post?.image?.url
            },
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('blogPost err', err);
        reject(err);
      },
    });
  };
  return new Promise(promiseExecuter);
};

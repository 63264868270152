import React from 'react';
function AtSearchList({ children }) {
  return (
    <ol className="flex flex-col gap-[20px]">
      {children}
    </ol>
  );
}

export default AtSearchList;

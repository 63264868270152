import { getSchedule } from '@stream/schedule';

// 프로모션 객체 내에 promotion_period와 check_in_period가 있는지 확인하는 함수
const isPromoPeriod = (promotion) => !!promotion?.promotion_period && !!promotion?.check_in_period

// 프로모션 객체로부터 start, end 그리고 경우에 따라 check_start, check_end를 반환하는 함수
// 하나라도 invalid한 Date 인스턴스가 있으면 false를 반환
const getPromoPeriod = (promotion) => {
  let period;
  if (isPromoPeriod(promotion)) {
    period = { // 숙세페는 이 구조임. 2024-05-27 이대균님께 확인 받음.
      // 숙세페 프로모션 기간
      start: new Date(promotion.promotion_period.start_on),
      end: new Date(promotion.promotion_period.end_on),

      // 프로모션 기간에 구입한 숙박 서비스의 체크인 가능 기간
      check_start: new Date(promotion.check_in_period.start_on),
      check_end: new Date(promotion.check_in_period.end_on),
    };
  } else {
    period = {
      start: new Date(promotion.start_on),
      end: new Date(promotion.end_on),
    };
  }
  if (Object.values(period).some(v => isNaN(v.valueOf()))) return false;
  return period;
}

// 프로모션이 available한지 확인하는 함수
export const isOpenPromotion = ({
  promotion,
  _checkIn=getSchedule()?.[0]?.startDate,
  _bookingDate=new Date(),
  enableSimulation=simulateAugSep2024Ssf(window.location.search)?.isEnabled
}) => {
  const promoPeriod = getPromoPeriod(promotion);
  if (!promoPeriod) return false;
  const { start, end } = promoPeriod;

  const bookingDate = new Date(enableSimulation ? start : _bookingDate);
  if (isNaN(bookingDate.valueOf())) return false;

  let checkIn = new Date(enableSimulation ? start : _checkIn);
  if (isNaN(checkIn.valueOf())) return false;

  if (isPromoPeriod(promotion)) {
    const { check_start, check_end } = promoPeriod;
    if (enableSimulation) checkIn = check_start;
    return !(start > bookingDate || end < bookingDate || check_start > checkIn || check_end < checkIn);
  } else {
    return !(start > bookingDate || end < bookingDate || start > checkIn || end < checkIn);
  }
}


// 두 프로모션 오브젝트를 비교하는 함수를 만들기 위한 함수
const defaultPredOfComparer = (a, b) => Object.entries(a).every(([k, v]) => v === b?.[k]);
const makePromoComparer = (a, pred=defaultPredOfComparer) => (b) => pred(a, b);

// 프로모션 비율 할인액도 고정 할인액으로 환산하고 할인 적용가를 계산하는 함수
export const adjustPromoPrice = (promotion, originalPrice) => {
  const {
    fixed_discount,
    percentage_discount,
  } = promotion;

  const dcOfAugSep2024Ssf = getDcOfAugSep2024Ssf(promotion, parseFloat(originalPrice));
  const adjusted_fixed_discount = dcOfAugSep2024Ssf || (
    percentage_discount ? originalPrice * (percentage_discount / 100) : fixed_discount
  );
  const adjusted_price = originalPrice - adjusted_fixed_discount;

  return {
    adjusted_fixed_discount,
    adjusted_price,
  };
}

/**
 * 이하 2024년 8월, 9월 숙세페를 위한 하드 코드들
 */

// 숙세페 2024년 8월 프로모션의 시작과 끝. 비교 또는 더미데이터를 위한 상수.
export const ssfAugSep2024PromoBase = {
  name: '숙박 세일 페스타',
  promotion_period: {
    start_on: new Date('2024-08-27T00:00:00+09:00'),
    end_on: new Date('2024-09-29T23:59:59+09:00'),
  },
  check_in_period: {
    start_on: new Date('2024-09-09T00:00:00+09:00'),
    end_on: new Date('2024-10-13T23:59:59+09:00'),
  },
};

const innerPredForPeriod = (a, b) => {
  const aPeriod = getPromoPeriod(a);
  const bPeriod = getPromoPeriod(b);
  
  return  Object.entries(aPeriod).every(([k, v]) => {
    let normalizedA = new Date(v).setHours(0, 0, 0, 0);
    let normalizedB = new Date(bPeriod?.[k]).setHours(0, 0, 0, 0);

    if (k === 'check_start') return normalizedA >= normalizedB;
    if (k === 'check_end') return normalizedA <= normalizedB;
    
    return normalizedA === normalizedB;
  });
};

const predForAugSep2024Ssf = (a, b) => a?.name === b?.name && innerPredForPeriod(a, b);

// 2024년 8월, 9월 숙세페인지 확인하는 함수
export const isSsfAugSep2024 = makePromoComparer(ssfAugSep2024PromoBase, predForAugSep2024Ssf);


// promotion이 2024년 5월, 6월 숙세페면 고정할인액(fixed_discound)과 할인적용가격(promo_price)을 반환하는 함수
export const getDcOfAugSep2024Ssf = (promotion, originalPrice) => {
  if (isSsfAugSep2024(promotion)) {
    if (originalPrice >= 70000) return 30000;
    return 20000;
  }
}

// 개발용 시뮬레이션 함수.
export const simulateAugSep2024Ssf = (searchParams) => {
  const simulatedSsf = (new URLSearchParams(searchParams))?.get('simulate_ssf');
  const dummyPromo = simulatedSsf === '202408' || simulatedSsf === '202409' ? ssfAugSep2024PromoBase : undefined;
  const isEnabled = dummyPromo
    && (
      window.location.hostname.startsWith('dev-www')
      || window.location.hostname === 'localhost'
      || window.location.hostname === '127.0.0.1'
    );

  return {
    dummyPromo,
    isEnabled,
  };
};


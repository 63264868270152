import * as _ from 'lodash';
import { useMediaQuery } from '@mui/material';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';
import normalizeCityCode from '@util/normalizeCityCode';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


function InterestedItem({
  type,
  id,
  name,
  star_rating,
  region,
  medium_data,
  hotel_i18ns,
  handleClickRm,
  country_code,
  city_code,
}) {
  const narrowMobileMatches = useMediaQuery('(max-width: 380px)');

  const [sp, setSp] = useState(getSearchParams());

  useEffect(() => {
    subscribeSearchParams(setSp);
  }, []);

  return (
    <Link
      to={`/hotel/${country_code}/${normalizeCityCode(city_code)}/${id}?${sp}`}
      className="flex flex-col items-stretch border-b border-b-[#EEEEEE] gap-[10px] pb-[20px]"
      key={`in-region-stay-${id}`}
    >
      <div
        className="stay-list-item-img flex justify-end items-start rounded-[4px]"
        style={{
          backgroundImage: `url("${medium_data}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: `${narrowMobileMatches ? 130 : 160}px`,
        }}>
        {type !== 'favorite' && !!handleClickRm &&
          <button
            className="p-[7px]"
            onClick={(e) => {
              e.preventDefault();
              handleClickRm();
            }}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="9.5" stroke="white" />
              <g clipPath="url(#clip0_1820_5393)">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.6666 6.27337L13.7266 5.33337L9.99992 9.06004L6.27325 5.33337L5.33325 6.27337L9.05992 10L5.33325 13.7267L6.27325 14.6667L9.99992 10.94L13.7266 14.6667L14.6666 13.7267L10.9399 10L14.6666 6.27337Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1820_5393">
                  <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                </clipPath>
              </defs>
            </svg>

            <span className="sr-only">최근 확인한 숙소 삭제 하기</span>
          </button>
        }
      </div>
      <div className="flex flex-col gap-[5px]">
        <div className="flex justify-between relative font-regular text-[11px] leading-[160%] text-[#777777]">
          <div>
            {region}{Math.round(star_rating) < 1 ? '' : ` · ${Math.round(star_rating)}성급`}
          </div>
          {
            !!handleClickRm &&
            <button
              className="absolute right-0"
              onClick={(e) => {
                e.preventDefault();
                handleClickRm();
              }}
            >
              {type === 'favorite' &&
                <>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 4.20845C10.9082 3.16213 12.2998 2.5 13.7498 2.5C16.3165 2.5 18.3332 4.4782 18.3332 6.99591C18.3332 10.0833 15.5045 12.5994 11.2187 16.4116L11.2082 16.421L9.99984 17.5L8.7915 16.4292L8.75861 16.3998C4.48536 12.5907 1.6665 10.0779 1.6665 6.99591C1.6665 4.4782 3.68317 2.5 6.24984 2.5C7.69984 2.5 9.0915 3.16213 9.99984 4.20845ZM9.99984 15.2929L10.0832 15.2112C14.0498 11.688 16.6665 9.35831 16.6665 6.99591C16.6665 5.36104 15.4165 4.13488 13.7498 4.13488C12.4665 4.13488 11.2165 4.94414 10.7832 6.06403H9.22484C8.78317 4.94414 7.53317 4.13488 6.24984 4.13488C4.58317 4.13488 3.33317 5.36104 3.33317 6.99591C3.33317 9.35831 5.94984 11.688 9.9165 15.2112L9.99984 15.2929Z" fill="#FF3755" />
                    <mask id="mask0_695_11536" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="16">
                      <path d="M13.7498 2.5C12.2998 2.5 10.9082 3.16213 9.99984 4.20845C9.0915 3.16213 7.69984 2.5 6.24984 2.5C3.68317 2.5 1.6665 4.4782 1.6665 6.99591C1.6665 10.0858 4.49984 12.6035 8.7915 16.4292L9.99984 17.5L11.2082 16.421C15.4998 12.6035 18.3332 10.0858 18.3332 6.99591C18.3332 4.4782 16.3165 2.5 13.7498 2.5Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_695_11536)">
                      <rect width="20" height="20" fill="#FF3755" />
                    </g>
                  </svg>
                  <span className="sr-only">찜 취소 하기</span>
                </>
              }

            </button>
          }
        </div>
        <div className="flex flex-col">
          <h3 className="font-bold text-[15px] leading-[160%] text-[#1E1E1E]">{_.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || name}</h3>
          <span className="font-regular text-[11px] leading-[160%] text-[#777777]">{_.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || name}</span>
        </div>
        {/* <div className={`items-baseline flex gap-[5px]`}>
          <span className="font-medium text-[12px] leading-[160%] text-[#4A90E2]">{nights}박</span>
          <strong className="font-bold text-[18px] leading-[140%] text-[#1E1E1E]">
            {
              !price ?
                <Skeleton width={150} animation="wave" /> :
                (price === Infinity ? '예약 문의' : `${formatNum(priceInfo[0]['currency'] === 'KRW' ? Math.ceil(priceInfo[0]['price']) : priceInfo[0]['price'])}${priceInfo[0]['currency'] === 'KRW' ? '원' : priceInfo[0]['currency']} ~`)
            }
          </strong>
        </div> */}
      </div>
    </Link>
  );
}

export default InterestedItem;

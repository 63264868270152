import { BehaviorSubject } from "rxjs";
import { DeviceUUID } from 'device-uuid';
import { postMsgRN, getMsgRN } from '@util/postMsgRN';
import { checkPostingContext, rnDeviceInfo } from '@util/getPostingContext';


const LS_KEY = 'AllstayTokenRequestingBody';

const getInitialState = () => {
  const storedStr = localStorage.getItem(LS_KEY);
  let metaData;
  const rnDeviceInfoObj = rnDeviceInfo()
  try {
    if (!storedStr) {
      throw new Error('no stored meta data');
    }
    metaData = JSON.parse(storedStr);
  } catch(error) {
    const du = new DeviceUUID();
    const parsed = du.parse();
    metaData = {
      channel: checkPostingContext() ? 'aos/ios' : 'web',
      device_id: du.get(),
      os_version: parsed.os,
      app_version: '1.0.0', // 앱 버전은 어디로부터 참조해 와야 하는가?
      device_model: parsed.platform,
      ...rnDeviceInfoObj,
    };
    localStorage.setItem(LS_KEY, JSON.stringify(metaData));
  }

  window?.dataLayer?.push(metaData);

  return metaData;
};

const _ = new BehaviorSubject(getInitialState());
const tokenRequestingBody = _;
export default tokenRequestingBody;
export const subscribeTokenRequestingBody = f => _.subscribe(f);
export const getTokenRequestingBody = () => _.getValue();
export const setTokenRequestingBody = obj => {
  const _obj = {
    ..._.getValue(),
    ...obj,
  };
  localStorage.setItem(LS_KEY, JSON.stringify(_obj));
  _.next({ ..._obj });
};
export const setChannel = channel => setTokenRequestingBody({ channel });
export const setDeviceId = device_id => setTokenRequestingBody({ device_id });
export const setOsVersion = os_version => setTokenRequestingBody({ os_version });
export const setAppVersion = app_version => setTokenRequestingBody({ app_version });
export const setDeviceModel = device_model => setTokenRequestingBody({ device_model });
//[TO-DO] 추후 6.1.1의 앱버전이 나간 뒤 이 밑의 코드는 바로 리팩토링 대상
export const reqNativeAppMeta = () => {
  postMsgRN('REQ_APP_META');
};
getMsgRN();

const __ = new BehaviorSubject(_.getValue());
_.subscribe(__);
export const bodyEmailLogin = __;
export const subscribeBodyEmailLogin = f => __.subscribe(f);
export const getBodyEmailLogin = () => __.getValue();
export const setBodyEmailLogin = obj => __.next({
  ...__.getValue(),
  ...obj,
});
export const setEmail = email => setBodyEmailLogin({ email });
export const setPawssword = password => setBodyEmailLogin({ password });


const ___ = new BehaviorSubject(_.getValue());
_.subscribe(___);
export const bodySnsLogin = ___;
export const subscribeBodySnsLogin = f => ___.subscribe(f);
export const getBodySnsLogin = () => ___.getValue();
export const setBodySnsLogin = obj => ___.next({
  ...___.getValue(),
  ...obj,
});
export const setUid = uid => setBodySnsLogin({ uid });
export const setProvider = provider => setBodySnsLogin({ provider });

reqNativeAppMeta();

import React from "react";
import jwt_decode from "jwt-decode";
import { closeResetModal, setModal } from "@store/modal";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "@service/auth";
import { setUserInfo } from "@stream/userInfo";
import { getBodySnsLogin, setBodySnsLogin } from "@stream/tokenRequestingBody";
import { Helmet } from "react-helmet";
import handleChangeHelmetState from '@util/handleChangeHelmetState';

function AppleSignIn() {
  const token = useLocation().search.split('id_token=').pop();
  const { sub, email, user } = jwt_decode(token);
  const snsInfo = {
    uid: sub,
    provider: 'apple'
  };
  setBodySnsLogin(snsInfo);
  const navigate = useNavigate();
  const showLoginFailModal = () => {
    setModal({
      isClosable: true,
      isOpened: true,
      template: () => (
        <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
          <div className="flex flex-col w-56 gap-6 text-gray-900-100">
            <h2 className="text-modal-h">로그인 실패</h2>
            <p className="text-modal-desc whitespace-normal">애플 로그인에 실패하였습니다. 잠시 후 다시 시도해 주세요.</p>
          </div>
          <button
            className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
            onClick={() => { navigate('/my-profile'); closeResetModal(); }}>
            <span className="w-full">확인</span>
          </button>
        </div>
      ),
    });
  }
  login(getBodySnsLogin())
  .then(res => {
    if (!res || !res.access_token) {
      setUserInfo();
      showLoginFailModal();
      return;
    };
    navigate('/');
    setUserInfo(res);
  })
  .catch(err => {
    if (!!err && !!err.response && !!err.response.status && 404 === err.response.status) {
      const name = user ? user.name.lastName + user.name.firstName : email.split("@")[0]
      const body = {
        user: {
          name: name,
          email_sns: email,
        },
        sns: snsInfo
      };
      navigate('/auth/sns-sign-up', { state: body });
      return;
    }
  });

  return <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
  </>
}

export default AppleSignIn;
import React from 'react';
import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { register } from 'swiper/element/bundle';

register();
function HorizontalScrollContainer({
  id,
  className,
  children,
  setScrollWidth,
  setClientWidth,
  setProgress,
  initialSlide,
  setCurIndex,
  slideToClickedSlide=false,
  setWrapperSize=false,
  slidesOffsetAfter=0,
  slidesOffsetBefore=0,
  speed=300,
  gap=0,
  centeredSlides=false,
  centeredSlidesBounds=false,
  onClickSlides,
  preventClicksPropagation=true,
  preventClicks=true,
}) {
  const swiperElRef = useRef(null);

  useEffect(() => {
    const $swiper = swiperElRef.current;

    const swipeListener = e => {
      const [, progress] = e.detail;

      setProgress && setProgress(progress);
    };

    const initListener = () => {
      const {
        scrollWidth,
        clientWidth,
      } = $swiper;
      
      setScrollWidth && setScrollWidth(scrollWidth);
      setClientWidth && setClientWidth(clientWidth);
    };
    
    $swiper.addEventListener('resize', initListener);
    $swiper.addEventListener('progress', swipeListener);

    return () => {
      $swiper.removeEventListener('progress', swipeListener);
      $swiper.removeEventListener('resize', initListener);
    }
  }, [setProgress, setScrollWidth, setClientWidth, swiperElRef]);

  useEffect(() => {
    if (!swiperElRef?.current || !onClickSlides || !onClickSlides?.length) return;
    const $swiper = swiperElRef.current;

    const once = (e) => {
      if ((typeof e.clickedIndex !== 'number') && (typeof e.clickedIndex !== 'string')) {
        $swiper.swiper.once('click', once);
        return;
      };
      onClickSlides?.[e.clickedIndex](e);
    };
    $swiper.swiper.once('click', once);
  }, [swiperElRef, speed, setCurIndex, onClickSlides]);

  useEffect(() => {
    if (!swiperElRef?.current) return;
    const $swiper = swiperElRef.current;
    setTimeout(() => {
      $swiper.swiper?.update();
      $swiper.swiper?.slideTo(initialSlide, speed);
    }, 50);
  }, [swiperElRef, initialSlide, speed]);


  return (
    <swiper-container
      id={id}
      slide-to-clicked-slide={slideToClickedSlide}
      initial-slide={initialSlide}
      speed={speed}
      class={className}
      ref={swiperElRef}
      direction="horizontal"
      slides-per-view="auto"
      set-wrapper-size={setWrapperSize}
      free-mode={true}
      space-between={gap}
      centered-slides={centeredSlides}
      centered-slides-bounds={centeredSlidesBounds}
      slides-offset-after={slidesOffsetAfter}
      slides-offset-before={slidesOffsetBefore}
      prevent-clicks-propagation={preventClicksPropagation}
      prevent-clicks={preventClicks}
      // free-mode-momentum="true"
      // mousewheel="true"
      // mousewheel-force-to-axis="true"
      // mousewheel-threshold-delta="10"
      // mousewheel-release-on-edges="true"
      // speed="10"
      // style={{
      //   marginLeft: 0,
      //   // width: 'fit-content',
      //   maxWidth: '100%',
      //   // overflow: 'hidden'
      // }}
    >
      {children}
    </swiper-container>
  );
};

HorizontalScrollContainer.propTypes = {
  /**
   * 자식 엘리먼트 입니다.
  */
  children: PropTypes.arrayOf(PropTypes.element),
  /**
   * 실시간으로 변경되는 스크롤 프로그레스 값을 받는 함수 입니다.
  */
  setProgress: PropTypes.func,
  /**
   * 전체 스크롤 너비 값을 받는 함수 입니다.
  */
  setScrollWidth: PropTypes.func,
  /**
   * 전체 스크롤 너비 중 가시적인 부분의 너비 값을 받는 함수 입니다.
  */
  setClientWidth: PropTypes.func,
};

HorizontalScrollContainer.defaultProps = {};

export default HorizontalScrollContainer;
import React from 'react';

function SectionHeading({ children }) {
  return (
    <h3
      className="font-bold text-[13px] leading-[160%] text-[#1E1E1E]"
    >
      {children}
    </h3>
  );
}

export default SectionHeading;

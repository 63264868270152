import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useRef, useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setModal, closeResetModal } from '@store/modal';
import { login } from '@service/auth';
import { getTokenRequestingBody, reqNativeAppMeta } from '@stream/tokenRequestingBody';
import LoadingButton from '@mui/lab/LoadingButton';
import { Helmet } from 'react-helmet';

const ModalEmailLoginFailed = ({
  heading,
  description,
  caption='확인',
  onClick,
}) => {
  const _onClick = () => {
    closeResetModal();
    onClick && onClick();
  };

  return (
    <div className="bg-white-100 rounded flex flex-col items-stretch text-center gap-8 p-modal">
      <div className="flex flex-col w-56 gap-6 text-gray-900-100">
        <h2 className="text-modal-h">{heading}</h2>
        <p className="text-modal-desc whitespace-normal">{description}</p>
      </div>
      <button
        className="text-modal-btn text-sky-blue-400 h-major-button border-t border-gray-100-100"
        onClick={_onClick}
      >
        <span className="w-full">{caption}</span>
      </button>
    </div>
  );
};

function EmailSignIn() {
  const logBtnRef = useRef();
  const [isLogingIn, setIsLogingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    reqNativeAppMeta();
  }, []);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => e.preventDefault();

  const handleClickLoginButton = e => {
    e.preventDefault();
    setIsLogingIn(true);
    login({
      email,
      password: pw,
      ...getTokenRequestingBody(),
    }).then(res => {
      if (!res || !res.access_token) throw new Error('로그인 응답에 액세스 토큰이 없음.');
      if (/^[0-9|a-z|A-Z]{8}$/.test(pw)) {
        setModal({
          isClosable: true,
          isOpened: true,
          data: {
            heading: '임시 비밀번호로 로그인 했어요.',
            description: '비밀번호를 재설정해 주세요.',
            caption: '비밀번호 바꾸러 가기',
            onClick: () => {
              navigate('/my-profile/edit');
            },
          },
          template: ModalEmailLoginFailed,
        });
        return;
      }
      navigate('/');
    })
    .catch(() => {
      setModal({
        isClosable: true,
        isOpened: true,
        data: {
          heading: '로그인에 실패했어요',
          description: '로그인에 실패했습니다. 이메일과 비밀번호를 다시 확인해 주세요.',
        },
        template: ModalEmailLoginFailed,
      });
    })
    .finally(() => setIsLogingIn(false));
  };

  const handleEnterUp = e => {
    if (e.key === 'Enter' && !!logBtnRef && !!logBtnRef.current) logBtnRef.current.click();
  };

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div
      className="
        flex
        flex-col
        justify-start
        items-stretch
        gap-9
        min-h-contents-container
      "
    >
      <div className="px-6 mt-4">
        <IconButton
          onClick={() => navigate('..')}
          size="large"
          style={{ padding: 0 }}
        >
          <ArrowBack />
        </IconButton>
      </div>
      <div
        className="
          flex
          flex-col
          items-stretch
          justify-start
          px-6
          gap-14
        "
      >
        <h2 className="text-screen-title">이메일로 로그인</h2>
        <form className="flex flex-col" onSubmit={e => e.preventDefault()}>
          <div className="mb-10 flex flex-col">
            <TextField
              id="usename"
              name="username"
              label="이메일"
              type="text"
              autoComplete="username"
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value.replace(/[^a-z0-9@\-_.]/ig, ''))}
              onBlur={e => setEmail(e.target.value.replace(/[^a-z0-9@\-_.]/ig, ''))}
              onKeyUp={handleEnterUp}
              disabled={isLogingIn}
            />
          </div>
          <div className="mb-5 flex flex-col gap-2.5">
            <FormControl variant="outlined">
              <InputLabel htmlFor="password-field">비밀번호</InputLabel>
              <OutlinedInput
                id="current-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                value={pw}
                onChange={e => setPw(e.target.value)}
                onKeyUp={handleEnterUp}
                disabled={isLogingIn}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className="flex justify-end">
              <Link
                to="/auth/find-password"
                className="text-sub-link text-gray-600"
              >
                비밀번호를 잊으셨나요?
              </Link>
            </div>
          </div>
          <LoadingButton
            ref={logBtnRef}
            onClick={handleClickLoginButton}
            variant="contained"
            loading={isLogingIn}
            disabled={(email && pw ? false : true) || isLogingIn}
            size="large"
          >
            {email && pw ? '로그인' : '로그인 정보를 입력하세요'}
          </LoadingButton>
        </form>
      </div>
    </div>
    </>
  );
}

export default EmailSignIn;

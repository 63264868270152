import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Detail10, H3 } from '@comp/StyledParagraph';
import { NavLink } from 'react-router-dom';
import { HorizontalItemContainer } from '@comp/HorizontalScroll';
import placeholderImg from '@img/placeholder-img.png';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';
import normalizeCityCode from '@util/normalizeCityCode';


//const formatNum = Intl.NumberFormat('ko-KR').format;

function AtMainItem({
  data,
  index,
}) {

  const [sp, setSp] = useState(getSearchParams());
  
  useEffect(() => {
    subscribeSearchParams(setSp);
  }, []);

  const {
    id,
    name,
    star_rating,
    region,
    medium_data,
    hotel_i18ns,
    country_code,
    city_code,
    pathname,
  } = data;

  return (
    <HorizontalItemContainer
      paddingRight={15}
      paddingLeft={index === 0 ? 20 : 0}
    >
      <NavLink
        to={`${pathname || `/hotel/${country_code}/${normalizeCityCode(city_code)}/${id}`}?${sp}`}
        className="flex flex-col gap-[10px] w-[150px] min-w-[150px] max-w-[150px]"
      >
        <div
          className="w-full h-[110px] rounded-[2px] bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url("${medium_data || placeholderImg}")`,
          }}
        >
          <span className="sr-only">{_.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || name} 대표 이미지</span>
        </div>
        <div
          className="flex flex-col gap-[5px]"
        >
          <Detail10>{ region } · {Math.round(star_rating)}성급</Detail10>
          <div className="flex flex-col whitespace-normal">
            <H3>{_.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || name}</H3>
            <Detail10>{_.find(hotel_i18ns, (o) => o.language_code === 'en_US')?.name || _.find(hotel_i18ns, (o) => o.language_code === 'ko_KR')?.name || name}</Detail10>
          </div>
          {/* {
            typeof data.salePrice === 'number' && data.salePrice >= 0 &&
            <div className="flex items-baseline gap-[5px]">
              <span className="font-medium text-[10px] leading-[160%] text-[#4A90E2]">1박</span>
              <Strong>{formatNum(data.salePrice)}원 ~</Strong>
            </div>
          } */}
        </div>
      </NavLink>
    </HorizontalItemContainer>
  );
}

export default AtMainItem;

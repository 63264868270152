import { Route } from "react-router-dom";
import { Hotel, LegacyNoIndex, Search } from "@page/legacy";
import NotFound404 from '@page/NotFound404';

const LegacyRoute = (
  <Route path="/ko" element={<LegacyNoIndex />}>
    <Route path="hotel/:allstayHotelId" element={<Hotel />} />
    <Route path="list/:type/:allstayCode" element={<Search />} />
    <Route index path="*" element={<NotFound404 />} />
  </Route>
);

export default LegacyRoute;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { getOutlink } from '@service/search';
import { Helmet } from 'react-helmet';

function Outlink() {
  const { hotelId, channel } = useParams();
  const [targetUrl, setTargetUrl] = useState();
  const loc = useLocation().search;
  const searchParams = useMemo(() => {
    return new URLSearchParams(loc);
  }, [loc]);

  const DEFAULT_OTA_COLOR = '#ee3855'

  const OTA_COLOR = {
    expedia: '#072f54',
    ctrip: '#2681ff',
    hotelsdotcom: '#273b7d',
    agoda: '#919191',
    hotelscom: '#ed2741',
    tourvis: '#cd2980',
    MODETOUR: '#0daa62',
  }

  useEffect(() => {
    try {
      document.getElementById('nav-bar').remove();
      document.getElementById("root-body").classList.remove("bg-gray-200");
    } catch (err) {
      console.log(err)
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => window.location.replace(targetUrl), 1500);
    return () => clearTimeout(timer);
  }, [searchParams, targetUrl]);

  useEffect(() => {
    getOutlink({ hotelId, channel, ...Object.fromEntries(searchParams) }).then(res => {
      setTargetUrl(res.data.target_url)
    })
    .catch(() => { })
  }, [channel, hotelId, searchParams]);

  return (
    <>
    <Helmet onChangeClientState={handleChangeHelmetState} />
    <div className="outlink-div">
      <nav className="outlink-top-img">
        <img src={`https://static.allstay.com/channel/${channel}.png`} alt="logo" align="right" />
        <div className="nesting">
          <div className="seprator" style={{ backgroundImage: `linear-gradient(to right, #ff3755, ${OTA_COLOR[channel] || DEFAULT_OTA_COLOR})` }}></div>
          <div className="seprator1 seprator1-expand" />
        </div>

        <div className="hotel-name">{searchParams.get('name')}</div>
        <div className="condition">체크인 : {searchParams.get('checkin')}<br />체크아웃 : {searchParams.get('checkout')} </div>

        <ul className="notice">
          <li>객실 및 가격 정보는 예약 현황, 환율 등에 따라 실시간으로 변동되므로 올스테이 서비스 내의 정보와 차이가 있을 수 있습니다.</li>
          <li>올스테이는 통신판매의 당사자가 아니며, 호텔 검색 결과 구매 및 환불 등에 관련한 의무와 책임은 각 판매사에게 있습니다.</li>
        </ul>
      </nav>
    </div>
    </>
  );
};

export default Outlink;

import handleChangeHelmetState from '@util/handleChangeHelmetState';
import * as _ from 'lodash';
import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import { H2, TipTitle, Desc, FooterLinkLook, Detail12, LabelLook } from '@comp/StyledParagraph';
import { Skeleton } from '@mui/material';

import { SelectedHistoryAtMain } from '@comp/SearchedSuggestionHistory';
import { getSearchedHistory, subscribeSearchedHistory, deleteSearchedHistory } from '@stream/searchedSuggestionHistory';

import { RecentVisitedStayAtMain } from '@comp/RecentVisitedStay';
import { subscribeRecent, getRecent } from '@store/recent';
import { getRecent as reqRecent } from '@service/recent';
import { getFeatured } from '@service/featured';
import { FeaturedAtMain, useFeatured } from '@comp/Featured';

import { blogPosts } from '@service/blog';
import { Recommended } from '@comp/Recommended';

import { ReactComponent as GlobIcon } from '@svg/Images/glob.svg';
import { ReactComponent as MicIcon } from '@svg/Images/mic.svg';
import { ReactComponent as MailIcon } from '@svg/Images/mail.svg';
import { ReactComponent as BubbleIcon } from '@svg/Images/bubble.svg';
import { ReactComponent as MagnifierIcon } from '@svg/Icons/magnifier.svg';
import { ReactComponent as AllstayLogo } from '@svg/Images/Logo/AllstayRenewal.svg';
import { ReactComponent as MyIconInactive } from '@asset/icon/profile-bd.svg';
import { ReactComponent as MyIconActive } from '@asset/icon/profile-fill-1e.svg';

import { setSearchPanelVisibility } from '@stream/searchPanelVisibility';
import { Helmet } from 'react-helmet';
import { checkIsLogedOutUser } from '@util/checkUserLogState';
import { getUserInfo, subscribeUserInfo } from '@stream/userInfo';

const HOSTNAME = process.env.REACT_APP__DEEP_HOST;


function Home() {
  const {
    searchParams,
  } = useOutletContext();
  const [sp, setSp] = searchParams;
  const [searchedSuggestion, setSearchedSuggestion] = useState(getSearchedHistory() || []);
  const [recent, setRecent] = useState(getRecent());
  const [remoteRecent, setRemoteRecent] = useState(recent);
  const [recommend, setRecommend] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [seoMeta, setSeoMeta] = useState(null);
  const featuredCities = useRef();

  const [userInfo, setUserInfo] = useState(getUserInfo());
  const [isLogedOutUser, setIsLogedOutUser] = useState(checkIsLogedOutUser(getUserInfo()));

  useLayoutEffect(() => {
    subscribeSearchedHistory(setSearchedSuggestion);
    subscribeRecent(setRecent);
    blogPosts()
      .then(res => res?.length ? res : [])
      .then(newCmsPosts => _.map(newCmsPosts, ({ id, title, slug, date, image, publisher}) => {
        return {
          id,
          date,
          image: image || '/allstay_logo_og_img_original.png',
          publisher: publisher || 'Allstay',
          slug,
          title,
          isFromNewCMS: false,
        };
      })
    ).then(n => setRecommend(p => [...p, ...n].sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime())));
    // 새 CMS 게시글 리스트 호출 부. 차후 재사용 위해 주석처리함 230925 임기완
    // getPosts()
    //   .then(res => res?.data?.length ? res.data : [])
    //   .then(newCmsPosts => [...newCmsPosts.map(({ id, attributes: {
    //     title, slug, publishedAt, mainImg, locale, author,
    //   }}) => {
    //     return {
    //       id,
    //       date: publishedAt || null,
    //       image: mainImg?.data?.attributes?.url || '/allstay_logo_og_img_original.png',
    //       publisher: author?.data?.attributes?.username || 'Allstay',
    //       slug,
    //       title,
    //       locale,
    //       isFromNewCMS: true,
    //     };
    //   })]
    // ).then(n => setRecommend(p => [...p, ...n].sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime())));
    getFeatured().then(({
      data,
      isOK
    }) => {
      if (isOK) {
        setFeatured(data);
      }
    }).catch(() => setFeatured([]));
  }, []);

  useEffect(() => { window.scroll(0, 0); }, []);
  useEffect(() => {
    const subscription = subscribeUserInfo(u => {
      setUserInfo(u);
      setIsLogedOutUser(checkIsLogedOutUser(u));
    });

    return () => subscription.unsubscribe();
  }, []);

  const {
    nations: featuredNations,
    activeIndex: activeFeaturedIndex,
    activeNation: activeFeaturedNation,
    activeCities: activeFeaturedCities,
    setActiveIndex: setActiveFeaturedIndex,
  } = useFeatured(featured);

  useEffect(() => {
    const _sp = new URLSearchParams(sp)
    const curation_region = _sp.get('curation_region')

    if (!!curation_region && !_.isEmpty(featured)) {
      const index = _.findIndex(featured, ['nation_code', curation_region]);
      if (index < 0) {
        _sp.delete('curation_region');
        setSp(_sp);
        return
      }
      setActiveFeaturedIndex(index);
      featuredCities.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      setSeoMeta(featured[index].seo_meta)
    }
  }, [featured, setActiveFeaturedIndex, sp, setSp]);

  useLayoutEffect(() => {
    const ids = _.map(recent, 'id');

    if (_.head(ids)) {
      reqRecent(ids)
        .then(({
          data,
          isOK
        }) => {
          if (isOK) {
            setRemoteRecent(data.map(rr => {
              const foundRecent = recent.find(lr => lr.id === rr.id);
              if (foundRecent?.pathname) {
                return { ...rr, pathname: foundRecent.pathname };
              }
              return rr;
            }));
            return;
          }
          throw new Error('원격 최근 확인 숙소 불러오기 실패');
        }).catch((err) => {
          setRemoteRecent(recent);
        });
      return;
    }
    setRemoteRecent(recent);
  }, [recent]);

  return (
    <>
      <Helmet onChangeClientState={handleChangeHelmetState}>
        {seoMeta && <title>{seoMeta.title}</title>}
        {seoMeta && <meta name="description" content={seoMeta.description} />}
        {seoMeta && <meta property="og:title" content={seoMeta.og_title || seoMeta.title} />}
        {seoMeta && <meta property="og:description" content={seoMeta.og_description || seoMeta.description} />}
        {seoMeta && <meta property="og:url" content={seoMeta.url} />}
        {seoMeta && <meta property="og:image" content={seoMeta?.image} />}
        {seoMeta && <link rel="canonical" href={seoMeta.url} />}
        {seoMeta && <meta name="twitter:title" content={seoMeta.title} />}
        {seoMeta && <meta name="twitter:description" content={seoMeta.description} />}
        {seoMeta && <meta name="twitter:image" content={seoMeta?.image} />}

        <script type="application/ld+json" class="naver-rich-result">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Allstay",
            "url": "${HOSTNAME}",
            "logo": "https://blog.allstay.com/wp-content/uploads/2020/10/app_icon.png",
            "sameAs": [
                      "https://www.instagram.com/allstay_kr/",
                      "https://www.facebook.com/allstay.kr",
                      "https://post.naver.com/allstay_kr?isHome=1"
                      ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "http://schema.org",
  "@type": "Person",
  "name": "올스테이",
  "url": "${HOSTNAME}",
  "sameAs": [
    "https://www.facebook.com/allstay.kr",
    "https://www.instagram.com/allstay_kr/",
    "https://post.naver.com/allstay_kr"
  ]
          }`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "http://schema.org",
  "@type": "Person",
  "name": "올스테이",
  "url": "${HOSTNAME}",
  "sameAs": [
    "https://www.facebook.com/allstay.kr",
    "https://www.instagram.com/allstay_kr/",
    "https://post.naver.com/allstay_kr"
  ]
}`}
        </script>
      </Helmet>
      <main id="allstay-main">
        <div className="allstay-contents allstay-contents-box">
          <div className="flex flex-col gap-[60px] pt-[40px] pb-[30px]">
            <div className="flex flex-col gap-[40px] px-[20px]">
              <div className="flex justify-between items-center">
                <h1>
                  <NavLink to="/">
                    <AllstayLogo />
                    <span className="sr-only">Allstay</span>
                  </NavLink>
                </h1>
                <NavLink to="/my-profile">
                  <div className="flex gap-1 text-xs items-center">
                    {userInfo?.payload?.name ? `${userInfo?.payload?.name}님 안녕하세요?` : ''} {isLogedOutUser ? <MyIconInactive /> : <MyIconActive />}
                  </div>
                  <span className="sr-only">클릭하면 마이 페이지로 이동합니다</span>
                </NavLink>
              </div>
              <div className="flex items-center gap-[10px]">
                <MagnifierIcon />
                <input
                  className="font-regular text-[13px] leading-[160%] px-[16px] py-[12px] grow bg-[#FFFFFF] border border-[#E0E0E0] rounded-[2px]"
                  placeholder="도시, 숙소 이름으로 찾을 수 있어요."
                  onClick={() => setSearchPanelVisibility(true)}
                />
              </div>
            </div>
            {
              !!searchedSuggestion.length &&
              <div className="flex flex-col gap-[20px]">
                <div className="px-[20px]">
                  <H2>최근 검색</H2>
                </div>
                <div>
                  <SelectedHistoryAtMain.List>
                    {searchedSuggestion.slice(0, 10).map((data, i) => <SelectedHistoryAtMain.Item
                      key={`main-searched-suggestion-item-${data.id}`}
                      data={data}
                      index={i}
                      onClick={() => deleteSearchedHistory(data.id)}
                    />)}
                  </SelectedHistoryAtMain.List>
                </div>
              </div>
            }
            {
              !!remoteRecent.length &&
              <div className="flex flex-col gap-[20px]">
                <div className="px-[20px]">
                  <H2>최근 확인한 숙소</H2>
                </div>
                <div>
                  <RecentVisitedStayAtMain.List>
                    {remoteRecent
                      .slice(0, 10)
                      .map((data, i) => {
                        return <RecentVisitedStayAtMain.Item
                          key={`main-recent-item-${data.id}`}
                          data={{ ...data }}
                          index={i}
                        />;
                      })}
                  </RecentVisitedStayAtMain.List>
                </div>
              </div>
            }
            {!!recommend.filter(d => !!d).length && <Recommended data={recommend}>올스테이 추천 여행</Recommended>}
            <div ref={featuredCities}>
              {
                !!featured.length ?
                  <div className="flex flex-col gap-[20px]" ref={featuredCities}>
                    <div className="px-[20px]">
                      <H2>지금 여행하기 좋은 도시</H2>
                    </div>
                    <div className="flex flex-col gap-[20px]">
                      <div>
                        <FeaturedAtMain.List>
                          {featuredNations.map(({ nation_name, nation_code, seo_meta }, i) => <FeaturedAtMain.Item
                            key={`featured-tab-btn-${i}`}
                            isActive={i === activeFeaturedIndex}
                            index={i}
                            onClick={(event) => {
                              event.stopPropagation();
                              setActiveFeaturedIndex(i);

                              const _sp = new URLSearchParams(sp);
                              _sp.set('curation_region', nation_code);
                              setSp(_sp);

                              setSeoMeta(seo_meta);
                            }}
                          >{nation_name}</FeaturedAtMain.Item>)}
                        </FeaturedAtMain.List>
                      </div>
                      {activeFeaturedCities && <div className="flex flex-wrap gap-[15px] px-[20px]">
                        {
                          activeFeaturedCities.slice(0, 6).map((city, index) => <FeaturedAtMain.Content
                            key={`featured-content-${city.region_id}-${index}`}
                            data={{
                              ...city,
                            }}
                            nationName={activeFeaturedNation}
                          />)
                        }
                      </div>}
                    </div>
                  </div>
                  :
                  <div className="flex flex-col gap-[20px]">
                    <div className="px-[20px]">
                      <H2>지금 여행하기 좋은 도시</H2>
                    </div>
                    <div className="flex px-[20px] gap-[10px]">
                      {_.times(3, index => {
                        return <Skeleton key={`home-skeleton-${index}`} variant="rounded" width={75} height={36} animation="wave" />
                      })}
                    </div>
                    <div className="flex flex-wrap gap-[10px] px-[20px]">
                      {_.times(4, index => {
                        return <div key={`home-skeleton-${index}`} className="flex flex-col gap-[10px] w-[160px] grow">
                          <Skeleton variant="rounded" height={150} animation="wave" />
                          <div className="items-center flex flex-col gap-[1px]">
                            <Skeleton variant="text" width={50} height={20} animation="wave" />
                            <Skeleton variant="text" width={45} height={16} animation="wave" />
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
              }
            </div>
            <div className="flex flex-col gap-[20px] px-[20px] py-[40px] bg-[#FAFAFA]">
              <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] items-center">
                  <GlobIcon />
                  <TipTitle>세상의 모든 숙소를 보여드릴게요.</TipTitle>
                </div>
                <Desc>전세계 유명 여행사의 250만개 숙소를 한번에 비교 검색이 가능해요!</Desc>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] items-center">
                  <MicIcon />
                  <TipTitle>원하는 목적지로 한번에 검색하세요.</TipTitle>
                </div>
                <Desc>도시나 관광지 검색으로 목적지 근처 숙소 모두 비교 검색해보세요.</Desc>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] items-center">
                  <MailIcon />
                  <TipTitle>컨텐츠를 통한 여행의 설렘과 감동을 느껴보세요.</TipTitle>
                </div>
                <Desc>다양한 여행 전문 정보을 확인하고 실시간 숙소 가격 비교까지 경험해보세요.</Desc>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[30px] pb-[40px]">
            <div className="flex flex-col gap-[30px] px-[20px] py-[30px]">
              <div className="flex flex-col gap-[10px]">
                <H2>저희가 도와드릴게요!</H2>
                <Desc>
                  평일 10:00~19:00 사이에 응대가 가능해요. <br />
                  주말과 공휴일은 휴무입니다.
                </Desc>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-[10px]">
                  <BubbleIcon />
                  <LabelLook>채팅 상담하기</LabelLook>
                </div>
                <a
                  className="flex px-[16px] py-[7px] font-regular text-[13px] text-[#1E1E1E] leading-[160%] border border-[#AEAEAE] bg-[#FFFFFF] rounded-[2px]"
                  href="https://pf.kakao.com/_xdxmEQxd"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  1:1 문의
                </a>
              </div>
            </div>
            <footer className="flex flex-col gap-4 px-[20px] border-t-[1px] border-t-[#EEEEEE] py-[20px]">
              <div className="flex justify-start gap-6">
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/allstay.kr"><img src="https://static.allstay.com/images/web_v2/fb.svg" alt="open-allstay-meta" /><span className="sr-only">올스테이 페이스북</span></a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/allstay_kr/"><img src="https://static.allstay.com/images/web_v2/insta.svg" alt="open-allstay-instagram" /><span className="sr-only">올스테이 인스타그램</span></a>
                <a target="_blank" rel="noreferrer" href="https://post.naver.com/allstay_kr"><img src="https://static.allstay.com/images/web_v2/naver.svg" alt="open-allstay-naver-blog" /><span className="sr-only">올스테이 네이버 블로그</span></a>
              </div>
              <div className="flex flex-col gap-[30px]">
                <div className="flex gap-2 items-end">
                  <NavLink to="/notice"><FooterLinkLook.Regular>공지사항</FooterLinkLook.Regular></NavLink>
                  <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                  <NavLink to="/faq"><FooterLinkLook.Regular>FAQ</FooterLinkLook.Regular></NavLink>
                  <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                  <NavLink to="/terms-of-use"><FooterLinkLook.Regular>이용약관</FooterLinkLook.Regular></NavLink>
                  <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                  <NavLink to="/privacy-policy"><FooterLinkLook.Bold>개인정보 처리방침</FooterLinkLook.Bold></NavLink>

                </div>
                <div className="flex gap-2 items-end">
                  <FooterLinkLook.Bold>올스테이 주식회사</FooterLinkLook.Bold>
                  <FooterLinkLook.Regular>|</FooterLinkLook.Regular>
                  <NavLink to="/about"><FooterLinkLook.Regular>회사소개</FooterLinkLook.Regular></NavLink>
                </div>
              </div>
              <div className="flex flex-col gap-[10px]">
                <div className="flex flex-col">
                  <Detail12>04542</Detail12>
                  <Detail12>서울특별시 중구 명동3길 6 개양빌딩 8층 올스테이</Detail12>
                </div>
                <div className="flex flex-col">
                  <Detail12>대표 : 공연수</Detail12>
                  <Detail12>사업자등록번호 : 114 - 87 - 22959</Detail12>
                  <Detail12>통신판매업신고번호 : 제2022-서울중구-2278호</Detail12>
                  <Detail12>관광사업등록증: 제2022- 000119호</Detail12>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;

import api from './api';

/*
* 호텔 상세 url을 구성하기 위한 country code를 받아오는 함수
*/
export const hotelCode = async id => {
  let data;
  await api.get(`/system/legacy/redirect/hotel/${id}`, {
    headers: {
      "x-api-key": process.env.REACT_APP__API_KEY
    }
  })
    .then(res => data = res.data)
    .catch(err => data = err.response.data);
  return data;
}

/*
* 검색 결과 url을 구성하기 위한 country code, city code를 받아오는 함수
*/
export const countryCode = async (type, code) => {
  let data;
  await api.get(`/system/legacy/redirect/search/${type}/${code}`, {
    headers: {
      "x-api-key": process.env.REACT_APP__API_KEY
    }
  })
    .then(res => data = res.data)
    .catch(err => data = err.response.data);
  return data;
}
import { useState, useMemo } from 'react';

export const useViewWidth = ({
  scrollWidth: sw,
  clientWidth: cw,
  clampWidth,
}) => {
  const [scrollWidth, setScrollWidth] = useState(sw || 0);
  const [clientWidth, setClientWidth] = useState(cw || 0);
  const viewWidth = useMemo(
    () => scrollWidth && clientWidth / scrollWidth * clampWidth,
    [scrollWidth, clientWidth, clampWidth]
  );

  return {
    viewWidth,
    setScrollWidth,
    setClientWidth,
  };
};

export const useProgress = () => {
  const [rawProgress, setRawProgress] = useState(0);
  const progress = useMemo(
    () => parseFloat(rawProgress.toFixed(2)),
    [rawProgress]
  );

  return {
    progress,
    setRawProgress,
  };
};


export const useTransition = () => {
  const [rawTransition, setRawTransition] = useState(0);
  const transition = useMemo(
    () => rawTransition * -1,
    [rawTransition]
  );

  return {
    transition,
    setRawTransition,
  };
};
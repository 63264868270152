import { Subject } from 'rxjs';
import { subscribeIsLogingIn, initIsLogingIn } from '@store/isLogingIn';
import { ModalSpinner } from '@comp/ModalSpinner';

const subject = new Subject();

const DEFAULT = {
  isClosable: false,
  isOpened: false,
  // data: 'test',
  template: ModalSpinner,
  closeCallback: undefined,
};

const INITIAL = {
  ...DEFAULT,
};

let state;

const _init = (initState) => state = { ...(initState || INITIAL) };
const _set = newState => state = { ...state, ...newState };
const _reset = () => state = { ...DEFAULT };
const _get = () => ({ ...state });
const _subscribe = read => subject.subscribe(read);

export const setModal = newState => {
  _set({ ...state, ...newState });
  if (!!newState) {
    if (newState.isOpened) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }
  subject.next(state);
};
export const resetModal = _reset;
export const readModal = _get;
export const readModalIsOpened = () => _get().isOpened;
export const setModalIsOpened = isOpened => {
  _set({ isOpened });
  subject.next(state);
};
export const openModal = () => {
  setModalIsOpened(true);
  document.body.style.overflow = 'hidden';
};
export const closeModal = () => {
  state?.closeCallback?.();
  setModalIsOpened(false);
  document.body.style.overflow = 'auto';
};
export const closeResetModal = () => {
  state?.closeCallback?.();
  _reset();
  subject.next(state);
  document.body.style.overflow = 'auto';
};
export const toggleModal = () => setModalIsOpened(!state.isOpened);
export const setModalData = data => {
  _set({ data });
  subject.next(state);
};
export const readModalData = () => _get().data;
export const setModalTemplate = template => {
  _set({ template });
  subject.next(state);
};
export const readModalTemplate = () => _get().template;
export const subscribeModal = _subscribe;
export const initModal = initState => {
  _init(initState);
  subscribeIsLogingIn(setModalIsOpened);
  initIsLogingIn();
  subject.next(state);
};

const _ = {
  setModal,
  readModal,
  readModalIsOpened,
  setModalIsOpened,
  openModal,
  closeModal,
  toggleModal,
  setModalData,
  readModalData,
  setModalTemplate,
  readModalTemplate,
  subscribeModal,
  initModal,
};

export default _;

import { BehaviorSubject } from "rxjs";
import { delFavorite, getFavorite, registerFavorite } from "@service/favorite";

const INITIAL = {};

let favorite;
let pageNum = 1;
let limit = 10;

const subjectStay = new BehaviorSubject();
const subjectContent = new BehaviorSubject();

export const initFavorite = () => favorite = { ...INITIAL };

export const subscribeStayFavorite = read => subjectStay.subscribe(read);
export const subscribeContentFavorite = read => subjectContent.subscribe(read);

export const registerFavoriteItem = async (refModel, refId) => {
  const result = await registerFavorite(refModel, refId);
  return result;
};

export const getFavoriteList = async (accessToken, forceRefresh = false, refModel) => {
  pageNum = 1;
  limit = 100;

  if (!forceRefresh && !!favorite && !!favorite.bookmarks.length) {
    if (refModel === 'hotel') {
      subjectStay.next(favorite);
    } else if (refModel === 'content') {
      subjectContent.next(favorite);
    }
    return;
  }

  const favoriteList = await getFavorite(pageNum, limit, refModel);
  if (!!favoriteList && favoriteList.isOK) {
    favorite = { ...favoriteList.data };
    if (refModel === 'hotel') {
      subjectStay.next(favorite);
    } else if (refModel === 'content') {
      subjectContent.next(favorite);
    }
  }
};

export const appendFavoriteList = async (accessToken, refModel) => {
  pageNum += 1;

  if (!!favorite && !!favorite.pagination && !!favorite.pagination.page_count) {
    if (favorite.pagination.page_count < pageNum) return;
  }

  const favoriteList = await getFavorite(pageNum, limit, refModel);
  if (!!favoriteList && favoriteList.isOK) {
    let old = [...(!!favorite && !!favorite.bookmarks ? favorite.bookmarks : [])];
    favorite = {
      ...favoriteList.data,
      bookmarks: [
        ...old,
        ...favoriteList.data.bookmarks,
      ],
    };
    if (refModel === 'hotel') {
      subjectStay.next(favorite);
    } else if (refModel === 'content') {
      subjectContent.next(favorite);
    }
  }
  return favoriteList;
};

export const delFavoriteItem = async (favoriteId) => {
  const result = await delFavorite(favoriteId);
  return result;
};

export const getCurrentStayFavoriteList = () => subjectStay.getValue();
export const getCurrentContentFavoriteList = () => subjectContent.getValue();

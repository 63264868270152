import React from 'react';
import * as Moment from 'moment';


function Collapse({ type, title, content, date, isOpened, onClick, noticeId }) {
  return (
    <div>
      <button
        className={`flex w-full p-[20px] gap-[50px]`}
        onClick={(e) => {
          onClick?.(!isOpened ? noticeId : NaN);
          if (!isOpened) {
            const $btn = e.currentTarget;
            setTimeout(() => window.scrollTo(0, $btn.offsetTop), 100);
          }
        }}
      >
        <div className="flex grow gap-[10px]">
          {type === "faq" && <p className='text-[14px] font-bold leading-[22.4px] text-[#1E1E1E]'>Q.</p>}
          {type === "notice" && <div className="flex my-[auto]"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_3101_15344)">
              <path d="M13.6667 2.66666L5 5.90196V9.43137L13.6667 12.6667V2.66666Z" stroke="#1E1E1E" strokeWidth="1.33333" strokeLinejoin="round" />
              <rect x="2.33325" y="6" width="2.66667" height="3.33333" stroke="#1E1E1E" strokeWidth="1.33333" strokeLinejoin="round" />
              <path d="M5 9.33334V12C5 12.7364 5.59695 13.3333 6.33333 13.3333V13.3333C7.06971 13.3333 7.66667 12.7364 7.66667 12V10" stroke="#1E1E1E" strokeWidth="1.33333" strokeLinejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_3101_15344">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg></div>}
          <p className={`${isOpened ? 'font-bold' : ''} text-[14px] leading-[22.4px] text-[#1E1E1E]`}>{title}</p>
        </div>
        <div className="flex my-[auto]">
          {isOpened ?
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.1879 0L10 0.838357L5 6L0 0.838357L0.812103 0L5 4.32329L9.1879 0Z" fill="#2B2E4A" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.812103 6L-7.32915e-08 5.16164L5 4.37114e-07L10 5.16164L9.1879 6L5 1.67671L0.812103 6Z" fill="#2B2E4A" />
            </svg>
          }
        </div>
      </button>
      {isOpened && <><hr className="border-[#F5F5F5]" />
        <div className="content p-[20px]" style={{ whiteSpace: 'pre-line' }}>
          {date && <p className='text-[12px] font-regular leading-[19.2px] text-[#777777]'>{Moment(date).format('YYYY년 MM월 DD일 HH:mm')}</p>}
          <div className='text-[14px] font-regular leading-[22.4px] text-[#1E1E1E]' dangerouslySetInnerHTML={{ __html: content }} />
        </div></>}
      <hr className="border-[#F5F5F5]" />
    </div>
  );
}

export default Collapse;

import { getSuggestedKeyword } from '@service/search';
import {
  debounceTime,
  switchMap,
  filter,
  map,
  BehaviorSubject,
  tap,
} from 'rxjs';

const lsKey = 'rawSearchedWord';
const lsRawSearchedWord = sessionStorage.getItem(lsKey);

const INITIAL = lsRawSearchedWord || '';
const _ = new BehaviorSubject(INITIAL);
const isProg = new BehaviorSubject(false);

export const rawSearchedWord = _;
export const subscribeRawSearchedWord = subscriber => _.subscribe(subscriber);
export const getRawSearchedWord = () => _.getValue();
export const setRawSearchedWord = newValue =>{
  sessionStorage.setItem(lsKey, newValue);
  _.next(newValue);
};

const __ = new BehaviorSubject([]);

export const setSearchedWordProg = newValue =>{
  isProg.next(true);
  sessionStorage.setItem(lsKey, newValue);
  _.next(newValue);
};

rawSearchedWord.pipe(
  debounceTime(isProg.getValue() ? 0 : 300),
  tap(() => isProg.next(false)),
  filter(word => !!word && !!word.length),
  switchMap(word => getSuggestedKeyword(word).then(res => res).catch(err => err)),
  map(({ isOK, data={} }) => {
    if (!isOK) return [];
    const keys = Object.keys(data);
    if (!keys.length) return keys;
    const result = keys.map(k => data[k].map(item => ({ type: k, ...item }))).reduce((acc, cur) => [...acc, ...cur]);
    return result;
  }),
).subscribe(__);

const searchedWord = __;
export const subscribeSearchedWord = subscriber => __.subscribe(subscriber);
export const getSearchedWord = () => __.getValue();
export const setSearchedWord = newValue => __.next(newValue);

export default searchedWord;

import { HorizontalScrollContainer } from '@comp/HorizontalScroll';
import AtMainItem from './AtMainItem';


export const SelectedHistoryAtMain = {
  List: HorizontalScrollContainer,
  Item: AtMainItem,
};

export const SelectedHistory = {
  AtMain: SelectedHistoryAtMain,
};

export default SelectedHistory;

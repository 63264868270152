import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const containerClass = 'w-full h-16 p-5 bg-white justify-start items-center gap-3 inline-flex text-left';
const mainUiClass = 'grow shrink basis-0 text-allstay-1e text-sm font-normal leading-tight';
const subUiClass = 'text-right text-allstay-gray-9e text-sm font-normal leading-tight';

const MainUiTemplate = ({ children }) => <div className={mainUiClass}>{children}</div>;
const SubUiTemplate = ({ children }) => <div className={subUiClass}>{children}</div>

function FullWidthBtn({
  Icon,
  SubFuncUi,
  to,
  onClick,
  children,
  ...rest
}) {
  if (to) {
    if (to.startsWith('http') || to.startsWith('//')) {
      return <Link to={to} className={containerClass} {...rest}>
        <Icon/>
        <MainUiTemplate>{children}</MainUiTemplate>
        {SubFuncUi && <SubUiTemplate>{(typeof SubFuncUi === 'string' ? SubFuncUi : <SubFuncUi className={subUiClass} />)}</SubUiTemplate>}
      </Link>;
    }
    return <NavLink to={to} className={containerClass} {...rest}>
      <Icon/>
      <MainUiTemplate>{children}</MainUiTemplate>
      {SubFuncUi && <SubUiTemplate>{(typeof SubFuncUi === 'string' ? SubFuncUi : <SubFuncUi className={subUiClass} />)}</SubUiTemplate>}
    </NavLink>;
  }
  if (onClick) {
    return <button onClick={onClick} className={containerClass} {...rest}>
    <Icon/>
    <MainUiTemplate>{children}</MainUiTemplate>
    {SubFuncUi && <SubUiTemplate>{(typeof SubFuncUi === 'string' ? SubFuncUi : <SubFuncUi className={subUiClass} />)}</SubUiTemplate>}
    </button>;
  }

  return <div className={containerClass} {...rest}>
    <Icon/>
    <MainUiTemplate>{children}</MainUiTemplate>
    {SubFuncUi && <SubUiTemplate>{(typeof SubFuncUi === 'string' ? SubFuncUi : <SubFuncUi className={subUiClass} />)}</SubUiTemplate>}
  </div>
};

export default FullWidthBtn;

import React from 'react';

function Strong({ children }) {
  return (
    <strong
      className="font-bold text-[14px] leading-[160%] text-[#1E1E1E]"
    >
      {children}
    </strong>
  );
}

export default Strong;

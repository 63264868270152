import React, { useLayoutEffect, useState } from 'react';
import { Slider } from '@mui/material';
import {
  subscribeSlider,
  getSlider,
  setSlider,
} from '@store/priceRange';
import { styled } from '@mui/material/styles';


const formatNum = new Intl.NumberFormat('ko-KR').format;

const AllstaySlider = styled(Slider)({
  color: '#1E1E1E',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#FFFFFF',
    border: '2px solid #1E1E1E',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    color: '#EEEEEE',
    height: 4,
  },
  '& .MuiSlider-markLabel': {
    color: '#1E1E1E',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '160%',
  },
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translateX(0)',
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: 'translateX(-100%)',
  },
  '& .MuiSlider-mark': {
    color: '#EEEEEE',
  },
});


function PriceRangeInput({ minPrice, maxPrice, currency }) {
  const [sliderRange, setSliderRange] = useState(getSlider());
  
  useLayoutEffect(() => {
    subscribeSlider(setSliderRange);
  }, []);

  const handleChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;
    
    const [v1, v2] = newValue;
    
    if (v1 === v2) {
      if (activeThumb === 0) {
        if (v1 + 5 >= 100) {
          const newRange = [100 - 5, 100];
          setSlider(newRange);
          return;
        }
        const newRange = [v1, v1 + 5];
        setSlider(newRange);
        return;
      } else {
        if (v2 - 5 <= 0) {
          const newRange = [0, 0 + 5];
          setSlider(newRange);
          return;
        }
        const newRange = [v2 - 5, v2];
        setSlider(newRange);
        return;
      }
    }
    setSlider(newValue);
  };

  return (
    <div className="flex flex-col gap-[20px]">
      <label className="flex items-baseline gap-[10px] font-regular text-[13px] leading-[160%] text-[#1E1E1E]">
        <span className="font-bold text-[15px]">요금 </span>
        {sliderRange.map((v) => formatNum((maxPrice - minPrice) * v / 100 + minPrice)).join(' ~ ')}
      </label>
      <div className="px-[12px]">
        <AllstaySlider
          value={sliderRange}
          min={0}
          max={100}
          step={5}
          marks={[
            {value: 0, label: `${formatNum(minPrice)}${currency === 'KRW' ? '원' : currency}`},
            {value: 100, label: `${formatNum(maxPrice)}${currency === 'KRW' ? '원' : currency}`}
          ]}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default PriceRangeInput;

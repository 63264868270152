import { allstayHttpService } from './api';

export const getFeatured = async () => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/public/search/recommend-city`)
    .subscribe({
      next: res => {
        if (res.status === 200) {
          resolve({
            isOK: true,
            data: res.data.recommend_city,
          });
          return;
        }
        reject(res);
      },
      error: err => {
        console.error('getFeatured err', err);
        reject(err);
      },
    });
  }
  return new Promise(promiseExecuter);
};

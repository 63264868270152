import React, { useState, useLayoutEffect, useMemo, useEffect, useRef } from 'react';
import {
  RawSearchWordInput,
  ScheduleInput,
  RoomInfoInput,
  SuggestedWordItem,
} from '@comp/SearchInputGroup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { subscribeSearchedWord, getSearchedWord } from '@stream/searchedWord';
import {
  subscribeSchedule,
  getSchedule,
} from '@stream/schedule';
import {
  subscribeRoomInfo,
  getRoomInfo,
} from '@stream/roomInfo';
import {
  subscribeRawSearchedWord,
  getRawSearchedWord,
} from '@stream/searchedWord';

import * as _ from 'lodash';
import { RecentVisitedStayAtSearch } from '@comp/RecentVisitedStay';
import { getRecent, rmAll, subscribeRecent } from '@store/recent';
import { getRecent as reqRecent } from '@service/recent';
import { H2 } from '@comp/StyledParagraph';
import { getFeatured } from '@service/featured';
import { differenceInDays, format } from 'date-fns';
import { ko } from 'date-fns/locale'
import { getSearchPanelVisibility, setSearchPanelVisibility, subscribeSearchPanelVisibility } from '@stream/searchPanelVisibility';

import { useFeatured } from '@comp/Featured';
import { getSearchParams, subscribeSearchParams } from '@stream/searchParams';
import { BackButton } from '@comp/BackButton';


function Search() {
  const navigate = useNavigate();
  const featuredCities = useRef(null);
  const [sp, setSp] = useState(getSearchParams());

  useEffect(() => {
    const subscription = subscribeSearchParams(setSp);
    return () => subscription.unsubscribe();
  }, []);

  const [searchPanelIsOpen, setSearchPanelIsOpen] = useState(getSearchPanelVisibility());

  const [searchedWord, setSearchedWord] = useState(getSearchedWord());
  const [rawSearchWord, setRawSearchWord] = useState(getRawSearchedWord());

  const [recent, setRecent] = useState(getRecent());
  const [remoteRecent, setRemoteRecent] = useState(recent);
  const [isRecentExp, setIsRecentExp] = useState(false);

  const [featured, setFeatured] = useState([]);

  const [schedule, setSchedule] = useState(getSchedule());
  const [roomInfo, setRoomInfo] = useState(getRoomInfo());

  useLayoutEffect(() => {
    subscribeSearchPanelVisibility(setSearchPanelIsOpen);
    subscribeRecent(setRecent);
    subscribeSearchedWord(setSearchedWord);
    subscribeRawSearchedWord(setRawSearchWord);
    subscribeSchedule(setSchedule);
    subscribeRoomInfo(setRoomInfo);

    if (!searchPanelIsOpen) return;
    getFeatured().then(({
      data,
      isOK
    }) => {
      if (isOK) {
        setFeatured(data);
      }
    }).catch(() => setFeatured([]));
  }, [searchPanelIsOpen]);

  useLayoutEffect(() => {
    if (!searchPanelIsOpen) return;

    const ids = _.map(recent, 'id');

    if (_.head(ids)) {
      reqRecent(ids)
        .then(({
          data,
          isOK
        }) => {
          if (isOK) {
            setRemoteRecent(data);
            return;
          }
          throw new Error('원격 최근 확인 숙소 불러오기 실패');
        }).catch((err) => {
          setRemoteRecent(recent);
        });
      return;
    }
    setRemoteRecent(recent);
  }, [recent, searchPanelIsOpen]);

  const [
    nights,
    checkin,
    checkout,
  ] = useMemo(() => {
    const {
      startDate,
      endDate,
    } = schedule[0];
    return [
      Math.abs(differenceInDays(startDate, endDate)),
      format(startDate, 'yy-MM-dd (EEEEE)', { locale: ko }),
      format(endDate, 'yy-MM-dd (EEEEE)', { locale: ko }),
    ];
  }, [schedule]);

  const [
    room,
    adult,
    minor,
  ] = useMemo(() => [
    roomInfo.length,
    roomInfo.reduce((acc, cur) => acc + parseInt(cur.adult), 0),
    roomInfo.reduce((acc, cur) => acc + cur.minor.length, 0),
  ], [roomInfo]);

  const handleClickBackButton = () => setSearchPanelVisibility(false);

  const [isScheduleInputOpened, setIsScheduleInputOpened] = useState(false);
  const [isRoomInfoInputOpened, setIsRoomInfoInputOpened] = useState(false);
  const toggleScheduleInputOpened = () => setIsScheduleInputOpened(v => {
    setIsRoomInfoInputOpened(false);
    return !v;
  });
  const toggleRoomInfoInputOpened = () => setIsRoomInfoInputOpened(v => {
    setIsScheduleInputOpened(false);
    return !v;
  });

  const isSearched = useMemo(() => !!rawSearchWord && !!searchedWord.length && searchedWord.length > 0, [rawSearchWord, searchedWord]);
  const isInputsClosed = useMemo(() => !isScheduleInputOpened && !isRoomInfoInputOpened, [isScheduleInputOpened, isRoomInfoInputOpened]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/search') return
    setSearchPanelVisibility(false);
  }, [location]);

  const {
    nations: featuredNations,
    activeIndex: activeFeaturedIndex,
    activeCities: activeFeaturedCities,
    setActiveIndex: setActiveFeaturedIndex,
  } = useFeatured(featured);

  return (
    <div id="allstay-main" className={`${searchPanelIsOpen ? '' : 'hidden'} absolute inset-0 min-h-screen`}>
      <div className="allstay-contents bg-[#FFFFFF]">
        <div className="flex flex-col top-0 pt-[20px] sticky z-300 bg-white-100 drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]">
          <div className="flex justify-stretch gap-[10px] px-[20px] pb-[20px]">
            {
              isInputsClosed ?
                (<>
                  {location.pathname === '/search' ?
                    <button onClick={() => navigate('/')}>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 17.409V9.68901L10 3.40901L17 9.68901V17.409H3Z" stroke="black" strokeWidth="1.5" />
                        <path d="M10 12.409L10 17.409" stroke="black" strokeWidth="1.5" />
                      </svg>
                    </button>
                    :
                    <BackButton clickBackbutton={handleClickBackButton} />}
                  <RawSearchWordInput />
                </>) : (<div className="flex justify-center items-center relative w-full py-[10px]">
                  <button className="absolute left-[3px]" onClick={() => {
                    if (isScheduleInputOpened) toggleScheduleInputOpened();
                    else toggleRoomInfoInputOpened();
                  }}>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13 1.30929L11.6907 0L6.5 5.19071L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z" fill="#1E1E1E" />
                    </svg>
                    <span className="sr-only">{isScheduleInputOpened ? '체크 인/아웃 날짜' : '객실 및 인원'} 선택 닫기</span>
                  </button>
                  <h2>{isScheduleInputOpened ? '체크 인/아웃 날짜' : '객실 및 인원'} 선택</h2>
                </div>)
            }
          </div>
          <div className="relative flex justify-stretch items-start w-full h-[70px] top-0">
            <button
              onClick={toggleScheduleInputOpened}
              className="absolute left-[33.33%] top-[25px] translate-x-[-50%] translate-y-[-50%] px-[6px] rounded-[4px] bg-[#4A90E2]"
            >
              <h2 className="font-regular text-[10px] leading-[160%] text-[#FFFFFF]">{nights}<span className="font-bold">박</span></h2>
            </button>
            <button
              onClick={toggleScheduleInputOpened}
              className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isScheduleInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
            >
              <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">체크인</h2>
              <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{checkin}</span>
            </button>
            <div className="h-[50px] w-[1px] bg-[#EEEEEE]" />
            <button
              onClick={toggleScheduleInputOpened}
              className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isScheduleInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
            >
              <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">체크아웃</h2>
              <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{checkout}</span>
            </button>
            <div className="h-[50px] w-[1px] bg-[#EEEEEE]" />
            <button
              onClick={toggleRoomInfoInputOpened}
              className={`pt-[5px] w-full h-full flex flex-col justify-start items-center ${isRoomInfoInputOpened ? 'border-b-[2px] border-b-[#4A90E2]' : ''}`}
            >
              <h2 className="font-regular text-[12px] leading-[160%] text-[#777777]">객실 {room}</h2>
              <span className="font-regular text-[12px] leading-[160%] text-[#1E1E1E]">{!!adult && `성인 ${adult}`}{!!minor && `, 어린이 ${minor}`}</span>
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-[60px] pb-[60px] pt-[30px]">
          {isScheduleInputOpened &&
            <div className="flex flex-col px-[20px] gap-[30px] z-800">
              <ScheduleInput />
              <div className="fixed left-0 bottom-0 w-full">
                <div className="m-auto max-w-[480px] h-[80px] py-[15px] px-[20px] bg-[#FFFFFF] border-t border-t-[#F5F5F5]">
                  <button
                    onClick={toggleScheduleInputOpened}
                    className="flex justify-center items-center bg-[#2B2E4A] rounded-[4px] w-full h-[50px] font-bold text-[14px] leading-[160%] text-[#FFFFFF]"
                  >
                    확인하기
                  </button>
                </div>
              </div>
            </div>
          }
          {isRoomInfoInputOpened &&
            <div className="flex flex-col px-[20px] gap-[30px]">
              <RoomInfoInput />
              <div className="fixed left-0 bottom-0 w-full z-800">
                <div className="m-auto max-w-[480px] h-[80px] py-[15px] px-[20px] bg-[#FFFFFF] border-t border-t-[#F5F5F5]">
                  <button
                    onClick={toggleRoomInfoInputOpened}
                    className="flex justify-center items-center bg-[#2B2E4A] rounded-[4px] w-full h-[50px] font-bold text-[14px] leading-[160%] text-[#FFFFFF]"
                  >
                    확인하기
                  </button>
                </div>
              </div>
            </div>
          }
          {
            !isSearched && isInputsClosed &&
            !!remoteRecent && !!remoteRecent.length &&
            <div className="flex flex-col px-[20px] gap-[20px]">
              <div className="flex justify-between items-baseline">
                <H2>최근 확인한 숙소</H2>
                <button
                  onClick={rmAll}
                  className="text-[13px] leading-[160%] text-[#9E9E9E]"
                >
                  전체 삭제
                </button>
              </div>
              <RecentVisitedStayAtSearch.List>
                {
                  remoteRecent
                    .slice(...(isRecentExp ? [0, 10] : [0, 3]))
                    .map((data) => {
                      const _data = {
                        ...recent.find(r => r.id === data.id),
                        ...data,
                      };
                      _data.schedule = _data.schedule || getSchedule();
                      _data.room_info = _data.room_info || getRoomInfo();
                      return <RecentVisitedStayAtSearch.Item
                        key={`search-recent-item-${data.id}`}
                        data={_data}
                      />;
                    })
                }
              </RecentVisitedStayAtSearch.List>
              {
                remoteRecent.length > 3 &&
                <button
                  onClick={() => setIsRecentExp(pre => !pre)}
                  className="text-[13px] leading-[160%] text-[#9E9E9E]"
                >
                  {isRecentExp ? '닫기' : '더보기'}
                </button>
              }
            </div>
          }
          {
            !isSearched && isInputsClosed &&
            !!featured && !!featured.length &&
            <>
              <div className="flex flex-col gap-[60px] px-[20px]">
                <div className="flex flex-col gap-[20px]">
                  <H2>지금 여행하기 좋은 도시</H2>
                  <div className="flex flex-wrap gap-x-[10px] gap-y-[14px]">
                    {
                      featuredNations.map(({ nation_name }, index) => {
                        const baseClass = 'flex flex-nowrap items-center justify-center h-[35px] text-[13px] px-[16px] leading-[160%] rounded-full';
                        const inactiveClass = 'border border-[#E0E0E0] bg-white text-[#1E1E1E] font-regular';
                        const activeClass = 'bg-[#4A90E2] text-[#FFFFFF] font-bold';
                        return (
                          <button
                            key={`featured-tab-btn-${index}`}
                            onClick={() => { setActiveFeaturedIndex(index); featuredCities.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' }); }}
                            className={`${baseClass} ${index === activeFeaturedIndex ? activeClass : inactiveClass}`}
                          >
                            {nation_name}
                          </button>
                        );
                      })
                    }
                  </div>
                  <div className="flex flex-col gap-[20px]" ref={featuredCities}>
                  <div className="flex flex-col gap-[20px]">
                    {
                      activeFeaturedCities.slice(0, 10).map(({
                        region_id,
                        region_code,
                        nation_code,
                        theme_id,
                        title,
                      }, i) => {                      
                        return (
                          <Link
                            key={`search-featured-item-${region_id}-${i}`}
                            to={`/search/${nation_code}/city/${region_code}/curation/${theme_id}?${sp.toString()}`}
                            className="flex items-center gap-[10px]"
                          >
                            <span className="grow leading-[160%] text-[14px] font-regular text-[#1E1E1E]">{title}</span>
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 11L6 6L1 1" stroke="#BDBDBD" strokeWidth="1.5" />
                            </svg>
                          </Link>
                        );
                      })
                    }
                  </div>
                </div>
                </div>
              </div>
            </>
          }
          {
            isSearched && isInputsClosed &&
            <div className="flex flex-col px-[20px] gap-[20px]">
              {searchedWord.map((props, index) =>
                <SuggestedWordItem
                  key={`suggested-keyword-${index}`}
                  {...props}
                />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Search;

import { allstayHttpService } from './api';

export const registerFavorite = (ref_model, ref_id) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .post('/private/bookmark', { ref_model, ref_id })
    .subscribe({
      next: (res) => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        reject(res);
      },
      error: (err) => {
        console.error('registerFavorite error', err);
        reject(err);
      },
    })
  };

  return new Promise(promiseExecuter);
};

export const getFavorite = (page=1, limit=30, ref_model='hotel') => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .get(`/private/bookmark?ref_model=${ref_model}&page=${page}&limit=${limit}`)
    .subscribe({
      next: (res) => {
        if (res.status === 200) {
          resolve({ isOK: true, data: {...res.data} });
          return;
        }
        reject(res);
      },
      error: (err) => {
        console.error('getFavorite error', err);
        reject(err);
      },
    });
  };

  return new Promise(promiseExecuter);
};

export const delFavorite = (favoriteId) => {
  const promiseExecuter = (resolve, reject) => {
    allstayHttpService
    .delete(`/private/bookmark/${favoriteId}`)
    .subscribe({
      next: (res) => {
        if (res.status === 200) {
          resolve({ isOK: true });
          return;
        }
        reject(res);
      },
      error: (err) => {
        console.error('delFavorite error', err);
        reject(err);
      },
    });
  };

  return new Promise(promiseExecuter);
};
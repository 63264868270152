import React from 'react';

function TogglePlainText({isToggled, children }) {
  const containerClass = `text-nowrap box-content w-full h-7 justify-center items-center flex flex-nowrap text-lg leading-7 ${isToggled ? 'text-allstay-gray-1e font-bold border-b-2 border-allstay-gray-1e' : 'text-allstay-gray-77 font-medium'}`;
  return <div className={containerClass}>
    <div className="leading-7">{children}</div>
  </div>;
}

export default TogglePlainText;

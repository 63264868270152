import { Route } from 'react-router-dom';
import {
  Profile,
  MyPage,
  EditProfile,
  ConfirmPassword,
  Alarm,
} from '@page/profile';


const ProfileRouteGroup = (
  <Route path="my-profile" element={<Profile />}>
    <Route index element={<MyPage />} />
    <Route path="edit" element={<ConfirmPassword />}>
      <Route index element={<EditProfile />} />
    </Route>
    <Route path="alarm" element={<Alarm />} />
  </Route>
);

export default ProfileRouteGroup;

import { Snackbar } from "@mui/material";
import { useState } from "react";

const { setModal } = require("@store/modal");
const { getTokenRequestingBody } = require("@stream/tokenRequestingBody");
const { postMsgRN } = require("@util/postMsgRN");
const { default: Share } = require("./Share");
const { default: checkIsMobileWeb } = require("@util/isMobileWeb");


function ShareBtn({
  url,
  image,
  templateUI,
  children,
  iconSize=20,
  className,
  onClick: onClickCallback,
}) {

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  const handleCloseToast = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsToastOpen(false);
  };

  return <>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isToastOpen}
      autoHideDuration={3000}
      onClose={handleCloseToast}
      message={toastMsg}
    />
    <button
      className={className}
      onClick={() => {
        try {
          const { channel } = getTokenRequestingBody();
          if (channel === 'aos' || channel === 'ios') {
            postMsgRN(JSON.stringify({
              eventName: 'WRITE_CLIPBOARD_URL',
              data: {
                url,
              },
            }))
          } else if (checkIsMobileWeb() && navigator.share) {
            navigator.share({ title: children, url: url });
          } else {
            const shareModal = () => {
              setModal({
                isClosable: true,
                isOpened: true,
                data: {
                  title: children,
                  url: url,
                  img: image,
                  setToastMsg,
                  setIsToastOpen,
                },
                template: templateUI || Share,
              })
            }
  
            shareModal()
          }
        } catch(err) {
          console.error(err);
        } finally {
          if (typeof onClickCallback === 'function') {
            onClickCallback();
          }
        }
      }}
    >
      <svg width={iconSize} height={iconSize} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 5.5C11.5 5.71557 11.5227 5.92582 11.5659 6.1285L7.76271 8.03012C7.21273 7.39891 6.40295 7 5.5 7C3.84315 7 2.5 8.34315 2.5 10C2.5 11.6569 3.84315 13 5.5 13C6.40295 13 7.21273 12.6011 7.76271 11.9699L11.5659 13.8715C11.5227 14.0742 11.5 14.2844 11.5 14.5C11.5 16.1569 12.8431 17.5 14.5 17.5C16.1569 17.5 17.5 16.1569 17.5 14.5C17.5 12.8431 16.1569 11.5 14.5 11.5C13.5971 11.5 12.7873 11.8989 12.2373 12.5301L8.43406 10.6285C8.47726 10.4258 8.5 10.2156 8.5 10C8.5 9.78443 8.47726 9.57418 8.43406 9.3715L12.2373 7.46988C12.7873 8.10109 13.5971 8.5 14.5 8.5C16.1569 8.5 17.5 7.15685 17.5 5.5C17.5 3.84315 16.1569 2.5 14.5 2.5C12.8431 2.5 11.5 3.84315 11.5 5.5ZM7 10C7 10.8284 6.32843 11.5 5.5 11.5C4.67157 11.5 4 10.8284 4 10C4 9.17157 4.67157 8.5 5.5 8.5C6.32843 8.5 7 9.17157 7 10ZM14.5 7C15.3284 7 16 6.32843 16 5.5C16 4.67157 15.3284 4 14.5 4C13.6716 4 13 4.67157 13 5.5C13 6.32843 13.6716 7 14.5 7ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z" fill="#1E1E1E" />
        <mask id="mask0_101_12322" style={{ maskType: 'luminance', }} maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.5 5.5C11.5 5.71557 11.5227 5.92582 11.5659 6.1285L7.76271 8.03012C7.21273 7.39891 6.40295 7 5.5 7C3.84315 7 2.5 8.34315 2.5 10C2.5 11.6569 3.84315 13 5.5 13C6.40295 13 7.21273 12.6011 7.76271 11.9699L11.5659 13.8715C11.5227 14.0742 11.5 14.2844 11.5 14.5C11.5 16.1569 12.8431 17.5 14.5 17.5C16.1569 17.5 17.5 16.1569 17.5 14.5C17.5 12.8431 16.1569 11.5 14.5 11.5C13.5971 11.5 12.7873 11.8989 12.2373 12.5301L8.43406 10.6285C8.47726 10.4258 8.5 10.2156 8.5 10C8.5 9.78443 8.47726 9.57418 8.43406 9.3715L12.2373 7.46988C12.7873 8.10109 13.5971 8.5 14.5 8.5C16.1569 8.5 17.5 7.15685 17.5 5.5C17.5 3.84315 16.1569 2.5 14.5 2.5C12.8431 2.5 11.5 3.84315 11.5 5.5ZM7 10C7 10.8284 6.32843 11.5 5.5 11.5C4.67157 11.5 4 10.8284 4 10C4 9.17157 4.67157 8.5 5.5 8.5C6.32843 8.5 7 9.17157 7 10ZM14.5 7C15.3284 7 16 6.32843 16 5.5C16 4.67157 15.3284 4 14.5 4C13.6716 4 13 4.67157 13 5.5C13 6.32843 13.6716 7 14.5 7ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_101_12322)">
          <rect width={iconSize} height={iconSize} fill="#1E1E1E" />
        </g>
      </svg>
    </button>
  </>
  ;
}

export default ShareBtn;
